// http://ejohn.org/blog/fast-javascript-maxmin/
Array.prototype.max = function () {
  return Math.max.apply(Math, this)
}
Array.prototype.min = function () {
  return Math.min.apply(Math, this)
}

// http://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays-in-javascript
Array.prototype.flatten = function () {
  return [].concat.apply([], this)
}

Array.prototype.last = function () {
  return this[this.length - 1]
}

// remove all instances of an item in an array
// return the array with items removed, ruby style (not js style)
Array.prototype.remove = function (item) {
  var index
  while ((index = this.indexOf(item)) > -1) {
    this.splice(index, 1)
  }
  return this
}

// similar to ruby's compact; remove null/undefined/NaN/zero-length (includes "") values from an array
Array.prototype.compact = function () {
  var temp = []
  for (var i = 0; i < this.length; i++) {
    if (!(this[i] === undefined || this[i] === null || !this[i].toString().length || (typeof this === "number" && isNaN(this[i])))) {
      temp.push(this[i])
    }
  }
  return temp
}
;
window.__CreateModel = function (accessible_attributes) {
  this.proto = this

  this.deprecated = function () {
    console.warn("Deprecated method called!")
  }

  this.update = function (attributes, callback) {
    _.each(attributes || {}, function (value, prop) {
      if (_.includes(accessible_attributes, prop)) {
        if (_.includes(this.proto.FREE_TEXT_ATTRIBUTES, prop)) {
          value = window.sanitizeTextForHtml(value)
        }

        if (_.includes(
          this.proto.REQUIRED_FIELDS_THAT_ARE_MOMENTS || [],
          prop
        )) {
          value = moment(value)
        }

        callback && callback(prop, value)
        if (this["set_" + prop]) {
          this["set_" + prop](value)
        } else {
          this[prop] = value
        }
      }
    }, this)

    if (_.includes(accessible_attributes, "client_updated_at")) {
      this.client_updated_at = moment()
    }

    return this
  }

  this.params = function () {
    var o = {}
    _.each(this.proto.COLUMN_NAMES_FOR_SAVE, function (prop) {
      o[prop] = this[prop]
      if (o[prop] && typeof (o[prop]) !== "string" && !_.includes(this.proto.COLUMNS_TO_NOT_CHANGE_TYPE_OF, prop)) {
        if (moment.isMoment(o[prop])) {
          o[prop] = o[prop].format(window.LH.Time.Formats.DateTime)
        } else {
          o[prop] = o[prop].toString()
        }
      }
      if (o[prop] === -1 && _.includes(this.proto.COLUMNS_TO_NULL_IF_NEGATIVE, prop)) {
        o[prop] = null
      }
      if (o[prop] instanceof Array && o[prop].length === 0 && _.includes(this.proto.COLUMNS_TO_EMPTY_STRING_IF_EMPTY_LIST, prop)) {
        o[prop] = ""
      }
    }, this)
    return o
  }
}
;
Date.prototype.ceil = function (minutes) {
  var that = new Date()
  that.setMinutes(Math.ceil(this.getMinutes() / minutes) * minutes)
  that.setSeconds(0)
  that.setMilliseconds(0)
  return that
}
Date.prototype.floor = function (minutes) {
  var that = new Date()
  that.setMinutes(Math.floor(this.getMinutes() / minutes) * minutes)
  that.setSeconds(0)
  that.setMilliseconds(0)
  return that
}
;
/**
 * This file is used to inject environment vars from ruby land
 */

window.env = window.env || {}

window.env.NODE_ENV = window.env.NODE_ENV || 'production'
;
// http://stackoverflow.com/a/2866613/641293
Number.prototype.toCurrency = function (zero_decimals) {
  return window.LH.Currency.from(this, zero_decimals)
}

Number.prototype.toFormattedNumber = function (options) {
  return window.LH.NumberHelpers.toFormattedNumber(this, options)
}

Number.prototype.hours = function () {
  return this * 60 * 60 * 1000
}

Number.prototype.minutes = function () {
  return this * 60 * 1000
}

Number.prototype.seconds = function () {
  return this * 1000
}

// To get the expected outcome please convert to minutes first
// 65.20 => 1hr, 5mins
// (65.20 * 60) => 65hrs, 12 mins
Number.prototype.to_hrs_and_mins = function (hrs_text, mins_text, delimiter) {
  return window.LH.NumberHelpers.toHrsAndMins(this, hrs_text, mins_text, delimiter)
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
Number.isNaN = Number.isNaN || function (value) {
  return typeof value === "number" && isNaN(value)
}
;
if (window.moment) {
  moment.fn.toUTCJSON = moment.fn.toJSON

  // https://momentjs.com/docs/#/displaying/as-json/
  moment.fn.toJSON = function () {
    if (window.env && window.env.NODE_ENV === "development") {
      console.error && console.error([
        "moment#toJSON() will convert your moment to UTC. " +
        "This is almost definitely not what you want. Tanda usually expects no tz DateTimes. " +
        "To correctly format DateTimes for tanda in a webpack context `import * as Time from 'helpers/time'` " +
        "and use moment#format(Time.Formats.DateTime). Outside webpack use moment#format(window.LH.Time.Formats.DateTime). " +
        "If you do want UTC use moment#toUTCJSON().",
      ].join())
    }

    return this.toUTCJSON()
  }
}
;
Object.values = Object.values || function (object) {
  return Object.keys(object).map(function (key) { return object[key] })
}
;
window.on_image_error = function (img, also_bg, show_empty_if_no_image) {
  img = $(img).off("error")
  if (show_empty_if_no_image) {
    img.remove()
  } else {
    if (img.attr("src") !== "/no_image.jpg") {
      img.attr("src", "/no_image.jpg")
    }
  }
  also_bg && img.parents(".photo").css("background-image", "url(/no_image.jpg)")
}
;
window.navigate_to = window.LH.Navigation.goto
window.querystring_get = window.LH.Navigation.querystringGet
window.querystring_set = window.LH.Navigation.querystringSet

// for links to downloadable files (eg. csv exports), show a loading indicator until a cookie is returned along with the file
window.get_file_to_download_with_spinner = function (target, override_navigation) {
  var e = $(target)
  var time = new Date().getTime().toString()
  var url = querystring_set(e.attr("href"), "downloadtimestamp", time)
  e.attr("href", url)

  window.LH.Spin.start()

  // just in case the spinner hasn't already gone, remove after 10 seconds
  var downloadTimerBackup = setTimeout(function () {
    window.LH.Spin.stop()
  }, 20 * 1000)

  var fileDownloadCheckTimer = setInterval(function () {
    var cookie = $.cookie("downloadtimestamp")
    if (cookie === time) {
      window.LH.Spin.stop()
      $.removeCookie("downloadtimestamp")
      clearInterval(fileDownloadCheckTimer)
      clearTimeout(downloadTimerBackup)

      if (e.data("navigate-after-download")) {
        window.navigate_to(e.data("navigate-after-download"))
      }
    }
  }, 500)

  if (override_navigation) {
    setTimeout(function () {
      window.navigate_to(url)
    }, 1) // return false from the click handler and do the "navigation" ourselves
    return false
  } else {
    return true
  }
}

;

String.prototype.capitalise = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.initials = function (num) {
  return window.LH.String.getInitials(this, num)
}

String.prototype.toTimeParts = function () {
  return window.LH.Time.interpret(this)
}

String.prototype.toCurrency = function () {
  // in non-strict mode, typeof this === 'object' -___-
  return window.LH.Currency.from(this.toString())
}
;
$.fn.autoselecter = function () {
  return this.on("click", function () {
    var proceed = confirm("Warning: This will update the award template that all employees are attached to. Are you sure?")
    if (proceed === true) {
      $("select").each(function () {
        $(this).val(this.options[this.options.length - 1].value).trigger("chosen:updated")
      })
      $(".btn-save").click()
    }
  })
}

/*
only fill blank selects:
$('select').each(function() {
  $(this).val() || $(this).val(this.options[ Math.floor(Math.random() * (this.options.length))].value).trigger("chosen:updated")
})

*/
;
$.fn.balanceConfiguration = function () {
  $(this).ready(function () {
    var balanceConfigurationCheckbox = $("#award_balance_configuration_checkbox")
    var awardBalanceConfigurationAdditionalSettings = $(".award_balance_configuration_additonal_settings")

    var awardBalanceShowHide = function () {
      if (balanceConfigurationCheckbox.is(":unchecked")) {
        awardBalanceConfigurationAdditionalSettings.hide()
      } else {
        awardBalanceConfigurationAdditionalSettings.show()
      }
    }

    awardBalanceShowHide()

    balanceConfigurationCheckbox.on("change", function () {
      awardBalanceShowHide()
    })
  })
}
;
if (window.BestInPlaceEditor) {
  BestInPlaceEditor.prototype.__oldUpdate = BestInPlaceEditor.prototype.update
  BestInPlaceEditor.prototype.update = function () {
    if (this.element.find("input.time-input").length) {
      var fixed_input = this.getValue().toTimeParts()
      if (fixed_input) {
        this.element.find("input.time-input").val(fixed_input.join(":"))
      } else {
        this.element.find("input.time-input").val("")
      }
    }
    this.__oldUpdate()
  }
}
;
$.fn.handleBlockRosterPublishing = function () {
  var form = $(this)
  var container = form.find(".outside-effective-dates-js")

  var isBlockRosterPublishingChecked = form.find(".block-roster-publishing-js").prop("checked")

  if (isBlockRosterPublishingChecked) {
    container.css({ visibility: "visible", display: "block" })
  } else {
    container.css({ visibility: "hidden", display: "none" })
    container.find(":checkbox").prop("checked", false)
  }

  form.on("click", ".block-roster-publishing-js", function () {
    if ($(this).is(":checked")) {
      container.css({ visibility: "visible", display: "block" })
    } else {
      container.css({ visibility: "hidden", display: "none" })
      container.find(":checkbox").prop("checked", false)
    }
  })
}
;
$.cascadingSelect = function (options) {
  var $parentField = $(options.parentClass)
  var $loadIcon = $(options.loadIconClass)

  if ($parentField.length && $parentField.chosen()) {
    $parentField.chosen().change(function () {
      repopulateOptions($parentField.val())
    })
  }

  var repopulateOptions = function (newValue) {
    showLoadIcons(true)
    makeRequest(newValue)
      .then(function (response) {
        handleOptions(response)
        showLoadIcons(false)
      })
  }

  var makeRequest = function (param) {
    return new Promise(function (resolve, reject) {
      $.get("/cascading_select/" + options.methodName + "/" + param)
        .success(function (data) {
          resolve(data)
        }).fail(function () {
          reject("Request failed")
        })
    })
  }

  var showLoadIcons = function (shouldShow) {
    shouldShow ? $loadIcon.show() : $loadIcon.hide()
  }

  var handleOptions = function (data) {
    $.each(options.childrenClasses, function (i, child) {
      var $childField = $(child.class)
      var fieldValue = $childField.chosen().val()
      if (fieldValue && fieldValue.length && child.keepSelectedOnChange) {
        $.each($childField.children("option"), function (i, opt) {
          var $opt = $(opt)
          $opt.prop("selected", true)
          if (fieldValue.indexOf($opt.val()) === -1) {
            $opt.remove()
          }
        })
      } else {
        $childField.empty()
        if (!$(child.class + "[multiple]").length) {
          $childField.append($("<option/>").prop("selected", true))
        }
      }
      var $selectField = $(options.lineClass).has(child.class)
      var optionsArray = data[child.collectionNode]
      if ($(optionsArray).length) {
        $selectField.show().removeClass("hidden")
        $.each(optionsArray, function (i, item) {
          var optionItem = item[child.valueNode]
          var optionWithSameValue = $(child.class + " option[value]").toArray().filter(function (opt) {
            if (opt.value == null || optionItem == null) {
              return false
            }

            return opt.value.toString() === optionItem.toString()
          })
          if (optionItem && !optionWithSameValue.length) {
            $childField.append($("<option/>", { value: optionItem, text: optionItem }))
          }
        })
      } else {
        $selectField.hide()
      }
      $childField.trigger("chosen:updated")
    })
  }
}
;
window.confetti  = function (selector, infinite) {
  infinite = infinite || false

  for (var i = 0; i < 150; i++) {
    create(i)
  }

  function create (i) {
    var width = Math.random() * 8
    var height = width * 0.4
    var colourIdx = Math.ceil(Math.random() * 3)
    var colour = "red"

    switch (colourIdx) {
      case 1:
        colour = "yellow"
        break
      case 2:
        colour = "blue"
        break
      default:
        colour = "red"
    }

    $('<div class="confetti-' + i + " confetti-" + colour + '"></div>')
      .css({
        position: "absolute",
        width: width + "px",
        height: height + "px",
        top: -Math.random() * 20 + "%",
        left: Math.random() * 100 + "%",
        opacity: Math.random() + 0.5,
        transform: "rotate(" + Math.random() * 360 + "deg)",
      })
      .appendTo(selector)

    drop(i)
  }

  function drop (x) {
    $(".confetti-" + x).animate(
      {
        top: "200%",
        left: "+=" + Math.random() * 15 + "%",
      },
      Math.random() * 3000 + 3000,
      function () {
        infinite ? reset(x) : $(this).remove()
      }
    )
  }

  function reset (x) {
    $(".confetti-" + x).animate({
      top: -Math.random() * 20 + "%",
      left: "-=" + Math.random() * 15 + "%",
    }, 0, function () {
      drop(x)
    })
  }
}
;
$.fn.costingHoursCalculation = function () {
  $(this).ready(function () {
    var costingHoursSlider = $("#costing_hours_slider")
    var costingHoursContainer = $("#costing_hours_wrapper")

    var secondaryExportShowHide = function () {
      if (costingHoursSlider.is(":unchecked")) {
        costingHoursContainer.hide()
      } else {
        costingHoursContainer.show()
      }
    }

    secondaryExportShowHide()

    costingHoursSlider.on("change", function () {
      secondaryExportShowHide()
    })
  })
}
;
$.fn.CSVDownload = function (table, headers, filename, parse_row) {
  var isFileSaverSupported = false
  try {
    isFileSaverSupported = !!new Blob()
  } catch (e) {}

  if (isFileSaverSupported) {
    return this.click(function () {
      try {
        var data = []
        var parsed_row

        if ($.isFunction(headers)) {
          headers = headers()
        }

        if (headers.length) {
          if (_.isArray(headers[0])) {
            // headers are multidimensional
            _.each(headers, function (header_row) {
              data.push(header_row)
            })
          } else {
            data.push(headers)
          }
        }

        table.rows({ search: "applied" }).data().each(function (row, _) {
          parsed_row = parse_row(row)
          if (parsed_row.length) {
            data.push(parsed_row)
          }
        })

        var blob = new Blob([window.Papa.unparse(data)], { type: "application/csv;charset=utf-8" })
        window.saveAs(blob, filename || "export.csv")
      } catch (e) {
        console.error(e)
        alert(I18n.t("js.reports.csv_download_report_error"))
      }
    })
  } else {
    console.warn("FileSaver is not supported, hiding download button.")
    return this.hide()
  }
}

// tested in test/javascripts/helpers/reporting_text_extractors_test.js
$.CSVDownloadParsers = {
  Leave: function (row) {
    return [$(row[0]).text().trim(), $(row[1]).text().replace(/—/, "-"), row[2].replace(/—/, "-"), $(row[3]).text().trim()]
  },
  _getRosterCompareReportCost: function (cell) {
    var match = cell.match(/[0-9,.]+/)
    if (match && match.length) {
      var matchZero = match[0]
      // handle negatives; they're annoying to regex because there's also a currency symbol in between, idk
      if (cell[0] === "-") {
        return "-" + matchZero
      } else {
        return matchZero
      }
    } else {
      return ""
    }
  },
  _getRosterCompareReportHours: function (cell) {
    var match = cell.match(/[0-9,.]+/)
    if (match && match.length) {
      return match[0]
    } else {
      return ""
    }
  },
  _getRosterCompareReportCellPercentage: function (cell) {
    var match = cell.match(/[-0-9.]+%/)
    if (match && match.length) {
      return match[0]
    } else {
      return ""
    }
  },
  UserAttendance: function (row) {
    var date = $(row[0]).text().replace(/[0-9]{4}-[0-9]{2}-[0-9]{2}/, "")
    var rstart = $(row[1]).attr("title")
    var astart = $(row[2]).attr("title")
    var rfinish = $(row[3]).attr("title")
    var afinish = $(row[4]).attr("title")

    return [date, rstart, astart, rfinish, afinish]
  },
  AttendanceSummary: function (row) {
    return [$(row[0]).text()].concat(_.map([1, 2, 3, 4, 5, 6], function (i) { return row[i] }))
  },
  Generic: function (row) {
    return _.map(row, function (item) {
      if (item[0] === "<") {
        return $(item).text()
      } else {
        return item
      }
    })
  },
  Reports: function (row) {
    var name, cost1, cost2
    var output = row

    try {
      name = $(row[0]).text().trim()
      cost1 = $(row[2]).text().trim()
      cost2 = $(row[6]).text().trim()
    } catch (e) {}

    if (!name || !name.length) {
      name = row[0] // if there is no html applied to this cell than the first way won't work -- this is a fallback
    }
    if (!cost1 || !cost1.length) {
      cost1 = row[2] // if there is no html applied to this cell than the first way won't work -- this is a fallback
    }
    if (!cost2 || !cost2.length) {
      cost2 = row[6] // if there is no html applied to this cell than the first way won't work -- this is a fallback
    }

    output[0] = name
    output[2] = cost1
    output[6] = cost2

    return output
  },
  RosterCompareReports: function (row) {
    var role
    var output = []

    try {
      role = $(row[0]).text().trim()
    } catch (e) {}

    if (!role || !role.length) {
      role = row[0] // if there is no html applied to this cell than the first way won't work -- this is a fallback
    }

    output.push(role)
    output.push($.CSVDownloadParsers._getRosterCompareReportCost(row[1]))
    output.push($.CSVDownloadParsers._getRosterCompareReportHours(row[2]))
    output.push($.CSVDownloadParsers._getRosterCompareReportCost(row[3]))
    output.push($.CSVDownloadParsers._getRosterCompareReportHours(row[4]))

    output.push($.CSVDownloadParsers._getRosterCompareReportCost(row[5]))
    output.push($.CSVDownloadParsers._getRosterCompareReportCost(row[6]))

    if (row[7]) {
      output.push($.CSVDownloadParsers._getRosterCompareReportCost(row[7]))
    }

    if (row[8]) {
      output.push($.CSVDownloadParsers._getRosterCompareReportCellPercentage(row[8]))
    }

    return output
  },
  TimelineReport: function (row) {
    var date
    var employmentTypeOrCost
    var output = []

    try {
      date = row[0].replace(/(<([^>]+)>)/gi, "")
      employmentTypeOrCost = row[1].replace(/(<([^>]+)>)/gi, "")
    } catch (e) {}

    if (!date || !date.length) {
      date = row[0] // if there is no html applied to this cell than the first way won't work -- this is a fallback
    }
    if (!employmentTypeOrCost || !employmentTypeOrCost.length) {
      employmentTypeOrCost = row[1] // if there is no html applied to this cell than the first way won't work -- this is a fallback
    }

    output.push(date)
    output.push(employmentTypeOrCost)

    if (row[2]) { output.push($.CSVDownloadParsers._getRosterCompareReportCost(row[2])) }
    if (row[3]) { output.push($.CSVDownloadParsers._getRosterCompareReportCost(row[3])) }
    if (row[4]) { output.push($.CSVDownloadParsers._getRosterCompareReportCost(row[4])) }
    if (row[5]) { output.push($.CSVDownloadParsers._getRosterCompareReportCellPercentage(row[5])) }
    if (row[6]) { output.push($.CSVDownloadParsers._getRosterCompareReportCost(row[6])) }
    if (row[7]) { output.push($.CSVDownloadParsers._getRosterCompareReportCellPercentage(row[7])) }

    return output
  },
  LeaveBalanceReport: function (row) {
    return [$(row[0]).attr("title"), row[1], $(row[2]).data("bip-value"), row[3], row[4], row[5], row[6]]
  },
}
;
!(function ($) {
  $.fn.ctrlClickPost = function (options) {
    return this.click(function (e) {
      if (e.ctrlKey || e.metaKey) {
        $(this).attr("target", "_blank")
      }
    })
  }
}(window.jQuery))
;
$.fn.handleCustomNotifications = function () {
  var form = $(this)
  var hidden_custom_notifs = form.find("[data-field=custom_qualification_notification_conditions]")
  var custom_notifs = form.find(".custom-notif")
  var all_custom_notifs_container = form.find(".all-custom-notifs")
  var new_notif_target = form.find(".new-notif-target")
  var template = form.find(".custom-notification-template").clone()

  // Using negative index here so we dont have to worry about
  // accidentally selecting an existing custom notification
  // and starting on -2 because findIndex used later on returns -1 for no match
  var new_custom_notif_count = -2

  form.on("click", ".custom-notifications-js", function () {
    if (all_custom_notifs_container.css("display") === "none") {
      $(this).find("#custom_notifications")[0].checked = true
      all_custom_notifs_container.css({ visibility: "visible", display: "block" })
    } else {
      $(this).find("#custom_notifications")[0].checked = false
      all_custom_notifs_container.css({ visibility: "hidden", display: "none" })
    }
  })

  custom_notifs.on("change", function () {
    handleOnChangeEvent($(this))
  })

  var removeCustomNotif = function (notif) {
    // Find the ID from our notif
    var notif_id = notif.find("[data-field=custom_qualification_notification_condition_id]").val()
    var notif_element_index = notif.attr("id")
    var notif_objs_in_hidden_field = JSON.parse(hidden_custom_notifs.val())

    // See if we can find a match in our hidden field
    var matched_notif_index = notif_objs_in_hidden_field.findIndex(function (obj) { return obj.notif_element_index === notif_element_index })

    if (notif_id > 0) {
      // If the ID is > 0, we know it's an existing notif so lets give it a destroy flag
      if (matched_notif_index > -1) {
        notif_objs_in_hidden_field[matched_notif_index] = {
          id: notif_id,
          marked_for_destruction: "true",
        }
      } else {
        // If we can't find a match, we'll just add it to the end
        notif_objs_in_hidden_field.push({
          id: notif_id,
          marked_for_destruction: "true",
        })
      }
    } else if (matched_notif_index === -1) {
      // If we didn't find a match, we can just remove the notif
      notif.remove()
    } else {
      // If we are here we know it's a new notif so lets remove it from the array
      notif_objs_in_hidden_field.splice(matched_notif_index, 1)
    }

    // Update the hidden field
    hidden_custom_notifs.val([JSON.stringify(notif_objs_in_hidden_field)])

    // Remove the notification from the DOM
    notif.remove()
  }

  var addNewCustomNotif = function () {
    var new_custom_notif = template.clone()
    new_custom_notif.removeClass("custom-notification-template")
    new_custom_notif.css({ display: "flex" })

    // Set the ID of the new notif then
    // increment the count so we can keep track of new notifs
    new_custom_notif.attr("id", new_custom_notif_count)
    --new_custom_notif_count

    // Add our onChange event to the new notif element
    new_custom_notif.on("change", function () {
      handleOnChangeEvent(new_custom_notif)
    })

    new_custom_notif.appendTo(new_notif_target)
  }

  var handleOnChangeEvent = function (changedNotif) {
    // Get all the values from the custom notification
    var notif_cadence_type = changedNotif.find("[data-field=custom_qualification_notification_condition_notif_cadence_type]").val().toLowerCase()
    var notif_cadence_time = changedNotif.find("[data-field=custom_qualification_notification_condition_cadence_value_before_notification]").val()
    var notif_id = changedNotif.find("[data-field=custom_qualification_notification_condition_id]").val()
    var notif_element_index = changedNotif.attr("id")

    // Create a new custom notif object from the values
    var notif_obj = {
      id: notif_id,
      notif_element_index: notif_element_index,
      notif_cadence_type: notif_cadence_type,
      cadence_value_before_notification: notif_cadence_time,
    }

    var notif_objs_in_hidden_field = JSON.parse(hidden_custom_notifs.val())
    var matched_notif_index = notif_objs_in_hidden_field.findIndex(function (obj) { return obj.notif_element_index === notif_element_index })

    // If we don't find a match, add the new notif to the array
    if (matched_notif_index === -1) {
      notif_objs_in_hidden_field.push(notif_obj)
    } else {
      // Otherwise, we're updating an existing custom notif
      notif_objs_in_hidden_field[matched_notif_index] = notif_obj
    }

    hidden_custom_notifs.val([JSON.stringify(notif_objs_in_hidden_field)])
  }

  form.on("click", ".add-new-custom-notif", function () {
    addNewCustomNotif()
  })

  form.on("click", ".remove-custom-notif", function () {
    // Second parent is the custom notif container
    removeCustomNotif($(this).parent())
  })
}
;
D3ColorScale = {
  Constants: {
    CHART_DEFAULT_SATURATIONS: [0.71, 0.56],
    CHART_DEFAULT_LIGHTNESS: [0.55, 0.8], // [0.41, 0.86]
    CHART_DEFAULT_RANGES: [
      {
        NAME: "BLUE",
        HUE: 209,
        SATURATION: [0.71, 0.56],
        LIGHTNESS: [0.41, 0.8],
      },
      {
        NAME: "ORANGE",
        HUE: 28,
        SATURATION: [1, 0.6],
        LIGHTNESS: [0.53, 0.8],
      },
      {
        NAME: "RED",
        HUE: 0,
        SATURATION: [0.8, 0.56],
        LIGHTNESS: [0.41, 0.8],
      },
      {
        NAME: "GREEN",
        HUE: 120,
        SATURATION: [0.57, 0.48],
        LIGHTNESS: [0.4, 0.8],
      },
      {
        NAME: "PURPLE",
        HUE: 271,
        SATURATION: [0.39, 0.31],
        LIGHTNESS: [0.55, 0.8],
      },
    ],
    CHART_GREY_RANGE: {
      NAME: "GREY",
      HUE: 0,
      SATURATION: [0, 0],
      LIGHTNESS: [0.35, 0.8],
    },
  },

  HSLScale: function (start, end) {
    this.getColor = function (value) {
      var h = value * (end.h - start.h) + start.h
      var s = value * (end.s - start.s) + start.s
      var l = value * (end.l - start.l) + start.l
      return d3.hsl(h, s, l)
    }

    this.getColorString = function (value) {
      return this.getColor(value).toString()
    }

    this.toRange = function (n) {
      if (n < 2) {
        return [start.toString()]
      } else {
        var that = this
        return _.times(n, function (i) { return that.getColorString(i / (n - 1)) })
      }
    }

    Object.defineProperties(this, {
      start: { get: function () { return start }, set: function (_) { start = _ } },
      end: { get: function () { return end }, set: function (_) { end = _ } },
    })
  },

  ChartGreyRange: function () {
    return new D3ColorScale.HSLScale(
      d3.hsl(D3ColorScale.Constants.CHART_GREY_RANGE.HUE, D3ColorScale.Constants.CHART_GREY_RANGE.SATURATION[0], D3ColorScale.Constants.CHART_GREY_RANGE.LIGHTNESS[0]),
      d3.hsl(D3ColorScale.Constants.CHART_GREY_RANGE.HUE, D3ColorScale.Constants.CHART_GREY_RANGE.SATURATION[1], D3ColorScale.Constants.CHART_GREY_RANGE.LIGHTNESS[1]))
  },

  ChartDefaultHsl: function (n) {
    return [
      d3.hsl(D3ColorScale.Constants.CHART_DEFAULT_RANGES[n].HUE, D3ColorScale.Constants.CHART_DEFAULT_RANGES[n].SATURATION[0], D3ColorScale.Constants.CHART_DEFAULT_RANGES[n].LIGHTNESS[0]),
      d3.hsl(D3ColorScale.Constants.CHART_DEFAULT_RANGES[n].HUE, D3ColorScale.Constants.CHART_DEFAULT_RANGES[n].SATURATION[1], D3ColorScale.Constants.CHART_DEFAULT_RANGES[n].LIGHTNESS[1]),
    ]
  },

  ChartDefaultRange: function (n) {
    var hslRange = D3ColorScale.ChartDefaultHsl(n)
    return new D3ColorScale.HSLScale(hslRange[0], hslRange[1])
  },
}
;
// Code based on the datatables natural sort plugin
// Provides sorting on alpha numeric fields like
// 1, "1", "01", "ABC123", "123ABC", 1.2
// Does not do hex or dates, that code was removed.
// Any numbers, even zero padded ones are converted to floats and compared. So 1 == '01' and '02' < 3.
// In your datatable options give the column the type "alphanumeric"
/*! © SpryMedia Ltd, Jim Palmer, Michael Buehler, Mike Grier, Clint Priest, Kyle Adams, guillermo - datatables.net/license */

(function (factory) {
  // eslint-disable-next-line no-undef
  if (typeof define === "function" && define.amd) {
    // AMD
    // eslint-disable-next-line no-undef
    define(["jquery", "datatables.net"], function ($) {
      return factory($, window, document)
    })
  }
  else if (typeof exports === "object") {
    // CommonJS
    var jq = require("jquery")
    var cjsRequires = function (root, $) {
      if (!$.fn.dataTable) {
        require("datatables.net")(root, $)
      }
    }

    if (typeof window !== "undefined") {
      module.exports = function (root, $) {
        if (!root) {
          // CommonJS environments without a window global must pass a
          // root. This will give an error otherwise
          root = window
        }

        if (!$) {
          $ = jq(root)
        }

        cjsRequires(root, $)
        return factory($, root, root.document)
      }
    }
    else {
      cjsRequires(window, jq)
      module.exports = factory(jq, window, window.document)
    }
  }
  else {
    // Browser
    factory(jQuery, window, document)
  }
}(function ($, window, document) {
  "use strict"

  var DataTable = $.fn.dataTable

  /*! © SpryMedia Ltd, Jim Palmer, Michael Buehler, Mike Grier, Clint Priest, Kyle Adams, guillermo - datatables.net/license */
  function alphaNumericSort(a, b) {
    // convert all to strings and trim()
    var sre = /(^[ ]*|[ ]*$)/g
    var x = (a || "").toString().replace(sre, "") || ""
    var y = (b || "").toString().replace(sre, "") || ""

    // chunk/tokenize
    var re = /(^-?[0-9]+(\.?[0-9]*)[df]?e?[0-9]?%?$|^0x[0-9a-f]+$|[0-9]+)/gi
    var xN = x
      .replace(re, "\0$1\0")
      .replace(/\0$/, "")
      .replace(/^\0/, "")
      .split("\0")

    var yN = y
      .replace(re, "\0$1\0")
      .replace(/\0$/, "")
      .replace(/^\0/, "")
      .split("\0")

    // natural sorting through split numeric strings and default strings
    var num = /^\d/
    for (var cLoc = 0, numS = Math.max(xN.length, yN.length); cLoc < numS; cLoc++) {
      // convert numbers to floats, even zero padded ones.
      var oFxNcL = ((xN[cLoc] || "").match(num) && parseFloat(xN[cLoc])) || xN[cLoc] || 0
      var oFyNcL = ((yN[cLoc] || "").match(num) && parseFloat(yN[cLoc])) || yN[cLoc] || 0

      // handle numeric vs string comparison - number < string - (Kyle Adams)
      if (isNaN(oFxNcL) !== isNaN(oFyNcL)) {
        return isNaN(oFxNcL) ? 1 : -1
      }
      // rely on string comparison if different types - i.e. 'ABC' < 123
      else if (typeof oFxNcL !== typeof oFyNcL) {
        oFxNcL = String(oFxNcL)
        oFyNcL = String(oFyNcL)
      }
      if (oFxNcL < oFyNcL) {
        return -1
      }
      if (oFxNcL > oFyNcL) {
        return 1
      }
    }
    return 0
  }
  DataTable.ext.type.order["alphanumeric-asc"] = function (a, b) {
    return alphaNumericSort(a, b)
  }
  DataTable.ext.type.order["alphanumeric-desc"] = function (a, b) {
    return alphaNumericSort(a, b) * -1
  }

  return DataTable
}))
;
/* https://www.datatables.net/examples/plug-ins/dom_sort */

if ($.fn.dataTable) {
  $.fn.dataTable.ext.order["dom-data-sort-int"] = function (settings, col) {
    return this.api().column(col, { order: "index" }).nodes().map(function (td) {
      var num = +$(td).data("sort-int")
      if (Number.isNaN(num)) {
        return -1
      } else {
        return num
      }
    })
  }

  $.fn.dataTable.ext.order["dom-text-numeric"] = function (settings, col) {
    var result = this.api()
      .column(col, { order: "index" })
      .nodes()
      .map(function (td, i) {
        if(td.innerText === ""){
          return -10
        }
        return parseInt(td.innerText) || -1
      })
    return result
  }

  // based on https://datatables.net/plug-ins/filtering/type-based/phoneNumber
  $.fn.DataTable.ext.type.search.hoverableFilter = function (data) {
    var hovertext
    var first_line = data.split("\n")[0]

    try {
      hovertext = $(data).data("hovertext")
    } catch (e) {}

    return first_line + " " + (hovertext || "")
  }

  $.fn.DataTable.ext.type.search.idList = function (data) {
    try {
      return $(data).data("idlist")
    } catch (e) {
      return ""
    }
  }
} else {
  console.log("Tried to define $.dataTable sorters, but $.dataTable is not defined!")
}
;
(function ($) {
  $.fn.pandaDatepicker = function (opts) {
    return $(this).each(function () {
      var $el = $(this)
      var querystring_start_key = $el.data("start-key") || "start"
      var querystring_end_key = $el.data("end-key") || "end"

      opts = _.extend({ reload_on_change: true }, opts)

      /**
       * On a page load, it checks the URL to see
       * if a date has been selected. If it has it
       * loads the date
       */
      var loadDate = function () {
        if (querystring_get(querystring_start_key) && querystring_get(querystring_end_key)) {
          var startMoment = moment(querystring_get(querystring_start_key))
          var endMoment = moment(querystring_get(querystring_end_key))

          if (startMoment.isValid() && endMoment.isValid()) {
            showSelectedDateRange(startMoment, endMoment)
          }
        }
      }

      var showSelectedDateRange = function (start, end) {
        if (end.diff(start, "days") === 0) {
          $el.find("span:not(.caret)").html(start.format("MMM D, YYYY"))
        } else {
          $el.find("span:not(.caret)").html(start.format("MMM D, YYYY") + " - " + end.format("MMM D, YYYY"))
        }
      }

      var dateRangeSelected = function (start, end) {
        var url = window.location.href
        url = querystring_set(url, querystring_start_key, start.format("YYYY-MM-DD"))
        url = querystring_set(url, querystring_end_key, end.format("YYYY-MM-DD"))
        if (opts.additional_params_to_set_on_reload) {
          Object.entries(opts.additional_params_to_set_on_reload).forEach(function (entry) {
            url = querystring_set(url, entry[0], entry[1])
          })
        }
        showSelectedDateRange(start, end)
        if (opts.reload_on_change) {
          window.navigate_to(url)
        } else {
          window.history.replaceState({}, "", url)
        }
      }

      var yesterday
      var current_pay
      var prev_pay
      var fin_year
      var prev_fin_year
      var cal_year
      var prev_twelve_months
      var prev_cal_year
      var prev_month
      var first_period
      var current_and_future
      var all_pending_leave
      var report_user_first_to_last

      if ($el.attr("yesterday")) { yesterday = JSON.parse($el.attr("yesterday")) }
      if ($el.attr("current_period")) { current_pay = JSON.parse($el.attr("current_period")) }
      if ($el.attr("previous_period")) { prev_pay = JSON.parse($el.attr("previous_period")) }
      if ($el.attr("prev_month")) { prev_month = JSON.parse($el.attr("prev_month")) }
      if ($el.attr("fin_year")) { fin_year = JSON.parse($el.attr("fin_year")) }
      if ($el.attr("prev_fin_year")) { prev_fin_year = JSON.parse($el.attr("prev_fin_year")) }
      if ($el.attr("prev_twelve_months")) { prev_twelve_months = JSON.parse($el.attr("prev_twelve_months")) }
      if ($el.attr("cal_year")) { cal_year = JSON.parse($el.attr("cal_year")) }
      if ($el.attr("prev_cal_year")) { prev_cal_year = JSON.parse($el.attr("prev_cal_year")) }
      if ($el.attr("first_period")) { first_period = JSON.parse($el.attr("first_period")) }
      if ($el.attr("current_and_future")) { current_and_future = JSON.parse($el.attr("current_and_future")) }
      if ($el.attr("all_pending_leave")) { all_pending_leave = JSON.parse($el.attr("all_pending_leave")) }
      if ($el.attr("report_user_first_to_last")) { report_user_first_to_last = JSON.parse($el.attr("report_user_first_to_last")) }

      var ranges = {}
      if (yesterday) { ranges[I18n.t("js.datepick.yesterday")] = [moment(yesterday[0]), moment(yesterday[1])] }
      if (current_pay) { ranges[I18n.t("js.datepick.this_pay_period")] = [moment(current_pay[0]), moment(current_pay[1])] }
      if (prev_pay) { ranges[I18n.t("js.datepick.last_pay_period")] = [moment(prev_pay[0]), moment(prev_pay[1])] }
      if (prev_month) { ranges[I18n.t("js.datepick.last_month")] = [moment(prev_month[0]), moment(prev_month[1])] }
      if (fin_year) { ranges[I18n.t("js.datepick.fin_year")] = [moment(fin_year[0]), moment(fin_year[1])] }
      if (prev_fin_year) { ranges[I18n.t("js.datepick.prev_fin_year")] = [moment(prev_fin_year[0]), moment(prev_fin_year[1])] }
      if (prev_twelve_months) { ranges[I18n.t("js.datepick.prev_twelve_months")] = [moment(prev_twelve_months[0]), moment(prev_twelve_months[1])] }
      if (cal_year) { ranges[I18n.t("js.datepick.cal_year")] = [moment(cal_year[0]), moment(cal_year[1])] }
      if (prev_cal_year) { ranges[I18n.t("js.datepick.prev_cal_year")] = [moment(prev_cal_year[0]), moment(prev_cal_year[1])] }
      if (first_period) { ranges[I18n.t("js.datepick.first_period")] = [moment(first_period[0]), moment(first_period[1])] }
      if (current_and_future) { ranges[I18n.t("js.datepick.current_and_future")] = [moment(current_and_future[0]), moment(current_and_future[1])] }
      if (all_pending_leave && opts.show_all_pending) { ranges[I18n.t("js.datepick.all_pending")] = [moment(all_pending_leave[0]), moment(all_pending_leave[1])] }
      if (report_user_first_to_last) { ranges[I18n.t("js.datepick.report_user_first_period", { user_name: report_user_first_to_last[2] })] = [moment(report_user_first_to_last[0]), moment(report_user_first_to_last[1])] }

      var startDate
      var endDate

      if (querystring_get(querystring_start_key) && moment(querystring_get(querystring_start_key)).isValid()) {
        startDate = moment(querystring_get(querystring_start_key))
      } else if (current_pay) {
        startDate = moment(current_pay[0])
      }

      if (querystring_get(querystring_end_key) && moment(querystring_get(querystring_end_key)).isValid()) {
        endDate = moment(querystring_get(querystring_end_key))
      } else if (current_pay) {
        endDate = moment(current_pay[1])
      }

      var dateRangePickerOptions = {
        startDate: startDate,
        endDate: endDate,
        ranges: ranges,
        opens: opts.datepicker_opens || "left",
        buttonClasses: ["btn btn-default"],
        applyClass: "btn-theme-primary",
        cancelClass: "hide",
        format: "MMM D",
        separator: " to ",
        locale: {
          format: "DD-MM-YYYY",
          applyLabel: I18n.t("js.datepick.submit"),
          cancelLabel: I18n.t("js.datepick.clear"),
          fromLabel: I18n.t("js.datepick.from"),
          toLabel: I18n.t("js.datepick.to"),
          customRangeLabel: I18n.t("js.datepick.custom"),
          daysOfWeek: moment.weekdaysMin(),
          monthNames: moment.months(),
          firstDay: window.current_user ? (window.current_user.week_start || 1) : 1,
        },
      }

      _.extend(dateRangePickerOptions, opts)

      $el.daterangepicker(dateRangePickerOptions, dateRangeSelected)
      loadDate()
    })
  }
}(jQuery))
;
$.fn.enableMonthlyOTAveraging = function (initial_values) {
  var checkbox = $("input#enable-monthly-ot-averaging[type=checkbox]")

  function hideMonthlyOTAveraging () {
    $(".monthly-ot-averaging").find("select").map(function () {
      $(this).val("")
    })
    $(".monthly-ot-averaging").hide()
  }

  checkbox.on("click", function () {
    if (checkbox.is(":checked")) {
      $(".monthly-ot-averaging").show()
      if (initial_values.class === "AwardTemplateOrganisation") {
        $(".monthly-ot-averaging").css("display", "flex").css("visibility", "visible")
      } else {
        $(".monthly-ot-averaging").css("display", "block").css("visibility", "visible")
      }
      $(".overtime-form-section").hide()
    } else {
      hideMonthlyOTAveraging()
      $(".overtime-form-section").show()
    }
  })

  if (initial_values.enabledMonthlyOTAveraging === "true") {
    if (initial_values.class !== "Setting") {
      $(".monthly-ot-averaging").show()
    }

    $(".overtime-form-section").hide()
    checkbox.prop("checked", true)
  } else {
    checkbox.prop("checked", false)
    hideMonthlyOTAveraging()

    if (initial_values.class !== "Setting") {
      $(".overtime-form-section").show()
    }
  }
}
;
$.fn.oldChosen = $.fn.chosen
$.fn.chosen = function (options) {
  var select = $(this)
  var is_creating_chosen = !options || _.isObject(options)

  if (is_creating_chosen) {
    options = options || {}
    if (_.isUndefined(options.search_fuzzy)) {
      options.search_fuzzy = true // default this to true everywhere (the library defaults it to false otherwise)
    }
  }

  _.each(["placeholder_text_multiple", "placeholder_text_single", "no_results_text", "create_option_text"], function (key) {
    if (!options[key]) {
      options[key] = I18n.t("js.chosen." + key)
    }
  })

  var ret = select.oldChosen(options)

  // Only apply additional CSS if chosen successfully initialised.
  // e.g. Chosen.js is not supported for mobile! https://github.com/harvesthq/chosen/pull/1388
  if (!select.data("chosen")) {
    return
  }

  if (is_creating_chosen && select.css("position") === "absolute") {
    // if we are creating a chosen and the select already has the appropriate styles added
    // we remove those (so that the select hasn't got a crazy width), then create the chosen
    // then we re-add them later
    select.removeAttr("style")
  }

  if (is_creating_chosen) {
    // https://github.com/harvesthq/chosen/issues/515#issuecomment-33214050
    // only do this if we are initializing chosen (no params, or object params) not calling a method
    select.attr("style", "display:block; overflow: hidden; position:absolute; clip:rect(0,0,0,0)")
  }
  return ret
}
;
$.fn.teamsAndLocationsRosters = function () {
  $(this).ready(function () {
    var fullRosterCheckBox = $("#setting_enable_full_rosters")
    var selectElement = $("#setting_enable_full_location_rosters")
    var originalValue = selectElement.val()

    if (fullRosterCheckBox.is(":unchecked")) {
      selectElement.hide()
    }

    fullRosterCheckBox.on("change", function () {
      if ($(this).is(":checked")) {
        selectElement.show()
        selectElement.val(originalValue)
      } else {
        selectElement.val(0)
        selectElement.hide()
      }
    })
  })
}
;
window.GenericStatusChecker = function (prefix, cache_key, redirect_to, interval, update_at, custom_text) {
  var progress_bar_text = $("#progressBarText")
  var progress_bar = $(".progress .bar")
  var previous_page = document.referrer
  var item = prefix.split("/")[0].slice(0, -1) || "item"
  var action = prefix.split("/")[1] || null
  var last_num

  var check_status = function () {
    $.ajax({
      type: "GET",
      url: "/" + prefix + "/check_status?cache_key=" + cache_key,
      success: function (data) {
        if (data === "error") {
          clearInterval(status_check)
          alert(I18n.t("js.generic_components.status_checker.error"))
          if (_.isFunction(redirect_to)) { redirect_to() } else { window.location.href = redirect_to }
        } else if (data === "export_failed") {
          clearInterval(status_check)
          alert(I18n.t("js.generic_components.status_checker.error"))
          window.location.href = previous_page
        } else if (data !== "blank_should_retry") {
          var num = parseFloat(data)
          var count = null
          var current = null

          if (custom_text) {
            if (action === "import" && !progress_bar_text.text()) {
              progress_bar_text.text(I18n.t("js.generic_components.status_checker.initial_import_text", { item: item }))
            }

            if (isNaN(num)) {
              var parsed_data = JSON.parse(data) // custom JSON payload

              if (parsed_data.value) {
                num = parsed_data.value
                count = parsed_data.count
                current = parsed_data.current

                if (action === "import" && current) {
                  progress_bar_text.text(
                    I18n.t("js.generic_components.status_checker.import_text", { item: item , count: count, current: current })
                  )
                }
              }
            } else if (num < 0.9) {
              progress_bar_text.text(I18n.t("js.generic_components.status_checker.progress_text", { num: (num * 100).toFixed(1) }))
            } else if (num === 0.9) {
              progress_bar_text.text(I18n.t("js.generic_components.status_checker.finish_text"))
            }
          }

          if (num !== last_num) {
            num = Math.round(num * 100) / 100 // 2 decimal places

            // we're done if it's some sort of data (not a number)
            // don't finish up until the progress text has updated
            if (isNaN(num) && (!custom_text || data === "true")) {
              clearInterval(status_check)
              if (_.isFunction(redirect_to)) {
                redirect_to()
              } else {
                progress_bar.stop().animate({ width: "100%" }, 500)
                setTimeout(function () {
                  window.location.href = redirect_to || data
                }, 1000)
              }
            } else if (num >= Math.max(last_num || 0, update_at || 0)) {
              progress_bar.stop().animate({ width: (num * 100) + "%" }, 500)
            }
            last_num = num
          }
        }
      },
    })
  }

  var status_check = setInterval(check_status, interval || 3000)

  // Remove the default bootstrap animation on progress bars as the status checker will animate it for us
  progress_bar.addClass("no-transition")

  check_status()
}
;
$.fn.restrictTotalHours = function (options) {
  var input = $(options.input)

  return this.on("change chosen:updated", function () {
    var original_value = parseFloat(input.val())
    var value = $(this).find("option:selected").html()
    var hours = value ? parseFloat((value.match(/([0-9.]+) hours/) || [])[1]) : null

    if (hours && original_value && !isNaN(original_value) && !isNaN(hours)) {
      input.val(Math.min(hours, original_value))
    }
  })
}
;
$.fn.hashHandler = function () {
  var hash = window.location.hash
  hash && this.find('a[href="' + hash + '"]').tab("show")
  // Hide the buttons on page load
  hideShowUpdateButtons(this.find(".active").children())

  return this.filter(function () { return !$(this).is(".no-nav-tab-handler") }).find("a").click(function (e) {
    e.preventDefault()

    var tab = $(this)

    // Hide the buttons on tab change
    hideShowUpdateButtons(tab)

    tab.tab("show")
    if (!tab.data("no-scroll")) {
      var scrollmem = $("body").scrollTop()
      window.location.replace((String(window.location)).split("#")[0] + tab.attr("href")) // http://stackoverflow.com/questions/2305069/can-you-use-hash-navigation-without-affecting-history
      $("html,body").scrollTop(scrollmem)
    }
  })

  // We are hiding the update/cancel buttons on the contracts tab because all the data on this page is updated through react
  function hideShowUpdateButtons (el) {
    if (el[0].hash === "#contract") {
      el.parents().find(".update-employee-target").hide()
    } else {
      el.parents().find(".update-employee-target").show()
    }
  }
}

// http://stackoverflow.com/questions/12131273/twitter-bootstrap-tabs-url-doesnt-change
;
$.fn.hoverable = function () {
  var dataTable = this.first().parents(".dataTable")
  if (dataTable.length && !dataTable.data("setHoverableHandler")) {
    dataTable.data("setHoverableHandler", true)
    dataTable.on("draw.dt", function () {
      $(".hoverable").hoverable()
      $(".hoverable-trigger").hoverableTrigger()
    })
  }

  return this.off("mouseover mouseleave").hover(function hoverIn () {
    var ele = $(this)
    if (ele.attr("data-hovertext")) {
      ele.html(ele.attr("data-hovertext"))
    }
  }, function hoverOut () {
    var ele = $(this)
    if (ele.attr("data-hovertext")) {
      setTimeout(function () { ele.html(ele.data("offhovertext") || "---") }, 200)
    }
  })
}

$.hoverableTrigger = {
  COOKIE_NAME: "hoverable-trigger-state",
  getCookie: function () {
    return $.cookie($.hoverableTrigger.COOKIE_NAME)
  },
  setCookie: function (val) {
    $.cookie($.hoverableTrigger.COOKIE_NAME, val, { expires: 7300, path: "/" })
  },
  removeCookie: function () {
    $.removeCookie($.hoverableTrigger.COOKIE_NAME, { expires: 7300, path: "/" })
  },
}

$.fn.hoverableTrigger = function () {
  var DASHES = "---"
  var DATA_KEY = "data-hovertext"

  function setListHoverableClass (is_checked) {
    list.toggleClass("mi-visibility", is_checked).toggleClass("mi-visibility-off", !is_checked)
  }

  function setTableRowsHoverableStatus (table_rows, is_checked) {
    table_rows.each(function () {
      $(this).find("td").filter(function () { return $(".hoverable", this).length }).each(function () {
        var content = $(this).find(".hoverable:not(.notrigger)")
        if (content.length) {
          if (is_checked) {
            // checked: html should be value
            if (content.html() === DASHES) {
              content.html(content.attr(DATA_KEY)).removeAttr(DATA_KEY).find("[tooltip]").loadTooltips()
            }
            content.removeClass("label label-faint")
          } else {
            // not checked: html should be dashes, hovertext should be value
            if (content.html() !== DASHES) {
              content.attr(DATA_KEY, content.html()).html(DASHES)
            }
            content.addClass("label label-faint")
          }
        }
      })
    })
  }

  function tableRows () {
    return list.parents("td,th").parents("tr:first").parents("table:first").find("tbody tr")
  }

  function setCookie (val) {
    $.hoverableTrigger.setCookie(val)
  }

  var list = this
  return list.off("click.hoverableTrigger").on("click.hoverableTrigger", function () {
    var box = $(this)
    var is_checked = box.is(".mi-visibility-off")

    setListHoverableClass(is_checked)
    setTableRowsHoverableStatus(tableRows(), is_checked)

    setCookie(is_checked)

    return false // don't bubble up to other parts of the <th>
  }).each(function () {
    var existing_cookie = $.hoverableTrigger.getCookie()
    var initial_state

    if (!existing_cookie) {
      existing_cookie = "true"
      setCookie(true)
    }

    initial_state = existing_cookie === "true"

    setTableRowsHoverableStatus(tableRows(), initial_state)
    setListHoverableClass(initial_state)
  })
}
;
$.fn.oldDataTable = $.fn.DataTable

$.fn.DataTable = function (options) {
  var t = function (key) { return I18n.t("js.generic_components.datatables." + key) }
  options = _.extend({
    language: {
      decimal: t("decimal"),
      emptyTable: t("emptyTable"),
      info: t("info"),
      infoEmpty: t("infoEmpty"),
      infoFiltered: t("infoFiltered"),
      infoPostFix: t("infoPostFix"),
      thousands: t("thousands"),
      lengthMenu: t("lengthMenu"),
      loadingRecords: t("loadingRecords"),
      processing: t("processing"),
      search: t("search"),
      zeroRecords: t("zeroRecords"),
      paginate: {
        first: t("paginate.first"),
        last: t("paginate.last"),
        next: t("paginate.next"),
        previous: t("paginate.previous"),
      },
      aria: {
        sortAscending: t("aria.sortAscending"),
        sortDescending: t("aria.sortDescending"),
      },
    },
  }, options || {})
  return this.oldDataTable(options)
}
;
$.fn.inlineTableCreation = function (options) {
  var input = $(this)
  var button = $(options.button)
  var post_to = options.post_path

  // for when server posting is disabled
  // this should be kept in sync with /app/views/shift_details/_shift_detail.html.haml
  function newRowTemplate (name) {
    var html = $(crel("div", { class: "shift-detail new-shift-detail" },
                  crel("a", { class: "name" }, name),
                  crel("div", { class: "edit-options" },
                    crel("i", { class: "mi mi-close delete-row pull-right" }))))

    html.on("click", ".delete-row", function () {
      var row = $(this).parents(".shift-detail")
      row.hide("fast", function () {
        row.remove()
        updateShiftDetailsHiddenField()
      })
    })

    return html
  }

  function updateShiftDetailsHiddenField () {
    var hidden_field = $("#department_shift_detail_names")
    var shift_details = $("#shiftDetails").find(".new-shift-detail").map(function () { return $(this).text() }).get().join(",")

    hidden_field.val(shift_details)
  }

  function loadValuesFromHiddenField () {
    var hidden_field = $("#department_shift_detail_names")
    var names = hidden_field.val().split(",").compact()

    _.each(names, function (name) {
      newRowTemplate(name).appendTo("#shiftDetails")
    })
  }

  if (options.load_from_hidden) {
    loadValuesFromHiddenField()
  }

  button.on("click", function () {
    var value = input.val()

    if (value) {
      window.LH.Spin.start(options.spin_container, { size: options.spin_size })

      if (post_to) {
        var data = window.add_auth_token({ name: value.trim(), _method: "post" })
        $.ajax({
          type: "POST",
          url: post_to,
          data: data,
          dataType: "script",
          success: function () {
            window.LH.Spin.stop(options.spin_container)
          },
          error: function (data) {
            window.LH.Spin.stop(options.spin_container)
            console.error("an error occurred while creating: " + data)
          },
        }) // end ajax
      } else if (options.inline_shift_details) {
        var row = newRowTemplate(value)
        row.appendTo("#shiftDetails").hide().show("slow")
        updateShiftDetailsHiddenField()
        window.LH.Spin.stop(options.spin_container)
      }

      input.val("")
    }
  })
}

window.init_inline_table_creation = function () {
  var create = function (input) {
    if (input.val()) {
      var data = window.add_auth_token({ name: input.val(), _method: "post" })

      var url = input.attr("data-url")
      window.LH.Spin.until(
        $.ajax({
          type: "POST",
          url: url,
          data: data,
          dataType: "script",
          success: function () {
            input.val("").focus()
          },
          error: function (data) {
            console.error("an error occurred while creating: " + data)
            input.val("").focus()
          },
        }) // end ajax
      )
    }
  }

  $("input[type=text][name=add-inline]").on("keyup", function (ev) {
    if (ev.keyCode === 13) { // enter (/return)
      create($(this))
    }
  })

  $(".create-inline").on("click", function () {
    create($(this).siblings("input[type=text][name=add-inline]"))
  })
}
;
/**
 * Takes an array and joins it in a human-friendly way. e.g. ['you', 'me'] => 'you and me'.  The tests should show expected behaviour pretty clearly.  Significantly slower that Array#join, so don't use unless you need it https://jsperf.com/joinarray
 * @param {Array} array The array to join together
 * @param {Number} [maxLength=null] All elements greater than this will be counted and joined using `andWord count finalPhrase`.  e.g.
 * ['you', 'me'] => 'you and 1 more'.  If null, not elements will be redacted.
 * @param {String} [andWord=and] The word to use to join the redacted elements to the array
 * @param {String|Array} [finalPhrase=more] A description of the redacted terms.  e.g ['you', 'me', 'him'] => 'you and 2 more people'.  Pass
 * in the singular version as a string if it just needs an 's' to be a plural.  Otherwise, pass in an array containing [singular,
 * plural].  Example would be...pass in 'more computer', because the plural is 'more computers'.  Or pass in ['more person', 'more
 * people'] because the plural is different
 * @param {String} [splitter=', '] How to connect all the other terms that aren't redacted.
 * @param {Boolean} [useAndwordForUnredacted=false] If true, the andword will be used to join arrays shorter than max length.  This can be useful if your andWord is something like 'with', but you want shorter array to still join using and.  If false, the word is 'and'.
 * @returns {String} The array joined together, with terms above maxLength redacted and replaced with the count.
 */


window.joinArray = function (array, maxLength, andWord, finalPhrase, splitter, useAndwordForUnredacted) {
  array = (array || []).filter(function (item) { return item && item.length > 0 })
  if (array.length === 0) { return "" }

  andWord = andWord || "and"
  maxLength = maxLength || array.length + 1 // put it above the bounds available so it's never used.
  var singlePhrase
  var multiPhrase
  if (finalPhrase instanceof Array) {
    if (finalPhrase.length < 2) {
      throw new Error("finalPhrase must be [singular, plural] if passed in as an array")
    }
    singlePhrase = finalPhrase[0]
    multiPhrase = finalPhrase[1]
  } else if (!finalPhrase) {
    singlePhrase = multiPhrase = "more"
  } else {
    singlePhrase = finalPhrase
    multiPhrase = finalPhrase + "s"
  }
  splitter = splitter || ", "
  if (array.length === 1) {
    return array[0]
  }
  if (array.length <= maxLength) {
    var and = useAndwordForUnredacted ? andWord : "and"
    return array.slice(0, array.length - 1).join(splitter) + " " + and + " " + array[array.length - 1]
  }
  var terms = array.slice(0, maxLength)
  var remaining = (array.length - maxLength)
  var phrase = remaining === 1 ? singlePhrase : multiPhrase
  return terms.join(splitter) + " " + andWord + " " + remaining + " " + phrase
}
;
(function ($) {
  var pluginName = "syncedCheckbox"

  var settings = {}

  function Plugin (el, opts) {
    this.$el = $(el)
    this.$children = $(opts.children)
    this.init(opts)
  }

  Plugin.prototype = {
    init: function (opts) {
      if (opts) {
        $.extend(settings, opts)
      }

      this.$el.on("change", this.clicked.bind(this))
      if (this.$children) {
        var childClicked = this.childClicked.bind(this)
        this.$children.on("change", childClicked)
      }
    },
    toggleChildren: function (check) {
      this.$children.prop("checked", check)
    },
    childClicked: function (ev) {
      var all_selected = this.$children.length === this.$children.filter(":checked").length

      this.$el.prop("checked", all_selected)
      settings.onChildClicked && settings.onChildClicked(ev.target)
    },
    clicked: function () {
      var checked = this.$el.prop("checked")
      this.toggleChildren(checked)
      settings.onClicked && settings.onClicked(checked)
    },

  }

  $.fn[ pluginName ] = function (options) {
    return this.each(function () {
      $.removeData(this, "plugin_" + pluginName) // this also `delete`s the old plugin var if there is one
      $.data(this, "plugin_" + pluginName, new Plugin(this, options))
    })
  }
})(jQuery)
;
$.fn.draggableFileInput = function (inputName, opts) {
  opts = $.extend({}, { accept: "*", multiple: false }, opts)

  var isAdvancedUpload = (function () {
    // Only seems to work in chrome and safari :(
    var div = document.createElement("div")
    return (("draggable" in div) || ("ondragstart" in div && "ondrop" in div)) && "FormData" in window && "FileReader" in window
  }())

  var fileHasCorrectType = function (file) {
    if (file.type === "") {
      var splitName = file.name.split(".")
      var fileExt = "." + splitName[splitName.length - 1]
      return _.includes(opts.accept.split(","), fileExt)
    } else {
      return _.includes(opts.accept.split(","), file.type)
    }
  }

  var inputArgs = {
    class: "draggable-file-input-file-input",
    accept: opts.accept,
    type: "file",
    name: inputName,
    id: inputName,
  }

  if (opts.multiple) {
    inputArgs.multiple = true
  }

  var upload_prompt = I18n.t("js.plugins.jquery_file_draggable_input." + (opts.multiple ? "choose_file_multiple_html" : "choose_file_single_html"))

  var $container = this
  $container.append($(
    crel("div", { class: "draggable-file-input-outer-container" },
      crel("div", { class: "draggable-file-input-inner-container" },
        crel("input", inputArgs),
        crel("label", { for: inputName, class: "draggable-file-input-default-label" },
          crel("span", { class: "draggable-file-input-drop-zone" }, upload_prompt)
        ),
        crel("label", { for: inputName, class: "draggable-file-input-filled-label", style: "display: none" }, ""),
        crel("label", { class: "draggable-file-input-bad-file-label" }, I18n.t("js.plugins.jquery_file_draggable_input.wrong_type")),
        crel("label", { class: "draggable-file-input-too-many-files-label" }, I18n.t("js.plugins.jquery_file_draggable_input.too_many")),
        crel("br"),
        crel("div", { class: "btn draggable-file-input-clear-button" }, I18n.t("js.plugins.jquery_file_draggable_input.clear"))
      )
    )
  ))

  var $draggableContainer = $container.find(".draggable-file-input-inner-container")
  var $input = $draggableContainer.find('input[type="file"]')
  var $defaultLabel = $draggableContainer.find(".draggable-file-input-default-label")
  var $filledLabel = $draggableContainer.find(".draggable-file-input-filled-label")
  var $clearButton = $draggableContainer.find(".draggable-file-input-clear-button")

  var updateFile = function (files, updateInput) {
    if (files.length > 0) {
      $filledLabel.text(_.pluck(files, "name").join(", "))
      $defaultLabel.hide()
      $filledLabel.show()
      $clearButton.show()
      $draggableContainer.addClass("file-selected")

      if (updateInput) {
        $input[0].files = files
      }
      $input.trigger("updateFile")
    } else {
      $clearButton.hide()
      $filledLabel.hide()
      $defaultLabel.show()
      $draggableContainer.removeClass("file-selected")

      if (updateInput) {
        $input.val("")
      }
      $input.trigger("updateFile")
    }
  }

  $defaultLabel.click(function (e) {
    if ($draggableContainer.hasClass("disabled")) {
      e.preventDefault()
      e.stopPropagation()
    }
  })

  $filledLabel.click(function (e) {
    if ($draggableContainer.hasClass("disabled")) {
      e.preventDefault()
      e.stopPropagation()
    }
  })

  $draggableContainer.click(function (e) {
    if (!$draggableContainer.hasClass("disabled") && e.target === $draggableContainer[0]) {
      $input.trigger("click")
    }
  })

  if (isAdvancedUpload) {
    $draggableContainer.addClass("has-advanced-upload")

    var badFile = false

    $draggableContainer.on("drag dragstart dragend dragover dragenter dragleave drop", function (e) {
      e.preventDefault()
      e.stopPropagation()
    }).on("dragover dragenter", function (e) {
      if ($input.prop("disabled") || $draggableContainer.hasClass("disabled")) {
        e.originalEvent.dataTransfer.dropEffect = "none"
        return
      }

      var numFiles = 0
      if (e.originalEvent.dataTransfer.items) {
        numFiles = _.filter(e.originalEvent.dataTransfer.items, { kind: "file" }).length
      }

      if (!_.includes(e.originalEvent.dataTransfer.types, "Files")) {
        badFile = true
        $draggableContainer.addClass("bad-file")
      } else if (e.originalEvent.dataTransfer.items && numFiles > 1 && !opts.multiple) {
        badFile = true
        $draggableContainer.addClass("too-many-files")
      } else {
        badFile = false
        e.originalEvent.dataTransfer.dropEffect = "copy"
      }
      $draggableContainer.addClass("is-dragover")
    }).on("dragleave dragend drop", function () {
      $draggableContainer.removeClass("is-dragover bad-file too-many-files")
    }).on("drop", function (e) {
      if ($draggableContainer.hasClass("disabled")) { return }

      if (!badFile && (
          opts.accept !== "*" &&
          e.originalEvent.dataTransfer.files &&
          e.originalEvent.dataTransfer.files.length > 0 &&
          !_.all(e.originalEvent.dataTransfer.files, fileHasCorrectType))) {
        $draggableContainer.addClass("is-dragover bad-file")
        setTimeout(function () { $draggableContainer.removeClass("is-dragover bad-file") }, 1000)
      } else if (!badFile) {
        updateFile(e.originalEvent.dataTransfer.files, true)
      }

      badFile = false
    })
  }

  $input.on("change", function (e) {
    updateFile(e.target.files, false)
  })

  $clearButton.click(function () {
    $input.val("")
    $input.trigger("change")
  })

  return {
    disabled: function (disabled) {
      if (disabled) {
        $draggableContainer.addClass("disabled")
      } else {
        $draggableContainer.removeClass("disabled")
      }
    },
    disable: function () {
      $draggableContainer.addClass("disabled")
    },
    enable: function () {
      $draggableContainer.removeClass("disabled")
    },
    clear: function () {
      $input.val("")
      $input.trigger("change")
    },
    inputElement: function () {
      return $input
    },
  }
}
;
// DA: 2018-06-27 - Fixed XSS Vulnerability

/**
 * Create a filterable select input
 * @param {Array|Object} initOptions The options to display to the user when searching.  If this is an Object, and `opts` is undefined,
 * this will be treated as opts
 * @param {Object} [opts] Options to configure the component
 * @param {Function} [opts.callback] A callback to fire when an option is selected, called in the context of the jQuery object this input
 * is attached to. param1 is the option selected.  param2 is the value of the selected item
 * @param {Boolean} [opts.debug=false] Display the debug output to the console? Envify this is possible
 * @param {String} [opts.placeholder] A placeholder to put inside the input box
 * @param {Number} [opts.show=6] The maximum number of options to show.  More than this will become scrollable
 * @param {String} [opts.type=single] The type of select box required.  If single, the box will close after an option is clicked.  If
 * 'checklist', options will act as checkboxes, receiving a class of 'selected' when checked. If 'footer', chosen options will be put at the
 * footer of the list and remain visible.  Note: Unexpected behaviour if the footer elements are a different height to the standard elements
 * @param {Number} [opts.footerShow=opts.show] How many options to show in the footer if the type is 'footer'.  Defaults to the same
 * number as opts.how
 * @param {Boolean} [opts.showCreate=false] When true, an option will appear at the bottom of the list (but above the footer) containing
 * the current value of the input field, with the classes '.filter-select-candidate .filter-select-option'.  This allows for options to
 * be created on the fly
 * @param {Boolean|String} [opts.createPrompt=false] Show a prompt to the user at the bottom of list that they can create tags.  Set to
 * false to disable, or set to a string to give text to the prompt.
 * @param {Function} [opts.createCallback] The function to be called when the create option is clicked.  Passed the text value and a 'done'
 * call back.  Fire the done callback with the option ({text: String, value: Any}) you want added to the list.  Mandatory is
 * opts.showCreate is true.
 * @param {String|Function} [opts.appendFooter] (You probably don't need/want this).  If you want the footer to remain visible after the
 * search box is closed, pass in a string representing a dom selector of the element to append the footer to (it will become the last
 * element in the tree of the selector passed in).  If you pass in a function, you can assume that `this` will refer to the element
 * being operated on, as a jQuery Object, which you can use for relative paths, and you should return a jquery object
 */

$.fn.filterSelect = function (initOptions, opts) {
  if (initOptions instanceof Object && !opts) {
    opts = initOptions
    initOptions = []
  }
  opts = opts || {}
  opts.debug = opts.debug || false
  opts.show = opts.show || 6
  opts.type = opts.type || "single"
  opts.footerShow = opts.footerShow || opts.show
  opts.showCreate = opts.showCreate || false
  opts.createPrompt = opts.createPrompt || false
  opts.appendFooter = opts.appendFooter || null
  if (!this.is("select") && !this.is("input") && (!opts.callback || !(opts.callback instanceof Function))) {
    throw new Error("You need to use either a <select /> with <option />'s, or an <input />.  Alternatively, set a `callback` to be" +
      " fired on selection inside `opts`")
  }
  if (this.length > 1 && !this.is("select")) {
    throw new Error("If calling on multiple components, you must use a <select /> with <option />'s.")
  }

  if (opts.showCreate === true && !(opts.createCallback instanceof Function)) {
    throw new Error("To use the 'Create' button, you must pass in `opts.createCallback`.  You've set `opts.showCreate` to true but" +
      " haven't set `opts.createCallback`.")
  }

  function throwOptionError (option, error) {
    throw new Error("Option " + JSON.stringify(option) + " does not have a valid `" + error + "`. Format: [{ text: String, value: Any }]")
  }

  this.each(function (i, el) {
    if (el.is_filter_select) {
      return el.filter_select_instance
    }
    var options = initOptions
    var parent = $(el)
    if (parent.is("select") && options.length < 1) {
      options = []
      parent.find("option").each(function () {
        var $this = $(this)
        options.push({
          value: $this.prop("value"),
          text: $this.text(),
          selected: $this.prop("selected"),
        })
      })
      if (!options || !(options instanceof Array)) {
        throw new Error("You must pass in an array of options to display. `" + JSON.stringify(options) + "` is not that.  You could also use a" +
          " <select /> populated with <option />'s.")
      }
    }
    var footerAppendParent
    if (opts.appendFooter !== null) {
      if (typeof opts.appendFooter === "string") {
        footerAppendParent = $(opts.appendFooter)
      } else if (typeof opts.appendFooter === "function") {
        footerAppendParent = opts.appendFooter.call(parent)
      } else {
        throw new Error("Unknown type `" + typeof opts.appendFooter + "` passed in as the `appendFooter` option.")
      }
    }

    options = options.map(function (option) {
      if (option.text == null) {
        throwOptionError(option, "text")
      }
      if (option.value == null) {
        if (opts.debug) {
          console.warn("Using `text` as `value` for", option)
        }
        option.value = option.text
      }
      return option
    })

    window.utils.hide_parent(parent)
    var wrapper = $('<div class="filter-select-wrapper"></div>') // the wrapper component
    var input = $('<input class="filter-select-input" type="text" />') // the input component
    var placeholder = opts.placeholder || parent.attr("placeholder")
    if (placeholder) {
      input.attr("placeholder", placeholder)
    }
    wrapper.append(input)
    var optionWrapper = $('<div class="filter-select-options-wrapper"></div>')
    var optionContainer = $('<div class="filter-select-options"></div>')// the options container
    optionWrapper.append(optionContainer)
    var footerContainer = $('<div class="filter-select-footer"></div>') // the footer container
    if (opts.type && opts.type === "footer" || opts.showCreate === true) {
      optionWrapper.append(footerContainer)
    }

    function addToFooter (el) {
      el.addClass("selected")
      footerContainer.append(el)
      footerContainer.css({ maxHeight: getFooterMaxHeight() })
    }

    function removeFromFooter (el) {
      el.removeClass("selected")
      if (footerContainer.find(".filter-select-option.selected").length === 0) {
        footerContainer.removeClass("static")
      }
      // we need to put it back at `data-index`
      var optContOpts = optionContainer.find(".filter-select-option")
      // the length is always 1
      var toInsertBefore = Math.min(optContOpts.length - 1, i)
      optContOpts.eq(toInsertBefore).before(el)
    }

    if (opts.showCreate) {
      var showCreate = $('<div class="filter-select-option filter-select-candidate"></div>')
      showCreate.click(function () {
        opts.createCallback(input.val(), function (option, selected) {
          if (!option) { return } // option not provided -> ignore
          selected = selected !== false
          // process the creation of a new option.
          if (!option.text) {
            throwOptionError(option, "text")
          }
          if (option.value == null) {
            if (opts.debug) {
              console.warn("Using `text` as `value` for", option)
            }
            option.value = option.text
          }
          var opt = createOption(option, pseudoOptions.length)
          pseudoOptions.push(opt)
          if (selected) {
            opt.click()
          }
          input.val("").focus().keyup()
        })
      })
      footerContainer.append(showCreate)
      showCreate.hide()
    }

    if (typeof opts.createPrompt === "string") {
      // show the create prompt
      var helpText = $('<div class="filter-select-create-prompt">' + opts.createPrompt + "</div>")
      footerContainer.append(helpText)
      helpText.hide()
    }

    function getMaxHeight () {
      var firstOption = optionContainer.find(".filter-select-option").first()
      var oneHeight = firstOption.outerHeight()
      return opts.show * oneHeight
    }

    function getFooterMaxHeight () {
      var footerChildren = footerContainer.find(".filter-select-option.selected,.filter-select-create-prompt,.filter-select-candidate")
      if (footerChildren.length === 0) {
        return 0
      }
      if (footerChildren.length <= opts.footerShow) {
        return ""
      }
      var oneHeight = footerChildren.first().outerHeight()

      return opts.footerShow * oneHeight
    }

    function handleOpen () {
      if (opts.appendFooter !== null) {
        footerContainer.removeClass("static")
        optionWrapper.append(footerContainer)
      }
      wrapper.addClass("open")

      optionWrapper.css({
        position: "absolute",
        top: (wrapper.offset().top + wrapper.outerHeight()) + "px",
        left: wrapper.offset().left + "px",
        width: wrapper.width() + "px",
      })

      optionContainer.css({ maxHeight: getMaxHeight() })

      var width = wrapper.outerWidth()

      footerContainer.css({
        maxHeight: getFooterMaxHeight(),
        maxWidth: width || "100%",
      })
      if (opts.createPrompt) {
        helpText.show()
      }
      optionWrapper.outerWidth(width)
      optionWrapper.slideDown(100)
      optionContainer.find(".hover").removeClass("hover")
      optionContainer.find(".filter-select-option").first().addClass("hover")
    }

    function handleClose () {
      wrapper.removeClass("open")
      if (opts.appendFooter !== null) {
        if (footerContainer.find(".filter-select-option.selected").length > 0) {
          footerContainer.addClass("static")
        }
        footerAppendParent.append(footerContainer)
      }
      if (opts.showCreate) {
        showCreate.hide()
      }

      if (opts.createPrompt) {
        helpText.hide()
      }
      optionWrapper.slideUp(100)
    }

    function createOption (opt, i) {
      // DA: FIXED XSS VULNERABILITY
      var pseudoOption = $('<div class="filter-select-option" data-index="' + i + '"></div>')
      pseudoOption.data("value", opt.value)
      pseudoOption.text(opt.text)
      // DA: END FIX
      pseudoOption.data("opt", opt)

      pseudoOption.click(function (e) {
        e.preventDefault()
        var option = $(this).data("opt")
        if (opts.callback) {
          opts.callback.call(parent, pseudoOption, option.value)
        }
        if (!opts.type || opts.type === "single") { // if type is set, it's a multi-select
          handleClose()
        } else {
          if (opts.type === "checklist") {
            pseudoOption.toggleClass("selected")
          }
          if (opts.type === "footer") {
            pseudoOption.removeClass("hover") // it will never actually unhover during the switch
            if (pseudoOption.parent(".filter-select-options").length > 0) {
              // it's inside the options list
              addToFooter(pseudoOption)
            } else {
              // it's inside the footer list
              removeFromFooter(pseudoOption)
              // re-run the filter
              input.keyup()
            }
          }
          // Clear and give focus back to the input after item is created so that
          // arrow key up/down continue to work afterwards.
          input.focus()
          input.val("")
        }
        parent.trigger({
          type: "select-change",
          value: option.value,
        })
      })

      pseudoOption.hover(function () {
        pseudoOption.addClass("hover")
      }, function () {
        pseudoOption.removeClass("hover")
      })

      optionContainer.append(pseudoOption)

      if (opt.selected) { pseudoOption.click() }

      return pseudoOption
    }

    var pseudoOptions = options.map(createOption)

    wrapper.val = function () {
      var selected = []
      if (opts.appendFooter !== null) {
        selected = footerContainer.find(".selected")
      } else {
        selected = optionWrapper.find(".selected")
      }

      return selected.map(function () { return $(this).data("value") }).get()
    }

    parent.data("getSelected", function () {
      // returns a string for 'single' mode. An Array for checklists/footer modes.
      if (opts.appendFooter !== null) {
        return footerContainer.find(".selected")
      }
      return optionWrapper.find(".selected")
    })

    var noOptionsText = opts.noOptionsText || "No Results Found"
    var noOptionsTag = $('<div class="filter-select-option no-options">' + noOptionsText + "</div>")
    optionContainer.append(noOptionsTag)
    noOptionsTag.hide()

    function filterOptions (e) {
      var val = e.target.value.toLowerCase()
      var hidden = 0
      var exactMatch = false
      pseudoOptions.forEach(function (el) {
        var $this = el
        var opt = $this.data("opt")
        if (opt.text === e.target.value) { exactMatch = true }
        if ($this.hasClass("selected")) { hidden++; return }
        var optText = opt.text.toLowerCase()
        if (optText.indexOf(val) > -1) {
          $this.show()
        } else {
          $this.hide()
          hidden++
        }
      })
      if (hidden === pseudoOptions.length) {
        noOptionsTag.show()
      } else {
        noOptionsTag.hide()
      }
      if (opts.showCreate) {
        if (e.target.value === "" || exactMatch) {
          if (input.is(":focus")) {
            opts.createPrompt ? helpText.show() : ""
          }
          return showCreate.hide()
        }
        opts.createPrompt ? helpText.hide() : ""
        showCreate.show().text(e.target.value)
      }
    }

    wrapper.data("open", handleOpen)
    wrapper.data("close", handleClose)

    if (opts.type === "footer") {
      parent.data("clearSelected", function () {
        pseudoOptions.map(removeFromFooter)
        // re-run the filter
        input.keyup()
      })
    }

    input.keyup(filterOptions)
    input.focus(handleOpen)

    function updateScroll (opts, delta) {
      opts.scrollTop(opts.scrollTop() + delta)
    }

    function checkElementIsTooFarDown (el) {
      var thisElBottom = el.offset().top + el.outerHeight()
      var options = el.offsetParent().find(".filter-select-options")
      var bottomOfShowing = options.offset().top + options.outerHeight()
      if (thisElBottom > bottomOfShowing) {
        updateScroll(options, thisElBottom - bottomOfShowing)
      }
    }

    function checkElementIsTooHighUp (el) {
      var thisElTop = el.offset().top
      var options = el.offsetParent().find(".filter-select-options")
      var topOfShowing = options.offset().top
      if (thisElTop < topOfShowing) {
        updateScroll(options, thisElTop - topOfShowing)
      }
    }

    wrapper.keyup(function (e) {
      if (e.key === "Escape") {
        handleClose()
        return
      }
      if (e.key === "Enter") {
        var selectOptions = optionContainer.find(".filter-select-option")
        var visibleOptions = selectOptions.filter(":visible")
        if (wrapper.hasClass("open")) {
          selectOptions.filter(".hover").first().click()
          visibleOptions.first().addClass("hover")
        }
      }
    })
    wrapper.keydown(function (e) {
      if (e.key === "Tab") {
        handleClose()
        return
      }
      var selectOptions = optionContainer.find(".filter-select-option")
      var visibleOptions = selectOptions.filter(":visible")
      var current = selectOptions.filter(".hover").first()
      var toFocus
      if (e.key === "ArrowDown") {
        if (current.index() < visibleOptions.last().index()) {
          optionContainer.find(".hover").removeClass("hover")
          toFocus = current.nextAll(":visible:first")
          if (toFocus.length) {
            toFocus.addClass("hover")
            checkElementIsTooFarDown(toFocus)
            checkElementIsTooHighUp(toFocus)
          }
        }
      } else if (e.key === "ArrowUp") {
        if (current.index() > visibleOptions.first().index()) {
          optionContainer.find(".hover").removeClass("hover")
          toFocus = current.prevAll(":visible:first")
          if (toFocus.length) {
            toFocus.addClass("hover")
            checkElementIsTooHighUp(toFocus)
            checkElementIsTooFarDown(toFocus)
          }
        }
      }
      if (!wrapper.hasClass("open")) {
        handleOpen()
      }
    })

    function globalKeydownHandler (e) {
      if ([38, 40].indexOf(e.keyCode) > -1) {
        e.preventDefault()
      }
    }

    input.focus(function () {
      window.addEventListener("keydown", globalKeydownHandler)
    })

    input.blur(function () {
      window.removeEventListener("keydown", globalKeydownHandler)
    })

    wrapper.data("options", optionWrapper)
    optionWrapper.data("wrapper", wrapper)

    parent.after(wrapper)
    $("body").append(optionWrapper)
    optionWrapper.hide()

    el.is_filter_select = true
    el.filter_select_instance = wrapper

    footerContainer.addClass("static")
    footerAppendParent.append(footerContainer)

    $(document).click(function (e) {
      if (wrapper.hasClass("open")) {
        // the thing is open, but we're not clicking it, and we're not already trying to close it
        var t = $(e.target)
        var tWrap = t.parents(".filter-select-options-wrapper")
        var tInput = t.hasClass("filter-select-input") ? t : t.parents(".filter-select-input")
        if (tWrap.length < 1 && tInput.length < 1) {
          handleClose()
        } else if (tWrap.length > 0 && tWrap[0] !== optionWrapper[0]) {
          // check if it's the wrapper that's in here, otherwise close
          handleClose()
        } else if (tInput.length > 0 && tInput[0] !== input[0]) {
          // close if the clicked input is not the same as this input
          handleClose()
        }
      }
    })
  })
}
;
$.fn.multi_select = function multi_select () {
  if (this.length < 1) { return null }

  function getClass (type) {
    if (type === "checkbox") {
      return "checkbox"
    } else if (type === "radio") {
      return "radio"
    } else {
      return ""
    }
  }

  function getValue (option) {
    if (option.val()) {
      return option.val()
    }
    return option.text()
  }

  this.each(function create_multi_select (i, el) {
    var $el = $(el)
    if ($el.find("option").length < 1) { return null }
    window.utils.hide_parent($el)

    $el.on("change", function () {
      wrapper.find(".selected").removeClass("selected")
      $el.find("[selected]").each(function () {
        var val = this.value
        wrapper.find('[data-value="' + val + '"]').addClass("selected")
      })
    })

    var optionClass = getClass($el.data("type"))

    // build the structure
    var wrapper = $('<div class="search-list">')
    wrapper.insertAfter($el)

    $el.find("option").each(function (i, opt) {
      var $opt = $(opt)
      var val = getValue($opt)
      var option = $('<div class="' + optionClass + ' value" data-value="' + val + '">' + $opt.text() + "</div>")
      if ($opt.is("[selected]")) {
        option.addClass("selected")
      }
      option.click(function () {
        $el.find('[value="' + val + '"]').prop("selected", true)
        $(this).toggleClass("selected")
      })
      wrapper.append(option)
    })

    wrapper.val = function get_multiselect_val () {
      return wrapper.find(".selected").map(function () { return $(this).data("value") }).get()
    }

    el.is_multi_select = true
    el.multi_select_instance = wrapper
  })
}
;
// Works like GenericStatusChecker but when the loading is complete, it loads in content to the container
(function ($) {
  var status_checker = function ($bar, prefix, cache_key, interval, callback) {
    var last_num
    var check_status = function () {
      $.ajax({
        type: "GET",
        url: "/" + prefix + "/check_status?cache_key=" + cache_key,
        success: function (data) {
          if (data !== "blank_should_retry") {
            var num = parseFloat(data)

            if (num !== last_num) {
              num = Math.round(num * 100) / 100 // 2 decimal places

              if (isNaN(num)) { // we're done if it's some sort of data (not a number)
                clearInterval(status_checker_inteval)
                $bar.stop().animate({ width: "100%" }, 500)
                callback && callback()
              } else if (num >= (last_num || 0)) {
                $bar.stop().animate({ width: (num * 100) + "%" }, 500)
              }
              last_num = num
            }
          }
        },
      })
    }

    var status_checker_inteval = setInterval(check_status, interval || 3000)

    // Remove the default bootstrap animation on progress bars as the status checker will animate it for us
    $bar.addClass("no-transition")

    check_status()
  }

  $.fn.progressAjaxLoader = function (prefix, options) {
    var to_complete = this.length

    this.each(function () {
      var $container = $(this)
      var cache_key = $container.data("progress-ajax-cache-key")
      var completed_path = $container.data("progress-ajax-completed-path")

      if (!cache_key || !completed_path) {
        to_complete -= 1
        return
      }

      status_checker($container.find(".progress .bar"), prefix, cache_key, 2000, function () {
        $container.load(completed_path) // eslint-disable-line jquery/no-load
        to_complete -= 1
        to_complete === 0 && options && options.onComplete && options.onComplete()
      })
    })
    return this
  }
})(jQuery)
;
$.fn.range_input = function age_input () {
  if (this.length < 1) {
    return this
  }

  this.each(function (i, el) {
    var $el = $(el)
    el.is_range_input = true

    function get_values () {
      var inputs = $el.find(":input")
      return inputs.map(function (i, el) {
        return window.utils.get_value($(el))
      })
        .get()
    }

    el.validate = function () {
      // Require two values
      var vals = get_values()

      if (vals.length !== 2) {
        return false
      }

      var val_one = parseInt(vals[0], 10)
      var val_two = parseInt(vals[1], 10)

      // Require: both values truthy, or neither value truthy
      if ((val_one && !val_two) || (!val_one && val_two)) {
        return false
      }

      if (val_one <= 0 || val_two <= 0) {
        return false
      }

      // make sure val_two is bigger, or at least the same
      return val_one <= val_two
    }

    el.get_title_wording = function get_title_wording (word) {
      word = word || "between"
      var vals = get_values()
      if (vals[0] === "0" && vals[1] === "0") {
        return ""
      }
      if (vals[0] === vals[1]) {
        return vals[0]
      }

      return "".concat(word, " ", vals[0], " and ", vals[1])
    }
  })
}
;
/*
 @title Select Input
 @author David Buchan-Swanson

 A generic select component that matches the style of the existing select components.
 No Options.  Call `$('select').selectInput();` to make all selects on the page match the new style,
 or on a specific select to just convert that select.

 Currently does not working with programmatic changing/updating of the original select.  HipChat me if you think you need to do this.
 */


$.fn.selectInput = function selectInput (opts) {
  var selects = this

  opts = opts || {}

  var placeholder = opts.placeholder

  selects.each(function (i, el) {
    var parent = $(el)
    parent.css({
      visibility: "hidden",
      position: "absolute",
      left: "-999999px",
    })

    var focussable = placeholder ? "" : 'tabindex="0"'
    var select = $('<div class="select-input"' + focussable + '><div class="select-input-selected"></div></div>')
    var optionContainer = $('<div class="select-input-options"></div>')
    parent.find("option").each(function (i, opt) {
      var option = $(opt)
      var selected = option.prop("selected")
      var disabled = option.prop("disabled")
      var pseudoOption = $(
        '<div class="select-input-option" data-value="' + option.prop("value") + '">' +
          '<span class="select-input-option-text">' + option.text() + "</span>" +
        "</div>"
      )

      if (selected) {
        pseudoOption.addClass("selected")
      }

      if (disabled) {
        pseudoOption.addClass("disabled")
      }

      pseudoOption.click(function (e) {
        e.preventDefault()
        if (disabled) { return } // we ignore clicks on disabled options
        parent.val(pseudoOption.data("value"))
        var selectChangeEvent = $.Event("select-change")
        selectChangeEvent.text = option.text()
        selectChangeEvent.val = option.prop("value")
        optionContainer.find(".select-input-option").removeClass("selected")
        pseudoOption.addClass("selected")
        select.find(".select-input-selected").text(pseudoOption.text())
        closeList()
        parent.trigger(selectChangeEvent)
      })

      pseudoOption.hover(function () {
        pseudoOption.addClass("hover")
      }, function () {
        pseudoOption.removeClass("hover")
      })

      if (selected) {
        pseudoOption.click()
      }

      optionContainer.append(pseudoOption)
    })

    if (optionContainer.find(".select-input-option.selected").length === 0) {
      optionContainer.find(".select-input-option").first().click()
    }

    var btn = $('<span class="select-input-button"></span>')
    select.append(btn)

    function globalKeydownHandler (e) {
      if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
        e.preventDefault()
      }
    }

    function openList (e) {
      if (opts.placeholder) {
        return
      }
      select.addClass("open")
      optionContainer.css({
        position: "absolute",
        top: (select.offset().top + select.outerHeight()) + "px",
        left: select.offset().left + "px",
        width: select.width() + "px",
      })
      optionContainer.width(select.width())
      optionContainer.slideDown(100)
    }

    function closeList (e) {
      if (opts.placeholder) {
        return
      }
      select.removeClass("open")
      optionContainer.slideUp(100)
    }

    function toggleList (e) {
      var open = select.hasClass("open")
      if (!open) {
        openList(e)
      } else {
        closeList(e)
      }
    }

    select.click(toggleList)
    select.hover(function () {
      select.addClass("hover")
    }, function () {
      select.removeClass("hover")
    })

    select.focus(function () {
      window.addEventListener("keydown", globalKeydownHandler)
    })

    select.blur(function (e) {
      window.removeEventListener("keydown", globalKeydownHandler)
      if (optionContainer.find(".hover").length > 0) {
        optionContainer.find(".hover").click()
      } else {
        closeList()
      }
    })

    // handle the various keypresses needed
    var numOptions = parent.find("option").length
    var currentlyFocussed = -1

    select.keyup(function (e) {
      if (e.key === " " || e.key === "Enter") {
        if (select.hasClass("open") && currentlyFocussed !== -1) {
          optionContainer.find(".hover").click().removeClass("hover")
          currentlyFocussed = -1
          return
        }
        currentlyFocussed = 0
        optionContainer.find(".hover").removeClass("hover")
        optionContainer.find(".select-input-option").eq(currentlyFocussed).addClass("hover")
        toggleList()
        return
      }

      if (e.key === "ArrowDown") {
        if (currentlyFocussed < numOptions - 1) {
          if (currentlyFocussed === -1) {
            openList()
          }
          currentlyFocussed++
          optionContainer.find(".hover").removeClass("hover")
          optionContainer.find(".select-input-option").eq(currentlyFocussed).addClass("hover")
        }
      }

      if (e.key === "ArrowUp") {
        if (currentlyFocussed === -1) {
          return
        }
        currentlyFocussed--
        optionContainer.find(".hover").removeClass("hover")
        if (currentlyFocussed === -1) {
          return closeList()
        } else {
          optionContainer.find(".select-input-option").eq(currentlyFocussed).addClass("hover")
        }
      }
    })

    parent.change(function () {
      var parentVal = parent.val()
      optionContainer.find('[data-value="' + parentVal + '"]').click()
    })

    parent.after(select)
    $("body").append(optionContainer)
    optionContainer.hide()
    $(document).click(function (e) {
      if (select.hasClass("open")) {
        // the thing is open, but we're not clicking it, and we're not already trying to close it
        var t = $(e.target)
        if (t.parents(".select-input-options").length < 1 && t.parents(".select-input").length < 1 && !t.hasClass("select-input")) {
          closeList()
        }
      }
    })
  })
}
;
$.fn.single_time_input = function single_time_input (opts) {
  var crel = window.crel

  opts = opts || {}

  if (this.length < 1) {
    return null
  }

  function create_meridian_select () {
    return crel("select", { class: "select-box" }, crel("option", "AM"), crel("option", "PM"))
  }

  function get_default () {
    return crel("option", { value: "1" }, "--")
  }

  function create_time_option (time, selected) {
    time = time.toString()
    return _.map([[".0", "00"], [".5", "30"]], function (args) {
      return crel("option", { value: time.concat(args[0]) }, time.concat(":", args[1]))
    })
  }

  function create_time_options () {
    return _.chain(12)
      .range()
      .map(function (i) { return create_time_option(i + 1) })
      .flatten()
      .value()
  }

  function create_single_time_select () {
    return crel("select", { class: "select-box" }, get_default(), create_time_options())
  }

  function get_selected (i, el) {
    return $(el).text()
  }

  function normaliseTime (time, meridian) {
    var t = parseFloat(time.replace(":00", ".0").replace(":30", ".5"))
    if (meridian === "PM" && t < 12) {
      t += 12
    } else if (meridian === "AM") {
      t %= 12 // if the time is 12:xx am, make it 0.
    }
        
    if (Number.isInteger(t)) {
      t = t.toFixed(1) // Add trailing zero if t is an integer
    }

    return t
  }

  function parse_time (time) {
    if (!time.includes(".")) {
      // If it's an integer, format time to have one decimal place
      time = parseFloat(time).toFixed(1)
    }

    var s = time.split(".")
    var t = parseInt(s[0], 10) // get the hours
    if (t === 0 || t === 24 || t === 12) {
      // 0 and 24 are midnight (AM), 12 is midday (PM)
      return {
        time: "12." + (s[1] === "0" ? "0" : "5"),
        meridian: t === 12 ? "PM" : "AM",
      }
    } else if (t > 12) {
      // pm
      return {
        time: (t - 12) + "." + (s[1] === "0" ? "0" : "5"),
        meridian: "PM",
      }
    } else {
      t = (t + 12) % 12
      return {
        time: t + "." + (s[1] === "0" ? "0" : "5"),
        meridian: "AM",
      }
    }
  }

  function set_single_val_from_initial (wrapper, start) {
    if (!start) {
      return // do nothing
    }
    var parsed_start = parse_time(start)

    var selects = wrapper.find("select")
    selects.eq(0).val(parsed_start.time)
    selects.eq(1).val(parsed_start.meridian)
  }

  function generateSingleTimeInputDom (active, $el, time_val) {
    // always active if we have incoming data to render
    if (!active && time_val) { active = true }
  
    var wrapper = $(
      crel("div", { class: ["single-time-input", active ? "active" : ""].join(" ") },
        create_single_time_select(),
        create_meridian_select()
      )
    )

    set_single_val_from_initial(wrapper, time_val)

    wrapper.find(".select-box").each(function (i, el) {
      $(el).selectInput({ placeholder: $el.data("placeholder") })
    })
    wrapper.on("select-change click change", function () { $el.trigger("change") })
  
    wrapper.val = function get_single_time_input_value () {
      var res = wrapper.find(".select-input-selected").map(get_selected).get()
      var time = normaliseTime.apply(null, res)
      if (isNaN(time)) {
        return ""
      }
      return time
    }
  
    $el[0].single_time_input_instance = wrapper
    
    return wrapper
  }
  
  function update_wrapper_parent_active_state(wrapper) {
    var parent = wrapper.parents(".option.single-time-input-toggle-parent-js")
    var hidden = parent.find("[type=hidden]")
  
    if (hidden.val()) {
      parent.find(".option-box").addClass("selected")
    } else {
      parent.find(".option-box").removeClass("selected")
    }
  }
  

  this.each(function (i, el) {
    var $el = $(el)
    window.utils.hide_parent($el)

    var initial_value = $el.val()
    
    var last_insert
    el.single_time_input_instance = el.single_time_input_instance || null

    var wrapper
    
    if (initial_value) {
      wrapper = generateSingleTimeInputDom(opts.active, $el, initial_value)

      wrapper.insertAfter(last_insert || $el)
      last_insert = wrapper

      update_wrapper_parent_active_state(wrapper)
    }
    else {
      wrapper = generateSingleTimeInputDom(opts.active, $el, null)

      wrapper.insertAfter($el)
      update_wrapper_parent_active_state(wrapper)
    }
    
    el.is_single_time_input = true
  })
}
;
// TODO: add a way to provide the initial value for the selects

$.fn.time_input = function time_input (opts) {
  var crel = window.crel

  opts = opts || {}

  if (this.length < 1) {
    return null
  }

  function create_meridian_select () {
    return crel("select", { class: "select-box" }, crel("option", "AM"), crel("option", "PM"))
  }

  function get_default () {
    return crel("option", { value: "1" }, "--")
  }

  function create_time_option (time, selected) {
    time = time.toString()
    return _.map([[".0", "00"], [".5", "30"]], function (args) {
      return crel("option", { value: time.concat(args[0]) }, time.concat(":", args[1]))
    })
  }

  function create_time_options () {
    return _.chain(12)
      .range()
      .map(function (i) { return create_time_option(i + 1) })
      .flatten()
      .value()
  }

  function create_time_select () {
    return crel("select", { class: "select-box" }, get_default(), create_time_options())
  }

  function get_selected (i, el) {
    return $(el).text()
  }

  function normaliseTime (time, meridian) {
    var t = parseFloat(time.replace(":00", ".0").replace(":30", ".5"))
    if (meridian === "PM" && t < 12) {
      t += 12
    } else if (meridian === "AM") {
      t %= 12 // if the time is 12:xx am, make it 0.
    }

    return t
  }

  function parse_time (time) {
    var s = time.split(".")
    var t = parseInt(s[0], 10) // get the hours
    if (t === 0 || t === 24 || t === 12) {
      // 0 and 24 are midnight (AM), 12 is midday (PM)
      return {
        time: "12." + (s[1] === "0" ? "0" : "5"),
        meridian: t === 12 ? "PM" : "AM",
      }
    } else if (t > 12) {
      // pm
      return {
        time: (t - 12) + "." + (s[1] === "0" ? "0" : "5"),
        meridian: "PM",
      }
    } else {
      t = (t + 12) % 12
      return {
        time: t + "." + (s[1] === "0" ? "0" : "5"),
        meridian: "AM",
      }
    }
  }

  function set_val_from_initial (wrapper, start, end) {
    if (!start || !end) {
      return // do nothing
    }
    var parsed_start = parse_time(start)
    var parsed_end = parse_time(end)

    var selects = wrapper.find("select")
    // start time, start merdian, end time, end meridian
    selects.eq(0).val(parsed_start.time)
    selects.eq(1).val(parsed_start.meridian)
    selects.eq(2).val(parsed_end.time)
    selects.eq(3).val(parsed_end.meridian)
  }

  function createTimesFrom (start, startM, end, endM) {
    var startTime = normaliseTime(start, startM)
    var endTime = normaliseTime(end, endM)

    return startTime + "-" + endTime
  }

  function generateTimeInputDom (active, $el, include_remove, start_val, end_val) {
    // always active if we have incoming data to render
    if (!active && (start_val && end_val)) { active = true }

    var wrapper = $(
      crel("div", { class: ["time-input", active ? "active" : ""].join(" ") },
        create_time_select(),
        create_meridian_select(),
        "to",
        create_time_select(),
        create_meridian_select(),
        (include_remove ? crel("div", { class: "remove-time" }) : undefined)
      )
    )

    set_val_from_initial(wrapper, start_val, end_val)

    wrapper.find(".select-box").each(function (i, el) {
      $(el).selectInput({ placeholder: $el.data("placeholder") })
    })

    wrapper.on("select-change click change", function () { $el.trigger("change") })

    wrapper.val = function get_time_input_value () {
      var res = wrapper.find(".select-input-selected").map(get_selected).get()
      var time = createTimesFrom.apply(null, res)
      if (time.indexOf("NaN") > -1) {
        return ""
      }
      return time
    }

    wrapper.title_val = function get_title_value () {
      var res = wrapper.find(".select-input-selected").map(get_selected).get()
      return res[0] + res[1] + " - " + res[2] + res[3]
    }

    wrapper.validate = function validate () {
      var res = wrapper.find(".select-input-selected").map(get_selected).get()
      var s = normaliseTime(res[0], res[1])
      var f = normaliseTime(res[2], res[3])

      // if either are empty, this input is not valid
      if (isNaN(s) && isNaN(f)) {
        return "empty"
      }

      return !(isNaN(s) || isNaN(f))
    }

    wrapper.on("click", ".remove-time", function () {
      var index = $el[0].time_input_instances.indexOf(wrapper)
      if (index > -1) {
        $el[0].time_input_instances.splice(index, 1)
      }
      wrapper.remove()
    })

    $el[0].time_input_instances.push(wrapper)

    return wrapper
  }

  function update_wrapper_parent_active_state (wrapper) {
    var parent = wrapper.parents(".time-input-toggle-parent-js")
    var hidden = parent.find("[type=hidden]")

    if (hidden.val()) {
      parent.find(".option-box").addClass("selected")
    } else {
      parent.find(".option-box").removeClass("selected")
    }
  }

  this.each(function (i, el) {
    var $el = $(el)
    window.utils.hide_parent($el)

    var initial_values = $el.val().split(",")
    var last_insert

    el.time_input_instances = el.time_input_instances || []

    if (initial_values.length) {
      _.each(initial_values, function (val, index) {
        var initial_value = val.split("-")
        var start_val
        var end_val
        if (initial_value.length > 1) {
          // we have a start and end
          start_val = initial_value[0]
          end_val = initial_value[1]
        }

        var wrapper = generateTimeInputDom(opts.active, $el, index !== 0, start_val, end_val)

        wrapper.insertAfter(last_insert || $el)
        last_insert = wrapper

        update_wrapper_parent_active_state(wrapper)
      })
    } else {
      var wrapper = generateTimeInputDom(opts.active, $el, false)
      wrapper.insertAfter($el)
      update_wrapper_parent_active_state(wrapper)
    }

    el.add_new_row = function ($el) {
      var wrapper = generateTimeInputDom(true, $(this), true)
      wrapper.insertAfter($(this).siblings(".time-input").last())
    }

    el.is_time_input = true
  })
}
;
// http://www.tikalk.com/incubator/week-picker-using-jquery-ui-datepicker
// tweaked to select a fortnight instead (optionally)

(function ($) {
  $.weekpicker = {
    same_fortnight_cycle: function (d1, d2) {
      // returns true if both dates are in the same fortnight cycle.
      // ie. assuming that one date is in the org's fortnightly timesheet cycle, return true if the other is too.
      var d1_m = moment(d1)
      var d2_m = moment(d2)

      return Math.abs(d1_m.diff(d2_m, "w")) % 2 === 0
    },
    week_start_date_for_clicked_date: function (date, week_start_wday, single_day) {
      if (single_day) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate())
      } else {
        var wday = date.getDay()
        var base = date.getDate() - wday
        var diff = week_start_wday

        if (week_start_wday > wday) {
          diff -= 7
        }

        return new Date(date.getFullYear(), date.getMonth(), base + diff)
      }
    },
    week_range_text: function (dateFormat, start, end, settings) {
      return $.datepicker.formatDate(dateFormat, start, settings) + " - " + $.datepicker.formatDate(dateFormat, end, settings)
    },
    addDays: function (date, days) {
      var result = new Date(date)
      result.setDate(result.getDate() + days)
      return result
    },
    highlighters: {
      mousemove: {
        weekly: function (e) {
          e.find("td a").addClass("ui-state-hover")
        },
        fortnightly: function (e, date_to_compare) {
          var firstCellHoveredWeek = e.find("td:first")
          var firstDateHoveredWeek = new Date(parseInt(firstCellHoveredWeek.data("year"), 10), parseInt(firstCellHoveredWeek.data("month"), 10), parseInt(firstCellHoveredWeek.text(), 10))
          if ($.weekpicker.same_fortnight_cycle(firstDateHoveredWeek, date_to_compare)) {
            e.find("td a").addClass("ui-state-hover")
            e.next("tr").find("td a").addClass("ui-state-hover")
          } else {
            e.find("td a").addClass("ui-state-hover")
            e.prev("tr").find("td a").addClass("ui-state-hover")
          }
        },
      },
      mouseleave: {
        weekly: function (e) {
          e.find("td a").removeClass("ui-state-hover")
        },
        fortnightly: function (e) {
          e.find("td a").removeClass("ui-state-hover")
          e.next("tr").find("td a").removeClass("ui-state-hover")
          e.prev("tr").find("td a").removeClass("ui-state-hover")
        },
      },
    },
  }

  $.fn.weekpicker = function (options) {
    var input = this
    var single_day = options.single_day != null && options.single_day // default: false
    var week_start = options.firstDay != null ? options.firstDay : 1 // default: 1
    var fortnight = options.fortnight != null && options.fortnight // default: false
    var frequency = options.frequency
    if (!frequency) {
      frequency = options.fortnight != null && options.fortnight ? "fortnightly" : "weekly"
    }
    var start = options.start
    var format = options.dateFormat || $.datepicker.ISO_8601
    if (start) { start = $.datepicker.parseDate(format, start) }
    var end = options.end
    if (end) { end = $.datepicker.parseDate(format, end) }
    var selected_date = options.selected_date
    if (selected_date) { selected_date = $.datepicker.parseDate(format, selected_date) }
    if (options.minDate) { options.minDate = $.datepicker.parseDate(format, options.minDate) }
    var timesheet_lookup_props = options.timesheet_lookup_props || null
    var use_querystring_url = !timesheet_lookup_props
    var timesheets_url_date_format = format
    var no_navigation = options.no_navigation != null && options.no_navigation // default: false
    var write_date_range = options.write_date_range != null && options.write_date_range // default: false
    var display_on_load = options.display_on_load
    var pay_period_start_date = options.pay_period_start_date
    if (pay_period_start_date) { pay_period_start_date = $.datepicker.parseDate(format, pay_period_start_date) }
    var date_to_compare = pay_period_start_date || start

    if (display_on_load && write_date_range && start && end) {
      $(this).val($.weekpicker.week_range_text(format, start, end))
    }

    return input.datepicker(_.extend({
      showOtherMonths: true,
      selectOtherMonths: true,
      dateFormat: "dd/mm/yy",
      defaultDate: selected_date || start,
    }, options, {
      // Always run the default handler, then also run the attached handler if it is passed.
      onClose: function (event, ui) {
        options.onClose && options.onClose.call(this, event, ui)
        return onClose.call(this, event, ui)
      },
      onSelect: function (event, ui) {
        options.onSelect && options.onSelect.call(this, event, ui)
        return onSelect.call(this, event, ui)
      },
      beforeShow: function (event, ui) {
        options.beforeShow && options.beforeShow.call(this, event, ui)
        return beforeShow.call(this, event, ui)
      },
      beforeShowDay: function (date) {
        options.beforeShowDay && options.beforeShowDay.call(this, date)
        return beforeShowDay.call(this, date)
      },
    }))

    function onClose (event, ui) {
      $(document).off(".weekpicker")
    }
    function beforeShow (event, ui) {
      if (!single_day) {
        $(document).on("mousemove.weekpicker", ".ui-datepicker-calendar tr", function () {
          var e = $(this)
          $.weekpicker.highlighters.mousemove[frequency] && $.weekpicker.highlighters.mousemove[frequency](e, date_to_compare)
        })
        $(document).on("mouseleave.weekpicker", ".ui-datepicker-calendar tr", function () {
          var e = $(this)
          $.weekpicker.highlighters.mouseleave[frequency] && $.weekpicker.highlighters.mouseleave[frequency](e)
        })
      }

      if (input.is(":hidden")) {
        setTimeout(function () {
          ui.dpDiv.css({ top: parseFloat(ui.dpDiv.css("top")) + 23 })
            // http://stackoverflow.com/a/11217955/641293
            // https://trello.com/c/olpVgezi
            // jquery UI gets offset() + height of element it's being set on
            // but if element is hidden (eg. input[type=hidden]) it will have a zero height
            // and thus will get awkwardly overridden by the datepicker
        }, 5)
      }
    }
    function onSelect (dateText, inst) {
      var date = $(this).datepicker("getDate")

      start = $.weekpicker.week_start_date_for_clicked_date(date, week_start, single_day)

      if (!single_day) {
        if (fortnight) {
          if (!$.weekpicker.same_fortnight_cycle(start, date_to_compare)) {
            start.setDate(start.getDate() - 7)
          }
          end = $.weekpicker.addDays(start, 13)
        } else {
          end = $.weekpicker.addDays(start, 6)
        }
      }

      var dateFormat = inst.settings.dateFormat || $.datepicker._defaults.dateFormat
      if (write_date_range) {
        $(this).val($.weekpicker.week_range_text(dateFormat, start, end, inst.settings))
      }

      if (!no_navigation) {
        var url = window.location.pathname
        if (use_querystring_url) {
          url = querystring_set(url, "start", $.datepicker.formatDate(dateFormat, start, inst.settings))
          if (!single_day) { url = querystring_set(url, "end", $.datepicker.formatDate(dateFormat, end, inst.settings)) }
        } else if (timesheet_lookup_props) {
          var formatted_date = $.datepicker.formatDate(timesheets_url_date_format, date, inst.settings)
          url = Routes.timesheets_lookup_path(_.extend(timesheet_lookup_props, { date: formatted_date }))
        }

        window.location.href = window.location.origin + url
      }
    }
    function beforeShowDay (date) {
      var cssClass = ""
      if (start && end) {
        if (date >= start && date <= end) {
          cssClass = "ui-datepicker-current-day ui-state-active"
        }
      }
      return [true, cssClass]
    }
  }
})(jQuery)
;
$.fn.LBRDownload = function (org_id) {
  $(this).ready(function () {
    var lbrDownloadButton = $("#lbr-download-button")

    lbrDownloadButton.on("click", function () {
      window.trackEvent("LBR csv download", {
        org_id: org_id,
      })
    })
  })
}
;
// locations form
$.fn.mapLoginRadius = function (selectors) {
  var address = $(selectors.address)
  var latitude = $(selectors.latitude)
  var longitude = $(selectors.longitude)
  var map_radius = $(selectors.map_radius)[0]
  var login_radius = $(selectors.login_radius)
  var center = { lat: parseFloat(latitude.val()), lng: parseFloat(longitude.val()) }

  var geocoder = new google.maps.Geocoder()

  var map = new google.maps.Map(map_radius, {
    center: center,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    draggable: false,
    scrollwheel: false,
    panControl: false,
  })

  var circle = new google.maps.Circle({
    strokeColor: "#3FAFD7",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#3FAFD7",
    fillOpacity: 0.35,
    map: map,
    center: center,
    radius: parseInt(login_radius.val()),
    editable: true,
    draggable: false,
  })

  map.fitBounds(circle.getBounds())

  longitude.on("change", function () {
    center = { lat: parseFloat(latitude.val()), lng: parseFloat(longitude.val()) }
    circle.setCenter(center)
    map.fitBounds(circle.getBounds())
  })

  google.maps.event.addListener(circle, "radius_changed", function () {
    login_radius.val(Math.floor(circle.getRadius()))
    map.fitBounds(circle.getBounds())
  })

  login_radius.on("change", function (event) {
    circle.setRadius(parseInt(event.target.value))
  })

  google.maps.event.addListener(circle, "center_changed", function () {
    map.fitBounds(circle.getBounds())
    geocoder.geocode({ location: { lat: circle.center.lat(), lng: circle.center.lng() } }, function (results, status) {
      if (status === "OK" && results[0]) {
        // only autocorrect address if center lat long is different from input lat long
        //
        // ie. only change address input if user moves the map center on map canvas
        // dont override address input if user types in address manually
        if (
          latitude.val().toString() !== circle.center.lat().toString() &&
          longitude.val().toString() !== circle.center.lng().toString()
        ) {
          address.val(results[0].formatted_address)
        }
        latitude.val(circle.center.lat())
        longitude.val(circle.center.lng())
      }
    })
  })
}
;
window.NotificationSendoutFilter = function (parent_element, userlist) {
  // select all, none, etc.
  parent_element.on("click", "a", function () {
    var val
    var boxes

    switch ($(this).attr("data-filter")) {
      case "all":
        val = true
        boxes = userlist.find("[type=checkbox]")
        break
      case "all-phones":
        val = true
        boxes = userlist.find("li").filter(function () { return $(this).find(".mi-error ").length === 0 }).find("[type=checkbox]")
        break
      case "none":
        val = false
        boxes = userlist.find("[type=checkbox]")
        break
      case "no-phones":
        val = false
        boxes = userlist.find("li").filter(function () { return $(this).find(".mi-error ").length > 0 }).find("[type=checkbox]")
        break
    }
    boxes.prop("checked", val)
    return false
  })
}

window.NotificationSendoutQueryStringFilter = function (userlist, param) {
  // autoselect user based on querystring
  var ids = querystring_get(param || "users")
  if (!ids) { return }
  var qsusers = decodeURIComponent(ids).split(",").map(function (user_id) { return parseInt(user_id, 10) })
  if (qsusers.length) {
    userlist.find("[type=checkbox]").prop("checked", false)
            .filter(function () {
              return $.inArray(parseInt(this.value, 10), qsusers) > -1
            }).prop("checked", true)
  }
}
;
window.OAuthPopup = {
  Singleton: null,
  override_oauth_redirect: function (newRedirectTo) {
    if (window.OAuthPopup.Singleton && window.OAuthPopup.Singleton.override_oauth_redirect) {
      window.OAuthPopup.Singleton.override_oauth_redirect(newRedirectTo)
      return true
    }
    return false
  },
  clear_singleton: function () {
    if (window.OAuthPopup.Singleton) {
      window.clearInterval(window.OAuthPopup.Singleton._oauthInterval)
      window.OAuthPopup.Singleton = null
    }
  },
  create: function (options) {
    window.OAuthPopup.clear_singleton()

    var default_callback = false

    if (_.isUndefined(options.callback) && options.redirectTo) {
      default_callback = true
      options.callback = function () {
        window.navigate_to(options.redirectTo, { force: true })
        window.LH.Spin.stop()
      }
    }

    options = $.extend({
      title: "ConnectWithOAuth",
      width: 600,
      height: 800,
      callback: function () {
        window.location.reload()
      },
    }, options)

    // Fixes dual-screen position                            Firefox        Most browsers
    var dual_screen_left = _.isUndefined(window.screenLeft) ? screen.left : window.screenLeft
    var dual_screen_top = _.isUndefined(window.screenTop) ? screen.top : window.screenTop

    var window_width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
    var window_height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

    var left = ((window_width / 2) - (options.width / 2)) + dual_screen_left
    var top = ((window_height / 2) - (options.height / 2)) + dual_screen_top
    var window_options = "location=0,status=0,width=" + options.width + ",height=" + options.height + ",top=" + top + ",left=" + left

    var that = this
    that.override_oauth_redirect = function (newRedirectTo) {
      options.redirectTo = newRedirectTo
    }

    if (!options.path) {
      console.error("window.OAuthPopup is missing a path!")
    }

    that._oauthWindow = window.open(options.path, options.title, window_options)

    if (window.focus) {
      that._oauthWindow.focus()
    }

    that._oauthInterval = window.setInterval(function () {
      if (that._oauthWindow.closed) {
        if (default_callback || options.spin) { window.LH.Spin.start() }
        window.clearInterval(that._oauthInterval)
        options.callback()
      }
    }, 500)

    window.OAuthPopup.Singleton = that
  },
}
;
(function ($) {
  $.fn.perPageFilter = function (options) {
    options = options || {}
    var per_page_param = options.per_page_param || "per_page"
    var page_param = options.page_param || "page"

    return this.on("change", function () {
      var url = window.location.href
      url = querystring_set(url, per_page_param, $(this).val())
      url = querystring_set(url, page_param, null)
      window.navigate_to(url)
    })
  }
})(jQuery)
;
$.fn.pickerSearch = function (hide_palette_function, on_search) {
  var palette = this
  var lastSearchString
  var searchfield = palette.find(".search-picker-input")

  on_search = on_search || _.noop

  function search_changed () {
    var current_search_val = $(this.searchField).val()
    if (lastSearchString !== current_search_val) {
      setTimeout(function () {
        palette.find(".colorPicker-item").removeClass("highlight")
        palette.find(".colorPicker-item:visible:first").addClass("highlight")
        on_search()
      }, 5)
      lastSearchString = current_search_val
    }
  }

  return palette.searchable({
    searchField: "#" + searchfield.attr("id"),
    selector: ".searchable-item-js,.searchable-parent-js",
    childSelector: ".searchable-text-js",
    matchOnElement: true,
    matcherFunction: function (term) {
      term = term.trim().toLowerCase()
      return function (element) {
        if (element.text().toLowerCase().indexOf(term) !== -1) {
          return true
        } else if (element.hasClass(".employee-option-js")) {
            // Don't try to search up the tree if in employee picker
          return false
        }
        var parent = element.parents(".searchable-item-js,.searchable-parent-js").first()
        if (parent.length) {
          if (parent.is(".searchable-item-js")) {
              // is a team. check parent location's name against search term.
            return parent.prevAll(".searchable-parent-js").first().find(".searchable-text-js").first().text().toLowerCase().indexOf(term) !== -1
          } else if (parent.is(".searchable-parent-js")) {
              // is a location. show if any teams should be showing.
            return _.some(parent.nextUntil(".searchable-parent-js", ".searchable-item-js"), function (elem) {
              return $(elem).find(".searchable-text-js").first().text().toLowerCase().indexOf(term) !== -1
            })
          }
        }
      }
    },
    onSearchActive: search_changed,
    onSearchEmpty: search_changed,
    onSearchBlur: function () {
      palette.find(".colorPicker-item").show()
    },
  }).off("keydown.pickersearch").on("keydown.pickersearch", function (e) {
    var itemSelected = $(this).find(".highlight")

    if (e.which === 13) { // enter
      itemSelected.mousedown()
    } else if (e.which === 27) { // ESC
      hide_palette_function && hide_palette_function()
    } else if (e.which === 40) { // down arrow
      if (itemSelected) {
        itemSelected.removeClass("highlight")
        var next = itemSelected.nextAll(":visible:first")
        if (next.length > 0) {
          next.addClass("highlight")
        } else {
          palette.find(".colorPicker-item:visible:first").addClass("highlight")
        }
      } else {
        palette.find(".colorPicker-item:visible:first").addClass("highlight")
      }
    } else if (e.which === 38) { // up arrow
      if (itemSelected) {
        itemSelected.removeClass("highlight")
        var prev = itemSelected.prevAll(":visible:first")
        if (prev.length > 0) {
          prev.addClass("highlight")
        } else {
          palette.find(".colorPicker-item:visible:last").addClass("highlight")
        }
      } else {
        palette.find(".colorPicker-item:visible:last").addClass("highlight")
      }
    }
  })
}
;
$.fn.preventNegativeLeave = function () {
  $(this).ready(function () {
    var preventNegativeLeaveCheckBox = $("#award_leave_prevent_negative")
    var preventNegativeAdditionalSettings = $("#prevent_negative_additional_settings")
    var withoutDefaultFallbackCheckBox = $("#without_default_fallback")
    var withDefaultFallbackCheckBox = $("#with_default_fallback")
    var fallbackSelectElement = $("#award_leave_default_fallback")
    var emptyFallbackWarning = $("#empty_fallback_warning")
    var ignorePredictedAccruals = $("#prevent_negative_ignore_predictions_wrapper")
    var ignorePredictedAccrualsCheckBox = $("#leave_prevent_negative_ignore_predictions")

    if (preventNegativeLeaveCheckBox.is(":unchecked")) {
      preventNegativeAdditionalSettings.hide()
      fallbackSelectElement.val(null).hide()
      emptyFallbackWarning.hide()
      ignorePredictedAccruals.hide()
    } else {
      if (!fallbackSelectElement.val()) {
        withoutDefaultFallbackCheckBox.prop("checked", true)
        withDefaultFallbackCheckBox.prop("checked", false)
        fallbackSelectElement.hide()
        emptyFallbackWarning.hide()
      } else {
        withoutDefaultFallbackCheckBox.prop("checked", false)
        withDefaultFallbackCheckBox.prop("checked", true)
        emptyFallbackWarning.hide()
      }
    }

    preventNegativeLeaveCheckBox.on("change", function () {
      if ($(this).is(":checked")) {
        preventNegativeAdditionalSettings.show()
        ignorePredictedAccruals.show()
        withoutDefaultFallbackCheckBox.prop("checked", true)
      } else {
        preventNegativeAdditionalSettings.hide()
        ignorePredictedAccruals.hide()
        withDefaultFallbackCheckBox.prop("checked", false)
        fallbackSelectElement.val(null).hide()
        emptyFallbackWarning.hide()
      }
    })

    withoutDefaultFallbackCheckBox.on("click", function () {
      if ($(this).is(":unchecked")) {
        $(this).prop("checked", true)
      } else {
        withDefaultFallbackCheckBox.prop("checked", false)
        fallbackSelectElement.val(null).hide()
        emptyFallbackWarning.hide()
      }
    })

    withDefaultFallbackCheckBox.on("click", function () {
      if ($(this).is(":unchecked")) {
        $(this).prop("checked", true)
      } else {
        withoutDefaultFallbackCheckBox.prop("checked", false)
        ignorePredictedAccrualsCheckBox.prop("checked", false)
        fallbackSelectElement.show()
        emptyFallbackWarning.show()
      }
    })

    fallbackSelectElement.on("change", function () {
      var value = !$(this).val()
      emptyFallbackWarning.toggle(value)
    })
  })
}
;
$.fn.addEffectiveDatesTrigger = function (qualification_id) {
  var date_object = date_object || {}

  $(this).on("click", function () {
    $(".date-range-" + qualification_id).find(".date-row-js").append(createDateRangeDropdown)
  })

  $(".date-range-" + qualification_id).on("click", ".remove-date-js", function () {
    $(this).parent().remove()
  })

  var createDateRangeDropdown = function (qualification_id) {
    var date_range_dropdown = crel("div", { class: "date-row" },
                                crel("div", { class: "hidden selected-dates-js" }),
                                crel("span", { class: "pad-right" }, I18n.t("js.users.qualification.from")),
                                create_day_select(true),
                                create_month_select(true),
                                create_year_select(true),
                                crel("span", { class: "pad-right pad-left" }, I18n.t("js.users.qualification.to")),
                                create_day_select(false),
                                create_month_select(false),
                                create_year_select(false),
                                crel("i", { class: "mi mi-close remove-date-js pad-left" })
                              )

    return date_range_dropdown
  }

  var create_day_select = function (is_start_date) {
    var name = is_start_date ? "user[effective_dates][" + qualification_id + "][][dates][start_date(3i)]" : "user[effective_dates][" + qualification_id + "][][dates][end_date(3i)]"
    return crel("select", { class: "auto-width date-select", name: name }, create_day_options())
  }

  var create_month_select = function (is_start_date) {
    var name = is_start_date ? "user[effective_dates][" + qualification_id + "][][dates][start_date(2i)]" : "user[effective_dates][" + qualification_id + "][][dates][end_date(2i)]"
    return crel("select", { class: "auto-width date-select", name: name }, create_month_options())
  }

  var create_year_select = function (is_start_date) {
    var name = is_start_date ? "user[effective_dates][" + qualification_id + "][][dates][start_date(1i)]" : "user[effective_dates][" + qualification_id + "][][dates][end_date(1i)]"
    return crel("select", { class: "auto-width date-select", name: name }, create_year_options())
  }

  var create_day_options = function (selected_day) {
    return _.chain(32)
      .range()
      .map(function (i) { return create_day_option(i, selected_day) })
      .value()
  }

  var create_month_options = function () {
    var months = ["", "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"]

    return _.map(months, function (month, idx) {
      return crel("option", { value: idx }, month)
    })
  }

  var create_year_options = function () {
    return _.chain(32)
      .range()
      .map(function (i) { return create_year_option(i) })
      .value()
  }

  var create_day_option = function (day) {
    if (day === 0) {
      return crel("option")
    }

    return crel("option", { value: day }, day)
  }

  var create_year_option = function (year) {
    if (year === 0) {
      return crel("option")
    }
    return crel("option", { value: year + 2004 }, year + 2004)
  }
}
;
/* the following files all must match:
/config/initializers/03_post_config_patches/tanda_asset_url.rb
/app/assets/webpack/helpers/image.js
/app/assets/javascripts/extras/s3_image_paths.js
/app/helpers/s3_image_helper.rb
*/


window.s3_image_path = function (path) {
  return "https://payauspics.s3-ap-southeast-2.amazonaws.com/web/" + path
}
;
/*
a simple search box that works with a DataTable
pass through a table param that's returned from a $().DataTable({}) call

usage:
  $("#searchbox").searchbox(DataTableInstance)

see also http://stackoverflow.com/a/19276854/641293
*/


$.fn.searchbox = function (table, options) {
  options = $.extend({ bind_enter: true }, options)
  var box = this

  if (table) {
    box.on("keyup search input paste cut", function () {
      table.search(this.value.replace(",", " ")).draw()
    })

    if (options.bind_enter) {
      box.on("keyup", function (e) {
        if (e.keyCode === 13) {
          var $row = $(table.row({ search: "applied" }).node())

          if ($row.parents("tbody").data("rowlink")) {
            // If the table has row links, click the first cell (that isn't a nolink cell)
            $row.children(":not(.nolink)").first().click()
          } else {
            // Otherwise click the first link in the row
            var el = $row.find("a").first()[0]
            el && el.click()
          }
        }
      })
    }
  }

  Mousetrap.bind("mod+f", function () {
    if (box.parents("body").length) {
      box.focus()
      return false
    }
  })
  return box
}
;
$.fn.secondaryExport = function () {
  $(this).ready(function () {
    var secondaryExportSlider = $("#secondary_export_slider")
    var secondaryExportContainer = $("#secondary_export_container")

    var secondaryExportShowHide = function () {
      if (secondaryExportSlider.is(":unchecked")) {
        secondaryExportContainer.hide()
      } else {
        secondaryExportContainer.show()
      }
    }

    secondaryExportShowHide()

    secondaryExportSlider.on("change", function () {
      secondaryExportShowHide()
    })
  })
}
;
(function ($) {
  $.selectFilter = function (options) {
    var btn_groups = []

    function updateAndNavigate (e) {
      var url = window.location.href.replace(/#[^#]*$/, "") // strip out the hash - http://stackoverflow.com/a/9513814/641293
      url = querystring_set(url, "page", null)

      $.each(btn_groups, function (_, btn) {
        url = querystring_set(url, btn.attr("data-qs-path"), btn.attr("data-filter-selected"))
      })

      var ele = $(this)
      if (ele.is("select")) {
        url = querystring_set(url, ele.attr("data-qs-path"), ele.val())
      } else {
        url = querystring_set(url, ele.parents(".btn-group").attr("data-qs-path"), ele.attr("data-filter"))
      }

      if (e.ctrlKey || e.metaKey || e.which === 2) {
        window.open(url)
      } else {
        window.navigate_to(url)
      }

      return false
    }

    for (var i = 0; i < options.btn_groups.length; i++) {
      var btn_group = $(".btn-group." + options.btn_groups[i].name)
      var is_select = false

      if (!btn_group.length) {
        btn_group = $("select." + options.btn_groups[i].name)
        is_select = true
      }

      btn_group.attr("data-qs-path", options.btn_groups[i].path)

      btn_groups.push(btn_group)

      if (is_select) {
        btn_group.on("change", updateAndNavigate)
      } else {
        btn_group.on("click", "a[data-filter]", updateAndNavigate)
      }
    }
  }
})(jQuery)
;
window.initSettingsJS = function (initial_values) {
  $("#setting_enable_predictive_scheduling").on("change", function (e) {
    var isChecked = e.target.checked
    if (isChecked) {
      $("#predictive_scheduling_actions").show()
    } else {
      $("#predictive_scheduling_actions").hide()
    }
  })

  $("#enable_predictive_headcounts").on("change", function (e) {
    var isChecked = e.target.checked
    $("#enable_predictive_headcounts input[type=checkbox]").prop("checked", isChecked)
  })

  $("#setting_enable_shift_feedback").on("change", function (e) {
    var isChecked = e.target.checked
    if (isChecked) {
      $("#feedback_links").show()
    } else {
      $("#feedback_links").hide()
    }
  })

  $(".show.advanced-toggle h3").on("click", function () {
    $(".advanced").css("display", "block")
    $(".show.advanced-toggle").css("display", "none")
    $(".hide.advanced-toggle").css("display", "block")

    if (initial_values && initial_values.enabledMonthlyOTAveraging === "true") {
      $(".overtime-form-section").hide()
    } else {
      $("input#enable-monthly-ot-averaging[type=checkbox]").prop("checked", false)
      $(".monthly-ot-averaging").css("display", "none")
    }
  })

  $(".hide.advanced-toggle h3").on("click", function () {
    $(".advanced").css("display", "none")
    $(".hide.advanced-toggle").css("display", "none")
    $(".show.advanced-toggle").css("display", "block")
  })

  $(".show.holidays-toggle h3").on("click", function () {
    $(".holidays").css("display", "block")
    $(".show.holidays-toggle").css("display", "none")
    $(".hide.holidays-toggle").css("display", "block")
  })

  $(".hide.holidays-toggle h3").on("click", function () {
    $(".holidays").css("display", "none")
    $(".hide.holidays-toggle").css("display", "none")
    $(".show.holidays-toggle").css("display", "block")
  })

  $("#enable_timeoff_calendar_for_staff").on("click", function (e) {
    var $checkbox = $(e.target)
    var isChecked = $checkbox.is(":checked")
    var $calendarViewOptions = $("#setting_time_off_calendar_view")
    $calendarViewOptions.toggle(isChecked)

    if (!isChecked) {
      $calendarViewOptions[0].selectedIndex = 0
    }
  })

  $(document).ready(function () {
    showHideScheduleSwapSettings()
  })

  $("#setting_enable_schedule_swapping").on("click", function (event) {
    showHideScheduleSwapSettings()
  })

  $("#setting_enable_one_to_one_schedule_swapping").on("click", function (event) {
    showHideScheduleSwapSettings()
  })

  $(document).ready(function () {
    showHideReqApprovalVacShiftClaim()
  })

  $("#setting_shift_claiming_enabled").on("click", function (event) {
    showHideReqApprovalVacShiftClaim()
  })


  $(document).ready(function () {
    var taggedItems = $("#protect_manager_salaries_checkbox").children().get()
    taggedItems.map(toggleAddManagerSalaries)
  })

  $("#protect_manager_salaries_checkbox").click(function () {
    var taggedItems = $("#protect_manager_salaries_checkbox").children().get()
    taggedItems.map(toggleAddManagerSalaries)
  })

  function toggleAddManagerSalaries(taggedItem) {
    if (taggedItem.id && taggedItem.checked) {
      $("#add_manager_salaries_to_totals").show()
    } else {
      $("#add_manager_salaries_to_totals").hide()
    }
  }

  function showHideScheduleSwapSettings() {
    if ($("#setting_enable_schedule_swapping").is(":checked")) {
      $("#schedule-swap-self-curate-container").show()
    } else {
      $("#schedule-swap-self-curate-container").hide()
    }
    if ($("#setting_enable_schedule_swapping").is(":checked") || $("#setting_enable_one_to_one_schedule_swapping").is(":checked")) {
      $("#setting_schedule_swap_unsupervised").prop("disabled", false)
      $("#schedule-swap-unsupervised-container").show()
      $("#schedule-swap-self-curate-container").show()
    } else {
      $("#schedule-swap-unsupervised-container").hide()
      $("#setting_schedule_swap_unsupervised").prop("disabled", true)
      $("#schedule-swap-self-curate-container").hide()
    }
  }

  function showHideReqApprovalVacShiftClaim() {
    if ($("#setting_shift_claiming_enabled").is(":checked")) {
      $("#require-approval-vacant-shift-claiming-container").show()
    } else {
      $("#require-approval-vacant-shift-claiming-container").hide()
    }
  }

  $("#setting_shift_claiming_enabled").on("click", function (e) {
    var $checkbox = $(e.target)
    var isChecked = $checkbox.is(":checked")
    var requiresManagersApproval = $("#setting_require_approval_vacant_shift_claiming")
    var fallbackDepartments = $("#setting_enable_fallback_departments")

    if (isChecked) {
      requiresManagersApproval.prop("disabled", false)
      fallbackDepartments.prop("disabled", false)
    } else {
      requiresManagersApproval.prop("disabled", true)
      fallbackDepartments.prop("disabled", true)
    }
  })

  $("#setting_enable_labour_budget").on("change", function (e) {
    var isChecked = e.target.checked
    if (isChecked) {
      $("#labour_budget_actions").show()
    } else {
      $("#labour_budget_actions").hide()
    }
  })

  $("#setting_enable_timesheet_esigning").on("change", function (e) {
    var isChecked = e.target.checked
    if (isChecked) {
      $("#esigning_tags").show()
    } else {
      $("#esigning_tags").hide()
    }
  })

  $("#select_all_esigning_tags").on("change", function (e) {
    var isChecked = e.target.checked
    $("#esigning_tags input[type=checkbox]").prop("checked", isChecked)
  })

  var $wageCompareAverageCadence = $("#setting_wage_compare_setting_attributes_averaging_cadence")
  var $wageCompareAnchorDate = $("#setting_wage_compare_setting_attributes_custom_anchor_date")

  function updateWageCompareCustomSettingsVisibility() {
    if ($wageCompareAverageCadence.length === 0) { return }

    var customSettingsVisible = $wageCompareAverageCadence.val() === "other"
    $(".outer_limits_custom_option").toggle(customSettingsVisible)

    var garbagePlaceholderDate = "0001-01-01"

    if (customSettingsVisible && $wageCompareAnchorDate.val() === garbagePlaceholderDate) {
      $wageCompareAnchorDate.val("")
    }

    if (!customSettingsVisible && $wageCompareAnchorDate.val() === "") {
      $wageCompareAnchorDate.val(garbagePlaceholderDate)
    }
  }

  $wageCompareAverageCadence.on("change", updateWageCompareCustomSettingsVisibility)
  updateWageCompareCustomSettingsVisibility()
}
;
$.fn.showHolidayDatePicker = function (initial_values, automatic_values) {
  var form = this
  var public_holiday_dates = form.find("[data-field=show_holiday_dates]")
  var other_dates_area = form.find(".other_dates")
  var line_selector = ".date_pick"
  var template = form.find(line_selector).clone()

  /**
  * format a YYYY-MM-DD string more neatly for date display
  */
  var formatPublicHolidayDateForDisplay = function (string_or_moment) {
    if (typeof string_or_moment === "string") {
      string_or_moment = string_or_moment.split("-")
      if (string_or_moment.length === 3) {
        string_or_moment = createMoment(string_or_moment[0], string_or_moment[1], string_or_moment[2])
      } else {
        return ""
      }
    }
    return string_or_moment.format("MMMM D, YYYY")
  }

  /**
   * Create a moment based on y, m, d
   */
  var createMoment = function (y, m, d) {
    // subtract 1 from moment, since moment().month(11) puts you in december
    return moment().year(y).month(parseInt(m, 10) - 1).date(d)
  }

  function parseTimeFromInput (date_pick_area, klass) {
    var hour = date_pick_area.find(".time." + klass).val()
    var time = hour.toTimeParts()

    if (time && ((time[0] < 24 && time[1] < 60) || (time[0] === "24" && time[1] === "00"))) {
      var minutes = parseInt(time[1]) / 60
      return parseInt(time[0]) + minutes
    }
  }

  function zeroPad (string, zeroes_to_add) {
    var s = ""
    for (var i = 0; i < zeroes_to_add; i++) { s += "0" }
    return (s + string).slice(-zeroes_to_add)
  }

  function writeTimesToDOM (date_pick_area, klass, time) {
    var hour = zeroPad(parseInt(time).toString(), 2)
    var min_float = time % 1
    var min = zeroPad(parseInt(min_float * 60).toString(), 2)
    date_pick_area.find(".time." + klass).val(hour + ":" + min)
  }

  /**
  * iterate over all of the public holiday datepickers, and update the list of selected dates
  * then write it to the hidden field we store them in
  */
  var updatePubHolDates = function () {
    var date_objects = other_dates_area.find(line_selector).map(function () {
      var date_pick_area = $(this)
      var date = (date_pick_area.find(".datepicker").data("date") || "").split("-")
      var from
      var to
      var times_visible = !date_pick_area.find(".toggle-times").is(":checked")

      if (date && date.length === 3) {
        date = createMoment(date[0], date[1], date[2]).format("YYYY-MM-DD")
      }

      if (times_visible) {
        from = parseTimeFromInput(date_pick_area, "from")
        to = parseTimeFromInput(date_pick_area, "to")
      }

      return { date: date, from: from, to: to }
    }).get().compact()

    public_holiday_dates.val(dateObjectToHiddenFieldFormat(date_objects))
  }

  var addNewDatepickerLine = function (should_trigger, date_object) {
    date_object = date_object || {}

    var ele = template.clone()
    if (date_object.date) {
      ele.find(".datepicker").attr("data-date", date_object.date).prop("value", formatPublicHolidayDateForDisplay(date_object.date))
    }
    ele.appendTo(other_dates_area)
    ele.find(".datepicker").datepicker(datepick_settings)
    if (should_trigger) {
      ele.find(".datepicker").trigger("focus")
    }
    if (date_object.from && date_object.to) {
      writeTimesToDOM(ele, "from", date_object.from)
      writeTimesToDOM(ele, "to", date_object.to)
      ele.find(".toggle-times").click()
    }
    ele.find("[tooltip]").loadTooltips({ placement: "top" })
    var duplicates = _.filter(automatic_values, function (date_obj) { return _.isEqual(date_obj, date_object) })
    if (!_.isEmpty(duplicates)) {
      ele.addClass("disabled")
    }
  }

  var hideAddIconsAsAppropriate = function () {
    var lines = other_dates_area.find(line_selector)

    // hide "add" icons from all but last line
    lines.last().find(".add-datepicker").css({ visibility: "visible" })
    lines.not(":last").find(".add-datepicker").css({ visibility: "hidden" })
  }

  var dateObjectToHiddenFieldFormat = function (date_object) {
    return date_object.map(function (date_obj) { return [date_obj.date, date_obj.from, date_obj.to].compact().join(",") }).join("|")
  }

  var hiddenFieldFormatToDateObject = function (hidden_field_val) {
    return hidden_field_val.split("|").map(function (date_obj) {
      var split = date_obj.split(",")
      var obj = { date: split[0] }
      if (split[1]) { obj.from = parseFloat(split[1]) }
      if (split[2]) { obj.to = parseFloat(split[2]) }
      return obj
    })
  }

  var datepick_settings = {
    dateFormat: $.datepicker.ISO_8601,
    onSelect: function (dateText, inst) {
      inst.input.data("date", dateText)
      inst.input.val(formatPublicHolidayDateForDisplay(dateText))
      updatePubHolDates()
    },
  }

  form.on("change", ".toggle-times", function () {
    // all day unchecked = show times
    $(this).parents(line_selector).find(".date-pick-times")[this.checked ? "hide" : "show"]()
    updatePubHolDates()
  })

  form.on("click", ".all-day-label", function () {
    $(this).siblings(".toggle-times").click()
  })

  form.on("input", ".time", function () {
    updatePubHolDates()
  })

  if (public_holiday_dates.length) {
    public_holiday_dates.val(dateObjectToHiddenFieldFormat(initial_values))
    var rawSelectedDates = hiddenFieldFormatToDateObject(public_holiday_dates.val())

    form.find(".date_pick").remove()

    _.each(rawSelectedDates, function (date_obj) {
      addNewDatepickerLine(false, date_obj)
    })

    updatePubHolDates()
    hideAddIconsAsAppropriate()

    $(".date_pick.disabled").each(function () {
      var datepick = $(this)
      datepick.find(".datepicker").datepicker("disable")
      datepick.find(".all-day-checkbox").attr("disabled", "disabled")
      datepick.find(".time.from").attr("disabled", "disabled")
      datepick.find(".time.to").attr("disabled", "disabled")
      datepick.find(".remove-datepicker").css({ visibility: "hidden" })
    })

    form.on("click", ".remove-datepicker", function (e) {
      var icon = $(this)
      var row = icon.parents(line_selector)

      if (other_dates_area.find(line_selector).length === 1) {
        // only a single row. after removing this one, we will create a new blank one.
        row.remove()
        addNewDatepickerLine(false)
      } else {
        row.remove()
      }

      updatePubHolDates()
      hideAddIconsAsAppropriate()
      return false
    })

    form.on("click", ".add-datepicker", function () {
      addNewDatepickerLine(true)
      updatePubHolDates()
      hideAddIconsAsAppropriate()
      return false
    })

    form.on("change", ".time-input-js", function (e) {
      var time = e.target.value.toTimeParts() || ["00", "00"]

      if (time[0] > 23) {
        time = ["24", "00"]
      }

      if (time[1] > 59) {
        time[1] = 59
      }

      $(this)[0].value = time.join(":")
    })
  }
}

$.fn.removeHolidayRegion = function () {
  $(this).click(function () {
    var placeholder = $("#setting_public_holiday_region_list").data("placeholder")

    $("#setting_public_holiday_region_list option")[0].selected = true
    $("a.chosen-single").addClass("chosen-default")
    $("a.chosen-single span").text(placeholder)
  })
}
;
window.benchmark = function benchmark (name, that, callback) {
  if (!callback && _.isFunction(that)) {
    callback = that
    that = this
  }
  if (window.prod_mode || !console.time || !console.timeEnd) {
    return callback.call(that)
  } else {
    console.time(name)
    var ret = callback.call(that)
    console.timeEnd(name)
    return ret
  }
}

window.benchmarkStart = function benchmarkStart (name) {
  if (!window.prod_mode && !window.ci_mode && console.time && console.timeEnd) {
    console.time(name)
  }
}

window.benchmarkEnd = function benchmarkEnd (name) {
  if (!window.prod_mode && !window.ci_mode && console.time && console.timeEnd) {
    console.timeEnd(name)
  }
}
;
$.fn.startingBalance = function () {
  $(this).ready(function () {
    var startingBalanceCheckbox = $("#starting-balance-div")
    var accrueBalanceCheckBox = $("#accrue-balance-div")
    var leaveHoursEntitlementInput = $("#award_leave_hours_entitlement")
    var accrualHoursInput = $("#award_hours_accrued_per_hour_worked")
    var startingBalanceAdditionalSettings = $("#starting-balance-additonal-settings")
    var accrueInputAdditionalSettings = $(".accrue-input-additional-settings")

    var startingBalanceShowHide = function () {
      if (startingBalanceCheckbox.is(":unchecked")) {
        startingBalanceAdditionalSettings.hide()
        accrueInputAdditionalSettings.show()
      } else if (accrueBalanceCheckBox.is(":checked")) {
        startingBalanceAdditionalSettings.hide()
        accrueInputAdditionalSettings.show()
      } else {
        startingBalanceAdditionalSettings.show()
        accrueInputAdditionalSettings.hide()
      }
    }

    startingBalanceShowHide()

    accrueBalanceCheckBox.on("change", function () {
      leaveHoursEntitlementInput.val("")
      accrualHoursInput.val("")

      if (accrueBalanceCheckBox.is(":unchecked")) {
        accrueBalanceCheckBox.prop("checked", false)
        accrueInputAdditionalSettings.hide()
        startingBalanceCheckbox.prop("checked", true)
        startingBalanceAdditionalSettings.show()
      } else if (accrueBalanceCheckBox.is(":checked")) {
        accrueBalanceCheckBox.prop("checked", true)
        accrueInputAdditionalSettings.show()
        startingBalanceCheckbox.prop("checked", false)
        startingBalanceAdditionalSettings.hide()
      }
    })

    startingBalanceCheckbox.on("change", function () {
      leaveHoursEntitlementInput.val("")
      accrualHoursInput.val("")

      if (startingBalanceCheckbox.is(":unchecked")) {
        startingBalanceCheckbox.prop("checked", false)
        startingBalanceAdditionalSettings.hide()
        accrueBalanceCheckBox.prop("checked", true)
        accrueInputAdditionalSettings.show()
      } else if (startingBalanceCheckbox.is(":checked")) {
        startingBalanceCheckbox.prop("checked", true)
        startingBalanceAdditionalSettings.show()
        accrueBalanceCheckBox.prop("checked", false)
        accrueInputAdditionalSettings.hide()
      }
    })
  })
}
;
$.fn.tagColourPicker = function (palette, save_on_change_object_key) {
  if (this.siblings(".sp-replacer").length !== 0) {
    this.siblings(".sp-replacer").remove()
  }

  this.spectrum({
    allowEmpty: true,
    showPalette: true,
    palette: palette,
    hideAfterPaletteSelect: true,
    showInitial: true,
    preferredFormat: "hex",
  })

  if (save_on_change_object_key) {
    this.on("change", function () {
      var input = $(this)
      var url = input.data("path")
      var linkedPill = document.getElementById(input.data("linkedpill"))
      linkedPill.style.color = input.val()
      linkedPill.style.backgroundColor = input.val() + "1a" // This gives it 10% opacity, which matches with the pill style on the mobile app
      var params = {}

      params[save_on_change_object_key] = { colour: input.val() }
      params["_method"] = "put"

      $.ajax({
        url: url,
        method: "POST",
        dataType: "text",
        data: window.add_auth_token(params),
        beforeSend: function (xhr) { xhr.setRequestHeader("Accept", "application/json") },
      }).fail(function () {
        input.spectrum("set", input.data("prevcol"))
      }).done(function () {
        input.data("prevcol", input.val())
      })
    })
  }

  return this
}
;
$.fn.loadTooltips = function (options) {
  var defaults = {
    placement: "right",
    trigger: "focus",
    title: "",
    html: false,
  }

  options = $.extend(defaults, options)

  var dataTable = this.first().parents(".dataTable")
  var selector = this.selector
  if (dataTable.length && !dataTable.data("setTooltipHandler" + selector)) {
    dataTable.data("setTooltipHandler" + selector, true)
    dataTable.on("draw.dt", function () {
      $(selector).loadTooltips(options)
    })
  }

  var dataTables = $(".dataTable")
  if (dataTables.length > 0) {
    if (window.MutationObserver && $(selector).is("[tooltip]")) {
      var observer = new MutationObserver(function () {
        observer.disconnect()
        $("[tooltip]").loadTooltips({ trigger: options.trigger })
      })

      var config = {
        attributes: true,
        childList: true,
        characterData: true,
      }

      dataTables.each(function () {
        var element = $(this).children("tbody").get(0)
        observer.observe(element, config)
      })
    }
  }

  return this.each(function (idx, input) {
    input = $(input)
    var text = input.attr("tooltip") || options.title
    var placement = input.attr("tooltip-placement") || options.placement
    var trigger = input.attr("tooltip-trigger") || options.trigger
    var html = (input.attr("tooltip-html") || options.html) === "true"
    var selector = input.attr("tooltip-selector") || options.selector
    var container = input.attr("tooltip-container") || options.container
    var el_class = input.attr("tooltip-class") || options.class
    // specific defaults for buttons
    if (trigger === "focus" && (input.is("button") || input.is("a"))) {
      trigger = "hover"
      // override default if no specific attr given
      if (placement === "right" && !input.attr("tooltip-placement")) {
        placement = "top"
      }
    }

    if ($.isFunction(text)) {
      text = text()
    }

    if (text && selector) {
      $("body").tooltip({
        selector: selector,
        placement: placement,
        title: text,
        trigger: trigger,
        container: container,
        html: html,
      }).data("tooltip").tip().addClass(el_class)
    } else if (text) {
      // BS2 ignores the passed 'title' and uses input.attr('title') if 'selector' isn't specified
      input.attr("title", text)
      input.tooltip({
        placement: placement,
        title: text,
        trigger: trigger,
        container: container,
        html: html,
      }).data("tooltip").tip().addClass(el_class)
    }
  })
}
;
$.fn.updateAndRecalculateControls = function () {
  var form = this

  $(this).ready(function () {
    $(".update-and-recalculate-show-hide-button").click(function () {
      updateAndRecalculateControlsShowHide()
    })

    form.find(".select-options-input").change(function () {
      var selectedOption = $(".select-options-input").find("option:selected")
      var leaveInDays = selectedOption.get(0).dataset.leaveInDays
      var leaveInDaysTarget = $(".leave-in-days-target")
      if (leaveInDays === "true") {
        leaveInDaysTarget.removeClass("hidden")
      } else {
        leaveInDaysTarget.addClass("hidden")
      }
    })

    var updateAndRecalculateControlsShowHide = function () {
      var $showHideButton = form.find(".update-and-recalculate-show-hide-button")

      var $recalcForm = form.find(".update-and-recalculate-controls-area-js")
        .find(".show-hide-recalc-target")

      if ($recalcForm.hasClass("hidden")) {
        $showHideButton.text(I18n.t("js.users.leave.cancel"))
        $showHideButton.removeClass("btn-action-primary")
        $showHideButton.addClass("btn-danger-primary")

        $recalcForm.removeClass("hidden")
        $recalcForm.show("fast")

        $recalcForm.parent().removeClass("pl2").addClass("sl-container modal-opened-padding")
      } else {
        $showHideButton.text(I18n.t("js.users.leave.recalculate_leave"))
        $showHideButton.removeClass("btn-danger-primary")
        $showHideButton.addClass("btn-action-primary")

        $recalcForm.hide("slow")
        $recalcForm.addClass("hidden")

        $recalcForm.parent().removeClass("sl-container modal-opened-padding").addClass("pl2")
      }
    }
  
    form.find(".select-options-input").change()
  })
}
;
(function () {
  window.utils = window.utils || {}

  var utils = window.utils

  utils.hide_parent = function hide_parent (p) {
    p.css({
      visibility: "hidden",
      position: "absolute",
      left: "-999999px",
    })
  }

  utils.display_locationless_orgs = function displayLocationlessOrgs () {
    var display = $("#locationless_orgs").css("display")
    var link = $("#location_org_count")
    if (display === "none") {
      link.text(["Hide"].concat(link[0].innerText.split(" ")).join(" "))
      $("#locationless_orgs").css("display", "block")
    } else {
      link.text(link[0].innerText.split(" ").splice(1, 6).join(" "))
      $("#locationless_orgs").css("display", "none")
    }
  }

  function get_correct_val (val) {
    if (typeof val === "string") {
      return val
    }
    return val.join(",")
  }

  utils.get_value = function get_value (element) {
    if (!element.length) {
      return null
    }

    if (element.is(":input")) {
      if (element.data("required-if")) {
        if (!$(element.data("required-if")).hasClass("selected")) {
          return false
        }
      }

      // is it a filterSelect?
      if (element.get(0).is_filter_select) {
        return get_correct_val(element.get(0).filter_select_instance.val())
      }
      if (element.get(0).is_multi_select) {
        return get_correct_val(element.get(0).multi_select_instance.val())
      }
      if (element.get(0).is_time_input) {
        return _.chain(element.get(0).time_input_instances).map(function (instance) {
          return instance.val()
        }).filter(function (v) { return !!v }).join(",").value()
      }
      if (element.get(0).is_single_time_input) {
        var instance = element.get(0).single_time_input_instance
        return instance ? instance.val() : null
      }
      if (element.is('[type="checkbox"]')) {
        return element.prop("checked")
      }
      return element.val()
    } else if (element.hasClass("option-box")) {
      return element.hasClass("selected") ? 1 : 0
    } else if (element.get(0).is_range_input) {
      return element.get(0).validate()
    } else {
      return element.hasClass("selected") || element.hasClass("active")
    }
  }
}())
;
(function ($) {
  $.fn.validatePassword = function () {
    var $el = $(this)
    var $form = $el.parents("form")
    var key = $el.attr("name")
    var rules = {}
    var messages = {}

    rules[key] = {
      remote: {
        url: Routes.validate_password_path(),
        type: "post",
      },
    }
    messages[key] = { remote: I18n.t("js.signup.validation.password_length_html") }

    // If jQuery.validate has been intialised on the form, add the rules.
    // Otherwise initialise it with password validation.
    if ($form.data("validator")) {
      $el.rules("add", _.extend({}, rules[key], { messages: messages[key] }))
    } else {
      $form.validate({
        rules: rules,
        messages: messages,
      })
    }
  }
})(jQuery)
;
/*
  @title View Switcher
  @author David Buchan-Swanson
  A generic view switcher
    - takes button presses and hides/shows panels

  Call on the root component, `.view-container`.
  Add all your views inside, `.view.view-<name>`

  On your buttons/actions, add the following attributes
  - `data-for-view` -> the #id of your `.view-container`
  - `data-view` -> the <name> of the view to display on click

  To hide all views, calls #switchToView(null) on your `.view-container`
 */


$.fn.viewSwitcher = function (defaultView) {
  var $this = $(this)
  if (!$this.hasClass("view-container")) {
    return null
  }

  var views = $this.find(".view")

  function switchViewTo (to) {
    views.removeClass("show")
    if (to !== null) {
      $this.find(".view-" + to).addClass("show")
    }
  }

  $this.switchViewTo = switchViewTo

  var id = $this.attr("id")
  var buttons = $('[data-for-view="' + id + '"]')

  buttons.click(function () {
    var $this = $(this)
    var viewToSwitchTo = $this.data("view")
    switchViewTo(viewToSwitchTo)
  })

  if (defaultView) {
    switchViewTo(defaultView)
  }

  return $this
}

$.fn.tabbedView = function (defaultSelected) {
  $(this).each(function () {
    var $this = $(this)
    if (!$this.hasClass("tabbed-view")) { return null }

    $this.find(".view-container").viewSwitcher()

    var tabButtons = $this.find(".nav-tabs a")
    tabButtons.click(function () {
      tabButtons.parent("li").removeClass("active")
      var button = $(this)
      button.parent("li").addClass("active")
    })

    if (defaultSelected) {
      tabButtons.find(defaultSelected).click()
    } else {
      tabButtons.first().click()
    }
  })
}

$.fn.bigRadioButtons = function (callback) {
  var buttons = this
  return buttons.click(function () {
    var $previousSelected = buttons.filter(".selected")
    buttons.removeClass("selected")
    var $this = $(this)
    $this.addClass("selected")
    if ($this[0] !== $previousSelected[0]) {
      callback && callback($this[0])
    }
  })
}
;
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

$.fn.timeFormatter = function () {
  return this.on("change", function () {
    var textbox = $(this)
    var time = textbox.val()
    var parsed_time = time.toTimeParts()

    if (parsed_time) {
      textbox.val(parsed_time.join(":"))
    }
  })
}

$.fn.awardTemplateFormSalaryHider = function (salary_tag_name) {
  function hideIfSalaried (row) {
    if (row.find(".salary-select-chosen-js option:selected").text() === salary_tag_name) {
      row.find(".hide-if-salaried-js").hide()
    } else {
      row.find(".hide-if-salaried-js").show()
    }
  }

  this.find(".hide-if-salaried-parent-js").each(function (row) { hideIfSalaried($(this)) })
  this.on("change", ".salary-select-chosen-js", function () { hideIfSalaried($(this).parents(".hide-if-salaried-parent-js")) })
  return this
}
;
document.addEventListener("DOMContentLoaded", function () {
  window.initDeviceManagementEditButton()
}, false)

window.initDeviceManagementEditButton = function () {
  var modals = document.getElementsByClassName("device-modal")
  var edit_buttons = document.getElementsByClassName("edit-button")
  var close_modals = document.getElementsByClassName("close-modal")
  var close_buttons = document.getElementsByClassName("close-button")

  for(var i=0;i<edit_buttons.length;i++){
    edit_buttons[i].onclick = function(e) {
      var modal = modals.namedItem(e.target.id)
      modal.classList.remove("display-none")
    }
    close_modals[i].onclick = function(e) {
      var modal = modals.namedItem(e.target.id)
      modal.classList.add("display-none")
    }
    close_buttons[i].onclick = function(e) {
      var modal = modals.namedItem(e.target.id)
      modal.classList.add("display-none")
    }
  }
}

;
window.ExportPreview = {
  init: function () {
    // Warning labeled errors
    $(".export-preview-show-affected-button").on("click", function () {
      var count = $(this).attr("count")
      var total = $(this).attr("total")

      // unhide all hidden timesheets
      $(".accordion-group").each(function () {
        if($(this).hasClass("hidden")) {
          $(this).removeClass("hidden")
        }
      })
      // this is to unselect previously clicked expand button (for different error)
      var currentlySelected = $(".currently-selected").not(this)
      if (currentlySelected.length > 0) {
        $(".highlight-affected-timesheet").each(function () {
          $(this).removeClass("highlight-affected-timesheet")
          $(this).find(".collapse").collapse("hide")
        })

        currentlySelected.each(function () {
          $(this).removeClass("currently-selected")
        })
      }
      
      // opening selected accordions
      $.when($(this).toggleClass("currently-selected")).then(function () {
        var currently_selected_error = $(this).attr("data-error")
        if ($(this).hasClass("currently-selected")) {
          $("#display_msg span").text(I18n.t("js.timesheets.export_summary.viewing_timesheets_count", { count: count, total: total }))
          // hide the timesheets that do not have the current selected error
          $(".accordion-group").each(function () {
            // find an element in this timesheet which contains the currently selected error
            var current_error_on_timesheet = $(this).has(".export-warnings-alert.timesheet-inner-error:contains(" + currently_selected_error + ")")
            
            // if no element was found containing the current error, hide this timesheet
            if(current_error_on_timesheet.length === 0) {
              $(this).addClass("hidden")
            }
          })
          
          $(".export-warnings-alert.timesheet-inner-error:contains(" + currently_selected_error + ")").each(function () {
            $(this).parents(".accordion-group").each(function () {
              $(this).removeClass("hidden")
              $(this).addClass("highlight-affected-timesheet")
              $(this).find(".collapse").collapse("show")
            })
          })
        } else {
          $("#display_msg span").text(I18n.t("js.timesheets.export_summary.viewing_timesheets_count", { count: total, total: total }))
          $(".export-warnings-alert.timesheet-inner-error:contains(" + currently_selected_error + ")").each(function () {
            $(this).parents(".accordion-group").each(function () {
              $(this).removeClass("highlight-affected-timesheet")
              $(this).find(".collapse").collapse("hide")
            })
          })
        }
      })
    })
    // Error labeled errors
    $(".export-preview-show-affected-error-button").on("click", function () {
      var count = $(this).attr("count")
      var total = $(this).attr("total")
      
      // unhide all hidden timesheets
      $(".accordion-group").each(function () {
        if($(this).hasClass("hidden")) {
          $(this).removeClass("hidden")
        }
      })

      // this is to unselect previously clicked expand button (for different error)
      var currentlySelected = $(".currently-selected").not(this)
      if (currentlySelected.length > 0) {
        $(".highlight-affected-timesheet").each(function () {
          $(this).removeClass("highlight-affected-timesheet")
          $(this).find(".collapse").collapse("hide")
        })

        currentlySelected.each(function () {
          $(this).removeClass("currently-selected")
        })
      }

      // opening selected accordions
      $.when($(this).toggleClass("currently-selected")).then(function () {
        var currently_selected_error = $(this).attr("data-error")
        if ($(this).hasClass("currently-selected")) {
          $("#display_msg span").text(I18n.t("js.timesheets.export_summary.viewing_timesheets_count", { count: count, total: total }))
          // hide the timesheets that do not have the current selected error
          $(".accordion-group").each(function () {
            // find an element in this timesheet which contains the currently selected error
            var current_error_on_timesheet = $(this).has(".export-errors-alert.timesheet-inner-error:contains(" + currently_selected_error + ")")
            
            // if no element was found containing the current error, hide this timesheet
            if(current_error_on_timesheet.length === 0) {
              $(this).addClass("hidden")
            }
          })
          $(".export-errors-alert.timesheet-inner-error:contains(" + currently_selected_error + ")").each(function () {
            $(this).parents(".accordion-group").each(function () {
              $(this).removeClass("hidden")
              $(this).addClass("highlight-affected-timesheet")
              $(this).find(".collapse").collapse("show")
            })
          })
        } else {
          $("#display_msg span").text(I18n.t("js.timesheets.export_summary.viewing_timesheets_count", { count: total, total: total }))
          $(".export-errors-alert.timesheet-inner-error:contains(" + currently_selected_error + ")").each(function () {
            $(this).parents(".accordion-group").each(function () {
              $(this).removeClass("highlight-affected-timesheet")
              $(this).find(".collapse").collapse("hide")
            })
          })
        }
      })
    })
  },
}
;
window.getKeypayBusinessNameOptions = function (path) {
  $.getJSON(path).done(function (data) {
    if (data.length) {
      var list = crel("ul", crel.apply(crel, data.map(function (name) { return crel("li", name) })))
      $("#keypayBusinessNameOptions").show().find(".list").html(list)
    }
  })
}
;
window.ReportPlot = {}

;(function () {
  var format = d3.format.bind(d3)

  if (window.i18n_props) {
    var currency = window.i18n_props.currency
    var locale = d3.locale(_.extend({}, window.d3.defaultLocale, {
      decimal: currency.separator,
      thousands: currency.delimiter,
      currency: [currency.unit, ""],
    }))

    format = locale.numberFormat.bind(locale)
  }

  ReportPlot.Consts = {
    TIME_FORMAT: "ddd D MMM 'YY",
    CURRENCY_FORMAT: format("$,.2f"),
    RND_CURRENCY_FORMAT: format("$,.0f"),
    HOUR_FORMAT: format(",.2f"),
    INT_FLOAT_FORMAT: format(",.f"),
  }
})()

ReportPlot.Colors = {
  GREEN: "rgb(153, 198, 60)",
  GREY: "rgb(89, 99, 111)",
  BLUE: "rgb(63, 175, 215)",
}

ReportPlot.Helpers = {
  moment_tz: function (time) {
    // use the original timezone instead of user's current timezone
    if (!moment.isMoment(time)) { time = moment(time) }
    return time._tzm ? time.utcOffset(time._tzm) : time
  },

  /**
   * Reshape json passed out by back-end into a form NVD3 likes.
   * If 'key_to_negate' is specified, calculate and return the difference in cost/hours
   * between the negated category and its complement.
   */
  json_to_nv: function (json_data, key_to_negate) {
    var categories = _.chain(json_data).map(function (e) {
      return _.keys(e)
    }).flatten().uniq().value().sort()
    var defaults = {}
    var data = {}

    // In case input data is sparse (missing keys), impute missing values.
    _.each(categories, function (c) { defaults[c] = { cost: 0, hours: 0 } })
    _.each(json_data, function (d) { _.defaults(d, defaults) })

    // If an object key is present but value is empty, insert defaults.
    _.each(json_data, function (o) {
      _.each(categories, function (c) {
        if (_.isEmpty(o[c])) {
          o[c] = { cost: 0, hours: 0 }
        }
      })
    })

    // If we are calculating 'variance', negate the values of the first category and
    // calculate the 'variance' as the sum of category values.
    if (key_to_negate) {
      _.each(json_data, function (d) {
        d[key_to_negate].cost = 0 - d[key_to_negate].cost
        d[key_to_negate].hours = 0 - d[key_to_negate].hours
        _.defaults(d, { Variance: { cost: d[categories[0]].cost + d[categories[1]].cost, hours: d[categories[0]].hours + d[categories[1]].hours } })
      })
      var variance = {
        key: "Variance",
        values: _.chain(json_data).map(function (k, v) { return k })
          .pluck("Variance")
          .pluck("cost")
          .map(function (v, i) { return { x: i, y: v } }).value(),
      }
      data["variance_data"] = variance
    }

    // Collect and aggregate values under each category.
    var cost_data = _.map(categories, function (c) { return { key: c, values: _.chain(json_data).map(function (k, v) { return k }).pluck(c).pluck("cost").map(function (v, i) { return { x: i, y: v } }).value() } })
    var hour_data = _.map(categories, function (c) { return { key: c, values: _.chain(json_data).map(function (k, v) { return k }).pluck(c).pluck("hours").map(function (v, i) { return { x: i, y: v } }).value() } })

    data["cost_data"] = cost_data
    data["hour_data"] = hour_data

    return data
  },

  /**
   * Reshape json passed out by back-end into a form NVD3 likes.
   * If 'key_to_negate' is specified, calculate and return the difference in cost/hours
   * between the negated category and its complement.
   */
  json_2d_to_nv: function (json_data, key_to_negate) {
    var first_dim_categories = _.chain(json_data).map(function (e) {
      return _.keys(e)
    }).flatten().uniq().value().sort()
    var second_dim_categories = _.chain(first_dim_categories).map(function (c) {
      return _.chain(json_data).map(function (k, v) {
        return k
      }).pluck(c).map(function (e) {
        return _.keys(e)
      }).value()
    }).flatten().uniq().value().sort()
    var outer_defaults = {}
    var inner_defaults = {}
    var data = {}

    // In case input data is sparse, impute missing values.
    _.each(first_dim_categories, function (c) { outer_defaults[c] = {} })
    _.each(second_dim_categories, function (c) { inner_defaults[c] = { cost: 0, hours: 0 } })
    _.each(json_data, function (d) { _.defaults(d, outer_defaults) })
    _.each(json_data, function (d) { _.each(first_dim_categories, function (c) { _.defaults(d[c], inner_defaults) }) })

    // Collect and aggregate values under each category.
    var cost_data = _.map(first_dim_categories, function (c_one) {
      return _.map(second_dim_categories, function (c_two) {
        return {
          keys: [c_one, c_two],
          values: _.chain(json_data).map(function (k, v) { return k }).pluck(c_one).pluck(c_two).pluck("cost").map(function (v, i) { return { x: i, y: v } }).value(),
        }
      })
    }).flatten()
    var hour_data = _.map(first_dim_categories, function (c_one) {
      return _.map(second_dim_categories, function (c_two) {
        return {
          keys: [c_one, c_two],
          values: _.chain(json_data).map(function (k, v) { return k }).pluck(c_one).pluck(c_two).pluck("hours").map(function (v, i) { return { x: i, y: v } }).value(),
        }
      })
    }).flatten()

    data["cost_data"] = cost_data
    data["hour_data"] = hour_data

    return data
  },

  /**
   * Sort an Array of Objects by an object attribute. Can specify whether to sort
   * ascending or descending. Uses d3 sort order.
   */
  sort_by_attr: function (attr, ascending) {
    return function (a, b) {
      return ascending ? d3.ascending(a[attr], b[attr]) : d3.descending(a[attr], b[attr])
    }
  },

  /**
   * Generate a colour palette (RGB) of length n.
   * Sample HSV-space using golden ratio modulus.
   */
  colour_palette: function (n) {
    var phi = 0.618033988749895
    var h = Math.random()
    return _.map(_.range(n), function () {
      h += phi
      h %= 1
      return window.LH.Color.hsvToRgb(h * 360, 0.5, 0.95)
    })
  },

  awards_colours: window.LH.Grapher.awardColors,

  /**
   * Return true and hide graph area if data is empty. Else show graph area and return false.
   * Recurses one level deep; if `strict` is truthy, ensure ALL elements are non-empty.
   */
  check_empty_data: function (json_data, chart, strict) {
    var empty = function (d) { return _.isEmpty(d) || d.empty }
    var empty_data = empty(json_data) || (strict ? _.some(json_data, empty) : _.all(json_data, empty))
    $(chart)[empty_data ? "hide" : "show"]()
    return empty_data
  },

  svg_to_base64: function (svg_node) {
    var svg = d3.select(svg_node)
      .attr("title", "lolsvg")
      .attr("version", 1.1)
      .attr("xmlns", "http://www.w3.org/2000/svg")

    var dummy_parent = document.createElement("p")
    d3.select(dummy_parent).node().appendChild(svg.node())
    var html = svg.node().parentNode.innerHTML

    return "url(data:image/svg+xml;base64," + btoa(html) + ")"
  },

  award_hash: window.LH.Grapher.awardHash,

  sort_award_data_by_ord_hours_then_multiplier: function (award1, award2) {
    if (award1.is_ord_hours !== award2.is_ord_hours) { // one is ord hours the other isn't
      return award1.is_ord_hours ? -1 : 1
    } else { // both ord or both not ord
      return award1.multiplier - award2.multiplier
    }
  },

  time_diff_sum: function (arr) {
    return d3.sum(_.map(arr, function (d) { return moment(d.finish) - moment(d.start) }))
  },

}

ReportPlot.Plot = {

  /**
   * Stacked/grouped bar charts.
   */
  nv_bar: function (opts) {
    var container = opts.container
    var json_data = opts.data
    var stacked = _.isUndefined(opts.stacked) ? true : opts.stacked
    var show_controls = _.isUndefined(opts.show_controls) ? true : opts.show_controls
    var y_format = opts.y_format || ReportPlot.Consts.CURRENCY_FORMAT

    if (ReportPlot.Helpers.check_empty_data(json_data, container)) { return }

    var cost_data = ReportPlot.Helpers.json_to_nv(opts.data).cost_data
    var dates = _.chain(json_data).keys().map(function (d) {
      return moment(d).format(ReportPlot.Consts.TIME_FORMAT)
    }).value()
    var axis_labels = opts.axis_labels

    var chart = nv.models.multiBarChart()
                .duration(350)
                .stacked(stacked)     // Default to stacked
                .reduceXTicks(true)   // If 'false', every single x-axis tick label will be rendered.
                .rotateLabels(0)      // Angle to rotate x-axis labels.
                .showControls(show_controls)   // Allow user to switch between 'Grouped' and 'Stacked' mode.
                .groupSpacing(0.1)    // Distance between each group of bars.
                .margin({ top: 30, right: 20, bottom: 50, left: 100 })

    chart.xAxis
      .tickValues(d3.range(dates.length))
      .tickFormat(function (d) {
        return dates[d]
      })
      .axisLabel(axis_labels[0])

    chart.yAxis
      .tickFormat(y_format)
      .axisLabel(axis_labels[1])
      .axisLabelDistance(30)

    d3.select(container)
      .datum(cost_data)
      .call(chart)

    nv.utils.windowResize(chart.update)

    return chart
  },

  nv_stacked_grouped_bar: function (opts) {
    var container = opts.container
    var json_data = opts.data

    if (ReportPlot.Helpers.check_empty_data(json_data, container)) { return }

    var cost_data = ReportPlot.Helpers.json_2d_to_nv(opts.data).cost_data
    var dates = _.chain(json_data).keys().map(function (d) {
      return moment(d).format(ReportPlot.Consts.TIME_FORMAT)
    }).value()
    var axis_labels = opts.axis_labels

    var chart = nv.models.stackedGroupedBarChart()
                .duration(350)
                .reduceXTicks(true)   // If 'false', every single x-axis tick label will be rendered.
                .rotateLabels(0)      // Angle to rotate x-axis labels.
                .groupSpacing(0.1)    // Distance between each group of bars.
                .margin({ top: 30, right: 20, bottom: 50, left: 100 })

    chart.xAxis
      .tickValues(d3.range(dates.length))
      .tickFormat(function (d) {
        return dates[d]
      })
      .axisLabel(axis_labels[0])

    chart.yAxis
      .tickFormat(ReportPlot.Consts.CURRENCY_FORMAT)
      .axisLabel(axis_labels[1])
      .axisLabelDistance(30)

    d3.select(container)
      .datum(cost_data)
      .call(chart)

    nv.utils.windowResize(chart.update)

    return chart
  },

  nv_cost_variance: function (opts) {
    var container = opts.container
    var json_data = opts.data

    if (ReportPlot.Helpers.check_empty_data(json_data, container)) { return }

    var formatted_data = ReportPlot.Helpers.json_to_nv(opts.data, opts.cat_to_negate)
    var cost_data = formatted_data.cost_data
    var variance_data = formatted_data.variance_data
    var dates = _.chain(json_data).keys().map(function (d) {
      return moment(d).format(ReportPlot.Consts.TIME_FORMAT)
    }).value()
    var axis_labels = opts.axis_labels
    var cat_labels = opts.category_labels

    // Apply custom category labels if they exist, otherwise just capitalise keys
    if (cat_labels) {
      _.each(cost_data, function (o) {
        o.key = _.indexOf(_.keys(cat_labels), o.key) > -1 ? cat_labels[o.key] : o.key.capitalise()
      })
    }

    // Assign chart types to first two categories
    _.each(cost_data, function (o) {
      o["type"] = "bar"
      o["yAxis"] = 1
    })

    variance_data["key"] = "Variance"
    variance_data["type"] = "line"
    variance_data["yAxis"] = 1
    cost_data.push(variance_data)

    var chart = nv.models.stackedMultiChart()
                  .margin({ top: 30, right: 20, bottom: 50, left: 100 })

    chart.xAxis
      .tickValues(d3.range(dates.length))
      .tickFormat(function (d) {
        return dates[d]
      })
      .axisLabel(axis_labels[0])

    chart.yAxis1
      .tickFormat(ReportPlot.Consts.CURRENCY_FORMAT)
      .axisLabel(axis_labels[1])
      .axisLabelDistance(25)

    d3.select(container)
      .datum(cost_data).transition().duration(700)
      .call(chart)
    nv.utils.windowResize(chart.update)

    return chart
  },

  nv_pie: function (opts, title) {
    var container = opts.container
    var cost_data = _.map(opts.data, function (v, k) {
      return { label: k, value: v.cost }
    })
    var json_data = opts.data

    if (ReportPlot.Helpers.check_empty_data(json_data, container)) { return }

    var chart = nv.models.pieChart()
      .x(function (d) { return d.label })
      .y(function (d) { return d.value })
      .valueFormat(ReportPlot.Consts.CURRENCY_FORMAT)
      .donut(true)
      .donutRatio(0.35)
      .title(title || "")
      .showLabels(false)

    d3.select(container)
      .datum(cost_data).transition().duration(700)
      .call(chart)
    nv.utils.windowResize(chart.update)

    return chart
  },

  nv_horizontal_bar: function (opts) {
    var container = opts.container
    var json_data = opts.data
    var categories = _.union(_.keys(json_data[0]), _.keys(json_data[1]))
    var titles = opts.labels || ["Primary time range", "Secondary time range"]
    var data1
    var data2
    var defaults = {}

    if (ReportPlot.Helpers.check_empty_data(json_data, container)) { return }

    // In case input data is sparse, impute missing values.
    _.each(categories, function (c) { defaults[c] = { cost: 0, hours: 0 } })
    _.each(json_data, function (d) { _.defaults(d, defaults) })

    data1 = {
      key: titles[0],
      values: _.map(categories, function (c) { return { label: c, value: json_data[0][c].cost } }),
    }
    data2 = { key: titles[1] }

    // No need to check for empty json_data[0]; this is caught early.
    if (_.isEmpty(json_data[1])) {
      data2["values"] = _.map(categories, function (c) { return { label: c, value: 0 } })
    } else {
      data2["values"] = _.map(categories, function (c) { return { label: c, value: json_data[1][c].cost } })
    }

    var data = [data1, data2]
    var chart = nv.models.multiBarHorizontalChart()
      .x(function (d) {
        return d.label
      })
      .y(function (d) {
        return d.value
      })
      .stacked(false)
      .showValues(true)
      .showControls(false)
      .valueFormat(ReportPlot.Consts.CURRENCY_FORMAT)
      .margin({ top: 30, right: 60, bottom: 50, left: 140 })

    chart.tooltip.enabled(false)

    chart.yAxis
        .tickFormat(ReportPlot.Consts.CURRENCY_FORMAT)
        .axisLabel(I18n.t("js.reports.grapher.raw_wage_cost"))

    d3.select(container)
        .datum(data)
      .transition().duration(500)
        .call(chart)

    nv.utils.windowResize(chart.update)

    // HACK: Addresses FF42 text printing bug - https://trello.com/c/AGLCJSaL/304-charts-on-reports-print-weirdly-in-firefox
    // need to clear some inherited styling
    d3.selectAll("text[text-anchor='start']")
      .style("stroke", "initial")
      .style("stroke-opacity", "initial")
      .style("fill-opacity", "initial")

    return chart
  },

  awards_chart: function (opts) {
    var container = opts.container
    var json_data = opts.data
    var dates = _.unique(_.map(json_data, function (d) {
      return d.date
    }))
    var awards = _.unique(_.map(opts.data, ReportPlot.Helpers.award_hash)).sort()
    var colours = ReportPlot.Helpers.awards_colours(awards.length)
    var colour_map = opts.colour_map || _.object(awards, colours)
    var gantt
    var chart_height
    var award_names_to_colour = {}

    if (ReportPlot.Helpers.check_empty_data(json_data, container) || d3.select(container).empty()) { return }

    // X-axis for this visualisation isn't a 'true' timescale. Since day of the week is captured
    // along the Y-axis, the 'dates' along the X-axis should in fact only be in hh:mm:ss
    // Pre-process start and end dates to start on the same day.
    var first_date = moment.min(_.map(json_data, function (award_mapping) {
      return ReportPlot.Helpers.moment_tz(award_mapping.start)
    })).startOf("day")
    var tasks = _.map(json_data,
      function (award_mapping) {
        var days_past = [ReportPlot.Helpers.moment_tz(award_mapping.start).diff(first_date, "days"), ReportPlot.Helpers.moment_tz(award_mapping.finish).diff(first_date, "days")]
        var task = {
          taskName: award_mapping.date,
          status: award_mapping.name,
          startDate: ReportPlot.Helpers.moment_tz(award_mapping.start).subtract(days_past[0], "days"),
          endDate: ReportPlot.Helpers.moment_tz(award_mapping.finish).subtract(days_past[1], "days"),
          colour: colour_map[ReportPlot.Helpers.award_hash(award_mapping)],
          ordinary: award_mapping.is_ord_hours,
          multiplier: award_mapping.multiplier,
          nickname: award_mapping.nickname,
          leave: award_mapping.is_leave,
          padding: false,
        }
        var name = award_mapping.name

        award_names_to_colour[name] = (award_names_to_colour[name] || task.colour)

        return task
      })

    chart_height = 50 + (dates.length * 30)

    gantt = d3.gantt(container).taskTypes(dates).taskStatus(awards).height(chart_height).setMargin(opts.margin || {}).setNTicks(opts.n_ticks)
    gantt(tasks, _.values(award_names_to_colour))

    // DODGY: we turn the timesheet payslip into a legend of sorts, so we don't need to show two identical legends
    var payslip_table
    if (opts.legend_selector) {
      payslip_table = $(opts.legend_selector)
    } else {
      payslip_table = $(".payslip:first")
    }
    if (payslip_table.length) {
      _.each(award_names_to_colour, function (colour, award_name) {
        var td = payslip_table.find("td").filter(function () { return $(this).text().trim() === award_name })
        if (td.length) { td.prepend(crel("span", { class: "cell", style: ("background-color: " + colour + ";") })) }
      })
    }

    return gantt
  },

  awards_chart_html_summary: function (opts) {
    var container = opts.container instanceof d3.selection ? opts.container : d3.select(opts.container)
    var awards = _.unique(_.map(opts.data, ReportPlot.Helpers.award_hash))
    var colours = ReportPlot.Helpers.awards_colours(awards.length)
    var colour_map = opts.colour_map || _.object(awards, colours)
    var total_hours = parseFloat(ReportPlot.Helpers.time_diff_sum(opts.data))
    var proportion_of_hours
    var plot = opts.plot || false
    // plot the chart or just return an image url?
    var w = opts.width || container.style("width")
    var h = opts.height || 5

    proportion_of_hours = d3.nest()
                   .key(ReportPlot.Helpers.award_hash)
                   .rollup(function (arr) { return ReportPlot.Helpers.time_diff_sum(arr) / total_hours })
                   .entries(opts.data)

    _.each(proportion_of_hours, function (o, i) {
      var prev = proportion_of_hours[i - 1]
      o.colour = colour_map[o.key]
      o.offset = (i === 0 ? 0 : prev.offset + prev.values)
    })

    var x = d3.scale.linear().domain([0, 1]).range([0, w])

    function createSVG () {
      var orphan_svg = document.createElementNS(d3.ns.prefix.svg, "svg")

      var svg = d3.select(orphan_svg)
          .attr("class", "chart")
          .attr("width", "100%")
          .attr("height", h)

      var chart = svg.append("g")
                      .attr("width", w)
                      .attr("height", h)

      var bars = chart.selectAll("rect")
                      .data(proportion_of_hours)

      bars.enter().append("rect")
                  .attr("fill", function (d) { return d.colour })
                  .attr("height", h)
                  .attr("x", function (d) { return x(d.offset) })
                  .attr("width", function (d) { return x(d.values) })
                  .attr("opacity", 1)
      return svg.node()
    }

    if (plot) {
      container.append(createSVG)
    } else {
      return { url: ReportPlot.Helpers.svg_to_base64(createSVG()), n: proportion_of_hours.length }
    }
  },

  nv_percentage_overtime: function (opts) {
    if (ReportPlot.Helpers.check_empty_data(opts.data, opts.container)) { return }

    var container = opts.container
    var all_data = _.map(opts.data, function (d) {
      return crossfilter(d)
    })
    var allowances_dim = _.map(all_data, function (da) {
      return da.dimension(function (d) {
        return d.is_allowance === "t"
      })
    })

    _.each(allowances_dim, function (a) { a.filter(false) })

    var date = _.map(all_data, function (data) {
      return data.dimension(function (d) {
        return +moment(d.date).format("x")
      })
    })
    var data_key = opts.cost ? "cost" : "hours"
    var data_by_day = _.map(date, function (da) {
      return da.group().reduceSum(function (d) {
        return +d[data_key]
      })
    })
    var all_dates = _.union.apply(null, _.map(data_by_day, function (list) {
      return _.pluck(list.all(), "key")
    }))

    var control_labels = _.extend({ stacked: "Stacked", expanded: "Expanded" }, opts.labels)

    var chart = nv.models.stackedAreaChart2()
                .useInteractiveGuideline(true)
                .showControls(true)
                .clipEdge(true)
                .title(opts.title)
                .x(function (d) { if (typeof d !== "undefined" && d !== null) { return d[0] } })
                .y(function (d) { if (typeof d !== "undefined" && d !== null) { return d[1] } })
                .controlLabels(control_labels)

    chart.xAxis
      .tickFormat(function (d) {
        return moment(d).format(ReportPlot.Consts.TIME_FORMAT)
      })

    chart.yAxis
        .tickFormat(data_key === "cost" ? ReportPlot.Consts.CURRENCY_FORMAT : ReportPlot.Consts.HOUR_FORMAT)

    var data = [{ key: data_key === "cost" ? "Cost" + " for ordinary hours" : "Ordinary hours worked", values: _.map(data_by_day[0].all(), function (o) { return [o.key, o.value] }) },
               { key: data_key === "cost" ? "Cost" + " for non-ordinary hours" : "Non-ordinary hours worked", values: _.map(data_by_day[1].all(), function (o) { return [o.key, o.value] }) }]

    // Impute missing values
    _.each(data, function (d) {
      var all_entries = _.flatten(d.values)
      _.each(all_dates, function (date) {
        d.values.push(_.includes(all_entries, date) ? null : [date, 0])
      })
      d.values = _.compact(d.values)
      d.values.sort(ReportPlot.Helpers.sort_by_attr(0, true))
    })

    d3.select(container)
      .datum(data)
      .call(chart)

    nv.utils.windowResize(chart.update)

    return chart
  },
}
;
/* @flow */

/* ::
 import moment from 'moment'
 const I18n = ({}: any)
 const $ = ({}: any)
*/


window.loadData = {
  get: function (url/* : string */, callback/* : Function */) {
    return $.ajax({
      url: url,
      dataType: "json",
      type: "GET",
      success: function (data) {
        return data
      },
      error: function (err) {
        console.log("AJAX error in request: " + JSON.stringify(err, null, 2))
        return err
      },
    })
  },
}

window.loadData.UserAttendance = (function () {
  var table
  var stats = {}
  var self = ({}/* : any */)

  self.init = function (data, dataTable, cutoff) {
    self.cutoff = {}
    self.cutoff.clockin = cutoff.clockin / 60 / 1000
    self.cutoff.clockout = cutoff.clockout / 60 / 1000
    self.cutoff.clockinleeway = cutoff.clockinleeway / 60 / 1000
    self.cutoff.clockoutleeway = cutoff.clockoutleeway / 60 / 1000

    table = dataTable // set the table we're going to be inserting data into
    self.parseData(data) // parse our data
  }

  self.parseData = function (data) {
    var foo = {}

    stats = {}
    stats.arrived_early = 0
    stats.arrived_ontime = 0
    stats.arrived_late = 0

    stats.left_early = 0
    stats.left_ontime = 0
    stats.left_late = 0

    stats.notrostered = 0
    stats.noshow = 0
    stats.arrived_late = 0

    stats.borrowed_time = 0

    stats.leave_taken = 0

    $.each(data, function (key, val) {
      if (val.leave) {
        stats.leave_taken++

        var startDate = moment(val.leave.start)
        var finishDate = moment(val.leave.finish)
        // Create a new key/val pair for each day in the leave request
        for (var m = moment(startDate); m.diff(finishDate, "days") <= 0; m.add(1, "days")) {
          data[m.format("YYYY-MM-DD")] = val
        }
      }
    })

    $.each(data, function (key, val) {
      if (
      (val.roster && (val.roster.start || val.roster.finish)) ||
      (val.shift && (val.shift.start || val.shift.finish)) ||
      (val.leave && (val.leave.start || val.leave.finish))
      ) {
        foo[key] = {}

        foo[key].rostered_start = val.roster ? val.roster.start : null
        foo[key].rostered_finish = val.roster ? val.roster.finish : null

        foo[key].leave_start = val.leave ? val.leave.start : null
        foo[key].leave_start_time = val.leave ? val.leave.start_time : null
        foo[key].leave_finish = val.leave ? val.leave.finish : null
        foo[key].leave_finish_time = val.leave ? val.leave.finish_time : null
        foo[key].leave_type = val.leave ? val.leave.leave_type : null

        foo[key].clockin = val.shift ? val.shift.start : null
        foo[key].clockin_diff = self.calcDifference(val.clockin_diff)
        foo[key].clockin_diff_desc = self.classifyDifference("login", foo[key].clockin_diff, stats, val)

        foo[key].clockout = val.shift ? val.shift.finish : null
        foo[key].clockout_diff = self.calcDifference(val.clockout_diff)
        foo[key].clockout_diff_desc = self.classifyDifference("logout", foo[key].clockout_diff, stats, val)

        foo[key].date = key
      }
    })
    self.renderAttendance(foo)
  }

  self.failed = function () {
    self.showDataViz(false)
  }

  self.renderAttendance = function (attendance) {
    // Update quick stats
    $("#arrived_ontime").text(stats.arrived_ontime + stats.left_ontime)
    $("#arrived_late").text(stats.arrived_late)
    $("#left_early").text(stats.left_early)
    $("#leave_taken").text(stats.leave_taken)

    var borrowed_time = parseInt(stats.borrowed_time.toFixed(0), 10)
    if (borrowed_time === 0) {
      $("#savings-text").hide()
    } else {
      $("#borrowed_time").show().text(borrowed_time + " min" + (borrowed_time === 1 ? "" : "s"))
    }

    // Add rows to table
    self.updateAttendanceTable(attendance)

    // Update punctiality score
    self.updatePunctuality()
  }

  self.updateAttendanceTable = function (attendance) {
    $.each(attendance, function (i, data) {
      if (data.date) {
        var row = [
          "<span><span style=\"display:none;\">" + data.date + "</span>" + moment(data.date).format("MMMM Do YYYY") + "</span>",
          self.constructRow(data),
        ]

        table.row.add(row.reduce(function (a, b) {
          return a.concat(b)
        }, [])).draw()
        $(".shiftTime").tooltip()
      }
    })
  }

  self.constructRow = function (data) {
    if (data.date) {
      var clockinDesc = data.clockin_diff_desc
      var inLabel, outLabel
      var clockoutDesc = data.clockout_diff_desc

      if (data.leave_start) { // on leave this day
        var leaveStartTime = window.time_formatter.short_time(moment(data.leave_start_time, window.LH.Time.Formats.DateTime))
        inLabel = "<span class=\"shiftTime\" data-toggle=\"tooltip\" title=\"" + leaveStartTime + "\">" +
                  "<span class=\"label attendance-yellow-tag text-uppercase\">" + data.leave_type + "</span>"
      } else if (data.clockin) { // normal clockin
        inLabel = "<span class=\"shiftTime\" data-toggle=\"tooltip\" title=\"" + window.time_formatter.short_time(moment(data.clockin, window.LH.Time.Formats.DateTime)) + "\">" +
              clockinDesc.label +
              (typeof clockinDesc.style === "undefined"
                ? "" : " <span class=\"label " + clockinDesc.style + "\">" + clockinDesc.diff + "</span>" +
              "</span>")
      } else {
        inLabel = "<span class=\"label label-danger text-uppercase\">" + I18n.t("js.reports.attendance.no_clockin") + "</span>"
      }

      if (data.leave_finish) { // on leave this day
        var leaveFinishTime = window.time_formatter.short_time(moment(data.leave_finish_time, window.LH.Time.Formats.DateTime))
        outLabel = "<span class=\"shiftTime\" data-toggle=\"tooltip\" title=\"" + leaveFinishTime + "\">" +
                  "<span class=\"label attendance-yellow-tag text-uppercase\">" + data.leave_type + "</span>"
      } else if (data.clockout) { // normal clockout
        outLabel = "<span class=\"shiftTime\" data-toggle=\"tooltip\" title=\"" + window.time_formatter.short_time(moment(data.clockout, window.LH.Time.Formats.DateTime)) + "\">" +
              clockoutDesc.label +
              (typeof clockoutDesc.style === "undefined"
                ? "" : " <span class=\"label " + clockoutDesc.style + "\">" + clockoutDesc.diff + "</span>" +
              "</span>")
      } else {
        outLabel = "<span class=\"label label-danger text-uppercase\">" + I18n.t("js.reports.attendance.no_clockout") + "</span>"
      }
      var rostered_start_output
      var rostered_end_output

      if (data.rostered_start) {
        var rosteredStartTime = window.time_formatter.short_time(moment(data.rostered_start, window.LH.Time.Formats.DateTime))
        rostered_start_output = "<span class=\"shiftTime\" title=\"" + rosteredStartTime + "\">" +
        rosteredStartTime + "</span>"
      } else {
        rostered_start_output = "<span class=\"label label-danger text-uppercase\">" + I18n.t("js.reports.attendance.no_roster") + "</span>"
      }

      if (data.rostered_finish) {
        var rosteredEndTime = window.time_formatter.short_time(moment(data.rostered_finish, window.LH.Time.Formats.DateTime))
        rostered_end_output = "<span class=\"shiftTime\" title=\"" + rosteredEndTime + "\">" +
        rosteredEndTime + "</span>"
      } else {
        rostered_end_output = "<span class=\"label label-danger text-uppercase\">" + I18n.t("js.reports.attendance.no_roster") + "</span>"
      }

      return [rostered_start_output, inLabel, rostered_end_output, outLabel]
    }
  }

  self.showDataViz = function (isAvail) {
    if (isAvail) {
      $(".widget .empty").hide()
      $(".widget .hide-if-no-data").show()
    } else {
      $(".widget .hide-if-no-data").hide()
      $(".widget .empty").show()
    }
  }

  self.updatePunctuality = function () {
    // Calculate their punctuality
    var totalClocks = stats.arrived_early + stats.arrived_ontime + stats.arrived_late +
             stats.left_early + stats.left_ontime + stats.left_late +
             stats.noshow + stats.notrostered

    var clocks_for_punctuality_calc = stats.arrived_late + stats.arrived_ontime + stats.left_ontime + stats.left_early

    if (totalClocks > 0) {
      var punctuality = clocks_for_punctuality_calc > 0 ? Math.floor(((stats.arrived_ontime + stats.left_ontime) / clocks_for_punctuality_calc) * 100) : 0

      // Update our punctuality viz and message
      window.chart.update(punctuality)
      $("#punctuality").text(punctuality)

      self.showDataViz(true)
    } else {
      self.showDataViz(false)
    }
  }

  /**
   * Calculates the difference between
   * their rostered time and the time
   * of their clock in or clock out time.
   *
   * We only want the minute difference, so
   * we remove the seconds from the time
   *
   * @param rosteredTime
   * @param eventTime
   * @return momentjs obj
   */
  self.calcDifference = function (milli) {
    if (milli !== undefined) {
      return moment.duration(milli, "milliseconds")
    }
  }

  /**
   * Does some calculations on the
   * difference between their rostered
   * time and their shift start/end time
   * and attributes a slightly humanized meaning
   *
   * @param action [login, logout]
   * @param diff - difference between times in milliseconds
   * @param stats - stats object
   * @param data - roster/shift data - may have properties `shift` and/or `roster`
   * @return obj
   */
  self.classifyDifference = function (action, diff, stats, data) {
    var response = {}
    var cutoff_clockin = self.cutoff.clockin
    var cutoff_clockout = self.cutoff.clockout
    var clockin_leeway = self.cutoff.clockinleeway
    var clockout_leeway = self.cutoff.clockoutleeway

    // Convert diff to minutes
    var mins = 0
    if (diff !== undefined) {
      mins = diff.asMinutes()
      response.diff = diff.humanize()
    }

    if (action === "login" && data.shift) {
      if (mins < 0 && mins >= (0 - cutoff_clockin) && (0 - mins) > clockin_leeway && diff) {
        // arrived less than (cutoff) minutes late -- bad
        stats.arrived_late++
        response.label = moment(data.shift.start, window.LH.Time.Formats.DateTime).format("h:mma")
        response.diff = diff.humanize()
        response.style = "label-warning"
        stats.borrowed_time += Math.abs(mins)
      } else if (mins < 0 && mins < (0 - cutoff_clockin) && diff) {
        // arrived more than (cutoff) minutes late -- outlier
        response.label = "late (outlier)"
        response.diff = diff.humanize()
      } else if (mins > 0 && mins > cutoff_clockin && diff) {
        // arrived more than (cutoff) minutes early -- outlier
        response.label = "early (outlier)"
        response.diff = diff.humanize()
      } else if (diff) {
        // arrived less than (cutoff) minutes early -- punctual
        response.label = moment(data.shift.start, window.LH.Time.Formats.DateTime).format("h:mma")
        response.diff = diff.humanize()
        response.style = "label-success"
        stats.arrived_ontime++
      } else {
        // no clockin, or no roster
        if (data.shift && data.shift.start) {
          response.label = moment(data.shift.start, window.LH.Time.Formats.DateTime).format("h:mma")
        } else {
          response.label = "no clockin"
          response.style = "label-danger"
        }
      }
    } else if (action === "logout" && data.shift) {
      if (mins > 0 && mins <= cutoff_clockout && mins > clockout_leeway && diff) {
        // left less than (cutoff) minutes early -- bad
        response.label = moment(data.shift.finish, window.LH.Time.Formats.DateTime).format("h:mma")
        stats.left_early++
        response.diff = diff.humanize()
        response.style = "label-warning"
        stats.borrowed_time += Math.abs(mins)
      } else if (mins > 0 && mins > cutoff_clockout && diff) {
        // left more than (cutoff) minutes early -- outlier
        response.diff = diff.humanize()
        response.label = "left early (outlier)"
      } else if (mins < 0 && (mins < (0 - cutoff_clockout)) && diff) {
        // left more than (cutoff) minutes late -- outlier
        response.diff = diff.humanize()
        response.label = "left late (outlier)"
      } else if (diff) {
        // left less than (cutoff) minutes late -- punctual
        response.label = moment(data.shift.finish, window.LH.Time.Formats.DateTime).format("h:mma")
        stats.left_ontime++
      } else {
        // no clockout, or no roster
        if (data.shift && data.shift.finish) {
          response.label = moment(data.shift.finish, window.LH.Time.Formats.DateTime).format("h:mma")
        } else {
          response.label = "no clockout"
          response.style = "label-danger"
        }
      }
    }

    return response
  }

  self.sortByKey = function (array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  }

  return self
})()
;
window.orgMetricsInit = function (id) {
  $("#users").one("click", function () {
    $.ajax({
      url: Routes.user_data_path(id),
      cache: false,
      success: function (html) {
        $(html).appendTo("#user_data tbody")
      },
    })
  })
  $(".nav-icons>li").click(function (e) {
    var target = "#" + e.target.id
    $(".tab-content .tab-pane").removeClass("active")
    $(".tab-content " + target).addClass("active")
  })
}
;
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

$.fn.locationShortNameSuggestion = function (target_selector) {
  var shortname_target = $(target_selector)
  var name_source = this
  var should_suggest_at_end_of_last_session = !shortname_target.val() // initially should suggest if did not have a value

  function shouldSuggestShortName () {
    return should_suggest_at_end_of_last_session && !!name_source.val() // suggest if we have a value to suggest from
  }

  shortname_target.on("input", function () {
    should_suggest_at_end_of_last_session = !shortname_target.val() // if a shortname is set, this disables suggestions
  })

  name_source.on("input", function () {
    if (shouldSuggestShortName()) {
      shortname_target.val(name_source.val().initials(3))
    }
  })

  return this
}

$.fn.locationsListSearch = function (options) {
  var childSelector = ".col-search-js"
  return this.searchable({
    selector: options.location + "," + options.team,
    childSelector: childSelector,
    searchField: options.search_field,
    matchOnElement: true,
    matcherFunction: function (term) {
      term = term.trim().toLowerCase()
      return function (element) {
        if (element.parents(options.team).length) {
                              // if the team matches the search term show it
                              // if the team's parent location matches the search term, continue to show it
          return element.text().toLowerCase().indexOf(term) !== -1 || element.parents(options.location).find(childSelector).first().text().toLowerCase().indexOf(term) !== -1
        } else if (element.parents(options.location).length) {
                              // if location matches the search term, show it
          return element.text().toLowerCase().indexOf(term) !== -1
        }
      }
    },
  })
}

$.fn.teamsListSortable = function (options) {
  var $this = $(this)

  if ($this.length > 1) {
    $this.each(function () {
      $(this).teamsListSortable(options)
    })
    return
  }

  var $header_row = $this.find(options.header)
  var $headers = $header_row.find("[data-" + options.header_data_key + "]")

  var getCurrentSortDirection = function ($el) {
    if ($el.hasClass(options.ascending_class)) {
      return "asc"
    } else if ($el.hasClass(options.descending_class)) {
      return "desc"
    }
  }

  var clearAllHeaderSorts = function () {
    $headers.removeClass(options.ascending_class + " " + options.descending_class)
  }

  $headers.click(function () {
    var $header = $(this)
    var currentSortDirection = getCurrentSortDirection($header)
    var fieldName = $header.data(options.header_data_key)
    var fieldType = $header.data(options.type_data_key)
    var fields = $this.find(options.rows + " [data-" + options.field_data_key + "=" + fieldName + "]")
    var valAndRow = _.map(fields, function (el) {
      var $field = $(el)
      var val = $field.text().trim()

      val = fieldType === "int" ? +val : val

      return [val, $field.parents(options.rows)]
    })
    var sortedValAndRows = _.sortBy(valAndRow, function (a) {
      if (a[0] === "") {
        return "zzzzzzzzzz" // sort empty to be last
      } else {
        return a[0]
      }
    })

    clearAllHeaderSorts()

    if (currentSortDirection === "asc") {
      $header.addClass(options.descending_class)
    } else {
      $header.addClass(options.ascending_class)
      sortedValAndRows.reverse()
    }

    _.each(sortedValAndRows, function (arr) {
      arr[1].insertAfter($header_row)
    })
  })
}

// Initialize Chosen selects when the DOM is fully loaded
document.addEventListener("DOMContentLoaded", function() {
  $(document).initializeChosen()
})

// Separate function for initialization logic
$.fn.initializeChosen = function () {
  // Find all select elements with the 'chosen' class
  var chosenSelects = document.querySelectorAll("select.chosen")

  // Initialize each select with Chosen
  chosenSelects.forEach(function(select){
    // Get placeholder text from data attribute if present
    var placeholder = select.dataset.placeholder

    // Initialize with options
    $(select).chosen({
      allow_single_deselect: true,
      search_contains: true,
      placeholder_text: placeholder || I18n.t("helpers.placeholder.location.public_holiday_region_list"), // Use data-placeholder or default
    })
  })
}
;
document.addEventListener("DOMContentLoaded", function () {
  window.initEnableManagedFeaturesButton()
}, false)

window.initEnableManagedFeaturesButton = function () {
  var modal = document.querySelector(".enable-managed-modal")
  var modal_button = document.querySelector(".enable-modal-button")
  var close_modal = document.querySelector(".close-modal")
  var close_button = document.querySelector(".close-button")
  var enable_button = document.querySelector(".enable-button")
  var terms_button = document.getElementById("accept-terms-and-conditions")

  if(modal_button){
    modal_button.onclick = function(e) {
      modal.classList.remove("display-none")
    }
    
    close_modal.onclick = function(e) {
      modal.classList.add("display-none")
    }
    
    terms_button.onclick = function(e) {
      enable_button.disabled = !terms_button.checked
    }

    close_button.onclick = function(e) {
      terms_button.checked = false
      modal.classList.add("display-none")
    }
  }
}
;
window.NoteController = function (options) {
  // makes self available to member functions
  var self = this

  // assign options
  self.inlined_model_and_id = options.inline
  self.model = options.model
  self.events = {}
  self.id = options.id
  self._usernames = []
  self._userdata = {}

  /**
   * Binds Instance To Modals Dom
   */
  self.configureModalBindings = function () {
    $(document).off("click", ".new-note").on("click", ".new-note", function () { // on create
      var btn = $(this)

      var ele = btn.parents(".modal").find(".modal-body")
      var text_area = ele.find(".note-body")
      var text = text_area.val()
      var notifications = ele.find(".note-notifications input:checked").map(function () { return this.id }).get()
      var specific_name = ele.find(".typeahead").val()

      if (text) {
        btn.prop("disabled", true).text(I18n.t("js.notes.adding"))

        if (specific_name && _.some(notifications, function (n) { return /specific/.test(n) })) {
          notifications.push("specific_id_" + get_manager_id_from_name(specific_name))
        }

        window.LH.Spin.until(
          $.ajax({
            url: "/notes",
            type: "POST",
            data: { model: self.model, id: self.id, user_id: window.current_user.id, text: text, notifications: notifications.join(",") },
          }).done(function (d) {
            self.add_note($(d))
            btn.prop("disabled", false).text(I18n.t("js.notes.add"))
            text_area.val("")
          })
        )
      } else {
        alert(I18n.t("js.notes.plz_enter"))
      }
    }).off("click", ".note-delete i").on("click", ".note-delete i", function () { // on delete
      var note = $(this).parents(".note").fadeOut("fast", function () { $(this).remove() })
      var note_id = note.attr("data-id")
      $.ajax({
        url: "/notes/" + note_id,
        type: "DELETE",
      })
    }).off("click", '[data-dismiss="modal"]').on("click", '[data-dismiss="modal"]', function () { // on close
      if (self.events.onClose) {
        self.events.onClose($(".note").length) // call event with notes count
      }
    })
  }

  self.bind_modal_listeners = function () {
    $(".note-icon").loadTooltips({ trigger: "hover", placement: "top" })

    $(document).off("click", ".note-icon").on("click", ".note-icon", function () {
      var ele = $(this)
      self.model = self.model || ele.attr("data-model") || ele.find("i").attr("data-model")
      self.id = self.id || ele.attr("data-id") || ele.find("i").attr("data-id")

      $(document).off("click", ".note-icon")
      self.configureModalBindings() // bind instance to modal dom on click

      self.render_modal()

      return false // otherwise other event handlers get run :/
    })

    $(document).ready(function () {
      var input = $("#specific-person-input")
      input.hide()

      $("#email_specific").on("change", function () {
        if ($(this).is(":checked")) {
          input.show()
        } else {
          input.val("")
          input.hide()
        }
      })
    })
  }

  // bind to note-icon unless no bind
  var noBind = options.noBind
  if (!noBind) {
    self.bind_modal_listeners()

    if (self.model && self.id) {
      // check if this model has any comments, show red icon if it does
      window.LH.Spin.until(
        $.ajax({
          url: "/notes/any",
          cache: true,
          data: { model: self.model, id: self.id },
        }).done(function (data) {
          if (data >= 1) {
            $(".note-icon " + ".leave-" + self.id).html("<span class='unread-count' style='display:block;'>" + data + "</span><span class='mi paddright-5 mi-textsms'></span>Comments")
          } else {
            $(".note-icon " + ".leave-" + self.id).html("<span class='unread-count' style='display:none;'>0</span><span class='mi paddright-5 mi-chat-bubble-outline'></span>Comments")
          }
        })
      )
    }
  }

  var get_manager_id_from_name = function (name) {
    var index = self._userdata.index[name]
    return _.isUndefined(index) ? null : (self._userdata.data[index] || { id: null }).id
  }

  /**
   * Instance methods
   */
  this.create_typeahead = function (jq_elem) {
    if (!jq_elem.length) { return }
    if (!self._usernames.length) {
      var url = "/notes/staff_list"

      if (self.model) {
        url += "?noteable_type=" + self.model
      }

      $.ajax({
        type: "GET",
        cache: true,
        url: url,
        dataType: "json",
      }).done(function (obj) {
        self._userdata = obj
        self._usernames = $.map(obj.data, function (elem, index) { return elem.name })
        jq_elem.extendedTypeahead({ source: self._usernames })
      })
    } else {
      jq_elem.extendedTypeahead({ source: self._usernames })
    }
  }
  this.add_note = function (jq_html) {
    jq_html.hide().prependTo($(".notes-modal .notes")).slideDown("fast")
  }
  this.modal_hidden = function () {
    if (self.inlined_model_and_id) {
      self.model = null
      self.id = null
    }
  }
  this.render_modal = function (modalEvents) {
    self.configureModalBindings()  // bind instance to modal dom on click

    _.extend(self.events, modalEvents)

    window.LH.Spin.until(
      $.ajax({
        url: "/notes/get",
        cache: true,
        dataType: "html",
        data: { model: self.model, id: self.id },
      }).done(function (data) {
        var modal = $(data)
        modal.appendTo(document.body).modal().modal("show").on("hidden", function () {
          self.modal_hidden()
          modal.remove()
        })
        self.create_typeahead(modal.find(".typeahead"))
        self.bind_modal_listeners()
        self.useNotificationDefaults()
      })
    )

    return false // otherwise other event handlers get run :/
  }

  this.useNotificationDefaults = function () {
    var checkedManagerBox = window.localStorage.getItem("email-managers-checked")
    if (checkedManagerBox !== null) {
      var managerCheckbox = document.getElementById("email_department_managers")
      checkedManagerBox === "true" ? managerCheckbox.checked = true : managerCheckbox.checked = false
    }
    document.addEventListener("click", function (event) {
      if (!event.target.matches("#email_department_managers")) { return }
      window.localStorage.setItem("email-managers-checked", event.target.checked)
    }, false)
  }

  return this
}
;
window.NotificationPageValidationAndHiddenFieldUpdates = function (panel, check_box_selector, child_selector_callback) {
  var option_buttons = panel.find(".send-option-button-js")
  var submit_el = panel.find(".submit-js")

  function toggleClickStateOnSendOption () {
    var $el = $(this)

    $el.toggleClass("selected")

    if ($el.hasClass("selected")) {
      $.cookie($el.data("name") + "-selected", true)
    } else {
      $.removeCookie($el.data("name") + "-selected")
    }
  }

  function setHiddenFieldBasedOnSelectedSendMode () {
    var selected_options = option_buttons.filter(".selected")

    switch (selected_options.length) {
      case 1:
        submit_el.val(selected_options.attr("value"))
        break
      case 2:
        submit_el.val("SEND BOTH")
        break
    }
  }

  option_buttons.off().on("click", function () {
    toggleClickStateOnSendOption.call(this)
  })

  setHiddenFieldBasedOnSelectedSendMode()

  function addActiveClassToActiveAccordion () {
    $("#steps .collapse.in").parents(".blue-accordion").addClass("active")
    $("#steps .collapse:not(.in)").parents(".blue-accordion").removeClass("active")
  }

  $(document).on("click", "[data-toggle=collapse]", addActiveClassToActiveAccordion)
  addActiveClassToActiveAccordion()
}
;
window.NotificationsSelectAll = function (panel, selector) {
  function selectAllEmployees (deselect) {
    panel.find(selector).prop("checked", !deselect).trigger("change")
  }

  panel.on("click", ".select-all-js", function () {
    selectAllEmployees()
  }).on("click", ".deselect-all-js", function () {
    selectAllEmployees(true)
  })
}
;
window.PayrollIntegration = (function () {
  var generateOAuthIntegrationClick = function (redirectToAfterOAuth) {
    return function () {
      window.OAuthPopup.create({ path: $(this).data("oauth-integration-url"), redirectTo: redirectToAfterOAuth })
    }
  }

  return {
    init_new: function (redirectToAfterOAuth) {
      var integrationClick = function () {
        window.navigate_to($(this).data("system-integration-url"))
      }

      $(".provider-js").click(integrationClick)

      var flatFileIntegrationClick = function () {
        window.navigate_to($(this).data("flat-file-integration-url"))
      }

      var showMoreIntegrationsClick = function () {
        $(".show-more-integrations-js").hide()
        $(".hidden-integration-js").show(200)
      }

      $(".oauth-integration-js").click(generateOAuthIntegrationClick(redirectToAfterOAuth))
      $(".flat-file-integration-js").click(flatFileIntegrationClick)

      $(".hidden-integration-js").hide()
      $(".show-more-integrations-js").click(showMoreIntegrationsClick)

      $.fn.providersListSearch = function (options) {
        var childSelector = ".provider-name-js"
        return this.searchable(
          {
            selector: options.provider,
            childSelector: childSelector,
            searchField: options.search_field,
            onSearchActive: function () {
              $(".show-more-integrations-js").hide()
            },
          }
        )
      }

      $("#providersList").providersListSearch(
        {
          provider: ".provider-js",
          search_field: "#searchbox",
        }
      )
      $("#searchbox").searchbox()
    },
    init_edit: function (redirectToAfterOAuth) {
      var integrationAppliesToChanged = function () {
        $(".integration-applies-to-ids-js")[$(this).val() === "reporting_location" ? "show" : "hide"]()
        $(".integration-pay-group-ids-js")[$(this).val() === "pay_group" ? "show" : "hide"]()
      }

      $("select.chosen").chosen()
      $(".oauth-integration-js:not([disabled])").click(generateOAuthIntegrationClick(redirectToAfterOAuth))
      $(".integration-applies-to-radio-js").change(integrationAppliesToChanged)
      if ($(".integration-applies-to-radio-js[checked]").val() !== "reporting_location") { $(".integration-applies-to-ids-js").hide() }
      if ($(".integration-applies-to-radio-js[checked]").val() !== "pay_group") { $(".integration-pay-group-ids-js").hide() }
    },
  }
})()
;
$.fn.reportEmailScheduleEditor = function () {
  var parent = this
  return parent.on("click", ".create-email", function () {
    // Update Frequency Input Label
    document.getElementById("current_frequency").innerHTML = $(this).html()

    // Append icon to active list item
    parent.find(".mi-done").remove()
    $(this).html($(this).html() + '<i class="mi mi-done"> </i> ')
  })
}
;
$.fn.reportEmailSendTimePicker = function (url) {
  var picker = this

  picker.timepicker({ forceRoundTime: true, className: "dropdown-menu", step: 60 })
  picker.on("changeTime", function () {
    $.ajax({
      url: url,
      data: { send_time: $(this).val() },
      method: "POST",
    })
  })
}
;
$.fn.reportFilterEditor = function () {
  var parent = this
  return parent.on("click", ".create-filter", function () {
    var name = prompt(I18n.t("js.reports.filter_creator.choose_name"), "")
    var url = window.location.href
    var data = { name: name, url: url }

    if (name) {
      $.post("/report_filters", data, function (data) {
        var new_filter_id = data.id

        var link = crel("li",
                  crel("a", { href: "/reports/filtered?filter_id=" + new_filter_id }, name))

        parent.prepend(link)
      })
    }

    return false
  }).on("click", ".delete-filter", function () {
    var list_item = $(this).parents(".report-filter")
    var id = list_item.data("filter-id")

    $.ajax({
      url: "/report_filters/" + id,
      type: "DELETE",
    })

    list_item.hide("fast")

    return false
  })
}
;
$.fn.rulesForm = function () {
  var form = this
  var days_array_set = form.find(".dates-array-set")
  var days_hidden = form.find("[data-field=days]")
  var weekdays = form.find("[data-field=weekdays]")
  var weekends = form.find("[data-field=weekends]")
  var public_holidays_names = $("#hidden-public-holiday-names-js")

  form.find(".chosen").chosen({ allow_single_deselect: true })

  var repeatable_plus_icon = function () {
    return crel("span", { class: "mi-wrapper repeat-icon" },
            crel("i", { class: "mi mi-add" }))
  }

  var hide_and_clear_children = function (show_hide_div) {
    show_hide_div.next(".show-hide-target")
                 .hide("fast")
                 .find("input[type=text], input[type=hidden]").val("").end()
                 .find("input[type=number]").val(0).end()
                 .find("select").val("").end()
                 .find("input[type=checkbox]").prop("checked", false)
  }

  var update_select_sets = function (select_sets_parent) {
    if (!select_sets_parent.length) { return }

    select_sets_parent.find("[data-field=times]")
                      .val(select_sets_parent.find(".select-set")
                                             .map(function () { return $(this).find(".from").val() + "-" + $(this).find(".to").val() })
                                             .get()
                                             .join(","))
  }

  var update_array_set = function (array_set_parent) {
    if (!array_set_parent.length) { return }

    array_set_parent.find("[type=hidden]:not([data-no-autofill])")
                    .val(array_set_parent.find("[type=checkbox]:checked")
                                         .map(function () { return this.value })
                                         .get()
                                         .join(","))
  }

  var update_days = function (array_set_parent) {
    var days = []
    if (weekdays.is(":checked")) { days = days.concat(["1", "2", "3", "4", "5"]) }
    if (weekends.is(":checked")) { days = days.concat(["0", "6"]) }
    days = days.concat(array_set_parent.find("[type=checkbox]:checked").map(function () { return this.value }).get())
    days_hidden.val(_.uniq(days).join(","))
  }

  var toggle_costing_hours_calculation = function () {
    if ($("#auto_create_leave").prop("checked")) {
      $("#costing_hours_calculation").show(1)
    } else {
      $("#costing_hours_calculation").hide(1)
    }
  }

  var toggle_fixed_length_costing = function () {
    if ($("#fixed_length_costing").prop("checked")) {
      $("#costing_hours_fixed").show(1).find(":input").val(null)
    } else {
      $("#costing_hours_fixed").hide(1).find(":input").val(null)
    }
  }

  var toggle_historic_costing = function () {
    if ($("#historic_base_costing").prop("checked")) {
      $("#costing_hours_historic").show(1).find(":input").val(null)
    } else {
      $("#costing_hours_historic").hide(1).find(":input").val(null)
    }
  }

  var toggleSelectionIcon = function (option) {
    option.toggleClass("included")
  }

  var on_load_visibilities = function () {
    // Applies to... - enable clicked checkboxes
    form.find(".applies-to-area-js")
        .find("input:checked, option[selected='selected']")
          .parents(".show-hide-target")
          .prev(".show-hide")
          .find("[type=checkbox]")
            .prop("checked", true)
            .trigger("change")

    if ($("#award_public_holidays").prop("checked")) {
      $("#award_public_holidays").parents(".check-box-container").find(".public-holidays-option").show()
    }

    // enable time drop downs if there's a value
    var times = form.find(".applies-on-area").find("[data-field=times]").filter(function (_, e) { return e.value.length })
    if (times.length) {
      times.each(function () {
        var time = $(this)
        var time_vals = time.val()

        if (time_vals.length) {
          $.each(time_vals.split(","), function (index, set) {
            var selects
            if (index === 0) {
              selects = time.next(".select-set").first().find("select")
            } else {
              selects = time.siblings(".select-set").last().clone().appendTo(time.parent()).find("select").slice(-2)
            }

            set = set.split("-")
            selects.filter(".from").val(set[0])
            selects.filter(".to").val(set[1])
          })
        }
        time.parent(".checkbox").prev(".checkbox").find("[type=checkbox]").prop("checked", true).trigger("change")
      })
    }

    // enable the specific days and public holidays
    form.find(".applies-on-area, .applies-to-area-js").find(".array-set [type=hidden]:not([data-no-autofill])").each(function (_, hidden) {
      hidden = $(hidden)
      var val = hidden.val()
      if (val[0] === "[") { val = JSON.parse(val) } else if (val.length) { val = val.split(",") }

      if (val.length) {
        val = val.map(function (e) { return e.toString() })
        hidden.parent().find("[type=checkbox]").filter(function (_, cb) { return $.inArray(cb.value, val) > -1 }).prop("checked", true)
        hidden.parents(".checkbox").prev(".checkbox").find("[type=checkbox]").prop("checked", true).trigger("change")
      }
    }).end().find(".array-set [type=hidden][data-no-autofill]").each(function (_, hidden) {
      hidden = $(hidden)
      var val = hidden.val()

      if (val) {
        hidden.parents(".checkbox").prev(".checkbox").find("[type=checkbox]").prop("checked", true).trigger("change")
      }
    })

    form.find(".show-hide-init-expand-area").find(":checkbox:checked").parent(".checkbox").next(".show-hide-target").show()

    form.find(".repeatable").each(function () {
      var select_set = $(this).find(".select-set").last()
      if (!select_set.find(".repeat-icon").length) {
        select_set.append(repeatable_plus_icon())
      }
    })
  }

  form.on("click", ".repeatable .mi.mi-add", function () {
    var icon = $(this).parent(".repeat-icon")
    var select_set = icon.parents(".select-set")
    var new_select_set = select_set.clone()

    new_select_set.insertAfter(select_set)
    update_select_sets(new_select_set.parents(".select-sets"))

    icon.remove()
  })

  form.find("#auto_create_leave").on("change", toggle_costing_hours_calculation)
  form.find("#fixed_length_costing").on("change", toggle_fixed_length_costing)
  form.find("#historic_base_costing").on("change", toggle_historic_costing)

  form.on("change", ".acts-like-radio[type=checkbox]", function () {
    // if a checkbox in an acts-like-radio set is checked
    // uncheck all other checkboxes in its set
    this.checked && $(this).parents(".acts-like-radio-parent").find(".acts-like-radio:checked").not(this).prop("checked", false).trigger("change")
  }).on("change", ".show-hide > [type=checkbox]", function () {
    // if a checkbox in a show/hide area is (un)checked
    // toggle the show/hide area and update the values of its children
    var ele = $(this)
    var show_hide = ele.parents(".show-hide")
    var show_hide_target = show_hide.next(".show-hide-target")

    if (this.checked) {
      show_hide_target.show("fast")
      update_select_sets(ele.parent().next(".select-sets"))
      update_array_set(ele.parent().next(".array-set"))

      if (show_hide_target.is(".repeatable") && !show_hide_target.find(".repeat-icon").length) {
        show_hide_target.find(".select-set").last().append(repeatable_plus_icon())
      }
    } else {
      hide_and_clear_children(show_hide)

      if (show_hide_target.is(".repeatable")) {
        show_hide_target.find(".select-set").slice(1).remove()
        show_hide_target.find(".repeat-icon").remove()
      }
    }
  }).on("change", ".select-set .times-set", function () {
    // if a value changes in a select set, update the hidden field that stores the value
    update_select_sets($(this).parents(".select-sets"))
  }).on("change", ".array-set:not(.dates-array-set) [type=checkbox]", function () {
    // if a value changes in an array set, update the hidden field that stores the value
    update_array_set($(this).parents(".array-set"))
  }).on("change", ".dates-array-set [type=checkbox], .dates-trigger [type=checkbox]", function () {
    // if a value changes in an array set, update the hidden field that stores the value
    update_days(days_array_set)
  })

  var cached_public_holidays_names = ""
  form.find(".specific-public-holidays-js").on("change", function () {
    if (!$(this).prop("checked")) {
      cached_public_holidays_names = public_holidays_names.val()
      public_holidays_names.val("")
    } else {
      public_holidays_names.val(cached_public_holidays_names)
    }
  })

  form.find(".public-holiday-select-js").on("select-change", function (event) {
    var values = public_holidays_names.val().split(",")
    var index = values.indexOf(event.value)

    public_holidays_names.val(index === -1
        ? values.concat(event.value).join(",")
        : values.splice(index, 1).join(","))
  }).filterSelect({
    callback: toggleSelectionIcon,
    type: "footer",
    show: 6,
    footerShow: 6,
    appendFooter: function () {
      if (this.parents(".tab-pane").length > 0) {
        return this.parents(".tab-pane")
      }
      return this.parents(".tag-search")
    },
  })

  on_load_visibilities()
  return form
}
;
$.fn.ruleableListFilter = function (options) {
  var childSelector = ".col-search-js"
  return this.searchable({
    selector: options.selector,
    childSelector: childSelector,
    searchField: options.search_field,
  })
}
;
window.manage_split_fields = function () {
  $(".settings-form").on("input", ".split-field", function () {
    var ele = $(this)
    var parent = ele.parents(".split-field-parent")
    var hidden = parent.find("[data-split-field-target]")

    var hours
    var minutes
    if (hidden.length) {
      hours = parseInt(parent.find(".hours").val(), 10)
      minutes = parseInt(parent.find(".minutes").val(), 10) / 60.0
      // deliberately don't check for NaNs here - only way to get a NaN is to leave a field blank, if user does that and saves
      // we don't want to silently drop their changes. instead we push to NaN to the backend which will send back a great big warning
      // saying the save failed.
      hidden.val((hours + minutes).toPrecision(3))
    }
  })
}

$.fn.describeRounding = function (options) {
  var FORMAT = "h:mm A"
  var warnAboutClockInBreaks = options.warn_about_clock_in_breaks

  function t (key, props) {
    props = $.extend({}, props || {}, { scope: "js.settings.rounding" })
    return I18n.t(key, props)
  }

  function updateClockinTexts () {
    var messages = []
    var interval = parseFloat(clockin_interval.val()) || 0
    var aggressive = clockin_aggressive.is(":checked")
    var rostered_time = function () { return moment().hour(9).minute(0).second(0) }
    var gap = Math.ceil(interval / 3)
    var time_near_rostered_time = rostered_time().subtract(gap, "minutes")
    var time_far_from_rostered_time = rostered_time().subtract(interval, "minutes").add(gap, "minutes")
    var one_interval_before_rostered_time = rostered_time().subtract(interval, "minutes")
    var time_more_than_one_interval_before_rostered_time = rostered_time().subtract(interval, "minutes").subtract(gap, "minutes")
    var time_after_rostered_start = rostered_time().add(gap, "minutes")
    var time_far_after_rostered_start = rostered_time().add(gap + interval, "minutes")
    var interval_after_rostered_start = rostered_time().add(interval, "minutes")

    if (interval === 0) {
      clockin_description.html(t("clockin_disabled_new"))
      return
    }

    if (clockin_roster.is(":checked")) {
      if (aggressive) {
        messages.push(t("clockin_not_rostered_aggressive"))
        messages.push("") // line break
      } else {
        messages.push(t("clockin_not_rostered_minutes", { interval: interval.toString() }))
        messages.push(t("clockin_not_rostered_example_down", { time_far_from_rostered_time: time_far_from_rostered_time.format(FORMAT), one_interval_before_rostered_time: one_interval_before_rostered_time.format(FORMAT) }))
        messages.push(t("clockin_not_rostered_example_up", { time_near_rostered_time: time_near_rostered_time.format(FORMAT), rostered_time: rostered_time().format(FORMAT) }))
        messages.push("") // line break
      }
      if (aggressive) {
        messages.push(t("clockin_rostered_aggressive", { interval: interval }))
      } else {
        messages.push(t("clockin_rostered_non_aggressive", { interval: interval }))
      }
      messages.push(t("clockin_example", { roster: rostered_time().format(FORMAT) }))
      if (aggressive) {
        messages.push(t("clockin_example_aggressive_rounded", { time: time_more_than_one_interval_before_rostered_time.format(FORMAT), from: time_more_than_one_interval_before_rostered_time.format(FORMAT) }))
      } else {
        messages.push(t("clockin_example_not_aggressive_rounded", { time: time_more_than_one_interval_before_rostered_time.format(FORMAT), to: one_interval_before_rostered_time.format(FORMAT) }))
      }
      messages.push(t("clockin_example_rounding_up", { time: time_far_from_rostered_time.format(FORMAT), rostered_time: rostered_time().format(FORMAT) }))
      messages.push(t("clockin_example_rounding_up", { time: time_near_rostered_time.format(FORMAT), rostered_time: rostered_time().format(FORMAT) }))
      if (aggressive) {
        messages.push(t("clockin_example_aggressive_rounded", { time: time_after_rostered_start.format(FORMAT), from: time_after_rostered_start.format(FORMAT) }))
      } else {
        messages.push(t("clockin_example_rounding_down", { time: time_after_rostered_start.format(FORMAT), rostered_time: rostered_time().format(FORMAT) }))
        messages.push(t("clockin_example_rounding_down", { time: time_far_after_rostered_start.format(FORMAT), rostered_time: interval_after_rostered_start.format(FORMAT) }))
      }
    } else {
      if (aggressive) {
        messages.push(t("clockin_no_roster_aggressive", { minutes: interval.toString() }))
        messages.push(t("clockin_no_roster_aggressive_example", { time: time_far_from_rostered_time.format(FORMAT), to: rostered_time().format(FORMAT) }))
      } else {
        messages.push(t("clockin_no_roster_no_aggressive", { minutes: interval.toString() }))
        messages.push(t("clockin_no_roster_no_aggressive_example", { time: time_far_from_rostered_time.format(FORMAT), to: one_interval_before_rostered_time.format(FORMAT) }))
      }
      messages.push(t("clockin_no_roster_general_example", { time: time_near_rostered_time.format(FORMAT), to: rostered_time().format(FORMAT) }))
    }

    clockin_description.html(messages.join("<br>"))

    // Clock-in times bylines

    $(".login-work-mins-increment-small-js").html(t("login_work_mins_increment_small", { minutes: interval }))
    $(".login-work-mins-increment-aggressive-small-js").html(t("login_work_mins_increment_aggressive_small", { minutes: interval }))
    $(".round-logins-to-rosters-small-js").html(t("round_logins_to_rosters_small", { minutes: interval }))
  }

  function updateClockoutTexts () {
    var messages = []
    var interval = parseFloat(clockout_interval.val()) || 0
    var aggressive = clockout_aggressive.is(":checked")
    var rostered_time = function () { return moment().hour(17).minute(0).second(0) }
    var gap = Math.ceil(interval / 3)
    var time_near_rostered_time = rostered_time().add(gap, "minutes")
    var time_far_from_rostered_time = rostered_time().add(interval, "minutes").subtract(gap, "minutes")
    var one_interval_before_rostered_time = rostered_time().add(interval, "minutes")
    var time_more_than_one_interval_before_rostered_time = rostered_time().add(interval, "minutes").add(gap, "minutes")
    var time_before_rostered_start = rostered_time().subtract(gap, "minutes")
    var time_far_before_rostered_start = rostered_time().subtract(gap + interval, "minutes")
    var interval_before_rostered_start = rostered_time().subtract(interval, "minutes")

    if (interval === 0) {
      clockout_description.html(t("clockout_disabled_new"))
      return
    }

    if (clockout_roster.is(":checked")) {
      if (aggressive) {
        messages.push(t("clockout_not_rostered_aggressive"))
        messages.push("") // line break
      } else {
        messages.push(t("clockout_not_rostered_minutes", { interval: interval.toString() }))
        messages.push(t("clockout_not_rostered_example_down", { time: time_near_rostered_time.format(FORMAT), to: rostered_time().format(FORMAT) }))
        messages.push(t("clockout_not_rostered_example_up", { time: time_far_from_rostered_time.format(FORMAT), to: one_interval_before_rostered_time.format(FORMAT) }))
        messages.push("") // line break
      }
      if (aggressive) {
        messages.push(t("clockout_rostered_aggressive", { interval: interval }))
      } else {
        messages.push(t("clockout_rostered_non_aggressive", { interval: interval })) // If someone is rostered, clockouts within 15 minutes of its end will be rounded down to it. All other clockouts will be rounded to the nearest 15 minutes.
      }
      messages.push(t("clockout_example", { roster: rostered_time().format(FORMAT) })) // So, if someone is rostered until 5:00 PM...
      if (aggressive) {
        messages.push(t("clockout_example_aggressive_rounded", { time: time_before_rostered_start.format(FORMAT), from: time_before_rostered_start.format(FORMAT) })) // A 4:55 PM clockout will be not be rounded, it will be paid until 4:55 PM.
      } else {
        messages.push(t("clockout_example_not_aggressive_rounded", { time: time_far_before_rostered_start.format(FORMAT), to: interval_before_rostered_start.format(FORMAT) })) // A 4:40 PM clockout will be rounded up to 4:45 PM.
        messages.push(t("clockout_example_not_aggressive_rounded", { time: time_before_rostered_start.format(FORMAT), to: rostered_time().format(FORMAT) })) // A 4:55 PM clockout will be rounded up to 5:00 PM.
      }
      messages.push(t("clockout_example_rounding_down", { time: time_near_rostered_time.format(FORMAT), rostered_time: rostered_time().format(FORMAT) })) // A 5:05 PM clockout will be rounded down to 5:00 PM.
      messages.push(t("clockout_example_rounding_down", { time: time_far_from_rostered_time.format(FORMAT), rostered_time: rostered_time().format(FORMAT) })) // A 5:10 PM clockout will be rounded down to 5:00 PM.
      if (aggressive) {
        messages.push(t("clockout_example_aggressive_far", { time: time_more_than_one_interval_before_rostered_time.format(FORMAT) }))
      } else {
        messages.push(t("clockout_example_rounding_down", { time: time_more_than_one_interval_before_rostered_time.format(FORMAT), rostered_time: one_interval_before_rostered_time.format(FORMAT) }))
      }
    } else {
      if (aggressive) {
        messages.push(t("clockout_no_roster_aggressive", { minutes: interval.toString() }))
      } else {
        messages.push(t("clockout_no_roster_no_aggressive", { minutes: interval.toString() }))
      }
      messages.push(t("clockout_no_roster_general_example", { time: time_near_rostered_time.format(FORMAT), to: rostered_time().format(FORMAT) }))
      if (aggressive) {
        messages.push(t("clockout_no_roster_aggressive_example", { time: time_far_from_rostered_time.format(FORMAT), to: rostered_time().format(FORMAT) }))
      } else {
        messages.push(t("clockout_no_roster_no_aggressive_example", { time: time_far_from_rostered_time.format(FORMAT), to: one_interval_before_rostered_time.format(FORMAT) }))
      }
    }

    clockout_description.html(messages.join("<br>"))

    // Clock-out times bylines

    $(".logout-work-mins-increment-small-js").text(t("logout_work_mins_increment_small", { minutes: interval }))
    $(".logout-work-mins-increment-aggressive-small-js").text(t("logout_work_mins_increment_aggressive_small", { minutes: interval }))
    $(".round-logouts-to-rosters-small-js").text(t("round_logouts_to_rosters_small", { minutes: interval }))
  }

  function updateBreakRoundingTexts () {
    var messages = []
    var interval = parseFloat(break_rounding_interval.val()) || 0
    var short_break = String(Math.max(0, 30 - interval))
    var long_break = String(Math.max(0, 30 + interval))

    if (interval === 0) {
      break_rounding_description.html(t("break_rounding_disabled"))
      $(".break_length_rounding_small-js").text(t("break_length_rounding_small", { minutes: "X", s: "s" }))
      return
    }

    $(".break_length_rounding_small-js").text(t("break_length_rounding_small", { minutes: interval, s: interval > 1 ? "s" : "" }))

    if (warnAboutClockInBreaks) {
      messages.push(t("break_rounding_warn_no_clock_in_breaks"))
      messages.push(t("break_rounding_warn_no_clock_in_breaks_two"))
    } else {
      messages.push(t("break_rounding_help_bubble"))
      messages.push(t("break_rounding_example", { short_break: short_break }))
      messages.push(t("break_rounding_down_example", { long_break: long_break }))
    }

    break_rounding_description.html(messages.join("<br><br>"))
  }

  var clockin_interval = this.find(".clockin-interval").on("input", updateClockinTexts)
  var clockin_aggressive = this.find(".clockin-aggressive").on("change", updateClockinTexts)
  var clockin_roster = this.find(".clockin-roster").on("change", updateClockinTexts)
  var clockout_interval = this.find(".clockout-interval").on("input", updateClockoutTexts)
  var clockout_aggressive = this.find(".clockout-aggressive").on("change", updateClockoutTexts)
  var clockout_roster = this.find(".clockout-roster").on("change", updateClockoutTexts)
  var clockin_description = this.find(".clockin-description")
  var clockout_description = this.find(".clockout-description")
  var break_rounding_description = this.find(".break-rounding-description")
  var break_rounding_interval = this.find(".break-rounding-interval").on("change", updateBreakRoundingTexts)

  updateClockinTexts()
  updateClockoutTexts()
  updateBreakRoundingTexts()

  return this
}

$.fn.describeReportConfig = function () {
  function t (key, props) {
    props = $.extend({}, props || {}, { scope: "js.settings.reports" })
    return I18n.t(key, props)
  }

  function updateReportPunctualityClockinLeeway () {
    var interval = parseFloat(report_punctuality_clockin_leeway.val()) || 0

    $(".report-punctuality-clockin-leeway-small-js").html(t("report-punctuality-clockin-leeway-small", { minutes: interval }))
  }

  function updateReportPunctualityClockinOutlier () {
    var interval = parseFloat(report_punctuality_clockin_outlier.val()) || 0

    $(".report-punctuality-clockin-outlier-small-js").html(t("report-punctuality-clockin-outlier-small", { minutes: interval }))
  }

  function updateReportPunctualityClockoutLeeway () {
    var interval = parseFloat(report_punctuality_clockout_leeway.val()) || 0

    $(".report-punctuality-clockout-leeway-small-js").html(t("report-punctuality-clockout-leeway-small", { minutes: interval }))
  }

  function updateReportPunctualityClockoutOutlier () {
    var interval = parseFloat(report_punctuality_clockout_outlier.val()) || 0

    $(".report-punctuality-clockout-outlier-small-js").html(t("report-punctuality-clockout-outlier-small", { minutes: interval }))
  }

  var report_punctuality_clockin_leeway = this.find(".clockin-leeway").on("input", updateReportPunctualityClockinLeeway)
  var report_punctuality_clockin_outlier = this.find(".clockin-outlier").on("input", updateReportPunctualityClockinOutlier)
  var report_punctuality_clockout_leeway = this.find(".clockout-leeway").on("input", updateReportPunctualityClockoutLeeway)
  var report_punctuality_clockout_outlier = this.find(".clockout-outlier").on("input", updateReportPunctualityClockoutOutlier)

  updateReportPunctualityClockinLeeway()
  updateReportPunctualityClockinOutlier()
  updateReportPunctualityClockoutLeeway()
  updateReportPunctualityClockoutOutlier()
}

$.fn.setAutoApproveControlDisplay = function (autoApproveCriteria, disabled) {
  autoApproveCriteria.namedItem("auto-approve-by-times").disabled = disabled
  autoApproveCriteria.namedItem("auto-approve-by-times-buffer").disabled = disabled
  autoApproveCriteria.namedItem("auto-approve-by-cost-variance").disabled = disabled
  autoApproveCriteria.namedItem("auto-approve-by-cost-variance-buffer").disabled = disabled
  autoApproveCriteria.namedItem("auto-approve-by-shift-length").disabled = disabled
  autoApproveCriteria.namedItem("auto-approve-by-shift-length-buffer").disabled = disabled
  autoApproveCriteria.namedItem("auto-approve-by-geofence").disabled = disabled
}

$.fn.hideAutoApproveCriteria = function () {
  var autoApproveCheckBox = document.getElementById("setting_auto_approve_shifts")
  var autoApproveCriteria = document.getElementsByClassName("auto-approve-subfield")

  if (autoApproveCheckBox.checked) {
    $.fn.setAutoApproveControlDisplay(autoApproveCriteria, false)
  } else {
    $.fn.setAutoApproveControlDisplay(autoApproveCriteria, true)
  }

  autoApproveCheckBox.addEventListener("change", function () {
    if (this.checked) {
      $.fn.setAutoApproveControlDisplay(autoApproveCriteria, false)
    } else {
      $.fn.setAutoApproveControlDisplay(autoApproveCriteria, true)
    }
  })
}

$.fn.hideAutoApprovalBuffers = function () {
  var autoApproveByTimesCheckBox = document.getElementById("auto-approve-by-times")
  var autoApproveByCostVarianceCheckBox = document.getElementById("auto-approve-by-cost-variance")
  var autoApproveByShiftLengthCheckBox = document.getElementById("auto-approve-by-shift-length")

  var autoApproveByTimesBuffer = document.getElementById("auto-approve-by-times-buffer")
  var autoApproveByCostVarianceBuffer = document.getElementById("auto-approve-by-cost-variance-buffer")
  var autoApproveByShiftLengthBuffer = document.getElementById("auto-approve-by-shift-length-buffer")

  if (autoApproveByTimesCheckBox.checked) {
    autoApproveByTimesBuffer.style.display = "block"
  } else {
    autoApproveByTimesBuffer.style.display = "none"
  }

  if (autoApproveByCostVarianceCheckBox.checked) {
    autoApproveByCostVarianceBuffer.style.display = "block"
  } else {
    autoApproveByCostVarianceBuffer.style.display = "none"
  }

  if (autoApproveByShiftLengthCheckBox.checked) {
    autoApproveByShiftLengthBuffer.style.display = "block"
  } else {
    autoApproveByShiftLengthBuffer.style.display = "none"
  }

  autoApproveByTimesCheckBox.addEventListener("change", function () {
    if (this.checked) {
      autoApproveByTimesBuffer.style.display = "block"
    } else {
      autoApproveByTimesBuffer.style.display = "none"
    }
  })

  autoApproveByCostVarianceCheckBox.addEventListener("change", function () {
    if (this.checked) {
      autoApproveByCostVarianceBuffer.style.display = "block"
    } else {
      autoApproveByCostVarianceBuffer.style.display = "none"
    }
  })

  autoApproveByShiftLengthCheckBox.addEventListener("change", function () {
    if (this.checked) {
      autoApproveByShiftLengthBuffer.style.display = "block"
    } else {
      autoApproveByShiftLengthBuffer.style.display = "none"
    }
  })
}

$.fn.updateLeaveDefaultHours = function () {
  var defaultLeaveLengthInput = document.getElementById("leave-request-default-length-input")
  var preventPrefillRequestedHoursCheckBox = document.getElementById("prevent-prefill-requested-hours-check-box")
  // Add event listener for checkbox -> input
  preventPrefillRequestedHoursCheckBox.addEventListener("change", function () {
    if (this.checked) {
      defaultLeaveLengthInput.value = "0.0"
    } else {
      defaultLeaveLengthInput.value = 7.6
    }
  })
  // Add event listener for input -> checkbox
  defaultLeaveLengthInput.addEventListener("change", function () {
    if (parseFloat(this.value) === 0) {
      preventPrefillRequestedHoursCheckBox.checked = true
    } else {
      preventPrefillRequestedHoursCheckBox.checked = false
    }
  })

  // Ensure state is what we want on doc load.
  if (parseFloat(defaultLeaveLengthInput.value) === 0) {
    preventPrefillRequestedHoursCheckBox.checked = true
  }
}
;
/* eslint-env es6 */

$.fn.ssoEmailHandler = function () {
  $("#sso_sign_in_subtext_toggle_off").hide()
  $("#sso_sign_in_subtext_toggle_on").hide()

  var email = document.getElementById("email")
  var ssoLogin = false

  $("#sso_sign_in_subtext_toggle_off").on("click", function () {
    ssoLogin = false
    $("#sso_sign_in_subtext_toggle_off").hide()
    $("#sso_sign_in_subtext_toggle_on").show()
    $(".password").show()
    $("#login_button").attr("value", I18n.t("js.devise.form.login"))
  })

  $("#sso_sign_in_subtext_toggle_on").on("click", function () {
    ssoLogin = true
    $("#sso_sign_in_subtext_toggle_off").show()
    $("#sso_sign_in_subtext_toggle_on").hide()
    $("#password").val(null)
    $(".password").hide()
    $("#login_button").attr("value", I18n.t("js.devise.form.sso_login"))
  })

  $(this).on("keyup", function () {
    if (email.value.toLowerCase().match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g) != null) {
      Request.post(
        Routes.check_is_sso_user_path(),
        { auth_user: { email: email.value } }
      )
        .then(function (response) {
          if (response.data.sso_user === true) {
            if (email.value.toLowerCase().match(/^[a-zA-Z0-9_.+-]+@(tanda.co|workforce.com)$/g) != null) {
              ssoLogin = false
              $("#sso_sign_in_subtext_toggle_off").hide()
              $("#sso_sign_in_subtext_toggle_on").show()
              $(".password").show()

              if (email.value.toLowerCase().match(/^[a-zA-Z0-9_.+-]+@(tanda.co)$/g)) {
                $("#login_button").attr("value", I18n.t("js.devise.form.team_login", { company_name_title: "Tanda" }))
              } else if (email.value.toLowerCase().match(/^[a-zA-Z0-9_.+-]+@(workforce.com)$/g)) {
                $("#login_button").attr("value", I18n.t("js.devise.form.team_login", { company_name_title: "Workforce" }))
              } else
                {$("#login_button").attr("value", I18n.t("js.devise.form.internal_login"))
              }
            }

            else {
              ssoLogin = true
              $("#sso_sign_in_subtext_toggle_off").show()
              $("#sso_sign_in_subtext_toggle_on").hide()
              $("#password").val(null)
              $(".password").hide()
              $("#login_button").attr("value", I18n.t("js.devise.form.sso_login"))
            }}
        })
    } else if (ssoLogin === true) {
      ssoLogin = false
      $("#sso_sign_in_subtext_toggle_off").hide()
      $(".password").show()
      $("#login_button").attr("value", I18n.t("js.devise.form.login"))
    } else {
      $("#login_button").attr("value", I18n.t("js.devise.form.login"))
      $(".password").show()
    }
  })
}
;
$.fn.hideInviteButtonsOnEmailChange = function (email_input_selector, email_changed_button_selector) {
  var email_input = $(email_input_selector)
  var email_changed_button = $(email_changed_button_selector).hide()
  var invite_button = $(this)

  email_input.data("original-email", email_input.val())

  email_input.on("change", function () {
    if (email_input.val() === email_input.data("original-email")) {
      invite_button.show()
      email_changed_button.hide()
    } else {
      invite_button.hide()
      email_changed_button.show()
    }
  })
}
;
$.fn.userDeleteButton = function (new_value) {
  return this.click(function () {
    var btn = $(this)
    btn.parents("form").find("[data-field=is_active]").val(new_value)
    btn.parents("form").submit()
  })
}
;
window.init_leave_request_subscribe_modal = function (baseUrl) {
  var $checkboxes = $(".ics-check-box-js")
  var $linkInput = $("input.ics-link-js")
  var $linkButton = $("span.ics-link-js")

  $checkboxes.change(function () {
    var $checkedCheckboxes = $(".ics-check-box-js:checked")
    var newUrl

    if ($checkboxes.length === $checkedCheckboxes.length || $checkedCheckboxes.length === 0) {
      newUrl = baseUrl
    } else {
      var departmentIds = $checkedCheckboxes.map(function () { return $(this).data("department-id") }).toArray().join(",")
      newUrl = window.LH.Navigation.querystringSet(baseUrl, "department_ids", departmentIds, true)
    }

    $linkInput.val(newUrl)
    $linkInput.attr("data-clipboard-text", newUrl)
    $linkButton.attr("data-clipboard-text", newUrl)
  })
}
;
// json result format accepted [{id:123, name: 'thereisnocowlevel'}]
// string displayed on list eg. thereisnocowlevel (id: 123)
/* global Bloodhound: true */


$.fn.suggestiveSearch = function (path, route, opts) {
  opts = opts || {}

  return this.each(function () {
    var typeAheadDefaults = _.extend(opts, { displayKey: "name", delay: 2, source: [], selectOnBlur: false, minLength: 3 })
    var $input = $(this).find(":text")
    var $parent = $(this).parent()

    var data = new Bloodhound({
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      datumTokenizer: function (dt) {
        return Bloodhound.tokenizers.whitespace(dt.name)
      },
      remote: {
        url: path,
        prepare: prepareRequestData,
        transform: transformResponseData,
      },
    })

    $input.extendedTypeahead(_.extend(typeAheadDefaults, { source: data.ttAdapter() }, { afterSelect: afterSelectCallback }))

    function afterSelectCallback (selectedItem) {
      if (selectedItem.id === undefined) {
        return
      }

      var itemId
      if (opts.include_id) {
        var match = selectedItem.id.match(/^\d+/)
        if (match) {
          itemId = match[0]
        } else {
          return
        }
      } else {
        itemId = selectedItem.id
      }
      window.navigate_to(route(itemId))
    }

    function prepareRequestData (query, settings) {
      $parent.addClass("search-group-loading")

      settings.url = window.LH.Navigation.querystringSet(path, "query", query)
      return settings
    }

    function transformResponseData (response) {
      $parent.removeClass("search-group-loading")
      var items = _.map(response.data, function (item, index) {
        var itemNameString = listItemCaption(item)
        return { id: item.id, name: itemNameString }
      })
      return items
    }

    function listItemCaption (item) {
      if (opts.exclude_id_from_listitem_caption) {
        var caption = item.id.match(/[!^\s].*/)
        return (caption !== null) ? item.name + " (" + caption[0].trim() + ")" : ""
      } else {
        return item.name + " (id: " + item.id + ")"
      }
    }
  })
}
;
$.fn.timeFormatToggle = function (hours) {
  var INITIAL_CLASS = "btn-ghost"
  var SELECTED = "btn-theme-primary"

  function toggleClasses(elem) {
    if (elem.hasClass(SELECTED)) {
      elem.removeClass(SELECTED).addClass(INITIAL_CLASS)
    } else {
      elem.removeClass(INITIAL_CLASS).addClass(SELECTED)
    }
  }

  var initial_selected = this.find("[data-time-format=" + hours + "]")
  var current_value = initial_selected.data("time-format")
  toggleClasses(initial_selected)

  var options = this.find("[data-time-format]")

  this.on("click", "[data-time-format]", function () {
    var selected = $(this).data("time-format")
    if (selected !== current_value) {
      current_value = selected
      $.post(Routes.profile_update_time_format_path(), { time_format: selected })
      toggleClasses(options)
      toggleClasses($(this))
    }
  })

  return this
}
;
window.TimesheetReminderer = function () {
  var panel = $(".timesheet-reminders")
  var quicklinks = panel.find(".quicklinks")
  var userlist = panel.find(".userlist")

  window.NotificationSendoutQueryStringFilter(userlist, "timesheet")
  window.NotificationSendoutFilter(quicklinks, userlist)
}
;
$.fn.updateTotalsOnFilter = function (dataTable, tfoot) {
  var searchbox = this
  var hours_rows, hours_outputs, costs_rows, costs_outputs, cached_clear_row_values

  dataTable.on("search", function () {
    var data = dataTable.rows({ search: "applied" }).data()

    if (!hours_rows) {
      hours_rows = tfoot.find(".hours[data-update-on-filter]").map(function () { return $(this).index() }).get()
    }

    if (!costs_rows) {
      costs_rows = tfoot.find(".cost[data-update-on-filter]").map(function () { return $(this).index() }).get()
    }

    if (!cached_clear_row_values) {
      cached_clear_row_values = _.object(tfoot.find("[data-clear-on-filter]").map(function () { return [[$(this).index(), $(this).html()]] }).get())
    }

    hours_outputs = {}
    costs_outputs = {}

    _.each(data, function (row) {
      _.each(hours_rows, function (index) {
        var val = parseFloat($.CSVDownloadParsers._getReportCellCostAsFloat(row[index]))
        hours_outputs[index] = hours_outputs[index] || 0
        if (val) {
          hours_outputs[index] += val
        }
      })

      _.each(costs_rows, function (index) {
        var val = parseFloat($.CSVDownloadParsers._getReportCellCostAsFloat(row[index]))
        costs_outputs[index] = costs_outputs[index] || 0
        if (val) {
          costs_outputs[index] += val
        }
      })
    })

    _.each(hours_outputs, function (sum, index) {
      tfoot.find("td").eq(index).find("strong").text(sum.toFixed(2) + " hours")
    })

    _.each(costs_outputs, function (sum, index) {
      tfoot.find("td").eq(index).find("strong").text(sum.toCurrency(2))
    })

    if (searchbox.val().length) {
      // we have a search term - hide the "clear" boxes
      _.each(cached_clear_row_values, function (_, index) {
        tfoot.find("td").eq(index).text("")
      })
    } else {
      // no search term - show "clear" boxes
      _.each(cached_clear_row_values, function (html, index) {
        tfoot.find("td").eq(index).html(html)
      })
    }
  })
}
;
$.fn.userLanguageUpdate = function (initialSelection) {
  this.on("change", "select", function (e) {
    var selectedValue = $(e.target).val()

    $.post(Routes.profile_update_user_language_path(), { language: selectedValue }, function () {
      location.reload(true) // force reload from server so it doesn't use cache
    }).fail(function () {
      // shouldn't happen but if it does reset selection to what it is actually set to
      $("#userLanguageUpdate select").val(initialSelection)
    })
  })

  return this
}
;
var configure_chosen_selects = function (salary_tag_name) {
  $("select.chosen").chosen()

  $("select#dept_member").on("change", function (e, change) {
    if (change.selected) {
      var selected = $(e.target).find(":selected")

      if (selected.length === 1) {
        $(".reporting-department select").val(selected[0].value).trigger("chosen:updated")
      }
    }
  })

  $(".salary-select-chosen-js").on("change", function () {
    if ($(this).val() === salary_tag_name) {
      $(".hide-if-salaried-js").hide()
    } else {
      $(".hide-if-salaried-js").show()
    }
  })

  function configureAwardTemplateChosens (initial_load) { // on initial load we read in tags from the current dropdown rather than the one we are changing to
    var no_template_is_selected = $("select#user_award_template_organisation_id").find(":selected").text() === "Custom Payroll Setup"
    var selected_tags
    var template_dropdowns = ".award-templates-template"
    var no_template_dropdowns = ".award-templates-no-template"

    function salaryTagIsSelected (selected_tags) {
      return _.includes(selected_tags, salary_tag_name)
    }

    function getSelectedTags (area) {
      return area.find("select").map(function () {
        var val = $(this).val()
        if (typeof val === "string") { val = [val] } // if val is a string (non multiple select), convert it to an array
        return val
      }).get().flatten().compact()
    }

    if (no_template_is_selected) {
      $(no_template_dropdowns).show()
      $(template_dropdowns).hide().find("select").prop("disabled", true)

      selected_tags = getSelectedTags(initial_load ? $(no_template_dropdowns) : $(template_dropdowns))
      $(no_template_dropdowns).find("select").val(selected_tags).prop("disabled", false).trigger("chosen:updated")
    } else {
      $(template_dropdowns).show()
      $(no_template_dropdowns).hide().find("select").prop("disabled", true)

      selected_tags = getSelectedTags(initial_load ? $(template_dropdowns) : $(no_template_dropdowns))
      $(template_dropdowns).find("select").val(selected_tags).prop("disabled", false).trigger("chosen:updated")

      if (salaryTagIsSelected(selected_tags)) {
        $(template_dropdowns).find(".hide-if-salaried-js").hide()
      }

      $(template_dropdowns).find("select").filter(function () { return $(this).find("option[value]").length === 0 }).each(function () { $(this).parents(".control-group:first").hide() })
    }
  }

  $("select#user_award_template_organisation_id").on("change", configureAwardTemplateChosens)
  configureAwardTemplateChosens(true)
}

var enable_user_age_updates = function () {
  var age_panel = $(".age")
  var age_output = age_panel.find(".user-age")

  var calculate_and_display_age = function () {
    var dob = get_date_from_panel(age_panel, "date_of_birth")

    if (dob === null) { return }

    var now = new Date()
    var age =  now.getFullYear() - dob.getFullYear() - ((now.getMonth() > dob.getMonth() || (now.getMonth() === dob.getMonth() && now.getDate() >= dob.getDate())) ? 0 : 1)

    age_output.text(window.I18n.t("js.users.form.age", { age: age }))
  }
  age_panel.on("change", "select", calculate_and_display_age)
  calculate_and_display_age()
}

var enable_user_employment_end_date_updates = function () {
  var employment_end_date_panel = $(".employment-end-date")
  var leave_adjustment_title_output = $(".ending-employment-title")
  var leave_adjustment_notice_output = $(".leave-adjustment-notice")

  var display_employment_end_date_warning = function () {
    var employment_end_date = get_date_from_panel(employment_end_date_panel, "employment_end_date")

    if (employment_end_date === null) { return }

    var latest_non_rejected_leave_request_finish_date = new Date(employment_end_date_panel.find("#latest_non_rejected_leave_request_finish_date").val())

    if (employment_end_date < latest_non_rejected_leave_request_finish_date) {
      $(".ending-employment").show()
      $(".ending-employment").find("a.btn-warning-primary").attr("href", function() {
        return $(this).attr("href") + "?employment_end_date=" + moment(employment_end_date).format("YYYY-MM-DD")
      })
      $(".ending-employment").find("a.btn-warning-primary").attr("target", "blank")
    } else {
      $(".ending-employment").hide()
    }
  }

  employment_end_date_panel.on("change", "select", display_employment_end_date_warning)
}

var get_date_from_panel = function (panel, date_label) {
  var year = panel.find("#user_" + date_label + "_1i").val()
  var month = panel.find("#user_" + date_label + "_2i").val()
  var day = panel.find("#user_" + date_label + "_3i").val()

  if (!(year && month && day)) {
    return null
  }

  return new Date(year, month - 1, day)
}

;(function () {
  function modalSetup (baseUrl, checkboxSelector, textFieldSelector) {
    var $checkboxes = $(checkboxSelector)
    var $linkInput = $("input" + textFieldSelector)
    var $linkButton = $("span" + textFieldSelector)
    $checkboxes.change(function () {
      var $checkedCheckboxes = $(checkboxSelector + ":checked")
      var newUrl
      if ($checkboxes.length === $checkedCheckboxes.length || $checkedCheckboxes.length === 0) {
        newUrl = baseUrl
      } else {
        var departmentIds = $checkedCheckboxes.map(function () { return $(this).data("department-id") }).toArray().join(",")
        newUrl = window.LH.Navigation.querystringSet(baseUrl, "department_ids", departmentIds, true)
      }
      $linkInput.val(newUrl)
      $linkInput.attr("data-clipboard-text", newUrl)
      $linkButton.attr("data-clipboard-text", newUrl)
    })
  }

  window.Users = {
    BirthdayModal: {
      init: function (baseUrl) {
        modalSetup(baseUrl, ".ics-check-box-birthday-js", ".ics-link-birthday-js")
      },
    },
    AnniversaryModal: {
      init: function (baseUrl) {
        modalSetup(baseUrl, ".ics-check-box-anniversary-js", ".ics-link-anniversary-js")
      },
    },
  }
})()

var assign_spa_payfields = function () {
  var pay_conditions_spa = $(".pay-fields-spa-inputs")

  
  var spa_hourly_rate_input = pay_conditions_spa.find("div[class='hourly_rate_input']")
  var spa_yearly_salary_input = pay_conditions_spa.find("div[class='yearly_salary_input']")
  var spa_award_template_input = pay_conditions_spa.find("div[class='industry_award_input']")
  var spa_employment_type_tags_input = pay_conditions_spa.find("div[class='employment_type_input']")
  var spa_classification_tags_input = pay_conditions_spa.find("div[class='classification_level_input']")
  var spa_allowances_tags_input = pay_conditions_spa.find("div[class='allowances_input']")
  var spa_additional_tags_input = pay_conditions_spa.find("div[class='additional_tags_input']")
  var spa_tag_groups_inputs = pay_conditions_spa.find("div[class$='.tg_input']")

  
  
  var hourly_rate = $(".pay-fields-hourly-rate")
  var yearly_salary = $(".pay-fields-yearly-salary")
  var award_template = $(".pay-fields-award-template")
  var award_tags = $(".pay-fields-award-tags")

  
  
  hourly_rate.val(Number(spa_hourly_rate_input.attr('name')))
  yearly_salary.val(Number(spa_yearly_salary_input.attr('name')))
  award_template.val(spa_award_template_input.attr('name'))

  accumulated_tags = []
  if (spa_employment_type_tags_input.attr('name')) {
    accumulated_tags.push(spa_employment_type_tags_input.attr('name'))
  }
  if (spa_classification_tags_input.attr('name')) {
    accumulated_tags.push(spa_classification_tags_input.attr('name'))
  }
  if (spa_allowances_tags_input.attr('name')) {
    accumulated_tags.push(spa_allowances_tags_input.attr('name'))
  }
  if (spa_additional_tags_input.attr('name')) {
    accumulated_tags.push(spa_additional_tags_input.attr('name'))
  }
  if (spa_tag_groups_inputs.length > 0) {
    var tag_group_tags = []

    spa_tag_groups_inputs.each(function(){
    tag_group_tags.push($(this).attr('name').split(','))})
    tag_group_tags_flat = tag_group_tags.flatten()
    accumulated_tags.push(tag_group_tags_flat)
    accumulated_tags = accumulated_tags.flatten()
  }
  award_tags.val(accumulated_tags.join(","))
}

var handle_pay_fields_saving = function () {
  var userForm = $("form[class*=user-form]")

  var userSaveButton = userForm.find(".user-tab-create-btn")
  var userCreateButton = userForm.find(".user-tab-save-btn")

  userSaveButton.click(assign_spa_payfields)
  userCreateButton.click(assign_spa_payfields)
}
;
// Route parameter should be a function, usually provided by jsroutes.
//  .searchInput("/organisations.json", Routes.organisation_edit_path)

$.fn.searchInput = function (path, route, opts) {
  opts = opts || {}

  return this.each(function () {
    var search_panel = $(this)
    var input = search_panel.find("input[type=text]")
    var _userdata
    var _names

    input.prop("disabled", "disabled")

    Mousetrap.bind("mod+f", function () {
      if (input.length) {
        input.focus()
        return false
      }
    })

    var prepare_typeahead = function (obj) {
      _userdata = obj
      _names = _.map(obj.data, function (elem, index) {
        var n = elem.name
        var without_name = _.omit(elem, "name")

        if (opts.include_id && !_.isEmpty(without_name)) {
          n += " ("
          n += _.map(without_name, function (value, key) { return key + ": " + value }).join(", ")
          n += ")"
        }

        return n
      })
      input.extendedTypeahead(_.extend({ source: _names, afterSelect: function (name) { load_user(name) } }, opts.opts || {})).prop("disabled", false)

      // Once typeahead is ready set focus, as long as another input doesn't
      // have focus.
      if (!document.activeElement || $("input:focus").length === 0) {
        input.focus()
      }
    }

    var load_user = function (val) {
      var user_id
      if (opts.include_id) {
        var match = val.match(/id:\s(\d+)/)
        if (match) {
          user_id = match[1]
        } else {
          return
        }
      } else {
        var index = _userdata.index[val]
        if (!val || undefined === index) { return }
        user_id = _userdata.data[index].id
      }
      window.navigate_to(route(user_id))
    }

    $.ajax({
      type: "GET",
      cache: true,
      url: path,
      dataType: "json",
    })
    .done(function () { input.prop("disabled", "") })
    .done(prepare_typeahead)
  })
}
;
window.validatePassword = function (passwordSelector, confirmSelector, agreedToTermsSelector) {
  var t = function (key, options) {
    options = _.extend({ scope: "js.users.set_password" }, options || {})
    return I18n.t(key, options)
  }

  function checkPasswordsMatch() {
    var password = $(passwordSelector).val()
    var confirm = $(confirmSelector).val()
    if (password === confirm && password !== "") {
      return true
    }
    return false
  }

  function renderMatchValidation(valid) {
    if (valid) {
      $("#pw-check-matches .mi").addClass("text-success mi-check-circle")
      $("#pw-check-matches .mi").removeClass("text-warning mi-error")
      $("#pw-check-matches p").html(t("matching_valid"))
    } else {
      $("#pw-check-matches .mi").removeClass("text-success mi-check-circle")
      $("#pw-check-matches .mi").addClass("text-warning mi-error")
      $("#pw-check-matches p").html(t("matching_invalid"))
    }
  }

  function check9Char() {
    var password = $(passwordSelector).val()
    if (password.length >= 9) {
      return true
    }
    return false
  }

  function render9CharValidation(valid) {
    if (valid) {
      $("#pw-check-nine-characters .mi").addClass("text-success mi-check-circle")
      $("#pw-check-nine-characters .mi").removeClass("text-warning mi-error")
      $("#pw-check-nine-characters p").html(t("9_characters_valid"))
    } else {
      $("#pw-check-nine-characters .mi").removeClass("text-success mi-check-circle")
      $("#pw-check-nine-characters .mi").addClass("text-warning mi-error")
      $("#pw-check-nine-characters p").html(t("9_characters_invalid"))
    }
  }

  function checkValidAgreedToTerms() {
    if (!agreedToTermsSelector) { return true }

    var checkbox = $(agreedToTermsSelector)
    return checkbox.length === 0 || checkbox.is(":checked")
  }

  function checkPassword() {
    var validLen = check9Char()
    var validMatch = checkPasswordsMatch()
    render9CharValidation(validLen)
    renderMatchValidation(validMatch)
    var validAgreedToTerms = checkValidAgreedToTerms()
    if (validLen && validMatch && validAgreedToTerms) {
      $("#submit-password-button").prop("disabled", false)
    } else {
      $("#submit-password-button").prop("disabled", true)
    }
  }

  function set9CharVisible() {
    $("#pw-check-nine-characters").removeClass("hidden")
  }

  function setMatchingVisible() {
    $("#pw-check-matches").removeClass("hidden")
  }

  function togglePassword() {
    if ($(passwordSelector).attr("type") === "password") {
      $(passwordSelector).add(confirmSelector).attr("type", "text")
      $("#pw-visibility-toggle").addClass("pw-shown")
      $("#pw-visibility-toggle").removeClass("pw-hidden")
      $("#pw-visibility-toggle .mi").addClass("mi-visibility-off")
      $("#pw-visibility-toggle .mi").removeClass("mi-visibility")
      $("#pw-visibility-toggle span").html(t("hide_pw"))
    } else {
      $(passwordSelector).add(confirmSelector).attr("type", "password")
      $("#pw-visibility-toggle").addClass("pw-hidden")
      $("#pw-visibility-toggle").removeClass("pw-shown")
      $("#pw-visibility-toggle .mi").addClass("mi-visibility")
      $("#pw-visibility-toggle .mi").removeClass("mi-visibility-off")
      $("#pw-visibility-toggle span").html(t("show_pw"))
    }
  }

  $(passwordSelector).on("input", checkPassword)
  $(confirmSelector).on("input", checkPassword)
  $(agreedToTermsSelector).on("change", checkPassword)
  $(passwordSelector).focus(set9CharVisible)
  $(confirmSelector).focus(setMatchingVisible)
  $("#pw-visibility-toggle").on("click", togglePassword)
}
;
window.CsvImporter = {
  init: function (configurations, presetType, fileInput, importTypes) {
    CsvImporter.DataStore.setup(configurations, fileInput, importTypes)
    CsvImporter.Handlers.updateType(presetType)

    CsvImporter.Handlers.attachHandlers()
    CsvImporter.Handlers.activeAccordionChanged()
  },
}
;
CsvImporter.DataStore = (function () {
  var importTypes, fileInput, fileData, type, configurations, configuration
  var fileSet = false
  var headers = []
  var rows = []
  var rowsWithErrors = []
  var currentRow = 0
  var firstRowHeader = true
  var showOnlyErrors = false

  return {
    setup: function (newConfigurations, newFileInput, newImportTypes) {
      fileInput = newFileInput
      configurations = newConfigurations
      importTypes = newImportTypes
    },

    setFile: function (file, cb) {
      window.Papa.parse(file, {
        complete: function (res) {
          fileData = res
          fileSet = true

          fileData.data = _.chain(fileData.data)
            // Filter out empty rows
            .filter(function (row) { return !_.all(row, function (cell) { return cell.match(/^\s*$/) }) })
            // Strip excess whitespace
            .map(function (row) { return _.map(row, function (cell) { return cell.replace(/^\s+|\s+$/g, "") }) })
            .value()

          headers = firstRowHeader ? fileData.data[0] : []
          rows = firstRowHeader ? fileData.data.slice(1) : fileData.data
          currentRow = 0

          cb && cb()
        },
      })
    },
    clearFile: function () {
      fileSet = false
      rows = []
      headers = []
    },

    setType: function (newType) {
      type = newType
    },
    setConfiguration: function (newConfiguration) {
      configuration = newConfiguration
    },
    setConfigurationById: function (newConfigurationId) {
      if (newConfigurationId === -1) {
        configuration = false
      } else {
        configuration = _.find(configurations[type] || [], { id: newConfigurationId })
      }
    },
    setFirstRowHeader: function (newFirstRowHeader) {
      firstRowHeader = newFirstRowHeader

      if (fileSet) {
        headers = firstRowHeader ? fileData.data[0] : []
        rows = firstRowHeader ? fileData.data.slice(1) : fileData.data

        if (firstRowHeader) {
          if (currentRow > 0) {
            currentRow -= 1
          }
        } else {
          currentRow += 1
        }
      }
    },
    setRowsWithErrorsCheckboxVisible: function (newShowOnlyErrorsVisible) {
      if (newShowOnlyErrorsVisible) {
        $(CsvImporter.Selectors.DataToggleButtons.showOnlyErrorsContainer).show()
      } else {
        $(CsvImporter.Selectors.DataToggleButtons.showOnlyErrorsContainer).hide()
        $(CsvImporter.Selectors.DataToggleButtons.showOnlyErrors).prop("checked", false)
        showOnlyErrors = false
      }
    },
    setShowOnlyErrors: function (newShowOnlyErrors) {
      showOnlyErrors = newShowOnlyErrors
    },
    showOnlyErrors: function () {
      return showOnlyErrors
    },
    setRowsWithErrors: function (newRowsWithErrors) {
      rowsWithErrors = _.sortBy(_.uniq(newRowsWithErrors), function (v) { return v })
    },
    getFirstRowWithError: function () {
      return rowsWithErrors[0]
    },

    moveCurrentRow: function (moveAmount) {
      if (showOnlyErrors && rowsWithErrors.length > 0) {
        var newCurrentRow
        if (moveAmount > 0) {
          newCurrentRow = _.find(rowsWithErrors, function (index) { return index >= currentRow + moveAmount })
        } else {
          newCurrentRow = _.find(rowsWithErrors.slice().reverse(), function (index) { return index <= currentRow + moveAmount })
        }

        currentRow = _.isUndefined(newCurrentRow) ? currentRow : newCurrentRow
      } else {
        currentRow += moveAmount
      }
      currentRow = Math.min(currentRow, rows.length - 1)
      currentRow = Math.max(currentRow, 0)
    },

    isFileSet: function () {
      return fileSet
    },
    getFileInput: function () {
      return fileInput
    },
    getFileDelimiter: function () {
      return fileSet ? fileData.meta.delimiter : ""
    },
    isConfigurationSet: function () {
      return !!configuration
    },
    getCurrentRow: function () {
      if (showOnlyErrors && rowsWithErrors.length > 0) {
        var index = _.indexOf(rowsWithErrors, currentRow)
        return index < 0 ? 0 : index
      }

      return currentRow
    },
    getMaxRowText: function () {
      if (showOnlyErrors && rowsWithErrors.length > 0) {
        return rowsWithErrors.length > 100 ? "100+" : rowsWithErrors.length
      }

      return rows.length
    },
    getRows: function () {
      return rows
    },
    getImportTypeFields: function () {
      if (!importTypes[type]) { return [] }
      return importTypes[type].fields
    },
    getNumColumns: function () {
      return (rows[0] || []).length
    },
    getConfigFieldByColumn: function (columnNumber) {
      if (!configuration) { return }
      return _.find(configuration.field_maps, { column: columnNumber })
    },
    getImportTypeFieldById: function (id) {
      if (!importTypes[type]) { return }
      return _.find(importTypes[type].fields, { id: id })
    },
    getHeaderForColumn: function (columnNumber) {
      return headers[columnNumber]
    },
    getCellForColumn: function (columnNumber) {
      return rows[currentRow][columnNumber]
    },
    isFirstRow: function () {
      return currentRow === 0
    },
    isLastRow: function () {
      return currentRow === rows.length - 1
    },
    configurationsForType: function () {
      if (!configurations[type]) { return [] }
      return configurations[type]
    },
    guessTandaFieldForColumn: function (columnNumber, usedTandaFields) {
      if (!firstRowHeader) { return }

      var field = _.find(importTypes[type].fields, function (field) {
        return !_.includes(usedTandaFields, field.id) && field.friendly_name.toLowerCase() === headers[columnNumber].toLowerCase()
      })

      if (field) {
        usedTandaFields.push(field.id)
        return field.id
      }
    },
  }
})()
;
CsvImporter.Handlers = {
  activeAccordionChanged: function () {
    var $steps = $(CsvImporter.Selectors.accordionContainer)
    $steps.find(".collapse.in").parents(".blue-accordion").addClass("active")
    $steps.find(".collapse:not(.in)").parents(".blue-accordion").removeClass("active")
  },
  newFieldIdChanged: function () {
    var $newFieldIdSelectInputs = $(CsvImporter.Selectors.ConfigurationTable.selectInputs)
    $newFieldIdSelectInputs.find("option").prop("disabled", false)
    $newFieldIdSelectInputs.each(function () {
      var $this = $(this)
      if ($this.val() === "-1") { return }

      $newFieldIdSelectInputs.not(this).find('option[value="' + $this.val() + '"]').prop("disabled", true)
    })
    $(this).closest(CsvImporter.Selectors.ConfigurationTable.rows).data("field-id", $(this).val())
    CsvImporter.Validators.validateData()
  },
  updateType: function (newType) {
    CsvImporter.DataStore.setType(newType)
    CsvImporter.DataStore.clearFile()
    CsvImporter.DataStore.getFileInput().clear()
    CsvImporter.DataStore.getFileInput().enable()

    CsvImporter.Renderers.updateConfigurationOptions()
    $(CsvImporter.Selectors.configurationSelect).prop("disabled", false)

    $(CsvImporter.Selectors.ConfigurationTable.table).hide()
    $(CsvImporter.Selectors.globalErrorsContainer).hide()
    $(CsvImporter.Selectors.submitButton).prop("disabled", true)
    CsvImporter.Renderers.updateDataToggles()
  },
  fileInputChanged: function () {
    CsvImporter.DataStore.clearFile()
    $(CsvImporter.Selectors.ConfigurationTable.table).hide()
    $(CsvImporter.Selectors.globalErrorsContainer).hide()
    $(CsvImporter.Selectors.submitButton).prop("disabled", true)
    if (this.files.length) {
      CsvImporter.DataStore.setFile(this.files[0], function () {
        $(CsvImporter.Selectors.fileDelimiter).val(CsvImporter.DataStore.getFileDelimiter())
        CsvImporter.Renderers.updateDataToggles()
        CsvImporter.Renderers.buildConfigurationTable()
        CsvImporter.Validators.validateData()
        CsvImporter.Handlers.jumpToStepAfterFileInput()
      })
    }
  },
  jumpToStepAfterFileInput: function () {
    $(CsvImporter.Selectors.accordionAfterFileInput).click()
  },
  configurationChanged: function () {
    CsvImporter.DataStore.setConfigurationById(+this.value)
    CsvImporter.DataStore.isConfigurationSet() ? $(CsvImporter.Selectors.newConfigurationName).hide() : $(CsvImporter.Selectors.newConfigurationName).show()
    if (CsvImporter.DataStore.isFileSet()) {
      CsvImporter.Renderers.buildConfigurationTable()
      CsvImporter.Validators.validateData()
    }
  },
  firstRowHeaderChanged: function () {
    CsvImporter.DataStore.setFirstRowHeader($(this).is(":checked"))

    if (!CsvImporter.DataStore.isFileSet()) { return }
    CsvImporter.Renderers.updateDataToggles()
    CsvImporter.Renderers.updateConfigurationTableData()
  },
  toggleDataClicked: function () {
    var $btn = $(this)
    if ($btn.hasClass("disabled")) { return }

    CsvImporter.DataStore.moveCurrentRow(+$btn.data("toggle-direction"))
    CsvImporter.Renderers.updateDataToggles()
    CsvImporter.Renderers.updateConfigurationTableData()
  },
  showOnlyErrorsChanged: function () {
    var newValue = $(this).is(":checked")
    var currentRow = CsvImporter.DataStore.getCurrentRow()
    CsvImporter.DataStore.setShowOnlyErrors(newValue)
    if (newValue) {
      CsvImporter.Validators.validateData()
      var newRow = CsvImporter.DataStore.getFirstRowWithError()
      if (!_.isUndefined(newRow)) {
        CsvImporter.DataStore.moveCurrentRow(newRow - currentRow)
        CsvImporter.Renderers.updateConfigurationTableData()
      }
    }
    CsvImporter.Renderers.updateDataToggles()
  },
  attachHandlers: function () {
    $(document).on("click", "[data-toggle=collapse]", CsvImporter.Handlers.activeAccordionChanged)
    $(document).on("change", ".new-field-id-js", CsvImporter.Handlers.newFieldIdChanged)
    CsvImporter.DataStore.getFileInput().inputElement().on("updateFile", CsvImporter.Handlers.fileInputChanged)
    $(CsvImporter.Selectors.typeRadioButtons).change(function () { CsvImporter.Handlers.updateType(this.value) })
    $(CsvImporter.Selectors.configurationSelect).change(CsvImporter.Handlers.configurationChanged)
    $(CsvImporter.Selectors.firstRowHeaderCheckbox).change(CsvImporter.Handlers.firstRowHeaderChanged)
    $(CsvImporter.Selectors.DataToggleButtons.left).click(CsvImporter.Handlers.toggleDataClicked)
    $(CsvImporter.Selectors.DataToggleButtons.right).click(CsvImporter.Handlers.toggleDataClicked)
    $(CsvImporter.Selectors.DataToggleButtons.showOnlyErrors).click(CsvImporter.Handlers.showOnlyErrorsChanged)
  },
}
;
CsvImporter.Renderers = {
  Components: {
    Icons: {
      spinner: function () {
        return crel("i", { class: "mi mi-spinner mi-spin", "aria-hidden": true })
      },
      tick: function () {
        return crel("i", { class: "mi mi-check-circle text-green", "aria-hidden": true })
      },
      cross: function () {
        return crel("i", { class: "mi mi-remove-circle text-red", "aria-hidden": true })
      },
      warn: function () {
        return crel("i", { class: "mi mi-error text-orange", "aria-hidden": true })
      },
    },
    fieldSelectForType: function (id) {
      var crelOpts = ["select"]

      if (_.isUndefined(id)) {
        crelOpts.push({ name: "new_field_id[]", class: "new-field-id-js" }, crel("option", { selected: true, value: -1 }, "-- Don't Import --"))
        _.each(CsvImporter.DataStore.getImportTypeFields(), function (field) {
          crelOpts.push(crel("option", { value: field.id }, field.friendly_name))
        })
      } else {
        crelOpts.push({ name: "new_field_id[]", class: "new-field-id-js" }, crel("option", { value: -1 }, "-- Don't Import --"))
        _.each(CsvImporter.DataStore.getImportTypeFields(), function (field) {
          if (id === field.id) {
            crelOpts.push(crel("option", { value: field.id, selected: true }, field.friendly_name))
          } else {
            crelOpts.push(crel("option", { value: field.id }, field.friendly_name))
          }
        })
      }

      return crel.apply(crel, crelOpts)
    },
    configurationTableBody: function () {
      var configField, importerField, header, tandaField
      var usedTandaFields = []
      var crelOpts = ["tbody"]

      for (var i = 0, len = CsvImporter.DataStore.getNumColumns(); i < len; i++) {
        if (CsvImporter.DataStore.isConfigurationSet()) {
          configField = CsvImporter.DataStore.getConfigFieldByColumn(i)
          if (!configField) { continue }
          importerField = CsvImporter.DataStore.getImportTypeFieldById(configField.id)
          if (!importerField) { continue }
        }

        if (CsvImporter.DataStore.isConfigurationSet()) {
          header = CsvImporter.DataStore.getHeaderForColumn(i) || configField.header
          tandaField = importerField.friendly_name
        } else {
          header = CsvImporter.DataStore.getHeaderForColumn(i) || "-"
          tandaField = CsvImporter.DataStore.guessTandaFieldForColumn(i, usedTandaFields)
          tandaField = CsvImporter.Renderers.Components.fieldSelectForType(tandaField)
        }

        crelOpts.push(crel(
          "tr", { class: "importer-field-js", "data-field-id": CsvImporter.DataStore.isConfigurationSet() ? importerField.id : -1, "data-column-number": i },
          crel("td", { class: "field-header-js min-width-column" }, header),
          crel("td", { class: "field-data-js" }, CsvImporter.DataStore.getCellForColumn(i)),
          crel("td", { class: "min-width-column" }, tandaField),
          crel("td", { class: "field-valid-status-js valid-data-status-cell min-width-column" }, CsvImporter.Renderers.Components.Icons.spinner())
        ))
      }

      return crel.apply(crel, crelOpts)
    },
    configurationOptions: function () {
      var configOptions = [crel("option", { value: -1, selected: true }, I18n.t("js.csv_importer.renderers.new_config"))]
      var configurations = CsvImporter.DataStore.configurationsForType()

      if (configurations.length > 0) {
        configOptions.push(crel("option", { value: configurations[0].id, selected: true }, configurations[0].name))
        for (var i = 1, lim = configurations.length; i < lim; i++) {
          configOptions.push(crel("option", { value: configurations[i].id }, configurations[i].name))
        }
      }

      return configOptions
    },
  },

  updateDataToggles: function () {
    if (CsvImporter.DataStore.isFileSet()) {
      CsvImporter.DataStore.isFirstRow() ? $(CsvImporter.Selectors.DataToggleButtons.left).addClass("disabled") : $(CsvImporter.Selectors.DataToggleButtons.left).removeClass("disabled")
      CsvImporter.DataStore.isLastRow() ? $(CsvImporter.Selectors.DataToggleButtons.right).addClass("disabled") : $(CsvImporter.Selectors.DataToggleButtons.right).removeClass("disabled")
      $(CsvImporter.Selectors.DataRowInfo.current).text(CsvImporter.DataStore.getCurrentRow() + 1)
      $(CsvImporter.Selectors.DataRowInfo.max).text(CsvImporter.DataStore.getMaxRowText())
      $(CsvImporter.Selectors.DataRowInfo.container).show()
    } else {
      $(CsvImporter.Selectors.DataToggleButtons.left).addClass("disabled")
      $(CsvImporter.Selectors.DataToggleButtons.right).addClass("disabled")
      $(CsvImporter.Selectors.DataRowInfo.container).hide()
    }
  },
  updateConfigurationTableData: function () {
    $(CsvImporter.Selectors.ConfigurationTable.rows).each(function () {
      var $tableRow = $(this)
      var columnNumber = +$tableRow.data("column-number")
      var configField = CsvImporter.DataStore.isConfigurationSet() ? CsvImporter.DataStore.getConfigFieldByColumn(columnNumber) : null

      $tableRow.find(".field-header-js").text(CsvImporter.DataStore.getHeaderForColumn(columnNumber) || (CsvImporter.DataStore.isConfigurationSet() ? configField.header : "-"))
      $tableRow.find(".field-data-js").text(CsvImporter.DataStore.getCellForColumn(columnNumber))
    })
  },
  buildConfigurationTable: function () {
    var $table = $(CsvImporter.Selectors.ConfigurationTable.table)
    $table.show()
    $table.find("tbody").remove()
    $table.append($(CsvImporter.Renderers.Components.configurationTableBody()))
    CsvImporter.Renderers.updateDataToggles()
    $(".new-field-id-js").trigger("change")
  },
  updateConfigurationOptions: function () {
    var $configSelect = $(CsvImporter.Selectors.configurationSelect)

    $configSelect.empty()
    _.each(CsvImporter.Renderers.Components.configurationOptions(), function (configOption) {
      $configSelect.append($(configOption))
    })

    if (CsvImporter.DataStore.configurationsForType().length < 1) {
      CsvImporter.DataStore.setConfiguration(false)
      $(CsvImporter.Selectors.newConfigurationName).show()
    } else {
      CsvImporter.DataStore.setConfiguration(CsvImporter.DataStore.configurationsForType()[0])
      $(CsvImporter.Selectors.newConfigurationName).hide()
    }
  },
  updateErrors: function (errors, warnings) {
    $(CsvImporter.Selectors.submitButton).prop("disabled", !_.isEmpty(errors))

    $(CsvImporter.Selectors.ConfigurationTable.rows).each(function () {
      var $tableRow = $(this)
      var $statusCell = $tableRow.find(".field-valid-status-js")
      var columnNumber = +$tableRow.data("column-number")
      var fieldId = $tableRow.data("field-id")
      var errs = errors[columnNumber]
      var warns = warnings[columnNumber]
      var errorMsg = ""

      $statusCell.tooltip("destroy")
      if (errs && errs.length > 0) {
        $statusCell.html(CsvImporter.Renderers.Components.Icons.cross())
        errorMsg = errs[0]
      } else if (warns && warns.length > 0) {
        $statusCell.html(CsvImporter.Renderers.Components.Icons.warn())
        errorMsg = warns[0]
      } else if (fieldId !== "-1" && fieldId !== -1 && !CsvImporter.Validators.columnIsComplete(columnNumber)) {
        $statusCell.html(CsvImporter.Renderers.Components.Icons.warn())
        errorMsg = I18n.t("js.csv_importer.renderers.missing_values")
      } else {
        $statusCell.html(CsvImporter.Renderers.Components.Icons.tick())
      }

      if (errorMsg) { $statusCell.find("i").data("container", "body").attr("title", errorMsg).tooltip() }
    })

    var $globalErrorsContainer = $(CsvImporter.Selectors.globalErrorsContainer)
    var $errorsList = $globalErrorsContainer.find(".errors-js")

    $errorsList.empty()
    if (errors.global && errors.global.length > 0) {
      _.each(errors.global, function (err) {
        $errorsList.append($(crel("li", err)))
      })
      $globalErrorsContainer.show()
    } else {
      $globalErrorsContainer.hide()
    }
  },
}
;
CsvImporter.Selectors = {
  DataRowInfo: {
    current: ".current-data-row-js",
    max: ".max-data-row-js",
    container: ".data-row-info-container-js",
  },
  DataToggleButtons: {
    left: ".toggle-data-js.data-toggle-left",
    right: ".toggle-data-js.data-toggle-right",
    showOnlyErrors: ".toggle-show-only-errors-js",
    showOnlyErrorsContainer: ".toggle-show-only-errors-container-js",
  },
  ConfigurationTable: {
    table: "#configuration-table-js",
    rows: "#configuration-table-js .importer-field-js",
    selectInputs: "#configuration-table-js .importer-field-js .new-field-id-js",
  },

  newConfigurationName: '#new_configuration_name,label[for="new_configuration_name"]',
  accordionContainer: "#steps",
  fileDelimiter: "#file_delimiter",
  configurationSelect: "#configuration",
  submitButton: "#submit-button",
  firstRowHeaderCheckbox: "#first_row_header",
  typeRadioButtons: "[name=type]",
  globalErrorsContainer: "#global-errors-container",
  accordionAfterFileInput: ".configuration-step-js",
}
;
CsvImporter.Validators = (function () {
  // spec: /test/javascripts/helpers/csv_importer_validators_test.js
  var FormatValidators = {
    string: function (value) {
      return value ? [value] : null // return an array since other validators call .match()
    },
    email: function (value) {
      return value.match(/^[^@\s]+@(?:[^@\s]+\.)+[^@\W]+$/)
    },
    integer: function (value) {
      return value.match(/^\d+$/)
    },
    numeric_string: function (value) {
      return value.match(/^[^A-Za-z]+$/)
    },
    float: function (value) {
      if (!value) { return null }

      return value.replace(/[,$]/g, "").match(/^-?\d+(?:\.\d+)?$/)
    },
    currency: function (value) {
      return value.match(/^[^A-Za-z]+$/)
    },
    date: function (value) {
      if (!value) { return null }

      return value.match(/^\d{4}-\d{2}-\d{2}$/) || value.match(/^\d{1,2}\/\d{1,2}\/\d{2,4}$/)
    },
    time: function (value) {
      if (!value) { return null }

      return value.match(/\d+:\d+/)
    },
    datetime: function (value) {
      if (!value) { return null }

      var dateMatch = value.match(/\d{4}-\d{2}-\d{2}/) || value.match(/\d{1,2}\/\d{1,2}\/\d{2,4}/)
      var timeMatch = value.match(/\d+:\d+/)

      return dateMatch && timeMatch && [dateMatch[0] + " " + timeMatch[0]]
    },
    array: function (value, innerType) {
      if (!_.isFunction(FormatValidators[innerType])) { return false }

      return _.all(value.split(","), function (val) {
        return FormatValidators[innerType](val)
      })
    },
  }

  var PresenceCheckers = {
    fieldIsEmpty: function (fieldValue) {
      return _.isUndefined(fieldValue) || fieldValue.match(/^\s*$/)
    },
    columnIsComplete: function (columnNumber) {
      if (!CsvImporter.DataStore.isFileSet()) { return false }
      return _.all(CsvImporter.DataStore.getRows(), function (row) { return !PresenceCheckers.fieldIsEmpty(row[columnNumber]) })
    },
    invalidColumnIsCompleteIndices: function (columnNumber) {
      if (!CsvImporter.DataStore.isFileSet() || !CsvImporter.DataStore.showOnlyErrors()) { return [] }
      var cnt = 0
      return _.without(_.map(CsvImporter.DataStore.getRows(), function (row, index) {
        if (cnt > 100 || !PresenceCheckers.fieldIsEmpty(row[columnNumber])) {
          return null
        }
        cnt += 1
        return index
      }), null)
    },
    columnDependsOnIsOk: function (columnNumber, dependsOnColumnNumber) {
      if (!CsvImporter.DataStore.isFileSet()) { return false }
      return _.all(CsvImporter.DataStore.getRows(), function (row) {
        return PresenceCheckers.fieldIsEmpty(row[columnNumber]) || !PresenceCheckers.fieldIsEmpty(row[dependsOnColumnNumber])
      })
    },
    invalidColumnDependsOnIndices: function (columnNumber, dependsOnColumnNumber) {
      if (!CsvImporter.DataStore.isFileSet() || !CsvImporter.DataStore.showOnlyErrors()) { return [] }
      var cnt = 0
      return _.without(_.map(CsvImporter.DataStore.getRows(), function (row, index) {
        if (cnt > 100 || PresenceCheckers.fieldIsEmpty(row[columnNumber]) || !PresenceCheckers.fieldIsEmpty(row[dependsOnColumnNumber])) {
          return null
        }
        cnt += 1
        return index
      }), null)
    },
    columnAntiRequisiteIsOk: function (columnNumber, antiRequisiteColumnNumber) {
      if (!CsvImporter.DataStore.isFileSet()) { return true }
      return _.all(CsvImporter.DataStore.getRows(), function (row) {
        return PresenceCheckers.fieldIsEmpty(row[columnNumber]) || PresenceCheckers.fieldIsEmpty(row[antiRequisiteColumnNumber])
      })
    },
    invalidColumnAntiRequisiteIndices: function (columnNumber, antiRequisiteColumnNumber) {
      if (!CsvImporter.DataStore.isFileSet() || !CsvImporter.DataStore.showOnlyErrors()) { return [] }
      var cnt
      return _.without(_.map(CsvImporter.DataStore.getRows(), function (row, index) {
        if (cnt > 100 || PresenceCheckers.fieldIsEmpty(row[columnNumber]) || PresenceCheckers.fieldIsEmpty(row[antiRequisiteColumnNumber])) {
          return null
        }
        cnt += 1
        return index
      }), null)
    },
    columnOverwrittenByPositionIsOk: function (columnNumber) {
      if (!CsvImporter.DataStore.isFileSet()) { return true }
      return _.all(CsvImporter.DataStore.getRows(), function (row) {
        return PresenceCheckers.fieldIsEmpty(row[columnNumber])
      })
    },
    columnIsUnique: function (columnNumber) {
      if (!CsvImporter.DataStore.isFileSet()) { return false }
      var values = _.chain(CsvImporter.DataStore.getRows()).pluck(columnNumber).filter(function (value) { return !PresenceCheckers.fieldIsEmpty(value) }).value()
      return values.length === _.uniq(values).length
    },
    invalidColumnIsUniqueIndices: function (columnNumber) {
      if (!CsvImporter.DataStore.isFileSet() || !CsvImporter.DataStore.showOnlyErrors()) { return [] }
      var rawValues = _.pluck(CsvImporter.DataStore.getRows(), columnNumber)
      var seenValues = []
      var cnt = 0

      return _.without(_.map(rawValues, function (value, index) {
        if (cnt > 100 || PresenceCheckers.fieldIsEmpty(value)) {
          return null
        }
        if (!_.includes(seenValues, value)) {
          seenValues.push(value)
          return null
        }
        cnt += 1
        return index
      }), null)
    },
  }

  var ValidateHelpers = {
    addErrorToColumn: function (errors, columnNumber, error) {
      errors[columnNumber] = errors[columnNumber] || []
      errors[columnNumber].push(error)
    },
    validateDependsOn: function (importType, columnNumber, errors, rowsWithErrors) {
      var otherImportType, otherColumnNumber

      for (var i = 0, len = importType.depends_on.length; i < len; i++) {
        otherImportType = CsvImporter.DataStore.getImportTypeFieldById(importType.depends_on[i])
        otherColumnNumber = findColumnNumberForFieldId(otherImportType.id)

        if (_.isUndefined(otherColumnNumber)) {
          ValidateHelpers.addErrorToColumn(errors, columnNumber, I18n.t("js.csv_importer.errors.depends_on_missing", { name: importType.friendly_name, other: otherImportType.friendly_name }))
          return
        } else if (!PresenceCheckers.columnDependsOnIsOk(columnNumber, otherColumnNumber)) {
          if (rowsWithErrors.length <= 100) {
            rowsWithErrors.push.apply(rowsWithErrors, PresenceCheckers.invalidColumnDependsOnIndices(columnNumber, otherColumnNumber))
          }
          ValidateHelpers.addErrorToColumn(errors, otherColumnNumber, I18n.t("js.csv_importer.errors.depends_on_invalid", { name: importType.friendly_name, other: otherImportType.friendly_name }))
          return
        }
      }
    },
    validateUniqueness: function (importType, columnNumber, errors, rowsWithErrors) {
      if (importType.unique && !PresenceCheckers.columnIsUnique(columnNumber)) {
        if (rowsWithErrors.length <= 100) {
          rowsWithErrors.push.apply(rowsWithErrors, PresenceCheckers.invalidColumnIsUniqueIndices(columnNumber))
        }
        ValidateHelpers.addErrorToColumn(errors, columnNumber, I18n.t("js.csv_importer.errors.duplicates", { name: importType.friendly_name }))
      }
    },
    columnHasCorrectDataType: function (columnNumber, importTypeFormat) {
      if (_.isObject(importTypeFormat)) {
        if (importTypeFormat.type !== "array") {
          return false
        }

        return _.all(CsvImporter.DataStore.getRows(), function (row) {
          return PresenceCheckers.fieldIsEmpty(row[columnNumber]) || FormatValidators.array(row[columnNumber], importTypeFormat.value)
        })
      }

      if (!_.isFunction(FormatValidators[importTypeFormat])) {
        return false
      }

      return _.all(CsvImporter.DataStore.getRows(), function (row) {
        return PresenceCheckers.fieldIsEmpty(row[columnNumber]) || FormatValidators[importTypeFormat](row[columnNumber])
      })
    },
    invalidColumnHasDataTypeIndices: function (columnNumber, importTypeFormat) {
      if (!CsvImporter.DataStore.showOnlyErrors()) { return [] }

      var cnt = 0

      if (_.isObject(importTypeFormat)) {
        if (importTypeFormat.type !== "array") {
          return []
        }

        return _.without(_.map(CsvImporter.DataStore.getRows(), function (row, index) {
          if (cnt > 100 || PresenceCheckers.fieldIsEmpty(row[columnNumber]) || FormatValidators.array(row[columnNumber], importTypeFormat.value)) {
            return null
          }
          cnt += 1
          return index
        }), null)
      }

      if (!_.isFunction(FormatValidators[importTypeFormat])) {
        return []
      }

      return _.without(_.map(CsvImporter.DataStore.getRows(), function (row, index) {
        if (cnt > 100 || PresenceCheckers.fieldIsEmpty(row[columnNumber]) || FormatValidators[importTypeFormat](row[columnNumber])) {
          return null
        }
        cnt += 1
        return index
      }), null)
    },
    validateDataFormat: function (importType, columnNumber, errors, rowsWithErrors) {
      if (!ValidateHelpers.columnHasCorrectDataType(columnNumber, importType.format)) {
        if (rowsWithErrors.length <= 100) {
          rowsWithErrors.push.apply(rowsWithErrors, ValidateHelpers.invalidColumnHasDataTypeIndices(columnNumber, importType.format))
        }
        ValidateHelpers.addErrorToColumn(errors, columnNumber, I18n.t("js.csv_importer.errors.data_format", { name: importType.friendly_name }))
      }
    },
    validateCoupledWith: function (importType, columnNumber, errors, rowsWithErrors) {
      var otherImportType, otherColumnNumber

      if (importType.coupled_with.length > 0) {
        for (var i = 0, len = importType.coupled_with.length; i < len; i++) {
          otherImportType = CsvImporter.DataStore.getImportTypeFieldById(importType.coupled_with[i])
          otherColumnNumber = findColumnNumberForFieldId(otherImportType.id)

          if (_.isUndefined(otherColumnNumber)) {
            ValidateHelpers.addErrorToColumn(errors, columnNumber, I18n.t("js.csv_importer.errors.coupled_with_missing", { name: importType.friendly_name, other: otherImportType.friendly_name }))
            return
          } else if (!PresenceCheckers.columnDependsOnIsOk(columnNumber, otherColumnNumber)) {
            if (rowsWithErrors.length <= 100) {
              rowsWithErrors.push.apply(rowsWithErrors, PresenceCheckers.invalidColumnDependsOnIndices(columnNumber, otherColumnNumber))
            }
            ValidateHelpers.addErrorToColumn(errors, otherColumnNumber, I18n.t("js.csv_importer.errors.coupled_with_invalid", { name: importType.friendly_name, other: otherImportType.friendly_name }))
            return
          }
        }
      }
    },
    validateAntiRequisites: function (importType, columnNumber, errors, rowsWithErrors) {
      var otherImportType, otherColumnNumber

      if (importType.anti_requisites.length > 0) {
        for (var i = 0, len = importType.anti_requisites.length; i < len; i++) {
          otherImportType = CsvImporter.DataStore.getImportTypeFieldById(importType.anti_requisites[i])
          otherColumnNumber = findColumnNumberForFieldId(otherImportType.id)

          if (!_.isUndefined(otherColumnNumber)) {
            if (!PresenceCheckers.columnAntiRequisiteIsOk(columnNumber, otherColumnNumber)) {
              if (rowsWithErrors.length <= 100) {
                rowsWithErrors.push.apply(rowsWithErrors, PresenceCheckers.invalidColumnAntiRequisiteIndices(columnNumber, otherColumnNumber))
              }
              ValidateHelpers.addErrorToColumn(errors, columnNumber, I18n.t("js.csv_importer.errors.anti_req", { name: importType.friendly_name, other: otherImportType.friendly_name }))
              return
            }
          }
        }
      }
    },
    validateOverwrittenBy: function (importType, columnNumber, warnings, rowsWithWarnings) {
      var otherColumnNumber = findColumnNumberForFieldId("position")

      if(importType.overwritten_by_position && !PresenceCheckers.columnOverwrittenByPositionIsOk(otherColumnNumber)) {
        ValidateHelpers.addErrorToColumn(warnings, columnNumber, I18n.t("js.csv_importer.warnings.overwritten_by_position", { name: importType.friendly_name }))
      }
    },
  }

  var findTableRowForFieldId = function (id) {
    var tableRow = $(CsvImporter.Selectors.ConfigurationTable.rows).filter(function () {
      return $(this).data("field-id") === id
    })[0]

    if (tableRow) { tableRow = $(tableRow) }
    return tableRow
  }

  var findColumnNumberForFieldId = function (id) {
    var $tableRow = findTableRowForFieldId(id)
    return $tableRow ? +$tableRow.data("column-number") : undefined
  }

  return {
    formatValidators: FormatValidators,
    columnIsComplete: PresenceCheckers.columnIsComplete,
    validateData: function () {
      if (!CsvImporter.DataStore.isFileSet()) { return }

      var rowsWithErrors = []
      var rowsWithWarnings = []
      var errors = {}
      var warnings = {}
      var missingRequirementIds = []
      var presentRequirementIds = []
      var columnNumber

      _.each(CsvImporter.DataStore.getImportTypeFields(), function (importType) {
        columnNumber = findColumnNumberForFieldId(importType.id)

        // CHECK MISSING COLUMN
        if (_.isUndefined(columnNumber)) {
          if (importType.required) {
            if (importType.requirement_id) {
              if (!_.includes(presentRequirementIds, importType.requirement_id) && !_.includes(missingRequirementIds, importType.requirement_id)) {
                missingRequirementIds.push(importType.requirement_id)
              }
            } else {
              ValidateHelpers.addErrorToColumn(errors, "global", I18n.t("js.csv_importer.errors.missing_field", { name: importType.friendly_name }))
            }
          }
          return
        }

        // Remove missing requirement id if current field has matching requirement id
        if (importType.requirement_id && !_.includes(presentRequirementIds, importType.requirement_id)) {
          presentRequirementIds.push(importType.requirement_id)
          var missingRequirementIndex = _.indexOf(missingRequirementIds, importType.requirement_id)
          if (missingRequirementIndex > -1) { missingRequirementIds.splice(missingRequirementIndex, 1) }
        }

        // CHECK REQUIREMENT
        if (importType.required && !PresenceCheckers.columnIsComplete(columnNumber)) {
          if (rowsWithErrors.length <= 100) {
            rowsWithErrors = rowsWithErrors.concat(PresenceCheckers.invalidColumnIsCompleteIndices(columnNumber))
          }
          ValidateHelpers.addErrorToColumn(errors, columnNumber, I18n.t("js.csv_importer.errors.missing_values", { name: importType.friendly_name }))
          return
        }

        ValidateHelpers.validateDependsOn(importType, columnNumber, errors, rowsWithErrors)
        ValidateHelpers.validateDataFormat(importType, columnNumber, errors, rowsWithErrors)
      })

      _.each(CsvImporter.DataStore.getImportTypeFields(), function (importType) {
        columnNumber = findColumnNumberForFieldId(importType.id)
        if (_.isUndefined(columnNumber)) { return }

        ValidateHelpers.validateCoupledWith(importType, columnNumber, errors, rowsWithErrors)
        ValidateHelpers.validateAntiRequisites(importType, columnNumber, errors, rowsWithErrors)
        ValidateHelpers.validateOverwrittenBy(importType, columnNumber, warnings, rowsWithWarnings)
        ValidateHelpers.validateUniqueness(importType, columnNumber, errors, rowsWithErrors)
      })

      if (missingRequirementIds.length > 0) {
        var fieldNames = _.chain(CsvImporter.DataStore.getImportTypeFields()).filter({ requirement_id: missingRequirementIds[0] }).pluck("friendly_name").value()
        ValidateHelpers.addErrorToColumn(errors, "global", I18n.t("js.csv_importer.errors.missing_requirements", { names: fieldNames.join(", ") }))
      }

      var hasErrors = _.values(errors).length > 0
      CsvImporter.DataStore.setRowsWithErrorsCheckboxVisible(hasErrors)
      CsvImporter.DataStore.setRowsWithErrors(rowsWithErrors)
      CsvImporter.Renderers.updateErrors(errors, warnings)
    },
  }
})()
;


$.fn.handleTimesheetOptions = function() {
  var timesheetIntervalOptions = $(".timesheet-interval-option")
  var weeklyTimesheetsOptions = $("#weekly-timesheets-options")
  var monthlyTimesheetsOptions = $("#monthly-timesheets-options")

  function showTimesheetOptions(radio_button) {
    if (radio_button.hasClass("weekly-timesheet-option")) {
      weeklyTimesheetsOptions.removeClass("collapse")
    } else if (radio_button.hasClass("monthly-timesheet-option")) {
      monthlyTimesheetsOptions.removeClass("collapse")
    }
  }

  function toggleTimesheetOptions() {
    weeklyTimesheetsOptions.addClass("collapse")
    monthlyTimesheetsOptions.addClass("collapse")

    showTimesheetOptions($(this))
  }

  timesheetIntervalOptions.each(function() {
    $(this).click(toggleTimesheetOptions)
  })

  timesheetIntervalOptions.each(function() {
    if ($(this)[0].checked) {
      showTimesheetOptions($(this))
      return false
    }
  })
}
;
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
;
window.Widget = {
  Helpers: {
    applyUserIdToUrl: function (url) {
      return querystring_set(url, "current_user_id", window.current_user ? window.current_user.id : 0)
    },
  },
}
;
// jQuery plugin for the dashboard grid layout. Uses https://github.com/hootsuite/grid
(function ($) {
  // Store some instance variables
  var grid = {
    instance: null,
    container: "#grid",
    currentSize: 12,
    min_width: 141, // Min widget width
    save_enabled: true,
    page_size: function () {
      var w = $(window).width()

      if (w < 1300) {
        return "ml"
      } else if (w < 1650) {
        return "l"
      } else {
        return "xl"
      }
    },
    // By default gridList adds a bunch of vertical spacing at the bottom of the page
    // reset it such that it the bottom of the page is immediately below the bottom of the last widget
    kill_padding: function () {
      var widget_bottoms = $("li.widget.ui-draggable").map(function () { return $(this).position().top + $(this).height() })
      $("#grid").height(_.max(widget_bottoms))
    },
    reflow_and_save: function () {
      grid.initialise_gridList()
      grid.resize_grid()
      grid.kill_padding()
      grid.save_positions()
    },
    save_positions: function () {
      if (!grid.save_enabled) {
        return
      }

      var dimensions = grid.page_size()
      var widgets = _.map(grid.instance.items, function (el) {
        return { id: el.$element.data("id"), x: el.x, y: el.y }
      }).filter(function (widget) {
        return !!widget.id
      })

      $.ajax({
        dataType: "json",
        method: "POST",
        url: "/dashboard/update_layout",
        data: { dimension: dimensions, widgets: widgets },
      })
    },
    resize_grid: function (size) {
      if (size) {
        this.currentSize = size
      }
      $(grid.container).gridList("resize", this.currentSize)
    },
    resize_widgets: function (widgets) {
      widgets = widgets || $("li.widget.ui-draggable")

      _.each(widgets, function (widget) {
        var $widget = $(widget)
        var new_w = +$(widget).data("w-" + grid.page_size())
        var new_h = +$(widget).data("h-" + grid.page_size())
        var current_w = $(widget).data("w")
        var current_h = $(widget).data("h")

        // eslint-disable-next-line eqeqeq
        if (current_w != new_w || current_h != new_h) {
          $widget.attr("data-w", new_w)
          $widget.attr("data-h", new_h)
        }
      })
    },
    initialise_positions: function () {
      var widgets = widgets || $("li.widget.ui-draggable")

      _.each(widgets, function (widget) {
        var $widget = $(widget)
        var new_x = $widget.data("x-" + grid.page_size())
        var new_y = $widget.data("y-" + grid.page_size())

        $widget.attr("data-x", new_x)
        $widget.attr("data-y", new_y)
      })
    },
    initialise_gridList: function () {
      $(grid.container).gridList({
        lanes: 12,
        direction: "vertical",
        widthHeightRatio: 264 / 220,
        heightToFontSizeRatio: 0.25,
        itemSelector: "li.widget.ui-draggable:not(.widget-hidden)",
        onChange: function (changed) {
          var item = grid.instance.items.filter(function (i) { return i.$element.is(".widget-add-new-widget") })[0]

          if (item) {
            if (changed.length === 1 && changed[0].$element === item.$element && item.x !== 20) {
              return
            }

            item.x = 20
            item.y = 99
            grid.instance._applyPositionToItems()
            grid.instance.resize()
          }
          _.defer(grid.kill_padding)
          if (_.isEmpty(changed)) { return }
          // Note: this DOES get called when objects shift as a result of window resize.
          _.defer(grid.save_positions)
        },
        dragAndDrop: true,
      }, {
        handle: ".inner-drag-handle-js",
        cancel: ".not-widget-draggable",
      })
      grid.instance = $(grid.container).data("_gridList")
    },
    init: function (opts) {
      if (opts) { _.extend(grid, opts) }

      // On window resize, re-calculate the number of grid columns
      $(window).resize(function () {
        grid.resize_widgets()
        grid.resize_grid(Math.floor($(window).width() / grid.min_width))
      })

      // Set initial widget size
      grid.resize_widgets()
      grid.initialise_positions()

      grid.initialise_gridList()

      // Force resize of grid to trigger collision repositioning
      grid.resize_grid()

      // In order to calculate kill padding correctly, there needs to be no animated widget movement
      // So we should first calulate kill padding, and then enable the animation
      grid.kill_padding()
      _.defer(
        function () {
          $("li.widget.ui-draggable").addClass("widgetAnimationEnabled")
        }
      )
    },
  }

  $.fn.gridLayout = function (method) {
    if (grid[method] && _.isFunction(grid[method])) {
      return grid[ method ].apply(this, Array.prototype.slice.call(arguments, 1))
    }
  }

  return grid
})(jQuery)
;
$.widget("dashboard.widget", {

  /* Instance variables */
  options: {
    url: null,
    interval: null,
    element: null,
    callback: null,
    animationCallback: null,
    xhr: null,
    current_state: "okay",
    STATE_OKAY: "okay",
    STATE_EMPTY: "empty",
    STATE_LOADING: "loading",
    STATE_ERROR: "error",
  },

  _create: function () {
    this.options.element = this.element

    // Retrieve data on initial load and on interval if specified
    this._get_data(true)
    this.options.interval && setInterval(this._get_data.bind(this, false), this.options.interval)
    window.LH.Spin.start(this.options.element.parent(".widget-body").find(".loading .spinner-mount")[0])
    // Bind listener to error state's try-again button
    this.element.parents(".inner").off("click").on("click", "a.try-again", this._get_data.bind(this, true))
    this.element.parents(".inner").find(".widget-hide-js").off("click").on("click", this._hide_widget.bind(this))
  },

  _get_data: function (animate) {
    if (!this._is_hidden()) {
      if (this.options.xhr && this.options.xhr.abort) {
        this.options.xhr.abort()
      }

      if (this.options.format === "iframe") {
        this.options.element.empty()
        $("<iframe>", {
          src: this.options.url,
          class: this.options.metabase ? "metabase-dashboard-widget" : "embedded-retool-dashboard-widget",
          allowtransparency: true,
          frameborder: "0",
        }).on("load", this._render_widget.bind(this))
          .appendTo(this.options.element)
      } else {
        this.options.xhr =
          $.ajax({
            type: "GET",
            url: Widget.Helpers.applyUserIdToUrl(this.options.url),
            success: this._render_widget.bind(this),
            error: this._on_error.bind(this),
          })
      }
    }

    if (animate) {
      this._display_state(this.options.STATE_LOADING)
    }
  },

  _display_state: function (state_to_set) {
    var that = this
    var $el = this.options.element
    var $parent = $el.parent(".widget-body")
    var state_map = {}

    state_map[this.options.STATE_OKAY] = $el
    state_map[this.options.STATE_LOADING] = $parent.find(".loading")
    state_map[this.options.STATE_ERROR] = $parent.find(".error-state")
    state_map[this.options.STATE_EMPTY] = $parent.find(".empty-state-js")

    // Clear any current animations
    _.each(state_map, function ($el) { $el.clearQueue().finish() })

    // Apply new animations
    state_map[this.options.current_state].fadeOut("slow", function () {
      state_map[state_to_set].fadeIn("slow", function () {
        if (state_to_set === that.options.STATE_OKAY) {
          that.options.animationCallback && _.defer(that.options.animationCallback, that)
        }
      })
    })

    if (state_to_set === this.options.STATE_EMPTY) {
      if (state_map[state_to_set].data("state-remote-load")) {
        $.get(Widget.Helpers.applyUserIdToUrl(state_map[state_to_set].data("state-remote-load")))
         .done(function (data) {
           state_map[state_to_set].html(data)
         })
      }
    }

    // Update state
    this.options.current_state = state_to_set
  },

  _on_error: function (jqXHR, text_status, e) {
    if (jqXHR.status === 401) {
      window.location.reload()
    }

    if (this.options.xhr.statusText !== "abort") {
      this._display_state(this.options.STATE_ERROR)
    }
  },

  _is_hidden: function () {
    return this.options.element.parents(".widget").is(".widget-hidden")
  },

  _render_widget: function (data) {
    if (!this.options.format || this.options.format === "html") {
      this.options.element.html(data)
    }

    if (this.options.empty_data && this.options.empty_data(data)) {
      this._display_state(this.options.STATE_EMPTY)
    } else {
      this._display_state(this.options.STATE_OKAY)
    }

    this.options.callback && _.defer(this.options.callback, this, data)
  },

  _hide_widget: function () {
    // alert("are you sure you want to? m,. ")
    this.options.element.parents(".widget").addClass("widget-hidden")
    _.defer(function () {
      $(".dashboard").gridLayout("reflow_and_save")
    })
  },

  /* Public methods */
  refresh_widget: function (url, animate) {
    if (url) {
      this.options.url = url
    }
    this._get_data(animate)
  },

  show_widget: function () {
    this.options.element.parents(".widget").removeClass("widget-hidden")
    this._get_data(true)
    _.defer(function () {
      $(".dashboard").gridLayout("reflow_and_save")
    })
  },
})
;
Widget.Birthday = Widget.Birthday || {}
Widget.Birthday.init = function (options) {
  var $target = $("#birthday")

  var callback = function () {
    $target.find(".nav-tabs a").click(function (e) {
      e.preventDefault()
      $(this).tab("show")
    })

    $target.find("[tooltip]").loadTooltips()
  }

  $target.widget({ interval: options.interval, url: options.url, callback: callback, name: "birthday" })
}
;
Widget.DailyShiftSummary = Widget.DailyShiftSummary || {}
Widget.DailyShiftSummary.init = function (options) {
  var crelDiv = function (className, children) {
    var args = ["div", { class: className }]

    if (!Array.isArray(children)) {
      // use all arguments from index 1 onwards
      children = Array.prototype.slice.call(arguments, 1)
    }

    Array.prototype.push.apply(args, children)
    return window.crel.apply(null, args)
  }

  var t = function (key, options) {
    options = _.extend({ scope: "js.dashboard.weekly_planner" }, options || {})
    return I18n.t(key, options)
  }

  var $target = $("#dailyshiftsummary")
  var $widget = $target.parents(".widget")
  var $refreshWidgetButton = $("#refreshwidgetbutton")
  var currentDepartmentId = options.default_ids.department_id
  var currentLocationId = options.default_ids.location_id
  var allTeams = options.default_ids.all_teams
  var currentWeekOffset = 0
  var dailyData, totalData, canSeeCosts, showProjectedRevenue, showWeekTotal, todayIndex, precision, valueToggle, splhValueToggle, $revenueTotal, wagePercentageUpdater, salesPerHourUpdater
  var WAGE_HOUR_COOKIE_KEY = "dashboard_daily_shift_wage_hour"
  var SPLH_WAGE_PERC_COOKIE_KEY = "dashboard_daily_shift_splh_wage_perc"
  var wage_hour_cookie = $.cookie(WAGE_HOUR_COOKIE_KEY)
  var splh_wage_perc_cookie = $.cookie(SPLH_WAGE_PERC_COOKIE_KEY)

  $widget.find(".widget-filter").hide()

  // ---------------------------------------------------------------------------
  // helper methods
  var showWtdTotals = function () {
    // no wtd totals for the first day of this week since there is no data
    return !(currentWeekOffset === 0 && todayIndex === 0)
  }

  var formatCurrency = function (n) {
    return n.toCurrency(precision)
  }

  var formatPercentage = function (n) {
    return n.toFixed(2) + "%"
  }

  /**
   * Formats float with a currency and a per hour unit (eg: '10' -> '$10/h')
   * Returns null if param is null
   * @param {int,float,null} n
   */
  var formatSalesPerHour = function (n) {
    return n == null ? null : t("per_hour", { value: n.toCurrency(precision) })
  }

  // it not exactly the same as the haml formatting side but it's close enough.
  var formatHours = function (n) {
    // to_hrs_and_mins expects minutes so x60
    return (n * 60).to_hrs_and_mins("h", "m", " ")
  }

  // takes a number and precision (number of decimal places) and rounds
  // accordingly. precision = 0 is effectively Math.round
  var roundToDecimals = function (n, precision) {
    var factor = Math.pow(10, precision)
    return Math.round(n * factor) / factor
  }

  var isPastDay = function (i) {
    return i < todayIndex && currentWeekOffset <= 0
  }

  // ---------------------------------------------------------------------------
  // ViewUpdater
  // used to update the Wage % and SPMH cells whenever revenue inputs change.
  var ViewUpdater = function (calcFunc, formatFunc, selector, propNames) {
    this.calc = calcFunc
    this.format = formatFunc
    this.selector = selector
    this.propNames = propNames
  }

  ViewUpdater.prototype.dailyTimesheet = function (dayIndex) {
    return dailyData[dayIndex][this.propNames.timesheet_incl || this.propNames.timesheet]
  }

  ViewUpdater.prototype.dailyRoster = function (dayIndex) {
    return dailyData[dayIndex][this.propNames.roster]
  }

  ViewUpdater.prototype.dailyValue = function (dayIndex) {
    var data = dailyData[dayIndex]

    return showProjectedRevenue || isPastDay(dayIndex)
      ? this.calc(this.dailyTimesheet(dayIndex), data.actual_revenue)
      : this.calc(this.dailyRoster(dayIndex), data.projected_revenue)
  }

  ViewUpdater.prototype.$dailyTd = function (dayIndex) {
    return $target.find("td.day-" + dayIndex).filter(this.selector)
  }

  ViewUpdater.prototype.$totalTd = function () {
    return $target.find("td.total").filter(this.selector)
  }

  ViewUpdater.prototype.totalWtdValue = function () {
    var totalTimesheet = totalData[this.propNames.timesheet]
    return this.calc(totalTimesheet, totalData.actual_revenue)
  }

  ViewUpdater.prototype.totalWeekValue = function () {
    var timesheetPlusRoster = totalData[this.propNames.timesheetPlusRoster]
    return this.calc(timesheetPlusRoster, totalData.week_revenue)
  }

  ViewUpdater.prototype.totalActualAndProjectedValue = function () {
    var timesheetPlusRoster = totalData[this.propNames.timesheetPlusRoster]
    return this.calc(timesheetPlusRoster, totalData.actual_and_projected_revenue)
  }

  ViewUpdater.prototype._updateEl = function (value, $el) {
    if (value === null) {
      // put &nbsp; in instead of .empty() to ensure Projected value doesn't
      // jump around (due to vertical alignment) if we take out the actual value
      $el.html("&nbsp;")
    } else {
      $el.text(this.format(value))
    }
  }

  ViewUpdater.prototype.updateDay = function (dayIndex) {
    var dailyValue = this.dailyValue(dayIndex)
    var projected = !showProjectedRevenue && !isPastDay(dayIndex)
    var $el = projected
      ? this.$dailyTd(dayIndex).children(".projected")
      : this.$dailyTd(dayIndex).children(".actual")
    var propName = projected ? this.propNames.projected : this.propNames.actual

    this._updateEl(dailyValue, $el)
    dailyData[dayIndex][propName] = dailyValue
  }

  ViewUpdater.prototype.updateTotal = function () {
    if (showWtdTotals()) {
      var wtdValue = this.totalWtdValue()
      var $wtdEl = this.$totalTd().children(".actual")
      this._updateEl(wtdValue, $wtdEl)
      totalData[this.propNames.actual] = wtdValue
    }

    if (showWeekTotal) {
      var weekValue = this.totalWeekValue()
      var $weekEl = this.$totalTd().children(".projected")

      this._updateEl(weekValue, $weekEl)
      totalData[this.propNames.weekTotal] = weekValue

      $weekEl.prepend("<span class='mi mi-date-range'></span>")
    }

    if (currentWeekOffset === 0) {
      var aAndPValue = this.totalActualAndProjectedValue()
      var $aAndPEl = this.$totalTd().children(".actualAndProjected")

      this._updateEl(aAndPValue, $aAndPEl)
      totalData[this.propNames.actualAndProjected] = aAndPValue

      $aAndPEl
        .prepend("<span class='mi mi-calendar-today'></span> ")
        .prepend("<span class='mi mi-trending-up'></span> ")
    }
  }

  // ---------------------------------------------------------------------------
  // wage percentage
  var calcWagePercentage = function (wages, revenue) {
    if (wages !== null && revenue !== null && revenue > 0) {
      return wages / revenue * 100
    }
    return null
  }

  wagePercentageUpdater = new ViewUpdater(calcWagePercentage, formatPercentage,
    ".wage-percentage", {
      actual: "actual_wage_percentage",
      projected: "projected_wage_percentage",
      timesheet: "timesheet_costs",
      timesheet_incl: "timesheet_costs_incl",
      roster: "roster_costs",
      timesheetPlusRoster: "timesheet_plus_roster_costs",
      weekTotal: "week_wage_percentage",
      actualAndProjected: "actual_and_projected_wage_percentage",
    }
  )

  // ---------------------------------------------------------------------------
  // SPMH
  var calcSalesPerHour = function (hours, revenue) {
    if (hours !== null && revenue !== null && hours > 0) {
      return revenue / hours
    }
    return null
  }

  salesPerHourUpdater = new ViewUpdater(calcSalesPerHour, formatSalesPerHour,
    ".sales-per-hour", {
      actual: "actual_sales_per_hour",
      projected: "projected_sales_per_hour",
      timesheet: "timesheet_hours",
      roster: "roster_hours",
      timesheetPlusRoster: "timesheet_plus_roster_hours",
      weekTotal: "week_sales_per_hour",
      actualAndProjected: "actual_and_projected_sales_per_hour",
    }
  )

  // ---------------------------------------------------------------------------
  // update total revenue cell
  var updateTotalRevenue = function () {
    if (showWtdTotals()) {
      var wtdTotal = totalData.actual_revenue.toCurrency(precision)
      $revenueTotal.children(".actual").text(wtdTotal)
    }

    if (showWeekTotal) {
      var weekTotal = totalData.week_revenue.toCurrency(precision)
      $revenueTotal.children(".projected").text(weekTotal)
                   .prepend("<span class='mi mi-calendar-today'></span> ")
    }

    if (currentWeekOffset === 0) {
      var newActualAndProjected = totalData.actual_and_projected_revenue.toCurrency(precision)
      $revenueTotal
        .children(".actualAndProjected")
        .text(newActualAndProjected)
        .prepend("<span class='mi mi-calendar-today'></span> ")
        .prepend("<span class='mi mi-trending-up'></span> ")
    }
  }

  // ---------------------------------------------------------------------------
  // updates dailyData and totalData objects
  // updates Total Revenue and all Wage % and SPMH cells
  var updateValues = function (dayIndex, revenue) {
    dailyData[dayIndex].actual_revenue = revenue

    // save this before potentially updating total revenue below
    var previousTotalActualRevenue = totalData.actual_revenue

    // recalculate total Revenue (actual)
    if (showWtdTotals()) {
      var daysToCount = dailyData.slice(0, todayIndex)
      totalData.actual_revenue = daysToCount.reduce(function (soFar, day) {
        return soFar + day.actual_revenue
      }, 0)
    }

    if (!showProjectedRevenue) {
      // use input as projected revenue if we don't have any projections
      dailyData[dayIndex].projected_revenue = revenue

      // recalculate week total Revenue
      totalData.week_revenue = dailyData.reduce(function (soFar, day) {
        return soFar + day.actual_revenue
      }, 0)
    }

    if (currentWeekOffset === 0) {
      var futureProjectedTotal = (totalData.actual_and_projected_revenue - previousTotalActualRevenue)
      totalData.actual_and_projected_revenue = totalData.actual_revenue + futureProjectedTotal
    }

    updateTotalRevenue()
    wagePercentageUpdater.updateDay(dayIndex)
    wagePercentageUpdater.updateTotal()
    salesPerHourUpdater.updateDay(dayIndex)
    salesPerHourUpdater.updateTotal()
  }

  var parseCurrency = function (amount) {
    var c = window.i18n_props.currency

    // remove whitespace
    amount = amount.replace(/\s/g, "")

    // remove thousands delimiters
    amount = amount.split(c.delimiter).join("")

    // remove unit symbol
    amount = amount.replace(c.unit, "")

    // replace decimal separator with "."
    amount = amount.replace(c.separator, ".")

    return parseFloat(amount)
  }

  var saveRevenueInput = function ($input) {
    var $td = $input.closest("td")
    var dayIndex = $td.data("dayIndex")
    var revenue = parseCurrency($input.text())

    // clear invalid or negative values
    if (_.isNaN(revenue) || revenue < 0) {
      revenue = null
      $input.empty()
    } else {
      revenue = roundToDecimals(revenue, precision)
      $input.text(revenue)
    }

    // update the contents of the table to reflect the new value
    updateValues(dayIndex, revenue)

    // save to db
    $.post("storestats/import/user_entered", {
      date: dailyData[dayIndex].date,
      stat: revenue,
      location_id: currentLocationId,
      department_id: currentDepartmentId,
    })
  }

  // ---------------------------------------------------------------------------
  // revenue inputs
  // focus event on revenue inputs
  $target.on("focus", "span.input.revenue", function () {
    $(this).closest("td").addClass("active")

    // select the contents of our faux input
    var selection = window.getSelection()
    var range = document.createRange()
    range.selectNodeContents(this)
    selection.removeAllRanges()
    selection.addRange(range)
  })

  // blur event on revenue inputs
  $target.on("blur", "span.input.revenue", function () {
    $(this).closest("td").removeClass("active")
    saveRevenueInput($(this))
  })

  // disable enter
  $target.on("keydown", "span.input.revenue", function (e) {
    if (e.which === 13) {
      saveRevenueInput($(this))
      return false
    }
  })

  // ---------------------------------------------------------------------------
  // revenue empty state Enter Manually button
  $target.on("click", "a.enter-manually", function () {
    var $trs = $target.find("tr.blur").removeClass("blur")

    /*
     * Edge 15 does not repaint after removeClass, so this forces it to.
     *
     * Element.clientHeight is read-only and the following unused access is
     * intentional. it triggers a layout which forces a repaint when it's
     * displayed again. accessing/calling most of the properties/methods
     * under at the bottom of this page will work:
     * http://kellegous.com/j/2013/01/26/layout-performance/
     *
     * Element.clientHeight was chosen because it's implemented universally
     * so it won't start throwing errors in any other browser:
     * https://developer.mozilla.org/en-US/docs/Web/API/Element/clientHeight
     *
     * original solution uses offsetHeight which is also supported in
     * the browsers we target (but the MDN page isn't as convincing):
     * https://stackoverflow.com/questions/3485365/how-can-i-force-webkit-to-redraw-repaint-to-propagate-style-changes/3485654#3485654
     * https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetHeight
     */
    $trs.css("display", "none")
    $trs[0].parentElement.clientHeight
    $trs.css("display", "")
    // end hack

    $(".revenue-empty-state-overlay").fadeOut()
    return false
  })

  // ---------------------------------------------------------------------------
  // widget load animation callback
  // use this to calculate height of revenue empty state
  var animationCallback = function () {
    var $revenueEmptyState = $(".revenue-empty-state-overlay")

    // do nothing if we don't have an empty state (markup is rendered if and
    // only if we need to show the empty state)
    if ($revenueEmptyState.length === 0) {
      return
    }

    // account for the height of the row, we want the overlay to start below it
    var $td = $revenueEmptyState.parent()
    var top = $td.position().top + $td.height()

    // use scrollHeight here because the widget sometimes scrolls vertically
    // when the container isn't high enough
    var height = $target.prop("scrollHeight") - top
    var contentsHeight = $revenueEmptyState.height()

    // stretch empty state to fill to the bottom of the widget and add padding
    // to centre vertically
    $revenueEmptyState.css({
      height: height,
      paddingTop: (height - contentsHeight) / 2,
    })

    $revenueEmptyState.fadeIn()
  }

  // ---------------------------------------------------------------------------
  // popovers
  var PopoverBuilder = function (popoverType, data) {
    this.isRevenue = popoverType.isRevenue
    this.format = popoverType.format
    this.translationKeys = popoverType.translationKeys
    this.propNames = popoverType.propNames
    this.data = data
    this.div = crelDiv("weekly-planner-popover")
  }

  PopoverBuilder.prototype.appendActual = function () {
    var actual = this.data[this.propNames.actual]
    if (actual === null) {
      return
    }

    var args = [
      "actual",
      crelDiv("type", t(this.translationKeys.actual)),
      crelDiv("value", this.format(actual)),
    ]

    // only include this note for actual costs that need it
    if (this.translationKeys.hasOwnProperty("includesNote")) {
      args.push(crelDiv("txt0750 note-actual", t(this.translationKeys.includesNote)))
    }

    if (this.data[this.propNames.excluding] != null) {
      var excluding = this.data[this.propNames.excluding]
      args.push(crelDiv("type", t("actual_wage_percentage_excl", { percent: this.format(excluding) })))
    }

    this.div.appendChild(crelDiv.apply(null, args))
  }

  PopoverBuilder.prototype.appendProjRevenueConf = function (projected) {
    if (this.data.projected_revenue_config && this.data.projected_revenue_config.average_of_dates) {
      var config = this.data.projected_revenue_config
      var percent = Math.round((config.growth_percentage - 1) * 100)
      var translationKey, translation

      if (config.multiple_dates && config.multiple_dates.length === 1) {
        translationKey = [
          "using_date",
          config.growth_percentage === 1 ? "no_growth" : "growth",
        ].join(".")
        translation = t(translationKey, { percent: percent, date: config.multiple_dates[0] })
      } else if (config.multiple_dates && config.multiple_dates.length > 1) {
        translationKey = [
          "using_dates",
          config.growth_percentage === 1 ? "no_growth" : "growth",
        ].join(".")
        translation = t(translationKey, { percent: percent })
      } else {
        translationKey = [
          "weeks",
          config.lookback_weeks === 1 ? "one_week" : "multiple_weeks",
          config.growth_percentage === 1 ? "no_growth" : "growth",
        ].join(".")
        translation = t(translationKey, {
          lookback_weeks: config.lookback_weeks,
          day_of_week: this.data.day_of_week,
          percent: percent,
        })
      }

      /*
       * get the translation, e.g.
       * "foo<br>bar"
       * turn it into an array
       * ["foo", "<br>", "bar"]
       * then turn that into
       * ["foo", crel("br"), "bar"]
       */
      var configChildren = translation.split(/(?=(<br>))<br>/).map(function (el) {
        if (el === "<br>") {
          return crel("br")
        }
        return el
      })

      projected.appendChild(crelDiv("note", t("projections_info")))

      if (config.multiple_dates != null && config.multiple_dates.length >= 1) {
        projected.appendChild(crelDiv("config", configChildren))
      } else {
        projected.appendChild(crelDiv("note", t("currently_using_default", { dow: this.data.day_of_week })))
      }
    }
  }

  PopoverBuilder.prototype.appendActualAndProjectedTotal = function () {
    var actualAndProjected = this.data[this.propNames.actualAndProjected]
    if (actualAndProjected == null) {
      return
    }

    var actualAndProjectedDiv = crelDiv(
      "actualAndProjected",
      crelDiv(
        "type",
        crel("span", { class: "mi mi-calendar-today" }),
        crel("span", { class: "mi mi-trending-up" }),
        t(this.translationKeys.actualAndProjected)
      ),
      crelDiv("value", this.format(actualAndProjected))
    )

    actualAndProjectedDiv.appendChild(crelDiv("note", t(this.translationKeys.actualAndProjectedNote)))

    this.div.appendChild(actualAndProjectedDiv)
  }

  PopoverBuilder.prototype.appendProjected = function () {
    var projected = this.data[this.propNames.projected]
    if (projected == null) {
      return
    }

    var projectedDiv = crelDiv("projected",
      crelDiv("type",
        crel("span", { class: "mi mi-trending-up" }), " ",
        t(this.translationKeys.projected)
      ),
      crelDiv("value", this.format(projected))
    )

    if (this.data !== totalData && this.isRevenue) {
      this.appendProjRevenueConf(projectedDiv)
    }

    this.div.appendChild(projectedDiv)
    this.appendProjectedTotalNote()
  }

  PopoverBuilder.prototype.appendWeekTotal = function () {
    var weekTotal = this.data[this.propNames.weekTotal]
    if (weekTotal == null) {
      return
    }

    this.div.appendChild(crelDiv("projected",
      crelDiv("type",
        crel("span", { class: "mi mi-calendar-today" }), " ",
        t(this.translationKeys.week)
      ),
      crelDiv("value", this.format(weekTotal))
    ))
  }

  PopoverBuilder.prototype.appendProjectedTotalNote = function () {
    // Show totalNote translation if:
    // - current week
    // - not the first day of the week
    // - the key exists
    if (currentWeekOffset !== 0 || todayIndex === 0 || !this.translationKeys.totalNote) {
      return
    }

    this.div.append(crelDiv("note", t(this.translationKeys.totalNote)))
  }

  PopoverBuilder.prototype.build = function () {
    if (this.data === totalData) {
      // total column

      // check to ensure that we don't show 0 values e.g. "$0"
      if (showWtdTotals()) {
        this.appendActual()
      }

      if (showProjectedRevenue) {
        this.appendProjected()
      } else if (showWeekTotal) {
        this.appendWeekTotal()
      } else {
        this.appendProjectedTotalNote()
      }

      if (currentWeekOffset === 0) {
        this.appendActualAndProjectedTotal()
      }
    } else if (showProjectedRevenue) {
      // weekday column
      this.appendActual()
      this.appendProjected()
    } else if (isPastDay(this.data.day_index)) {
      // weekday column, no projected revenue
      // revenue from the past is "actual"
      this.appendActual()
    } else {
      // weekday column, no projected revenue
      // so revenue from today onwards is "projected"
      this.appendProjected()
    }

    if (this.translationKeys.note) {
      this.div.appendChild(crelDiv("note", t(this.translationKeys.note)))
    }
  }

  var PopoverType = function (classNames, formatFunc, translationKeys,
                              propNames, isRevenue) {
    this.classNames = classNames
    this.format = formatFunc
    this.translationKeys = translationKeys
    this.propNames = propNames
    this.isRevenue = isRevenue || false // isRevenue is optional
  }

  PopoverType.prototype.build = function (data) {
    var builder = new PopoverBuilder(this, data)
    builder.build()
    return builder.div
  }

  PopoverType.prototype.builder = function () {
    var that = this
    return function (data) {
      return that.build(data)
    }
  }

  PopoverType.prototype.match = function ($td) {
    return this.classNames.reduce(function (match, className) {
      return match && $td.hasClass(className)
    }, true)
  }

  PopoverType.prototype.has = function (data) {
    // a != null is equivalent to:
    // (a !== null) || (a !== undefined)
    return data[this.propNames.actual] != null ||
           data[this.propNames.projected] != null ||
           data[this.propNames.weekTotal] != null ||
           data[this.propNames.actualAndProjected] != null
  }

  var popoverTypes = [
    new PopoverType(["revenue"], formatCurrency, {
      actual: "actual",
      projected: "projected",
      week: "week_revenue",
      totalNote: "total_revenue_note",
      actualAndProjected: "actual_and_projected",
      actualAndProjectedNote: "actual_and_projected_revenue_note",
      heading: "revenue_popover_heading",
    }, {
      actual: "actual_revenue",
      projected: "projected_revenue",
      weekTotal: "week_revenue",
      actualAndProjected: "actual_and_projected_revenue",
    }, true),
    new PopoverType(["wage-percentage"], formatPercentage, {
      actual: "actual_wages_actual_revenue",
      projected: "roster_wages_projected_revenue",
      week: "week_wage_percentage",
      totalNote: "total_wage_percentage_note",
      actualAndProjected: "actual_and_projected_wage_percentage",
      actualAndProjectedNote: "actual_and_projected_wage_percentage_note",
      includesNote: "wage_percentage_note",
      heading: "wage_percent_popover_heading",
    }, {
      actual: "actual_wage_percentage",
      projected: "projected_wage_percentage",
      weekTotal: "week_wage_percentage",
      actualAndProjected: "actual_and_projected_wage_percentage",
      excluding: "actual_wage_percentage_excl",
    }),
    new PopoverType(["sales-per-hour"], formatSalesPerHour, {
      actual: "actual_sales_per_hour",
      projected: "roster_sales_per_hour",
      week: "week_sales_per_hour",
      totalNote: "total_sales_per_hour_note",
      actualAndProjected: "actual_and_projected_sales_per_hour",
      actualAndProjectedNote: "actual_and_projected_sales_per_hour_note",
      includesNote: "includes_paid_leave_and_allowances_note",
      heading: "spmh_popover_heading",
    }, {
      actual: "actual_sales_per_hour",
      projected: "projected_sales_per_hour",
      actualAndProjected: "actual_and_projected_sales_per_hour",
    }),
    new PopoverType(["total", "wages-view", "timesheet-cost"], formatCurrency, {
      actual: "actual_wages",
      actualAndProjected: "week_wages",
      actualAndProjectedNote: "total_timesheet_wages_note",
      includesNote: "includes_paid_leave_and_allowances_note",
      heading: "timesheet_wages_popover_heading",
    }, {
      actual: "timesheet_costs",
      actualAndProjected: "timesheet_plus_roster_costs",
    }),
    new PopoverType(["total", "hours-view", "timesheet-cost"], formatHours, {
      actual: "actual_hours",
      actualAndProjected: "week_hours",
      actualAndProjectedNote:"total_timesheet_hours_note",
      includesNote: "includes_paid_leave_and_allowances_note",
      heading: "timesheet_hours_popover_heading",
    }, {
      actual: "timesheet_hours",
      actualAndProjected: "timesheet_plus_roster_hours",
    }),
  ]

  var getType = function ($td) {
    var type
    for (var i = 0; i < popoverTypes.length; i++) {
      type = popoverTypes[i]
      if (type.match($td)) {
        return type
      }
    }
    return null
  }

  var showPopovers = function ($td, data) {
    var type = getType($td)
    if (type) {
      return type.has(data)
    }
    return false
  }

  var getData = function ($cell) {
    if ($cell.hasClass("total")) {
      return totalData
    }
    return dailyData[$cell.data("dayIndex")]
  }

  // show/hide revenue popovers
  $target.on("mouseenter", "td.popover-cell", function () {
    var $this = $(this)
    var data = getData($this)

    // only show if we have data
    if (showPopovers($this, data)) {
      $this.popover("show")
    }
  })
  .on("mouseleave", "td.popover-cell", function () {
    $(this).popover("hide")
  })

  var initialisePopovers = function () {
    $target.find(".variance-popover-js").popover({ template: "<div class='popover daily-summary-variance-popover' role='tooltip'><div class='arrow'></div><h3 class='popover-title'></h3><div class='popover-content'></div></div>" })

    $target.find("td.popover-cell").each(function () {
      var $this = $(this)
      var data = getData($this)
      var title
      var headingKey = ""
      var buildFunction
      var type = getType($this)

      if (type) {
        headingKey = type.translationKeys.heading
        buildFunction = type.builder()
      } else {
        buildFunction = function (data) {
          return crelDiv("weekly-planner-popover")
        }
      }

      title = headingKey ? t([
        headingKey,
        $this.hasClass("total") ? "week_total" : "day",
      ].join("."), { day: data.date_formatted }) : ""

      $this.popover({
        title: title,
        placement: "top",
        trigger: "manual",
        container: "body",
        animation: false, // animation with this plugin is buggy
        html: true,
        content: function () {
          /*
           * we don't cache the buildFunction call because dailyData can change,
           * and we want to use the latest data. note: we're only allowed to
           * return an HTML string.
           */
          return buildFunction(data).outerHTML
        },
      })
    })
  }

  // ---------------------------------------------------------------------------
  // Wage %/SPMH toggle
  // Hides all cells which display wages, shows all cells which show hours
  function toggle_wages () {
    // Never show users who can't see wages the wages view
    if (canSeeCosts) {
      $widget.find(".toggle-wages-hour").removeClass("show-hours-view")
      $widget.find(".toggle-wages-hour").addClass("show-wages-view")
      $widget.find(".wages-hour-toggle-js").text(t("wages"))
      set_costs_tooltip_attributes()
    }
  }

  // Hides all cells which display hours, shows all cells which show wages
  function toggle_hours () {
    $widget.find(".toggle-wages-hour").removeClass("show-wages-view")
    $widget.find(".toggle-wages-hour").addClass("show-hours-view")
    $widget.find(".wages-hour-toggle-js").text(t("hours"))
    set_hours_tooltip_attributes()
  }

  function toggle_wage_perc () {
    // Never show users who can't see wages the wages view
    if (canSeeCosts) {
      $widget.find(".toggle-wages-hour").removeClass("show-splh-view")
      $widget.find(".toggle-wages-hour").addClass("show-wage-perc-view")
      $widget.find(".splh-wage-perc-toggle-js").text(t("wage_perc"))
    }
  }

  function toggle_splh () {
    $widget.find(".toggle-wages-hour").removeClass("show-wage-perc-view")
    $widget.find(".toggle-wages-hour").addClass("show-splh-view")
    $widget.find(".splh-wage-perc-toggle-js").text(t("splh"))
  }

  function set_costs_tooltip_attributes () {
    var roster_tooltip = $widget.find(".roster-tooltip")[0]
    var actual_tooltip = $widget.find(".actual-tooltip")[0]
    var variance_tooltip = $widget.find(".variance-tooltip")[0]

    roster_tooltip.setAttribute("title", t("rostered_cost_tooltip"))
    roster_tooltip.setAttribute("tooltip", t("rostered_cost_tooltip"))
    roster_tooltip.setAttribute("data-original-title", t("rostered_cost_tooltip"))

    actual_tooltip.setAttribute("title", t("actual_cost_tooltip"))
    actual_tooltip.setAttribute("tooltip", t("actual_cost_tooltip"))
    actual_tooltip.setAttribute("data-original-title", t("actual_cost_tooltip"))

    variance_tooltip.setAttribute("title", t("variance_cost_tooltip"))
    variance_tooltip.setAttribute("tooltip", t("variance_cost_tooltip"))
    variance_tooltip.setAttribute("data-original-title", t("variance_cost_tooltip"))
  }

  function set_hours_tooltip_attributes () {
    var roster_tooltip = $widget.find(".roster-tooltip")[0]
    var actual_tooltip = $widget.find(".actual-tooltip")[0]
    var variance_tooltip = $widget.find(".variance-tooltip")[0]

    roster_tooltip.setAttribute("title", t("rostered_hours_tooltip"))
    roster_tooltip.setAttribute("tooltip", t("rostered_hours_tooltip"))
    roster_tooltip.setAttribute("data-original-title", t("rostered_hours_tooltip"))

    actual_tooltip.setAttribute("title", t("actual_hours_tooltip"))
    actual_tooltip.setAttribute("tooltip", t("actual_hours_tooltip"))
    actual_tooltip.setAttribute("data-original-title", t("actual_hours_tooltip"))

    variance_tooltip.setAttribute("title", t("variance_hours_tooltip"))
    variance_tooltip.setAttribute("tooltip", t("variance_hours_tooltip"))
    variance_tooltip.setAttribute("data-original-title", t("variance_hours_tooltip"))
  }

  // Inverts whatever the current toggle is set to
  function inverse_toggle () {
    if (valueToggle === "wages") {
      valueToggle = "hours"
      toggle_hours()
      toggle_splh()
    } else {
      valueToggle = "wages"
      toggle_wages()
      toggle_wage_perc()
    }
  }

  $(document).on("keydown keyup", function (e) {
    // If alt key is pressed, trigger toggle
    if (e.which === 18) {
      inverse_toggle()
    }
  })

  // ---------------------------------------------------------------------------
  // refresh widget
  var refreshWidget = function(data) {
    $target.widget("refresh_widget", getUrl(data), true)
  }

  // ---------------------------------------------------------------------------
  // widget load callback
  var callback = function () {
    var $table = $target.children("table")
    dailyData = $table.data("daily")
    totalData = $table.data("total")
    todayIndex = $table.data("todayIndex")
    canSeeCosts = $table.data("seeCosts")
    showWeekTotal = $table.data("showWeekTotal")
    showProjectedRevenue = $table.data("showProjectedRevenue")
    precision = $table.data("precision")
    $revenueTotal = $target.find("td.revenue.total")
    if (valueToggle == null) {
      // Never show users who can't see wages the wages view
      if (canSeeCosts) {
        valueToggle = wage_hour_cookie || "wages"
      } else {
        valueToggle = wage_hour_cookie || "hours"
      }
    }

    if (splhValueToggle == null) {
      if (canSeeCosts) {
        splhValueToggle = splh_wage_perc_cookie || "wages"
      } else {
        splhValueToggle = splh_wage_perc_cookie || "hours"
      }
    }

    if (valueToggle === "wages" && canSeeCosts) {
      toggle_wages()
    } else if (valueToggle === "hours") {
      toggle_hours()
    }

    if (splhValueToggle === "wages") {
      toggle_wage_perc()
    } else if (splhValueToggle === "hours") {
      toggle_splh()
    }

    $widget.find(".wages-toggle-js").click(function (cb) {
      valueToggle = "wages"
      $.cookie(WAGE_HOUR_COOKIE_KEY, valueToggle)
      toggle_wages()
    })
    $widget.find(".hours-toggle-js").click(function (cb) {
      valueToggle = "hours"
      $.cookie(WAGE_HOUR_COOKIE_KEY, valueToggle)
      toggle_hours()
    })

    $widget.find(".wage-perc-toggle-js").click(function (cb) {
      splhValueToggle = "wages"
      $.cookie(SPLH_WAGE_PERC_COOKIE_KEY, splhValueToggle)
      toggle_wage_perc()
    })
    $widget.find(".splh-toggle-js").click(function (cb) {
      splhValueToggle = "hours"
      $.cookie(SPLH_WAGE_PERC_COOKIE_KEY, splhValueToggle)
      toggle_splh()
    })

    // Naughty css hack as per Nick's request
    $widget.find(".widget-filter").fadeIn()
    $widget.find(".dropdown-menu").css(
      "max-height", $target.parents(".widget-body").height() * 1)
    $target.find("[tooltip]").loadTooltips()

    initialisePopovers()
  }

  // ---------------------------------------------------------------------------
  // getUrl of a new widget load request
  function getUrl (opts) {
    var query_arr = []
    var query_string = ""

    /*
     * getUrl({offset: 0}) -> offset = 0
     *
     * getUrl({department_id: 7}) -> currentDepartmentId = 7
     *                               currentLocationId = undefined
     *
     * getUrl({location_id: 5}) -> currentDepartmentId = undefined
     *                             currentLocationId = 5
     *
     * getUrl({}) -> currentDepartmentId = undefined
     *               currentLocationId = undefined
     */
    if (opts.hasOwnProperty("offset")) {
      currentWeekOffset = opts.offset
    } else {
      currentDepartmentId = opts.department_id || undefined
      currentLocationId = opts.location_id || undefined
      allTeams = opts.all_teams || undefined
    }

    if (currentDepartmentId) { query_arr.push("department_id=" + currentDepartmentId) }
    if (currentLocationId) { query_arr.push("location_id=" + currentLocationId) }
    if (currentWeekOffset) { query_arr.push("offset=" + currentWeekOffset) }
    if (allTeams) { query_arr.push("all_teams=" + true) }

    if (!_.isEmpty(query_arr)) {
      query_string = "?" + query_arr.join("&")
    }

    return opts.url + query_string
  }

  $(".daily_shift_summary_filter").off("click").on("click", function (e) {
    var $filter = $(e.target)

    $filter.parents(".widget-filter").toggleClass("open").find("span.text")
      .html($filter.html())

    refreshWidget($filter.data())
    return false
  })

  $refreshWidgetButton.off("click").on("click", function (e) {
    var data = e.target.dataset

    refreshWidget({
      url: options.url,
      department_id: data.department_id,
      location_id: data.location_id,
      all_teams: !(data.department_id || data.location_id),
    })
    return false
  })

  // ---------------------------------------------------------------------------
  // initialise widget
  $target.widget({
    interval: options.interval,
    url: getUrl({
      url: options.url,
      department_id: currentDepartmentId,
      location_id: currentLocationId,
      all_teams: allTeams,
    }),
    callback: callback,
    animationCallback: animationCallback,
    name: "weekly_planner",
  })

  // reload widget every 15 minutes to get latest data
  setInterval(function () {
    refreshWidget({
      url: options.url,
      department_id: currentDepartmentId,
      location_id: currentLocationId,
      allTeams: allTeams,
    })
  }, 1000 * 60 * 15)
}
;
Widget.LeaveCalendar = Widget.LeaveCalendar || {}
Widget.LeaveCalendar.init = function (options) {
  var $target = $("#leave_calendar")
  // Since we're mounting a react app we don't need to do any special initialisation here in the js
  var callback = function () { return null }

  $target.widget({ interval: options.interval, url: options.url, callback: callback, name: "leave_calendar" })
}
;
Widget.LiveFeed = Widget.LiveFeed || {}

Widget.LiveFeed.INITIAL_COUNT = 10

Widget.LiveFeed.LiveFeedCookieKey = function () {
  return "dashboard_live_feed" + window.current_user.org_id
}

Widget.LiveFeed.Clockin = function (row) {
  _.each(Widget.LiveFeed._headers, function (header, index) {
    this[header] = row[index]
  }, this)

  var moment_time = moment.unix(parseInt(this.time, 10))
  if (this.zone) {
    moment_time.utcOffset(parseInt(this.zone, 10))
  }

  this.render = function () {
    if (this.cached_html) {
      return this.cached_html
    }

    this.cached_html = Widget.LiveFeed.Renderer.render_clockin(this)
    return this.cached_html
  }

  this.unix_time = function () {
    return moment_time.unix()
  }

  this.date = function () {
    return moment_time.format("YYYY-MM-DD")
  }

  this.time_pp = function () {
    var secs = moment_time.valueOf()
    var rounded_output = moment(secs).second() >= 30 ? moment(moment_time).add(1, "minute").startOf("minute") : moment(moment_time).startOf("minute")
    return window.time_formatter.live_feed_time(rounded_output)
  }

  this.is_clockin = function () {
    return this.action === "login"
  }
}

Widget.LiveFeed.ClockinSearch = function (data) {
  this.list = d3.csv.parseRows(data).map(function (row) {
    return new Widget.LiveFeed.Clockin(row)
  })

  this.is_empty = function () {
    return this.count() === 0
  }

  this.count = function () {
    return this.list.length
  }

  /*
  given a set of filters, returns rows to render for those filters
  filters should be an object that looks like:
  {staff: STRING[], ins: BOOL, outs: BOOL, device_name: STRING?}
  */
  this.get_rows = function (filters) {
    var staff = filters.staff || []
    var clockins = filters.ins
    var clockouts = filters.outs
    var device_name = filters.device_name
    var all_statuses = (clockins && clockouts) || (!clockins && !clockouts) // show all if neither box or both boxes are ticked

    if (!staff.length && !device_name) {
      // show all employees
      // we should be able to cache/index these responses fairly easily
      if (all_statuses) {
        return this.list // short circuit. no employee chosen + showing all statuses.
      }

      if (clockins && !clockouts) {
        if (this._cache_clockin_list) { return this._cache_clockin_list }

        this._cache_clockin_list = _.filter(this.list, function (login) { return login.is_clockin() })
        return this._cache_clockin_list
      }

      if (clockouts && !clockins) {
        if (this._cache_clockout_list) { return this._cache_clockout_list }

        this._cache_clockout_list = _.filter(this.list, function (login) { return !login.is_clockin() })
        return this._cache_clockout_list
      }
    } else {
      // filter by a specific employee

      return _.filter(this.list, function (login) {
        var valid = true

        if (valid && filters.staff && filters.staff.length) {
          valid = _.indexOf(filters.staff, login.user_name) > -1
        }

        if (valid && device_name) {
          valid = login.device_name === device_name
        }

        if (valid && !all_statuses) {
          // also check login status if not showing all statuses
          if (clockins) {
            valid = login.is_clockin()
          } else if (clockouts) {
            valid = !login.is_clockin()
          }
        }

        return valid
      })
    }
  }
}

Widget.LiveFeed.Renderer = {
  render_rows: function (target, filters, skip_defer) {
    function render () {
      var rows = Widget.LiveFeed.ClockinList.get_rows(filters) || []

      var fragment = document.createDocumentFragment()
      _.each(rows, function (clockin) {
        fragment.appendChild(clockin.render())
      })

      target.find(".feed-list")
            .find(".clockin").remove().end()
            .prepend(fragment)
      target.find("[tooltip]").loadTooltips()
      target.find("img").on("error", function () { window.on_image_error(this) })

      Widget.LiveFeed.update_login_times(target)

      var show_more = target.find(".limited-results").show()
      var flavour_url = show_more.find(".employee-logins a")
      var flavour_text = show_more.find(".limited-text")

      if (filters.staff && filters.staff.length) {
        var name = filters.staff[0]

        if (Widget.LiveFeed.url_for(name)) {
          flavour_text.text(I18n.t("js.dashboard.widgets.live_feed.more_results"))
          flavour_url.attr("href", Widget.LiveFeed.url_for(name)).text(I18n.t("js.dashboard.widgets.live_feed.all_by", { name: name }))
        } else {
          flavour_text.text(I18n.t("js.dashboard.widgets.live_feed.no_results"))
          flavour_url.attr("href", "/logins").text(I18n.t("js.dashboard.widgets.live_feed.show_all"))
        }
      } else if (!rows.length) {
        flavour_text.text(I18n.t("js.dashboard.widgets.live_feed.no_results"))
        flavour_url.attr("href", "/logins").text(I18n.t("js.dashboard.widgets.live_feed.show_all"))
      } else {
        show_more.hide()
      }
    }

    if (skip_defer) {
      render()
    } else {
      _.defer(render)
    }
  },
  render_clockin: function (login) {
    var timesheet_url = window.Routes.current_timesheet_for_user_path(login.user_id, login.date())
    var roster_url = window.Routes.profile_time_and_attendance_user_path(login.user_id, { tab: "logins" })

    var all_clocks
    if (login.is_clockin()) {
      all_clocks = I18n.t("js.dashboard.widgets.live_feed.all_clock_ins")
    } else {
      all_clocks = I18n.t("js.dashboard.widgets.live_feed.all_clock_outs")
    }

    return crel("div", { class: ("feed-list-row clockin type-" + login.action), "data-name": login.user_name },
      crel("div", { class: "feed-list-row-left" },
        crel("div", { class: "profile-photo" },
          crel("img", { loading: "lazy", src: login.photo })
        )
      ),
      crel("div", { class: "feed-list-row-right" },
        crel("div", { class: "name-location-wrap" },
          crel("div", { class: "employee-name" }, login.user_name),
          crel("div", { class: "time-clock-location" }, login.device_name)
        ),
        crel("div", { class: "clock-event-time" },
          crel("div", { class: "time-digits" }, login.time_pp()),
          crel("div", { class: "time-ago-or-timesheet-link" },
            crel("span", { class: "time-ago", "data-time": login.unix_time() }),
            crel("a", { class: "timesheet-link", href: timesheet_url },
              I18n.t("js.dashboard.widgets.live_feed.timesheet"),
              crel("i", { class: "mi mi-chevron-right" })),
            crel("span", { class: "divider" }, "|"),
            crel("a", { class: "clockins-link", href: roster_url },
              all_clocks,
              crel("i", { class: "mi mi-chevron-right" }))
          )
        )
      )
    )
  },
}

Widget.LiveFeed.init = function (options) {
  // globally accessible
  Widget.LiveFeed._headers = options.headers
  Widget.LiveFeed._original_url = options.url
  Widget.LiveFeed._users_url = options.users_url

  var $target = $("#widget-live-feed").hide()
  var $widget = $target.parents(".widget")

  var callback = function (widget, data) {
    Widget.LiveFeed.ClockinList = new Widget.LiveFeed.ClockinSearch(data)

    if (!Widget.LiveFeed.ClockinList.is_empty()) {
      // get users so we can start setting up the staff filter
      $.getJSON(Widget.Helpers.applyUserIdToUrl(Widget.LiveFeed._users_url)).done(Widget.LiveFeed.init_employee_filter)

      // start by showing all rows
      Widget.LiveFeed.Renderer.render_rows($target, {}, true)

      // once rendered, display initially disabled attributes
      $target.find("[disabled]").prop("disabled", false)

      // once we get the initial small dataset, go back and request a larger one, but only if the initial one returned a full result list
      // if it didn't we assume that there's less than x clockins ever, so there's no real value in getting a more restricted (by date) list
      if (Widget.LiveFeed.ClockinList.count() >= Widget.LiveFeed.INITIAL_COUNT) {
        widget.secondary_xhr = $.ajax({
          type: "GET",
          url: Widget.Helpers.applyUserIdToUrl(Widget.LiveFeed._original_url),
        }).done(function (data) {
          Widget.LiveFeed.ClockinList = new Widget.LiveFeed.ClockinSearch(data)
          Widget.LiveFeed.Renderer.render_rows($target, Widget.LiveFeed.get_filters($target))
        })
      }

      var device_names_filtering = $(".widget-filter")
      device_names_filtering.off("click").on("click", ".live-feed-filter", function () {
        device_names_filtering.find(".selected-device").text($(this).text())
                                                       .attr("data-device-name", $(this).data("device-name") || "")

        $(this).data("device-name") ? $.cookie(Widget.LiveFeed.LiveFeedCookieKey(), $(this).data("device-name")) : $.removeCookie(Widget.LiveFeed.LiveFeedCookieKey())

        Widget.LiveFeed.Renderer.render_rows($target, Widget.LiveFeed.get_filters($target))
      })
    }

    Widget.LiveFeed.disable_employee_filter_if_empty($widget)

    // Naughty css hack as per Nick's request
    $widget.find(".dropdown-menu").css("max-height", $target.parents(".widget-body").height() * 1)
  }

  $target.widget({
    interval: options.interval,
    url: Widget.Helpers.applyUserIdToUrl(querystring_set(options.url, "count", Widget.LiveFeed.INITIAL_COUNT)),
    callback: callback,
    format: options.format,
    name: "live_time_clock_feed",
    empty_data: function (data) {
      return (new Widget.LiveFeed.ClockinSearch(data)).is_empty(data)
    },
  })
}

Widget.LiveFeed.update_login_times = function (target) {
  if (!$.contains(document, target[0])) { return } // do nothing if removed from DOM (page unloaded)

  var $list = target.find(".feed-list")
  if ($list.length) {
    $list.find(".time-ago").each(function () {
      var ele = $(this)
      var date = moment.unix(ele.attr("data-time"))

      ele.html("(" + date.fromNow() + ")")
    })
    setTimeout(function () { Widget.LiveFeed.update_login_times(target) }, 60 * 1000) // every minute
  }
}

Widget.LiveFeed.get_filters = function (target) {
  var login_checked = $.cookie("dashboard_live_feed_checkbox_in") === "true"
  var logout_checked = $.cookie("dashboard_live_feed_checkbox_out") === "true"
  var staff = target.find("input[type=text]").val()
  var device_name = target.closest(".widget").find(".selected-device").data("deviceName") || $.cookie(Widget.LiveFeed.LiveFeedCookieKey())

  $(".widget-filter").find(".selected-device").text($.cookie(Widget.LiveFeed.LiveFeedCookieKey()))
  target.find("input[value=login]").prop("checked", login_checked)
  target.find("input[value=logout]").prop("checked", logout_checked)
  return { staff: staff.length ? [staff] : [], ins: login_checked, outs: logout_checked, device_name: device_name }
}

Widget.LiveFeed.url_for = function (name) {
  if (Widget.LiveFeed.user_objects_for_search) {
    return Widget.LiveFeed.user_objects_for_search[name]
  }
}

Widget.LiveFeed.disable_employee_filter_if_empty = function (widget) {
  widget.find(":checkbox").prop("disabled", Widget.LiveFeed.ClockinList.is_empty())
  widget.find("input[type=text]").prop("disabled", Widget.LiveFeed.ClockinList.is_empty())
}

Widget.LiveFeed.init_employee_filter = function (user_data) {
  Widget.LiveFeed.user_objects_for_search = user_data

  var $target = $("#widget-live-feed")
  var $widget = $target.parents(".widget")

  // Checkbox filters
  Widget.LiveFeed.disable_employee_filter_if_empty($widget)
  $widget.off("click").on("click", ":checkbox", function () {
    $.cookie("dashboard_live_feed_checkbox_in", $target.find("input[value=login]").is(":checked"))
    $.cookie("dashboard_live_feed_checkbox_out", $target.find("input[value=logout]").is(":checked"))
    Widget.LiveFeed.Renderer.render_rows($target, Widget.LiveFeed.get_filters($target))
  })

  // Name filter
  $widget.find("input[type=text]").extendedTypeahead({ source: _.keys(user_data) }).on("change keyup", function (e) {
    // always search on a change (name clicked, or enter pressed)
    // search on a keyup if the textbox is empty (selection blanked)
    if (e.type === "change" || (e.type === "keyup" && !this.value)) {
      Widget.LiveFeed.Renderer.render_rows($target, Widget.LiveFeed.get_filters($target))
    }
  })
}
;
Widget.MyTimeclocks = Widget.MyTimeclocks || {}
Widget.MyTimeclocks.init = function (options) {
  var $target = $("#mytimeclocks")
  var callback = function () {
    $target.find("[tooltip]").loadTooltips()
    $target.find(".return-time-clock").on("click", function (e) {
      $(e.target).parents(".device-list-row").fadeOut("fast")
    })
  }
  $target.widget({ interval: options.interval, url: options.url, callback: callback, name: "our_time_clocks" })
}
;
Widget.OrgSummary = Widget.OrgSummary || {}
Widget.OrgSummary.init = function (options) {
  var $target = $("#orgsummary")

  function attachSpinner () {
    $("#load_users").replaceWith("<div id='load_users' style='display: flex; justify-content: center; overflow-y: hidden; margin-top: 6.5em; '/>")
    window.LH.Spin.start($("#load_users")[0], { size: "xl" }).setAttribute("class", "spin-target")
  }

  var callback = function () {
    $target.find(".nav-tabs").on("click", "li", function () {
      $(".active").removeClass("active")
      $(this).addClass("active")
      $(".tab-pane").addClass("active")
    })

    $target.find("[tooltip]").loadTooltips()

    var $load_data_links = $("#loading_circle a")
    $load_data_links.each(function () {
      $(this).click(function () {
        attachSpinner()
      })
    })
  }

  $target.widget({ interval: options.interval, url: options.url, callback: callback, name: "whos_using" })
}

;
Widget.RightNow = Widget.RightNow || {}
Widget.RightNow.init = function (options) {
  var $target = $("#rightnow")
  var $widget = $target.parents(".widget")
  var currentDepartmentId = options.department_id
  var currentLocationId = options.location_id
  var allTeams
  var COOKIE_KEY = "dashboard_right_now" + window.current_user.org_id
  var cookie

  try {
    cookie = JSON.parse($.cookie(COOKIE_KEY))
  } catch (error) {
    // Cookie considered invalid. Removing cookie.
    $.removeCookie(COOKIE_KEY)
  }

  $widget.find(".widget-filter").hide()

  // rule has no documentation on how to fix https://github.com/dgraham/eslint-plugin-jquery/blob/master/rules/no-load.js
  // eslint-disable-next-line jquery/no-load
  $("#rightNowTeamFilterJS").load(options.filter_url, function () {
    $(".right_now_filter").off("click").on("click", function (e) {
      $(e.target).parents(".widget-filter").toggleClass("open").find("span.text").html($(e.target).html())

      var base_url = $(e.target).data("url")
      var query_arr = []
      var query_string = ""

      if ($(e.target).data("department_id") || $(e.target).data("location_id")) {
        currentDepartmentId = $(e.target).data("department_id")
        currentLocationId = $(e.target).data("location_id")
        if (currentDepartmentId) {
          $.cookie(COOKIE_KEY, JSON.stringify({ department_id: currentDepartmentId }))
        }
        if (currentLocationId) {
          $.cookie(COOKIE_KEY, JSON.stringify({ location_id: currentLocationId }))
        }
      } else {
        currentDepartmentId = currentLocationId = null
        $.removeCookie(COOKIE_KEY)
      }

      if (currentDepartmentId) { query_arr.push("department_id=" + currentDepartmentId) }
      if (currentLocationId) { query_arr.push("location_id=" + currentLocationId) }

      allTeams = $(e.target).data("all_teams")
      if (allTeams) { query_arr.push("all_teams=" + true) }

      if (!_.isEmpty(query_arr)) {
        query_string = "?" + query_arr.join("&")
      }

      $target.widget("refresh_widget", base_url + query_string, true)

      return false
    })
  })

  var callback = function () {
    $(".photo-popover").extendedPopover({ placement: "top", html: true })
    $(".showhide").showHideList({ gradient_selector: ".showmorelessgradient" })
    $target.find("[tooltip]").loadTooltips()

    $(".accordion").off("show hide").on("show hide", function (e) {
      // check whether event was triggered by clicking to toggle accordion.
      // required because show/hide event on the tooltip bubbles up to the '.accordion'
      // if we ever make the move to bootstrap 3, hook onto the 'collapse' event instead of show/hide
      if ($(e.target).hasClass("accordion-body")) {
        $(this).parent().parent().toggleClass("active")
        $(this).find(".toggle-icon").toggleClass("mi-keyboard-arrow-down mi-keyboard-arrow-up")
      }
    })

    // Naughty css hack as per Nick's request
    $widget.find(".widget-filter").fadeIn()
    $widget.find(".dropdown-menu").css("max-height", $target.parents(".widget-body").height() * 1)
  }

  if (cookie) {
    options.url += "?" + $.param(cookie)
  }

  $target.widget({ interval: options.interval, url: options.url, callback: callback, name: "whos_in_today" })
}
;
Widget.TaskList = Widget.TaskList || {}
Widget.TaskList.init = function (options) {
  var $target = $("#tasklist")
  
  var callback = function () {
    $target.find("[tooltip]").loadTooltips()
  }

  $target.widget({ interval: options.interval, url: options.url, callback: callback, name: "task_list" })
}
;


(function () {
  function Ruleable(type, container, steps, defaults) {
    this.is_manual = true
    this.is_all_purpose = false
    this.is_valid = false
    this.defaults = defaults || {}
    this.container = $(container)
    var $steps = this.container.find(".step")
    if (steps.length < 1) {
      throw new Error("You must provide at least one step")
    }

    // allowance or award rules without the trailing S
    this.type = type

    this.steps = _.chain(steps)
      .map(function (step, i) {
        return new Ruleable.Step(step, this, $steps.eq(i))
      }, this)
      .compact()
      .value()

    if ($steps.length > this.steps.length) {
      throw new Error("You must provide a step definition for each step")
    }

    this.update_step_numbers() // step numbers reflect the number of hidden steps
    this.prepare_params() // attach the params to the object
    this.attach_event_listeners() // listen for events on each step
  }

  Ruleable.prototype.attach_event_listeners = function attach_event_listeners() {
    // iterate through the steps.
    // for each selector, attach a click, change, blur handler that calls that steps validator
    _.each(this.steps, function (step) { step.attach_event_listener() })
  }

  Ruleable.prototype.update_props = function update_props(key1, value1, key2, value2) {
    this[key1] = value1
    this[key2] = value2
    var value = value1 || value2
    _.each(this.steps, function (step) {
      if (typeof step["is_updated"] === "function") {
        var fn = step["is_updated"].bind(step)
        fn(value)
      }
    })
  }

  Ruleable.prototype.update_step_numbers = function update_step_numbers() {
    _.chain(this.steps)
      .filter(function (step) { return !step.hidden }) // This is where we toggle the visibility of steps depending on the Allowance Type
      .each(function (step, i) {
        step.element.find(".step-number-circle .character").first().text(i + 1)
      })
  }

  Ruleable.prototype.close_all_accordions = function close_all_accordions() {
    _.each(this.steps, function (step) { if (!step.checkbox_accordion) { step.close_accordion() } })
  }

  Ruleable.prototype.prepare_params = function prepare_params() {
    _.each(this.steps, function (step) { step.update_state() })
  }

  Ruleable.prototype.next_incomplete = function next_incomplete(step) {
    var current_step = this.steps.indexOf(step)
    var next_incomplete = _.find(this.steps.slice(current_step + 1), function (step) {
      return !step.hidden && !step.element.hasClass("complete") && !step.checkbox_accordion// get the next invalid step
    })
    this.close_all_accordions()
    if (next_incomplete) {
      next_incomplete.open_accordion()
    }
  }

  Ruleable.prototype.refresh_step_status = function refresh_step_status(manual, all_purpose) {
    _.each(this.steps, function refresh_step_status_for_step(step) {
      if (manual || all_purpose) {
        if (!step.hidden) {
          step.opened = true
        }
      } else {
        step.opened = true
      }

      step.element.find("[data-shows]").trigger("update")
      step.element.find(":input").trigger("change")

      step.validate()
    })

    this.update_props("is_manual", manual, "is_all_purpose", all_purpose)
  }

  Ruleable.prototype.prepare_for_post = function prepare_for_post() {
    _.chain(this.steps)
      .each(function (step) {
        step.set_input_values()
      })
    if (this.is_manual) {
      _.chain(this.defaults)
        .keys()
        .each(function (d) { this.container.find("#" + this.type + "_" + d).val(this.defaults[d]) }.bind(this))
    }
  }

  Ruleable.prototype.validate = function validate() {
    this.is_valid = true
    var invalid_steps = []
    _.each(this.steps, function (step) {
      if ((!step.opened || !step.is_valid) && !step.hidden && !step.checkbox_accordion) { // invalid steps that are not hidden
        invalid_steps.push(step)
        this.is_valid = false
      }
    }.bind(this))

    var tooltip_wrapper = this.container.find(".tooltip-wrapper-js")

    if (this.is_valid) {
      tooltip_wrapper.tooltip("destroy")
    } else {
      var invalid_steps_list_args = ["ul", { class: "form-tooltip-list" }].concat(_.map(_.pluck(invalid_steps, "name"), function (name) {
        return crel("li",
          crel("span", window.sanitizeTextForHtml(name)))
      }))

      var invalid_steps_list = crel.apply(crel, invalid_steps_list_args)

      var new_title = crel("div",
        crel("div", { class: "form-tooltip-list-title" },
          crel("i", { class: "mi mi-error" }),
          I18n.t("js.awards.form.incomplete_field")
        ),
        crel("div", invalid_steps_list)
      )
      var current_tooltip = tooltip_wrapper.data("tooltip")
      var current_title = current_tooltip ? current_tooltip.options.title : null

      if (new_title !== current_title) {
        if (current_tooltip) {
          current_tooltip.options.title = new_title
        } else {
          tooltip_wrapper.tooltip("destroy")
          tooltip_wrapper.tooltip({ title: new_title, trigger: "hover", html: true })
        }
      }
    }

    this.container.find(".btn-action-primary").prop("disabled", !this.is_valid) // disable the button if !this.is_valid
  }

  window.Ruleable = Ruleable
}())
;
(function () {
  function getName ($this) {
    if ($this.data("name") === "" || !$this.prop("name")) {
      return ""
    }
    if ($this.data("name")) {
      return $this.data("name") + ": "
    }
    return $this.prop("name").replace(/(award|allowance)\[(\w+)\]/, "$2").split("_").map(function (str) { return str.charAt(0).toUpperCase() + str.substring(1) }).join(" ") + ": "
  }

  function Step (step, rule, element) {
    if (!step.name) {
      throw new Error("Step does not have a name")
    }

    this.validator = step.validator || _.constant(true)
    this.title_updater = step.title_updater || _.constant(false)
    this.after_state_update = step.after_state_update || _.noop
    this.change_advanced_tab = step.change_advanced_tab || _.noop
    this.element = element
    this.rule = rule
    this.state = {}
    this.hidden = this.element.hasClass("hide")
    this.is_open = this.element.hasClass("active")
    this.opened = this.element.hasClass("active")
    this.is_valid = false
    this.is_advanced = false // true if 'Advanced Options' is open
    this.title = ""
    this.is_multi_accordion = this.element.has(".multi-select-accordion")

    _.chain(step).keys(step).each(function (key) {
      this[key] = step[key]
    }.bind(this))

    this.on_form_load && this.on_form_load()
    this.update_state()
  }

  Step.prototype.update_state = function update_state () {
    this.state = _.extend(
      this.state, _.object(
        this.element
          .find('[name*="' + this.rule.type + '"]')
          .map(function (i, el) {
            var $el = $(el)
            var val = window.utils.get_value($el)
            return [[$el.attr("name").replace(/(award|allowance)\[(\w+)\]/, "$2"), val]]
          })
          .get()
      ),
      _.object(
        this.element
          .find("[data-key]")
          .map(function (i, el) {
            var $el = $(el)
            var val = window.utils.get_value($el)
            return [[$el.data("key"), val]]
          })
          .get()
      )
    )

    this.after_state_update()
  }

  Step.prototype.update_form_controls = function update_form_controls () {
    var type = this.rule.type
    this.element.find("[data-control]").each(function () {
      var $this = $(this)
      var val = window.utils.get_value($this)
      var control = $(String("#" + type + "_" + $this.data("control")))

      control.val(val)
    })
  }

  Step.prototype.set_input_values = function set_input_values () {
    _.chain(this.state)
      .keys()
      .each(function (key) {
        if (this.state[key] === false) { // this piece is not allowed
          var value = this.rule.defaults.hasOwnProperty(key) ? this.rule.defaults[key] : 0
          var type = this.rule.type
          this.element.find("#" + type + "_" + key).val(value)
        }
      }.bind(this))
  }

  Step.prototype.update_inputs_from_state = function update_inputs_from_state () {
    _.chain(this.state)
      .keys()
      .map(function (key) {
        var type = this.rule.type
        this.element.find("#" + type + "_" + key).val(this.state[key])
      }.bind(this))
  }

  Step.prototype.on_event = function on_event () {
    this.update_form_controls()
    this.update_state()
    this.validate()
  }

  Step.prototype.close_accordion = function close_accordion () {
    this.is_open = false

    var has_closed = this.has_closed
    if (has_closed) { has_closed = has_closed.bind(this) }

    if (this.is_closing) {
      this.is_closing()
    }
    this.element.removeClass("active")
    this.element.find(".accordion-body").slideUp(200, function () {
      $(this).removeClass("in")

      if (has_closed) {
        has_closed()
      }
    })

    this.element.children(".multi-select-accordion").removeClass("active")

    this.update_completed()
  }

  Step.prototype.open_accordion = function open_accordion () {
    if (this.is_opening) {
      this.is_opening()
    }

    var has_opened = this.has_opened
    if (has_opened) { has_opened = has_opened.bind(this) }

    if (this.is_multi_accordion) {
      this.element.children(".multi-select-accordion").addClass("active")
    }
    this.is_open = true
    this.opened = true
    this.element.addClass("active")
    this.element.find(".accordion-body").slideDown(200, function (el) {
      $(el).addClass("in")
      // one of the dirtiest hacks
      var selectWrapper = this.element.find(".filter-select-wrapper")
      if (selectWrapper.length > 0) {
        selectWrapper.each(function () {
          $(this).data("close")()
        })
        var footer = this.element.find(".filter-select-footer")
        if (parseInt(footer.css("max-height")) === 0) {
          footer.css("max-height", 6 * 36)
        }
      }

      if (has_opened) {
        has_opened()
      }
    }.bind(this))

    this.element.find("[data-focus]").focus() // focus the first element available

    this.validate()
  }

  Step.prototype.uncheck_step = function (e) {
    if (this.is_unchecked && (this.element.hasClass("complete") || this.element.hasClass("active"))) {
      this.is_unchecked()
      this.on_event()
      if (!this.element.hasClass("active")) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  Step.prototype.header_click = function header_click (e) {
    if (!this.checkbox_accordion && (this.is_open || $(e.target).hasClass("button"))) {
      return null // ignore the click if the header is already open
    }
    if (this.checkbox_accordion && this.is_open) {
      this.close_accordion()
    } else {
      this.rule.close_all_accordions()
      this.open_accordion()
    }
  }

  Step.prototype.done_click = function done_click (e) {
    if (this.is_valid) {
      this.rule.next_incomplete(this)
    }
  }

  Step.prototype.attach_event_listener = function attach_event_listener () {
    var EVENTS = "change blur keyup select-change"
    var handler = this.on_event.bind(this)

    this.element.on("click", handler)
    this.element.find("input").on(EVENTS, handler)
    this.element.find("select").on(EVENTS, handler)
    this.element.find(".accordion-heading").click(this.header_click.bind(this))
    this.element.find(".button").click(this.done_click.bind(this))
    this.element.find(".parent-checkbox-js").click(this.uncheck_step.bind(this))

    // TODO: this is very magical - is there a safer way?
    // runs functions in app/assets/javascripts/ruleable/Step.initialisers.js
    _.chain(Object.getPrototypeOf(this))
      .keys()
      .filter(function (key) {
        return /initialise_/.test(key)
      })
      .each(function (key) {
        this[key]()
      }.bind(this))
  }

  Step.prototype.validate = function validate () {
    this.is_valid = true
    if (!this.validator()) {
      this.is_valid = false
    }

    this.element.find("[data-required]").each(function (i, el) {
      var $el = $(el)
      var val = window.utils.get_value($el)
      var res = Step.passes_as_required(val)
      if (!res) {
        this.is_valid = false
      }
      $el.toggleClass("invalid", !res)
    }.bind(this))

    this.element.find("[data-required-if]").each(function (i, el) {
      var $el = $(el)
      var $ifEl = $($el.data("required-if"))
      if (!$ifEl.hasClass("selected") && !$ifEl.hasClass("open")) {
        return $el.removeClass("invalid") // short-circuit here
      }
      var val = window.utils.get_value($el)
      var res = Step.passes_as_required(val)
      if (!res) {
        this.is_valid = false
      }
      $el.toggleClass("invalid", !res)
    }.bind(this))

    this.element.find("[data-max]").each(function (i, el) {
      var $el = $(el)

      if (!$el.is(":visible")) {
        return // don't need to validate if this field isn't visible
      }

      var val = parseFloat($el.val() || $el.attr("value"))
      var max = parseFloat($el.data("max"))

      if (val > max) {
        this.is_valid = false
      }
    }.bind(this))

    this.element.find("[data-min]").each(function (i, el) {
      var $el = $(el)

      var val = parseFloat($el.val())
      var max = parseFloat($el.data("max"))

      if (val > max) {
        this.is_valid = false
      }
    }.bind(this))

    this.element.find("[data-min]:visible").each(function (i, el) {
      var $el = $(el)

      var val = parseFloat($el.val() || $el.attr("value"))
      var min = parseFloat($el.data("min"))

      if (val < min) {
        this.is_valid = false
      }
    }.bind(this))

    this.element.find(".button").toggleClass("enabled", this.is_valid)

    this.update_completed()
    this.update_title()
    this.rule.validate()
  }

  Step.prototype.update_completed = function update_completed () {
    if (!this.is_open && this.is_valid && this.opened) {
      this.element.addClass("complete")
      this.element.find(".multi-select-accordion").addClass("complete")
    } else {
      this.element.removeClass("complete")
      this.element.find(".multi-select-accordion").removeClass("complete")
    }
  }

  Step.prototype.get_title = function get_title () {
    var step = this
    return step.element.find("[data-title]")
      .map(function () {
        var $this = $(this)

        if ($this.data("title") === true) {
          if (this.value) {
            return getName($this).concat(this.value)
          } else {
            return null
          }
        }

        if (!$this.hasClass("selected")) {
          return null
        }

        if ($this.data("title-from-shows")) {
          var element_to_read_from
          if ($this.data("value-provider")) {
            element_to_read_from = step.element.find($this.data("value-provider"))
          } else {
            element_to_read_from = step.element.find($this.data("shows")).find("input,select")
          }
          var shows_val = element_to_read_from.val()
          if (shows_val) {
            return I18n.t("js.awards.form.title_and_hours", { title: $this.data("title"), hours: shows_val })
          }
        }

        return $this.data("title")
      })
      .get()
      .join(", ")
  }

  Step.prototype.update_title = function update_title () {
    this.element.find(".line").toggleClass("show-line", this.is_valid)
    if (!this.is_valid) {
      return this.element.find(".selection").text("").hide()
    }
    var from_updater = this.title_updater()
    var title
    if (from_updater !== false) {
      title = from_updater
    } else {
      title = this.get_title()
    }
    this.element.find(".selection").text(title).show()
  }

  window.Ruleable.Step = Step
}())
;
(function () {
  var Step = window.Ruleable.Step

  Step.Constants = {
    INVALID_MESSAGE_WRAPPER: "invalid-message-wrapper",
    INVALID_MESSAGE: "invalid-message",
  }

  Step.passes_as_required = function passes_as_required (value) {
    if (value instanceof Array) {
      return value.length > 0
    }

    if (typeof value === "string") {
      return value !== ""
    }

    return !!value
  }

  Step.create_invalid_message = function create_invalid_message (message) {
    var crel = window.crel

    return crel("div",
      { class: Step.Constants.INVALID_MESSAGE_WRAPPER },
      crel("span", { class: Step.Constants.INVALID_MESSAGE }, message)
    )
  }
}())
;
(function () {
  var Step = window.Ruleable.Step

  Step.prototype.initialise_advanced_options = function initialise_advanced_options () {
    var advanced_link = this.element.find(".advanced-link")

    if (advanced_link.length < 1) {
      return null // we can ignore this
    }

    var advanced_container = this.element.find(".advanced-options")
    if (!advanced_link.hasClass("open")) {
      advanced_container.hide()
    }

    advanced_link.click(function () {
      this.change_advanced_tab(this.is_advanced)
      this.is_advanced = !this.is_advanced
      advanced_link.toggleClass("open")

      advanced_container.slideToggle(200)
    }.bind(this))
  }

  Step.prototype.initialise_radio_groups = function init_radio_groups () {
    this.element.find("[data-radio-group]").click(function () {
      // find this elements group
      // deselect all of that group
      // select this element
      var $this = $(this)
      if ($this.hasClass("selected") || $this.hasClass("disabled")) { return null }
      var radio_groups = $this.data("radio-group").split(",")
      _.each(radio_groups, function (group) {
        var el = $('[data-radio-group*="' + group + '"]')
        el.each(function (i, element) {
          var $el = $(element)
          if (element === $this.get(0)) { return null } // don't act on the original element
          // in case we match more than one type of element
          if ($el.is(":input")) {
            $el.prop("checked", false)
          } else {
            $el.removeClass("selected")
          }

          $el.trigger("update")
        })
      })

      $this.addClass("selected")
    })
  }

  Step.prototype.initialise_disabled_conditions = function initialise_disabled_conditions () {
    this.element.find("[data-disabled-if]").each(function (i, el) {
      var $el = $(el)
      var disabler = $($el.data("disabled-if"))

      function handleDisable () {
        var state = disabler.hasClass("selected") || disabler.prop("checked") || disabler.prop("selected")
        if (state) {
          // disabling the element
          $el.data("prev-val", $el.hasClass("selected") || $el.prop("checked") || $el.prop("selected"))
          $el.removeClass("selected")
          $el.prop("selected", false)
          $el.prop("checked", false)
          $el.prop("disabled", true)
          $el.addClass("disabled")
        } else {
          var prev_value = $el.data("prev-val") || false
          $el.toggleClass("selected", prev_value)
          $el.prop("selected", prev_value)
          $el.prop("checked", prev_value)
          $el.prop("disabled", false)
          $el.removeClass("disabled")
        }
      }

      disabler.on("click change", handleDisable)

      handleDisable() // on boot
    })
  }

  Step.prototype.initialise_toggles = function initialise_toggles () {
    this.element.find(".toggle-js").click(function () {
      $(this).toggleClass("selected")
    })
  }

  Step.prototype.initialise_option_box_inputs = function initialise_option_box_inputs () {
    function updateOptionBox (box) {
      if (!box.hasClass("selected")) {
        if (box.data("value-provider")) {
          that.element.find(box.data("value-provider")).val("")
        } else {
          box.next(".stretch-input").find("input").val("")
        }
      }
    }

    var that = this
    that.element.find(".option-box").click(function () {
      var box = $(this)
      updateOptionBox(box)

      box.siblings(".option-box").each(function () { updateOptionBox($(this)) })
      if (box.attr("data-radio-group")) {
        that.element.find("[data-radio-group=" + box.attr("data-radio-group") + "]").not(box).each(function () { updateOptionBox($(this)) })
      }
    })
  }

  Step.prototype.initialise_selective_shows = function initialise_selective_shows () {
    this.element.find("[data-shows]").each(function (i, el) {
      var $this = $(this)
      _.each($this.data("shows").split(","), function (selector) {
        var shows = $(selector)
        $this.on("click update", function () {
          var showee = $($this.data("shows"))
          if ($this.hasClass("selected")) {
            showee.slideDown(200, function () {
              showee.find("input").first().focus()
            })
          } else {
            showee.slideUp(200)
          }
        })
        shows.hide()
      })
    })
  }

  Step.prototype.initialise_tooltips = function initiailise_tooltips () {
    this.element.find("[data-message]").hover(function mouse_in () {
      var $this = $(this)
      if (!$this.hasClass("invalid")) {
        return null
      }

      var message = $this.data("message")
      var invalid_message_el = Step.create_invalid_message(message)

      $this.after(invalid_message_el)
    }, function mouse_out () {
      // just try and close the tooltip
      $(this).next("." + Step.Constants.INVALID_MESSAGE_WRAPPER).remove()
    })
  }

  Step.prototype.initialise_tabbed_views = function initialise_tabbed_view () {
    this.element.find(".tabbed-view").tabbedView()
  }
})()
;




window.ImportResults = window.ImportResults || {}

_.extend(window.ImportResults, {
  init: function init () {
    var userSelectedToggleAction = _.compose(
      window.ImportResults.DOM.updateImportCount,
      window.ImportResults.DOM.toggleSelected,
      window.ImportResults.DOM.getUserRow,
      window.ImportResults.DOM.updateToggleView
    )

    $(window.ImportResults.Consts.$userSelectedToggle).click(function () {
      return userSelectedToggleAction(this)
    })

    var submitDeactivateAction = _.compose(
      window.ImportResults.Ajax.submitDeactivateUsers,
      window.ImportResults.Core.getAllSelectedUserIds,
      window.ImportResults.DOM.getAllUserRows
    )

    $(window.ImportResults.Consts.$submitDeactivate).click(function () {
      return submitDeactivateAction(this)
    })
  },

  DOM: {
    /**
     * @param {element} userToggle to render new state
     */
    updateToggleView: function updateToggleView (userToggle) {
      window.ImportResults.DOM.getUserToggleLabel(userToggle).each(function (i, el) {
        $(el).text(/Undo/.test($(el).text()) ? "Don't Import" : "Undo")
      })

      return userToggle
    },

    /**
     * @param {element} userToggle element to find label of
     * @returns {jquery$selector} label
     */
    getUserToggleLabel: function getUserToggleLabel (userToggle) {
      return $(userToggle)
        .children(window.ImportResults.Consts.$userSelectedToggleLabel)
    },

    /**
     * @param {element} userToggle a user deactivate toggle pressed
     */
    getUserRow: function getUserRow (userToggle) {
      return $(userToggle)
        .closest(window.ImportResults.Consts.$userRow)
    },

    /**
     * @param {jquery$selector} $userRow a user row to be toggled as selected
     */
    toggleSelected: function toggleSelected ($userRow) {
      var nextState = $userRow.attr(window.ImportResults.Consts.selected) === "false"

      $userRow
        .attr(window.ImportResults.Consts.selected, nextState)

      return nextState
    },

    /**
     * @param {number} isLess the new count
     */
    updateImportCount: function updateImportCount (isLess) {
      var count = $(window.ImportResults.Consts.$userImportCount)

      return count
        .text(Number(count.text()) + (isLess ? -1 : +1))
    },

    /**
     * gets all user rows
     * @returns {jquery$selector}
     */
    getAllUserRows: function getAllUserRows () {
      return $(window.ImportResults.Consts.$userRow).toArray()
    },

  },

  Core: {
    /**
     * @param {element[]} userRows to retrieve ids of selected
     */
    getAllSelectedUserIds: function getAllSelectedUserIds (userRows) {
      return userRows.reduce(function (ids, el) {
        return $(el).attr(window.ImportResults.Consts.selected) === "true"
          ? ids.concat([$(el).attr(window.ImportResults.Consts.userId)])
          : ids
      }, [])
    },
  },

  Ajax: {
    /**
     * @param {number[]} userIds to deactivate
     */
    submitDeactivateUsers: function submitDeactivateUsers (user_ids) {
      var $form = $(window.ImportResults.Consts.$userForm)

      user_ids.forEach(function (id) {
        $form.append(
          $('<input name="user_ids[]" value="' + id + '" hidden />')
        )
      })

      return $form.submit()
    },
  },

  Consts: {
    // data props
    selected: "data-selected",
    userId: "data-user-id",
    // dom node hooks
    $userImportCount: "#js-user-import-count",
    $userRow: ".js-imported-user",
    $userSelectedToggle: ".js-user-selected-toggle",
    $userSelectedToggleLabel: ".js-user-selected-toggle-label",
    $submitDeactivate: ".js-submit-deactivate",
    $userForm: "#js-user-form",
  },
})
;
/**

 */

;
window.LeaveBalanceReport = function () {
  $(".generate-report-js").on("click", function (event) {
    var successCallback = function (response) {
      location.reload()
    }

    $.ajax({
      type: "POST",
      url: Routes.generate_report_leave_balances_path(),
      success: successCallback,
    })
  })
}
;
window.WageCompareReport = function (csv_data, compared_cost_csv_data) {
  // Here we just toggle between the 'state' of the dropdown. If it's expanded, shrink, else expand.
  var manage_row = function (action, dataRow, breakdownRow) {
    if (action === "EXPAND") {
      dataRow.addClass("selected")
      dataRow.css({ backgroundColor: "#F0F2F5" })

      breakdownRow.removeClass("inactive")
    } else if (action === "SHRINK") {
      dataRow.removeClass("selected")
      dataRow.css({ backgroundColor: "#FFFFFF" })

      breakdownRow.addClass("inactive")
    }
  }

  var expand_hidden_content = function (e) {
    var rowContainer = $(e.target).parentsUntil("tbody").parent()
    var icon = rowContainer.find(".drop-down-arrow")
    var dataRow = rowContainer.find(".data_row")
    var breakdownRow = rowContainer.find(".breakdown_row")

    dataRow.hasClass("selected")
      ? manage_row("SHRINK", dataRow, breakdownRow)
      : manage_row("EXPAND", dataRow, breakdownRow)

    icon && icon.hasClass("mi-keyboard-arrow-down")
      ? icon.removeClass(" mi-keyboard-arrow-down") && icon.addClass("mi-keyboard-arrow-right")
      : icon.removeClass("mi-keyboard-arrow-right") && icon.addClass(" mi-keyboard-arrow-down")
  }

  var downloadCSV = function (unformattedCsv, filename) {
    if (window.URL) {
      var csvData = unformattedCsv.replace(/&quot;/g, '"')
      var blob = new Blob([csvData], { type: "application/csv;charset=utf-8" })
      var link = document.createElement("a")
      var url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  document.getElementById("exportCSV").addEventListener("click", function () { downloadCSV(csv_data, "wage_comparison.csv") })
  document.getElementById("exportComparedCostCSV").addEventListener("click", function () { downloadCSV(compared_cost_csv_data, "compared_costs.csv")})

  // For every dropdown_container, we need to add the 'expand functionality'
  $(".drop-down-arrow").on("click", expand_hidden_content)
}
;
window.User = window.User || {}

User.init = function (options) {
  User.Handlers.init_global_handlers()
}
;
User.Handlers = {
  init_global_handlers: function (options) {
    $(".approve-user-qualification-js").on("click", function (event) {
      event.stopPropagation()
      var $target = $(event.currentTarget)
      var userId = $target.data("user-id")
      var uqId = $target.data("uqid")
      var successCallback = function (response) {
        location.reload()
        $target.attr("disabled", true)
      }

      $.ajax({
        type: "PUT",
        url: Routes.approve_user_qualification_user_path({ id: userId, format: "json" }),
        data: { uq_id: uqId },
        success: successCallback,
      })
    })
    var t = function (key, options) {
      options = _.extend({ scope: "js.users" }, options || {})
      return I18n.t(key, options)
    }

    function addAlert(employee_name, employee_id, active) {
      var link = Routes.profile_personal_user_path(employee_id)
      $(".email-input").after('<br><p class="email-alert"> ' + t("form.add_new_staff"))
      $(".email-alert").append($("<br><p class='employee-name'><p>"))
      $(".employee-name").html("<a href=" + link + ">" + employee_name + "</a>")
      if (!active) {
        $(".email-alert").append(String(t("form.reactivate_staff")))
      }
    };

    $(".email-input").on("input", function () {
      var email = this.value
      if (/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(email)) {
        $.ajax({
          type: "GET",
          url: Routes.staff_search_path({ format: "json" }),
          data: {
            query: email,
            include_inactive: true,
          },
          success: function (result) {
            if (result.data.map(function (employee) { return employee.email }).includes(email)) {
              addAlert(result.data[0].name, result.data[0].id.split(",")[0], result.data[0].is_active)
              $(".email-input").css("border-color", "#FFA500")
              $(".email-input").on("input", function () {
                $(".email-alert").hide()
                $(".email-input").removeAttr("style")
              })
            }
          },
        })
      }
    })
  },
}
;


window.CopyShortcodes = function () {
  var t = function (key, options) {
    options = _.extend({ scope: "js.contract_templates.copy_shortcodes" }, options || {})
    return I18n.t(key, options)
  }

  var shortcodeCopyBtns = Array.from(document.querySelectorAll(".shortcode-copy"))

  shortcodeCopyBtns.forEach(function (btn) {
    btn.addEventListener("click", function (event) {
      if (navigator.clipboard) {
        var shortcode = event.target.dataset.shortcode
        var tooltipTarget = event.target.dataset.tooltip
        navigator.clipboard.writeText(shortcode)
        var tooltip = document.getElementById(tooltipTarget)
        if (tooltip) {
          tooltip.innerHTML = String(t("copied")) + " " + shortcode
        }
      } else {
        btn.remove()
      }
    })
    btn.addEventListener("mouseout", function (event) {
      var tooltipTarget = event.target.dataset.tooltip
      var tooltip = document.getElementById(tooltipTarget)
      if (tooltip) {
        tooltip.innerHTML = t("copy")
      }
    })
  })
}
;
document.addEventListener("DOMContentLoaded", function () {
  var contractEditor = document.getElementById("contract-editor")
  if (contractEditor) {
    var shortcodeRegex = /{{(.*?)}}/g
    window.initToggler(".contracts-nav button", ".contracts-page .content-target")
    window.initToggler(".preview-toggler button", ".new-contract-panel")
    window.initCorrectTab()
    window.initSelectContract(contractEditor.dataset, shortcodeRegex)
    window.initNewContractButton()
    window.initViewContractButton()
    window.viewContractPreview(contractEditor.dataset, shortcodeRegex)
    window.initPrintContract()
  }
  var contractFrame = document.getElementById("contract-frame")
  if (contractFrame) {
    window.initPrintContract()
  }
}, false)

window.initCorrectTab = function () {
  var currentTab = window.location.hash || "history"
  if (currentTab === "history") {
    return
  }
  var target = document.querySelector("button[data-contentid='" + currentTab.replace("#", "") + "']")
  if (target) {
    target.click()
  }
}

window.initToggler = function (buttonsSelector, contentTargetsSelector) {
  var buttonTargets = Array.from(document.querySelectorAll(buttonsSelector))
  var contentTargets = Array.from(document.querySelectorAll(contentTargetsSelector))

  buttonTargets.forEach(function (btn) {
    btn.addEventListener("click", function () {
      window.returnToEditorPanel(btn, buttonTargets, contentTargets)
    })
  })
}

// This injects the print styles when the print button is clicked and then removes
// them immediately after. Without this method the print styles apply everywhere on the
// route which is is bad news if they want to print, but not print the preview screen.

window.initPrintContract = function () {
  var printBtn = document.getElementById("print-contract")
  if (printBtn) {
    printBtn.addEventListener("click", function () {
      window.addEventListener("afterprint", window.removeContractPrintStyles)
      var contractEditor = document.getElementById("contract-editor")
      if (contractEditor) {
        document.head.insertAdjacentHTML("beforeend", '<div id="contract-print-styles"><style title="">@media print {body * {visibility: hidden;}#contract_template_content,#contract_template_content * {visibility: visible; overflow: visible; height: auto;}#contract_template_content{position: absolute;left: 0;top: 0;}}</style></div>')
      }
      var contractFrame = document.getElementById("contract-frame")
      if (contractFrame) {
        document.head.insertAdjacentHTML("beforeend", '<div id="contract-print-styles"><style title="">@media print {body * {visibility: hidden;}#contract-frame,#contract-frame * {visibility: visible; overflow: visible; height: auto; width: auto;}#contract-frame{position: absolute;left: 0;top: 0; padding: 0; margin:0; box-shadow: none;}}</style></div>')
      }
      window.print()
    })
  }
}

window.removeContractPrintStyles = function () {
  var printStyles = document.getElementById("contract-print-styles")
  if (printStyles) {
    printStyles.remove()
  }
  document.removeEventListener("afterprint", window.removeContractPrintStyles)
}

window.viewContractPreview = function (shortcodeValues, shortcodeRegex) {
  var previewBtn = document.getElementById("contract-preview-btn")
  var previewContent = document.getElementById("preview-content")
  previewBtn.addEventListener("click", function () {
    var invalidShortcodes = new Set()
    var contractContent = document.getElementById("contract_template_content").value
    var parsedContent = contractContent.replaceAll(shortcodeRegex, function (match) {
      var sanitisedMatch = match.replace("{{", "").replace("}}", "").trim()
      if (shortcodeValues[sanitisedMatch]) {
        return shortcodeValues[sanitisedMatch]
      } else {
        invalidShortcodes.add(match)
        return match
      }
    })
    previewContent.innerHTML = parsedContent
    var errorNotice = document.getElementById("invalid-shortcodes")
    window.renderErrorMessages(errorNotice, invalidShortcodes)
  })
}

window.initViewContractButton = function () {
  var contractData = document.querySelector(".new-contract-form-wrapper")
  var contractForm = document.querySelector(".new-contract-builder-wrapper")
  var viewContractBtns = Array.prototype.slice.call(document.querySelectorAll(".view-contract-btn"))
  contractData = contractData != null ? JSON.parse(contractData.dataset.contracts) : null
  var nameInput = document.querySelector(".name-input")
  var contractContent = document.querySelector(".contract-content")
  var contractsTable = document.querySelector(".contracts-table-section")
  var newContractSection = document.querySelector(".new-contract-section")
  var formTitle = document.querySelector(".form-title")
  if (viewContractBtns && contractData) {
    viewContractBtns.map(function (viewContractBtn) {
      viewContractBtn.addEventListener("click", function () {
        var currentContractData = contractData.filter(function (data) { return data.id.toString() === viewContractBtn.id.toString() })[0]
        contractForm.action = document.querySelector("input#create-url").value + "/" + viewContractBtn.id
        contractForm.method = "post"
        contractForm.childNodes[0].value = "put"
        nameInput.value = currentContractData.name
        contractContent.value = currentContractData.content
        formTitle.innerHTML = I18n.t("js.contract_templates.confirmations.edit_contract")
        contractsTable.classList.add("display-none")
        newContractSection.classList.remove("display-none")
      })
    })
  }
}

window.initNewContractButton = function () {
  var contractsTable = document.querySelector(".contracts-table-section")
  var newContractSection = document.querySelector(".new-contract-section")
  var newContractBtn = document.querySelector(".new-contract-btn")
  var contractContent = document.querySelector(".contract-content")
  var backBtn = Array.from(document.querySelectorAll(".new-contract-section .back-btn"))
  var nameInput = document.querySelector(".name-input")
  var contractForm = document.querySelector(".new-contract-builder-wrapper")
  var formTitle = document.querySelector(".form-title")
  if (newContractBtn) {
    newContractBtn.addEventListener("click", function () {
      contractForm.action = document.querySelector("input#create-url").value
      contractForm.method = "post"
      contractForm.childNodes[0].value = "post"
      nameInput.value = ""
      contractContent.value = "<div>Dear {{name}}</div><br><div>{{date}}</div><br><div>{{org_name}}</div>"
      formTitle.innerHTML = I18n.t("js.contract_templates.confirmations.new_contract")
      contractsTable.classList.add("display-none")
      newContractSection.classList.remove("display-none")
    })
  }
  backBtn.forEach(function (btn) {
    return btn.addEventListener("click", function () {
      newContractSection.classList.add("display-none")
      contractsTable.classList.remove("display-none")

      var buttonTargets = Array.from(document.querySelectorAll(".preview-toggler button"))
      var contentTargets = Array.from(document.querySelectorAll(".new-contract-panel"))
      var btn = document.querySelector("#contract-edit-btn")
      window.returnToEditorPanel(btn, buttonTargets, contentTargets)
    })
  })
}


window.initSelectContract = function (shortcodeValues, shortcodeRegex) {
  var contractDropdown = document.querySelector("select[name='user[contracts]']")
  var previewText = document.getElementById("preview-text")
  var contractIdParam = document.querySelector("input[name='user_contract[contract_template_id]']")

  if (contractDropdown) {
    var selectedOptionDataAttributes = contractDropdown.selectedOptions[0].dataset
    var currentContractId = selectedOptionDataAttributes["id"]
    var unfilteredContent = selectedOptionDataAttributes.unfiltered_content

    var missing_shortcodes_inputs = Array.prototype.slice.call(document.querySelectorAll(".replacement-shortcode"))
    window.generateShortCodeErrors(shortcodeValues, unfilteredContent, missing_shortcodes_inputs, currentContractId)
    var contractContent = selectedOptionDataAttributes.content
    contractIdParam.value = selectedOptionDataAttributes.id
    previewText.innerHTML = contractContent

    contractDropdown.addEventListener("change", function() {
      selectedOptionDataAttributes = contractDropdown.selectedOptions[0].dataset
      currentContractId = selectedOptionDataAttributes["id"]
      contractContent = selectedOptionDataAttributes.content
      contractIdParam.value = selectedOptionDataAttributes.id
      previewText.innerHTML = contractContent
      unfilteredContent = selectedOptionDataAttributes.unfiltered_content

      window.generateShortCodeErrors(shortcodeValues, unfilteredContent, missing_shortcodes_inputs, currentContractId)
    })
    missing_shortcodes_inputs.forEach(function(shortcode_input){
      shortcode_input.addEventListener("change", function() {
        currentContractId = selectedOptionDataAttributes["id"]
        window.generateShortCodeErrors(shortcodeValues, unfilteredContent, missing_shortcodes_inputs, currentContractId)
      })
    })
  }
}

window.generateShortCodeErrors = function (shortcodeValues, content, missingShortcodeInputs, currentContractId) {
  var invalidShortcodes = new Set()
  var filteredShortcodeInputs = missingShortcodeInputs.filter(function(shortcodeInput) {
    return shortcodeInput.closest(".missing-shortcode-container").id === currentContractId
  })

  var missing_shortcodes = document.getElementById("missing_shortcodes")
  var no_missing_shortcodes = document.getElementById("no_missing_shortcodes")

  if (filteredShortcodeInputs.length === 0) {
    missing_shortcodes.classList.add("display-none")
    no_missing_shortcodes.classList.remove("display-none")
  }
  else {
    missing_shortcodes.classList.remove("display-none")
    no_missing_shortcodes.classList.add("display-none")
  }

  filteredShortcodeInputs.forEach(function(shortcode_input) {
    var shortcode = shortcode_input.name.replace("shortcode-", "")
    var formatted_shortcode = "{{" + shortcode + "}}"
    if(content.match(shortcode) !== null){
      content.match(shortcode).map(function(shortcode){
        if((shortcodeValues[shortcode] === "" || shortcodeValues[shortcode] == null) && shortcode_input.value === "") {
          invalidShortcodes.add(formatted_shortcode)
        }
      })
    }
  })

  var errorNotice = document.getElementById("send-invalid-shortcodes")
  window.renderErrorMessages(errorNotice, invalidShortcodes)

  var sendButton = document.querySelector(".send-button")
  if(sendButton){
    sendButton.disabled = invalidShortcodes.size > 0
  }

  var missing_shortcode_containers = Array.prototype.slice.call(document.querySelectorAll(".missing-shortcode-container"))
  missing_shortcode_containers.forEach(function(shortcodeContainer){
    var input = shortcodeContainer.querySelector("input")
    if(shortcodeContainer.id === currentContractId) {
      shortcodeContainer.style.display = ""
      input.disabled = false
    } else {
      shortcodeContainer.style.display = "none"
      input.disabled = true
    }
  })
}

window.renderErrorMessages = function (errorNotice, invalidShortcodes) {
  if (invalidShortcodes.size > 0) {
    errorNotice.classList.remove("display-none")
    errorNotice.querySelector("p").innerText = invalidShortcodes.join(", ")
  } else {
    errorNotice.classList.add("display-none")
  }
}

window.returnToEditorPanel = function (btn, buttonTargets, contentTargets) {
  buttonTargets.forEach(function (btn) {
    btn.classList.remove("active")
  })
  btn.classList.add("active")
  contentTargets.forEach(function (content) {
    content.classList.add("display-none")
  })
  var contentId = btn.dataset.contentid
  var contentTarget = document.getElementById(contentId)
  contentTarget.classList.remove("display-none")
}
;


//
// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// PLEASE AVOID ADDING NEW JS TO THIS FILE. NEW JS SHOULD GO IN THE `app/assets/webpack` DIR.
// Ask in Engineering if you don't think that's possible for your use case.
//
// manual asset compilation trigger - 19
//













window.$.ajaxSetup({ cache: false })

window.add_auth_token = function (data_to_merge, string_to_append) {
  var csrf_token = $("meta[name=csrf-token]").attr("content")

  var obj = { csrf_param: encodeURIComponent(csrf_token) }
  if (data_to_merge) {
    return (string_to_append ? string_to_append + "&" : "") + $.param($.extend(data_to_merge, obj))
  } else {
    return obj
  }
}

window.do_once = function (key, callback) {
  if ($.cookie(key) === "true") { return }
  $.cookie(key, "true", { expires: 7300, path: "/" })
  if (callback) { return callback() }
}

window.maininit = function () {
  $(".on-image-error-js").each(function () {
    var $that = $(this)
    $that.on("error", function () { window.on_image_error(this, $that.is(".on-image-error-also-bg-js")) })
  })

  $("[data-method]:not([data-remote])").ctrlClickPost()

  // load tooltips for form elements
  $("[tooltip]").loadTooltips({ trigger: "hover" })

  // for tooltips triggered to show briefly on load - show at load
  // then hide, a few seconds later
  setTimeout(function () {
    $("[data-init-tooltip]").tooltip("show")

    setTimeout(function () {
      $("[data-init-tooltip]").tooltip("destroy")
    }, 2000)
  }, 2000)

  $(".first-field-js").focus()

  $(".alert").alert().on("closed", function () {
    var only_do_once = $(this).find("[data-show-once]").attr("data-show-once")
    if (only_do_once) {
      window.do_once(only_do_once)
    }
  })

  $(".hoverable-trigger").hoverableTrigger()
  $(".hoverable").hoverable()

  $(".copy-to-clipboard").clippable().on("mouseover", function () {
    $(this).tooltip("show")
  }).on("mouseout", function () {
    $(this).tooltip("hide")
  }).on("aftercopy", function (event) {
    var copy_item = $(event.target)
    if (copy_item.data("clipboard-row-class")) {
      copy_item.parents("tr").first().addClass(copy_item.data("clipboard-row-class"))
    }
    if (copy_item.data("copied-hint")) {
      copy_item.tooltip("destroy").data("title", copy_item.data("copied-hint")).tooltip({ trigger: "manual", container: "body", html: true }).tooltip("show")
    }
  }).tooltip({ trigger: "manual", container: "body", html: true })

  $("input.selectable").click(function () {
    var t = $(this)
    t.select()
    if (t.data("clipboard-row-class")) {
      t.parents("tr").first().addClass(t.data("clipboard-row-class"))
    }
  }).keydown(function () { return false })

  // $FlowFixMe this is undefined now for some reason
  Mousetrap.bindGlobal("mod+s", function () {
    var save_button = $(".btn-save, .btn-action-primary[value=Save]").eq(0)
    if (save_button.length) {
      save_button.click()
      return false
    }
  })

  $("[data-absorbs-querystring-on-load]").each(function () {
    var $exportButton = $(this)
    window.setQuerystringParamsToLink.call(this)
    if ($exportButton.attr("data-querystring-absorb-removes-disable")) {
      $exportButton.removeAttr("disabled")
    }
  })
}

$(document).ready(function () {
  // Firefox has a bug where the ondrop event will bubble up and eventually redirect
  // to the data contained in it.
  // EG. if you drag and drop a 'schedule' with an id of '12412412', it will redirect you
  // to the octect of that schedule id.
  window.addEventListener("drop", function (e) {
    if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
      if (e.preventDefault) { e.preventDefault() }
      if (e.stopPropagation) { e.stopPropagation() }
    }
  })

  // when a field that's failed validation is changed, remove the red highlighting
  $(document).on("input", ".field_with_errors > input, .field_with_errors > textarea, .field_with_errors > select", function () {
    var input = $(this)
    var parent = input.parent(".field_with_errors")
    if (parent.length && parent.children().length === 1) {
      parent.replaceWith(this)
      input.focus()
    }
  })

  // hide a row when it is destroyed by a remote link_to
  $(document).on("ajax:success", 'a[data-method="delete"]', function () {
    var clicked_item = $(this)
    if (clicked_item.parents("tr").length) {
      clicked_item.parents("tr").first().hide("slow", function () { $(this).remove() })
    } else if (clicked_item.parents("li").length) {
      clicked_item.parents("li").remove()
    } else if (clicked_item.parents(".deleteable-row-js").length) {
      var reload_parent = clicked_item.parents(".reload-on-empty-list-js")

      clicked_item.parents(".deleteable-row-js").first().hide("slow", function () {
        $(this).remove()

        if (reload_parent.length && !reload_parent.find(".deleteable-row-js").length) {
          window.location.reload()
        }
      })
    }
  })

  $(document).on("click", "a.format-export-download", function () {
    return window.get_file_to_download_with_spinner(this)
  })

  $(document).on("click", "a.format-export-download-slow", function () {
    return window.trigger_to_download_later(this)
  })

  $(document).on("click", "[data-requires-credit-card=true]", function (e) {
    if ($(this).parents("body").find(".alert-disabled-without-billing").show("fast").length) {
      e.stopImmediatePropagation()
      return false
    }
  })

  // http://getbootstrap.com/2.3.2/javascript.html#buttons
  $(document).on("click", "[data-loading-text]", function () {
    var clicked = $(this)
    var form = clicked.parents("form")[0]

    if (form && form.checkValidity && !form.checkValidity()) { return } // if we can check validation client side, and the form is invalid, don't disable the button

    $("<input>").attr({ type: "hidden", name: "original_commit" }).val(clicked.val()).insertBefore(clicked)
    clicked.button("loading")
  })

  function setChosenHeightBasedOnContents() {
    var chosen_floatover_parent = $(this.target).parents(".floatover-chosen")
    chosen_floatover_parent.css("height", chosen_floatover_parent.find(".chosen-container").height())
  }

  $(document).on("chosen:showing_dropdown", setChosenHeightBasedOnContents).on("chosen:hiding_dropdown", setChosenHeightBasedOnContents)

  // enables us to have an item inside a <label> that is a link but does not toggle the label's checkbox
  // if this item is an <a> then we still go to the link in a new tab
  // http://stackoverflow.com/questions/1098269/how-to-prevent-checkbox-check-when-clicking-on-link-inside-label
  $(document).on("click", "label .prevent-default-on-label", function () {
    var clicked_ele = $(this)

    if (clicked_ele.is("a")) {
      window.open(clicked_ele.attr("href")).focus()
    }

    return false
  })

  $(document).on("click", ".close-help-box-js[data-help-box-cookie-id]", function () {
    if (window.current_user.id) {
      $.cookie("help-dismissed-" + $(this).data("help-box-cookie-id") + "-" + window.current_user.id, true, { expires: 10000 })
    }
    $(this).closest($(this).data("target")).hide("fast")
  })

  window.init_video_colorbox()
  window.reposition_roster_bar()
})

window.reposition_roster_bar = function reposition_roster_bar() {
  // get height required
  var header = $("header.navbar").last()
  if (header.length < 1) { // do nothing if there are no headers
    return
  }
  var height = header.outerHeight(true)
  $(".panda_roster_container .sidebar").css({ top: height })
  $(".sidebar.data-stream-sidebar").css({ top: height })
}

window.init_video_colorbox = function (globalEventname) {
  if (!$.colorbox) {
    return
  }

  $.colorbox.remove() // Wipe all colorbox items from entire document

  $(".btn-video").each(function () {
    var btn = $(this)
    var eventname = globalEventname || btn.data("video-eventname") || "HomepageVideo"
    btn.colorbox({
      iframe: true,
      innerWidth: 853,
      innerHeight: 480,
      fixed: true,
      onOpen: function () {
        window._gaq && window._gaq.push(["_trackEvent", eventname, "Start Watching"])
      },
      onClosed: function () {
        $("#modalStartDemo").modal().modal("show")
        window._gaq && window._gaq.push(["_trackEvent", eventname, "Finished Watching"])
      },
    })
  })
  if (window.querystring_get("video")) {
    $(".btn-video").click()
  }
}

// This tracking is duplicated for the mobile app in mobile_app/track_event.js
// Keep this in mind if you edit this code.
window.trackEvent = function (name, data, opts) {
  window.Intercom && window.Intercom("trackEvent", name, data)

  // https://docs.intercom.io/install-on-your-web-product/intercom-javascript-api - call 'update' to re-check for new messages. needs slight delay after event being received.
  // Note that Intercom("update") is throttled at 20 calls per half hour. https://developers.intercom.com/docs/intercom-javascript
  _.delay(function () {
    window.Intercom && window.Intercom("update")
  }, 1000)

  if (!window.Intercom && !window.analytics && !window.ci_mode) {
    console.log("trackEvent called " + name)
    console.log("trackEvent call with data " + JSON.stringify(data))
    console.log("trackEvent call with opts " + JSON.stringify(opts))
  }
}

window.sanitizeTextForHtml = function (text) {
  // NOTE since this function may be called with
  // text that has been previously escaped,
  // we unescape the text before escaping to
  // normalize results
  return _.escape(_.unescape(text))
}

window.yeet = function (text) {
  text = text || "YEET"

  var node = document.createElement("div")
  node.className = "yeet"
  node.style.position = "absolute"
  node.style.width = "100%"
  node.style.height = "100%"
  node.style.top = "0"
  node.style.zIndex = "99999"
  node.style.left = "0"
  node.style.display = "flex"
  node.style.overflow = "hidden"
  node.style.justifyContent = "center"
  node.style.alignItems = "center"
  node.style.fontSize = "5rem"
  node.style.fontWeight = "900"
  node.style.backgroundColor = "rgba(255,255,255,0.4)"
  var textnode = document.createTextNode(text)
  node.appendChild(textnode)
  setTimeout(function () {
    node.remove()
  }, 3000)
  document.body && document.body.appendChild(node)
  window.confetti(".yeet", true)
}
;
